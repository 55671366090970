import { IResourceComponentsProps, HttpError, CrudFilters } from '@pankod/refine-core';
import {
  List,
  Table,
  useTable,
  Row,
  Col,
  Space,
  ShowButton,
  DateField,
  FormProps,
  Form,
  Input,
  Button,
  Icons,
} from '@pankod/refine-antd';
import { PAGINATION_FIELDS } from 'common/constant';
import { IUser, UserFilterVariables, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { Incentive } from 'api';

export const RefereeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<IUser, HttpError, UserFilterVariables>({
    dataProviderName: 'gql',
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyReferees',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialPageSize: 25,
    permanentSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q } = params;

      filters.push({
        field: 'username',
        operator: 'contains',
        value: q,
      });

      filters.push({
        field: 'email',
        operator: 'contains',
        value: q,
      });

      return filters;
    },
  });

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} lg={24} xs={24}>
        <Filter formProps={searchFormProps} />
      </Col>
      <Col span={24}>
        <List>
          <Table {...tableProps} rowKey="id">
            <Table.Column<Incentive> key="username" dataIndex="username" title="Username" />
            <Table.Column<Incentive> key="email" dataIndex="email" title="Email" />
            <Table.Column<Incentive> key="firstName" dataIndex="firstName" title="First Name" />
            <Table.Column<Incentive> key="lastName" dataIndex="lastName" title="Last Name" />
            <Table.Column
              title="Created At"
              dataIndex="createdAt"
              key="createdAt"
              render={(value) => <DateField value={value} format="LL" />}
            />
            <Table.Column<Incentive>
              key="status"
              dataIndex="status"
              title="Status"
              render={(_, record) => (record?.status ? 'Enabled' : 'Disabled')}
            />
            <Table.Column<Incentive>
              title="Action"
              render={(_, record) => (
                <Space>
                  <ShowButton hideText recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col span={20}>
          <Form.Item label={'Search'} name="q">
            <Input placeholder="Search Username or Email" prefix={<Icons.SearchOutlined />} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button style={{ width: '100%' }} htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
