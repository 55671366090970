import { message } from '@pankod/refine-antd';
import { AuthProvider as Provider } from '@pankod/refine-core';
import { GraphQLClient, gql } from '@pankod/refine-graphql';

const TOKEN_KEY = 'reskill-marketing';

export interface Identity {
  username: string;
  role: 'user' | 'admin';
}

interface AuthProviderProps extends Provider {
  setAuth: (auth: any) => void;
  removeAuth: () => void;
}

export const authProvider = (gqlClient: GraphQLClient): AuthProviderProps => {
  const removeAuth = () => {
    localStorage.removeItem(TOKEN_KEY);
    gqlClient.setHeaders({ Authorization: '' });
  };

  const setAuth = (auth: any) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(auth));
    gqlClient.setHeaders({ Authorization: `Bearer ${auth.accessToken}` });
  };

  const defaultProvider = {
    login: async ({ username, password }: { username: string; password: string }) => {
      try {
        const mutation = gql`
          mutation login($username: String!, $password: String!) {
            login(data: { username: $username, password: $password }) {
              accessToken
              refreshToken
            }
          }
        `;

        const response = await gqlClient.request(mutation, {
          username,
          password,
        });

        setAuth(response.login);

        message.success('Login success!');

        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject({
          name: 'Error',
          message: 'Login Failed!',
        });
      }
    },
    logout: () => {
      removeAuth();
      return Promise.resolve();
    },
    checkError: (error) => {
      const isUnauthorizedError = (error?.response?.errors || []).some((err) => {
        return err?.extensions?.response?.statusCode === 401;
      });

      if (isUnauthorizedError) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    checkAuth: () => {
      const token = localStorage.getItem(TOKEN_KEY);

      if (token) {
        const parsedToken = JSON.parse(token);

        gqlClient.setHeaders({
          Authorization: `Bearer ${parsedToken.accessToken}`,
        });

        return Promise.resolve();
      }

      removeAuth();

      return Promise.reject();
    },
    getPermissions: () => {
      // TODO:

      return Promise.resolve();
    },
    getUserIdentity: async () => {
      // TODO:

      const token = localStorage.getItem(TOKEN_KEY);

      if (!token) {
        return Promise.reject();
      }

      return Promise.resolve({});
    },
  };

  return {
    ...defaultProvider,
    setAuth,
    removeAuth,
  };
};
