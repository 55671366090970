import { Row, Col, Card, Typography, DateField, Form, Button, DatePicker } from '@pankod/refine-antd';
import { useCustom } from '@pankod/refine-core';
import dayjs from 'dayjs';
import { useState } from 'react';
const { Title, Paragraph } = Typography;

export const DashboardPage: React.FC = () => {
  const [dates, setDates] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);

  let queryResult = useCustom({
    url: '',
    method: 'get',
    dataProviderName: 'gql',
    metaData: {
      operation: 'getMyRefereesSummary',
      fields: ['totalReferees', 'totalRefereesSubscribed'],
      variables: {
        where: {
          value: {
            createdAt: {
              gte: dates[0],
              lte: dates[1],
            },
          },
          type: 'GetMyRefereesSummaryInput',
        },
      },
    },
    queryOptions: {
      enabled: dates.length > 0,
    },
  });

  const summary = queryResult?.data?.data;

  const [form] = Form.useForm();

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Form
              layout="inline"
              form={form}
              initialValues={{
                dates,
              }}
              onFinish={(values) => {
                setDates(values.dates);
              }}
            >
              <Form.Item label={'Search Date'} name="dates">
                <DatePicker.RangePicker style={{ width: '100%' }} allowClear={false} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={queryResult.isRefetching}>
                  Search
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>

      <Col xl={7} lg={12} md={24} sm={24} xs={24}>
        <Card bodyStyle={{ height: 150 }} style={{ backgroundColor: 'white' }}>
          <Title level={3}>Hi there!</Title>
          Today is: <br />
          <DateField value={new Date()} format="LLL" />
        </Card>
      </Col>

      <Col xl={7} lg={12} md={24} sm={24} xs={24}>
        <Card bodyStyle={{ height: 150 }} style={{ backgroundColor: '#3d335b' }}>
          <Title style={{ color: 'white' }} level={3}>
            Total Referees
          </Title>
          <Paragraph style={{ color: 'white' }}>{summary?.totalReferees || 0}</Paragraph>
        </Card>
      </Col>

      <Col xl={7} lg={12} md={24} sm={24} xs={24}>
        <Card bodyStyle={{ height: 150 }} style={{ backgroundColor: '#332a4b' }}>
          <Title style={{ color: 'white' }} level={3}>
            Total Referees Subscribed
          </Title>
          <Paragraph style={{ color: 'white' }}>{summary?.totalRefereesSubscribed || 0}</Paragraph>
        </Card>
      </Col>
    </Row>
  );
};
