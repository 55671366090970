import { IResourceComponentsProps, useCustom } from '@pankod/refine-core';
import { Typography, Row, Col, Card, Space, DateField } from '@pankod/refine-antd';
import { RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { useParams } from 'react-router-dom';
import { SubscriptionList } from './subscription/list';

export const RefereeShow: React.FC<IResourceComponentsProps> = () => {
  const { id } = useParams();

  let queryResult = useCustom({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getUser',
      fields: [...RESOURCE_FIELDS],
      variables: {
        id: {
          value: id,
          type: 'BigInt',
          required: true,
        },
      },
    },
    queryOptions: {
      enabled: true,
    },
  });

  const user = queryResult?.data?.data;

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} xs={24}>
          <Card style={{ height: '100%' }}>
            <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
              <Typography.Text>
                <b>Username</b>: {user?.username}
              </Typography.Text>
              <Typography.Text>
                <b>First Name</b>: {user?.firstName}
              </Typography.Text>
              <Typography.Text>
                <b>Last Name</b>: {user?.lastName}
              </Typography.Text>
              <Typography.Text>
                <b>Date Of Birth</b>: {user?.dateOfBirth}
              </Typography.Text>
              <Typography.Text>
                <b>Email</b>: {user?.email}
              </Typography.Text>
              <Typography.Text>
                <b>Phone</b>: {user?.phone}
              </Typography.Text>
              <Typography.Text>
                <b>Created At</b>: <DateField value={user?.createdAt} format="LL" />
              </Typography.Text>
            </Space>
          </Card>
        </Col>

        <Col xl={16} xs={24}>
          <SubscriptionList userId={id} />
        </Col>
      </Row>
    </>
  );
};
