import { useGetLocale, useSetLocale } from '@pankod/refine-core';
import { AntdLayout, Space, Menu, Button, Icons, Dropdown, Avatar } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

const { DownOutlined } = Icons;

const languages = {
  en: 'English',
  sc: '简体',
};

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          // icon={
          //   <span style={{ marginRight: 8 }}>
          //     <Avatar size={16} src={`/images/flags/${lang}.svg`} />
          //   </span>
          // }
        >
          {lang === 'en' ? 'English' : '简体'}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '48px',
        backgroundColor: '#FFF',
      }}
    >
      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            {/* <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} /> */}
            {/* @ts-ignore */}
            {languages[currentLocale]}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </AntdLayout.Header>
  );
};
