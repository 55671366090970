import { HttpError, IResourceComponentsProps } from '@pankod/refine-core';
import { Table, List, useTable, DateField } from '@pankod/refine-antd';
import { PAGINATION_FIELDS } from 'common/constant';
import { Subscription } from 'api';
import { startCase } from 'lodash';

export const SubscriptionList = ({ userId }: { userId: string }) => {
  const { tableProps } = useTable<Subscription, HttpError>({
    dataProviderName: 'gql',
    resource: 'subscriptions',
    metaData: {
      items: ['id', 'packageType', 'expiredAt', 'subscribedAt', 'status'],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [
      {
        field: 'userId',
        operator: 'eq',
        value: userId,
      },
    ],
  });

  return (
    <List
      title="Subscription history"
      pageHeaderProps={{
        extra: <></>,
      }}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column key="packageType" dataIndex="packageType" title="Package Type" />
        <Table.Column
          key="expiredAt"
          dataIndex="expiredAt"
          title="Expired At"
          render={(value) => <DateField value={value} format="LL" />}
        />
        <Table.Column
          key="subscribedAt"
          dataIndex="subscribedAt"
          title="Subscribed At"
          render={(value) => <DateField value={value} format="LL" />}
        />
        <Table.Column
          key="status"
          dataIndex="status"
          title="Status"
          render={(value) => (value ? startCase(value) : '-')}
        />
      </Table>
    </List>
  );
};
