import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  Decimal: any;
  JSON: any;
};

export type ActiveChannel = {
  __typename?: 'ActiveChannel';
  id: Scalars['Int'];
  locale: Scalars['String'];
  name: Scalars['String'];
  setDefault: Scalars['Boolean'];
};

export type AdminLoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type AdminUser = {
  __typename?: 'AdminUser';
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  rememberToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAssessment = {
  __typename?: 'AggregateAssessment';
  _avg?: Maybe<AssessmentAvgAggregate>;
  _count?: Maybe<AssessmentCountAggregate>;
  _max?: Maybe<AssessmentMaxAggregate>;
  _min?: Maybe<AssessmentMinAggregate>;
  _sum?: Maybe<AssessmentSumAggregate>;
};

export type AggregateBannerType = {
  __typename?: 'AggregateBannerType';
  _avg?: Maybe<BannerTypeAvgAggregate>;
  _count?: Maybe<BannerTypeCountAggregate>;
  _max?: Maybe<BannerTypeMaxAggregate>;
  _min?: Maybe<BannerTypeMinAggregate>;
  _sum?: Maybe<BannerTypeSumAggregate>;
};

export type AggregateCategory = {
  __typename?: 'AggregateCategory';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
};

export type AggregateCertificate = {
  __typename?: 'AggregateCertificate';
  _avg?: Maybe<CertificateAvgAggregate>;
  _count?: Maybe<CertificateCountAggregate>;
  _max?: Maybe<CertificateMaxAggregate>;
  _min?: Maybe<CertificateMinAggregate>;
  _sum?: Maybe<CertificateSumAggregate>;
};

export type AggregateClassRating = {
  __typename?: 'AggregateClassRating';
  _avg?: Maybe<ClassRatingAvgAggregate>;
  _count?: Maybe<ClassRatingCountAggregate>;
  _max?: Maybe<ClassRatingMaxAggregate>;
  _min?: Maybe<ClassRatingMinAggregate>;
  _sum?: Maybe<ClassRatingSumAggregate>;
};

export type AggregateCourse = {
  __typename?: 'AggregateCourse';
  _avg?: Maybe<CourseAvgAggregate>;
  _count?: Maybe<CourseCountAggregate>;
  _max?: Maybe<CourseMaxAggregate>;
  _min?: Maybe<CourseMinAggregate>;
  _sum?: Maybe<CourseSumAggregate>;
};

export type AggregateIncentive = {
  __typename?: 'AggregateIncentive';
  _avg?: Maybe<IncentiveAvgAggregate>;
  _count?: Maybe<IncentiveCountAggregate>;
  _max?: Maybe<IncentiveMaxAggregate>;
  _min?: Maybe<IncentiveMinAggregate>;
  _sum?: Maybe<IncentiveSumAggregate>;
};

export type AggregateInterestType = {
  __typename?: 'AggregateInterestType';
  _avg?: Maybe<InterestTypeAvgAggregate>;
  _count?: Maybe<InterestTypeCountAggregate>;
  _max?: Maybe<InterestTypeMaxAggregate>;
  _min?: Maybe<InterestTypeMinAggregate>;
  _sum?: Maybe<InterestTypeSumAggregate>;
};

export type AggregateMaterial = {
  __typename?: 'AggregateMaterial';
  _avg?: Maybe<MaterialAvgAggregate>;
  _count?: Maybe<MaterialCountAggregate>;
  _max?: Maybe<MaterialMaxAggregate>;
  _min?: Maybe<MaterialMinAggregate>;
  _sum?: Maybe<MaterialSumAggregate>;
};

export type AggregateNewsUpdate = {
  __typename?: 'AggregateNewsUpdate';
  _avg?: Maybe<NewsUpdateAvgAggregate>;
  _count?: Maybe<NewsUpdateCountAggregate>;
  _max?: Maybe<NewsUpdateMaxAggregate>;
  _min?: Maybe<NewsUpdateMinAggregate>;
  _sum?: Maybe<NewsUpdateSumAggregate>;
};

export type AggregateNormalClass = {
  __typename?: 'AggregateNormalClass';
  _avg?: Maybe<NormalClassAvgAggregate>;
  _count?: Maybe<NormalClassCountAggregate>;
  _max?: Maybe<NormalClassMaxAggregate>;
  _min?: Maybe<NormalClassMinAggregate>;
  _sum?: Maybe<NormalClassSumAggregate>;
};

export type AggregatePackageType = {
  __typename?: 'AggregatePackageType';
  _avg?: Maybe<PackageTypeAvgAggregate>;
  _count?: Maybe<PackageTypeCountAggregate>;
  _max?: Maybe<PackageTypeMaxAggregate>;
  _min?: Maybe<PackageTypeMinAggregate>;
  _sum?: Maybe<PackageTypeSumAggregate>;
};

export type AggregatePodcast = {
  __typename?: 'AggregatePodcast';
  _avg?: Maybe<PodcastAvgAggregate>;
  _count?: Maybe<PodcastCountAggregate>;
  _max?: Maybe<PodcastMaxAggregate>;
  _min?: Maybe<PodcastMinAggregate>;
  _sum?: Maybe<PodcastSumAggregate>;
};

export type AggregatePopupBanner = {
  __typename?: 'AggregatePopupBanner';
  _avg?: Maybe<PopupBannerAvgAggregate>;
  _count?: Maybe<PopupBannerCountAggregate>;
  _max?: Maybe<PopupBannerMaxAggregate>;
  _min?: Maybe<PopupBannerMinAggregate>;
  _sum?: Maybe<PopupBannerSumAggregate>;
};

export type AggregatePushNotification = {
  __typename?: 'AggregatePushNotification';
  _avg?: Maybe<PushNotificationAvgAggregate>;
  _count?: Maybe<PushNotificationCountAggregate>;
  _max?: Maybe<PushNotificationMaxAggregate>;
  _min?: Maybe<PushNotificationMinAggregate>;
  _sum?: Maybe<PushNotificationSumAggregate>;
};

export type AggregateQuestion = {
  __typename?: 'AggregateQuestion';
  _avg?: Maybe<QuestionAvgAggregate>;
  _count?: Maybe<QuestionCountAggregate>;
  _max?: Maybe<QuestionMaxAggregate>;
  _min?: Maybe<QuestionMinAggregate>;
  _sum?: Maybe<QuestionSumAggregate>;
};

export type AggregateSystemConfig = {
  __typename?: 'AggregateSystemConfig';
  _avg?: Maybe<SystemConfigAvgAggregate>;
  _count?: Maybe<SystemConfigCountAggregate>;
  _max?: Maybe<SystemConfigMaxAggregate>;
  _min?: Maybe<SystemConfigMinAggregate>;
  _sum?: Maybe<SystemConfigSumAggregate>;
};

export type AggregateTagging = {
  __typename?: 'AggregateTagging';
  _avg?: Maybe<TaggingAvgAggregate>;
  _count?: Maybe<TaggingCountAggregate>;
  _max?: Maybe<TaggingMaxAggregate>;
  _min?: Maybe<TaggingMinAggregate>;
  _sum?: Maybe<TaggingSumAggregate>;
};

export type AggregateTransaction = {
  __typename?: 'AggregateTransaction';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
};

export type AggregateUserIncentive = {
  __typename?: 'AggregateUserIncentive';
  _avg?: Maybe<UserIncentiveAvgAggregate>;
  _count?: Maybe<UserIncentiveCountAggregate>;
  _max?: Maybe<UserIncentiveMaxAggregate>;
  _min?: Maybe<UserIncentiveMinAggregate>;
  _sum?: Maybe<UserIncentiveSumAggregate>;
};

export type AggregateUserSetting = {
  __typename?: 'AggregateUserSetting';
  _avg?: Maybe<UserSettingAvgAggregate>;
  _count?: Maybe<UserSettingCountAggregate>;
  _max?: Maybe<UserSettingMaxAggregate>;
  _min?: Maybe<UserSettingMinAggregate>;
  _sum?: Maybe<UserSettingSumAggregate>;
};

export type AggregateVertical = {
  __typename?: 'AggregateVertical';
  _avg?: Maybe<VerticalAvgAggregate>;
  _count?: Maybe<VerticalCountAggregate>;
  _max?: Maybe<VerticalMaxAggregate>;
  _min?: Maybe<VerticalMinAggregate>;
  _sum?: Maybe<VerticalSumAggregate>;
};

export type AggregateVideoRepo = {
  __typename?: 'AggregateVideoRepo';
  _avg?: Maybe<VideoRepoAvgAggregate>;
  _count?: Maybe<VideoRepoCountAggregate>;
  _max?: Maybe<VideoRepoMaxAggregate>;
  _min?: Maybe<VideoRepoMinAggregate>;
  _sum?: Maybe<VideoRepoSumAggregate>;
};

export type Answer = {
  __typename?: 'Answer';
  _count?: Maybe<AnswerCount>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  questionAnswers: Array<QuestionAnswer>;
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAssessmentRecord: Array<UserAssessmentRecord>;
};

export type AnswerQuestionAnswersArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};

export type AnswerUserAssessmentRecordArgs = {
  cursor?: InputMaybe<UserAssessmentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserAssessmentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserAssessmentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserAssessmentRecordWhereInput>;
};

export type AnswerCount = {
  __typename?: 'AnswerCount';
  questionAnswers: Scalars['Int'];
  userAssessmentRecord: Scalars['Int'];
};

export type AnswerCreateNestedOneWithoutQuestionAnswersInput = {
  connect?: InputMaybe<AnswerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<AnswerCreateWithoutQuestionAnswersInput>;
};

export type AnswerCreateNestedOneWithoutUserAssessmentRecordInput = {
  connect?: InputMaybe<AnswerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerCreateOrConnectWithoutUserAssessmentRecordInput>;
  create?: InputMaybe<AnswerCreateWithoutUserAssessmentRecordInput>;
};

export type AnswerCreateOrConnectWithoutQuestionAnswersInput = {
  create: AnswerCreateWithoutQuestionAnswersInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateOrConnectWithoutUserAssessmentRecordInput = {
  create: AnswerCreateWithoutUserAssessmentRecordInput;
  where: AnswerWhereUniqueInput;
};

export type AnswerCreateWithoutQuestionAnswersInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isAnswer: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecord?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutAnswersInput>;
};

export type AnswerCreateWithoutUserAssessmentRecordInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isAnswer: Scalars['Int'];
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutAnswerInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAnswer?: InputMaybe<SortOrder>;
  questionAnswers?: InputMaybe<QuestionAnswerOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userAssessmentRecord?: InputMaybe<UserAssessmentRecordOrderByRelationAggregateInput>;
};

export type AnswerRelationFilter = {
  is?: InputMaybe<AnswerWhereInput>;
  isNot?: InputMaybe<AnswerWhereInput>;
};

export type AnswerUpdateOneRequiredWithoutQuestionAnswersNestedInput = {
  connect?: InputMaybe<AnswerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<AnswerCreateWithoutQuestionAnswersInput>;
  update?: InputMaybe<AnswerUpdateWithoutQuestionAnswersInput>;
  upsert?: InputMaybe<AnswerUpsertWithoutQuestionAnswersInput>;
};

export type AnswerUpdateOneRequiredWithoutUserAssessmentRecordNestedInput = {
  connect?: InputMaybe<AnswerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AnswerCreateOrConnectWithoutUserAssessmentRecordInput>;
  create?: InputMaybe<AnswerCreateWithoutUserAssessmentRecordInput>;
  update?: InputMaybe<AnswerUpdateWithoutUserAssessmentRecordInput>;
  upsert?: InputMaybe<AnswerUpsertWithoutUserAssessmentRecordInput>;
};

export type AnswerUpdateWithoutQuestionAnswersInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isAnswer?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecord?: InputMaybe<UserAssessmentRecordUpdateManyWithoutAnswersNestedInput>;
};

export type AnswerUpdateWithoutUserAssessmentRecordInput = {
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  isAnswer?: InputMaybe<Scalars['Int']>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutAnswerNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AnswerUpsertWithoutQuestionAnswersInput = {
  create: AnswerCreateWithoutQuestionAnswersInput;
  update: AnswerUpdateWithoutQuestionAnswersInput;
};

export type AnswerUpsertWithoutUserAssessmentRecordInput = {
  create: AnswerCreateWithoutUserAssessmentRecordInput;
  update: AnswerUpdateWithoutUserAssessmentRecordInput;
};

export type AnswerWhereInput = {
  AND?: InputMaybe<Array<AnswerWhereInput>>;
  NOT?: InputMaybe<Array<AnswerWhereInput>>;
  OR?: InputMaybe<Array<AnswerWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  isAnswer?: InputMaybe<IntFilter>;
  questionAnswers?: InputMaybe<QuestionAnswerListRelationFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userAssessmentRecord?: InputMaybe<UserAssessmentRecordListRelationFilter>;
};

export type AnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type AppRemoteConfig = {
  __typename?: 'AppRemoteConfig';
  activeChannels: Array<ActiveChannel>;
  activeVerticals: Array<Vertical>;
  appUpdateAvailability: AppUpdateAvailability;
  defaultPackageSettings: PackageSettings;
  featureFlags: FeatureFlags;
  shouldPromptForAppUpdate: Scalars['Boolean'];
  urls?: Maybe<Urls>;
};

export enum AppUpdateAvailability {
  Available = 'Available',
  ForceUpgradeRequired = 'ForceUpgradeRequired',
  NotAvailable = 'NotAvailable',
}

export type Assessment = {
  __typename?: 'Assessment';
  _count?: Maybe<AssessmentCount>;
  assessmentQuestions: Array<AssessmentQuestion>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hasExceedAttempt: Scalars['Boolean'];
  hasPassed: Scalars['Boolean'];
  id: Scalars['BigInt'];
  liveClassAssessments: Array<LiveClassAssessment>;
  passScore: Scalars['Int'];
  questionWithAnswers: Array<QuestionWithAnswers>;
  status: Scalars['Int'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAssessmentRecords: Array<UserAssessmentRecord>;
  userAssessmentResults: Array<UserAssessmentResult>;
};

export type AssessmentAssessmentQuestionsArgs = {
  cursor?: InputMaybe<AssessmentQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssessmentQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssessmentQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentQuestionWhereInput>;
};

export type AssessmentHasExceedAttemptArgs = {
  liveClassId: Scalars['Float'];
};

export type AssessmentHasPassedArgs = {
  liveClassId: Scalars['Float'];
};

export type AssessmentLiveClassAssessmentsArgs = {
  cursor?: InputMaybe<LiveClassAssessmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<LiveClassAssessmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LiveClassAssessmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassAssessmentWhereInput>;
};

export type AssessmentUserAssessmentRecordsArgs = {
  cursor?: InputMaybe<UserAssessmentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserAssessmentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserAssessmentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserAssessmentRecordWhereInput>;
};

export type AssessmentUserAssessmentResultsArgs = {
  cursor?: InputMaybe<UserAssessmentResultWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserAssessmentResultScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserAssessmentResultOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserAssessmentResultWhereInput>;
};

export type AssessmentAvgAggregate = {
  __typename?: 'AssessmentAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  passScore?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type AssessmentAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AssessmentCount = {
  __typename?: 'AssessmentCount';
  assessmentQuestions: Scalars['Int'];
  liveClassAssessments: Scalars['Int'];
  userAssessmentRecords: Scalars['Int'];
  userAssessmentResults: Scalars['Int'];
};

export type AssessmentCountAggregate = {
  __typename?: 'AssessmentCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  passScore: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type AssessmentCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AssessmentCreateInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionCreateNestedManyWithoutAssessmentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentCreateNestedManyWithoutAssessmentInput>;
  passScore: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutAssessmentInput>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultCreateNestedManyWithoutAssessmentsInput>;
};

export type AssessmentCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  passScore: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentCreateNestedOneWithoutAssessmentQuestionsInput = {
  connect?: InputMaybe<AssessmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssessmentCreateOrConnectWithoutAssessmentQuestionsInput>;
  create?: InputMaybe<AssessmentCreateWithoutAssessmentQuestionsInput>;
};

export type AssessmentCreateNestedOneWithoutUserAssessmentRecordsInput = {
  connect?: InputMaybe<AssessmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssessmentCreateOrConnectWithoutUserAssessmentRecordsInput>;
  create?: InputMaybe<AssessmentCreateWithoutUserAssessmentRecordsInput>;
};

export type AssessmentCreateOrConnectWithoutAssessmentQuestionsInput = {
  create: AssessmentCreateWithoutAssessmentQuestionsInput;
  where: AssessmentWhereUniqueInput;
};

export type AssessmentCreateOrConnectWithoutUserAssessmentRecordsInput = {
  create: AssessmentCreateWithoutUserAssessmentRecordsInput;
  where: AssessmentWhereUniqueInput;
};

export type AssessmentCreateWithoutAssessmentQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentCreateNestedManyWithoutAssessmentInput>;
  passScore: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutAssessmentInput>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultCreateNestedManyWithoutAssessmentsInput>;
};

export type AssessmentCreateWithoutUserAssessmentRecordsInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionCreateNestedManyWithoutAssessmentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentCreateNestedManyWithoutAssessmentInput>;
  passScore: Scalars['Int'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultCreateNestedManyWithoutAssessmentsInput>;
};

export type AssessmentGroupBy = {
  __typename?: 'AssessmentGroupBy';
  _avg?: Maybe<AssessmentAvgAggregate>;
  _count?: Maybe<AssessmentCountAggregate>;
  _max?: Maybe<AssessmentMaxAggregate>;
  _min?: Maybe<AssessmentMinAggregate>;
  _sum?: Maybe<AssessmentSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  passScore: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentMaxAggregate = {
  __typename?: 'AssessmentMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  passScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AssessmentMinAggregate = {
  __typename?: 'AssessmentMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  passScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AssessmentOrderByWithAggregationInput = {
  _avg?: InputMaybe<AssessmentAvgOrderByAggregateInput>;
  _count?: InputMaybe<AssessmentCountOrderByAggregateInput>;
  _max?: InputMaybe<AssessmentMaxOrderByAggregateInput>;
  _min?: InputMaybe<AssessmentMinOrderByAggregateInput>;
  _sum?: InputMaybe<AssessmentSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AssessmentOrderByWithRelationInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentOrderByRelationAggregateInput>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordOrderByRelationAggregateInput>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultOrderByRelationAggregateInput>;
};

export type AssessmentQuestion = {
  __typename?: 'AssessmentQuestion';
  assessment: Assessment;
  assessmentId: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  question: Question;
  questionId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AssessmentQuestionCreateManyAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  questionId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionCreateManyAssessmentInputEnvelope = {
  data: Array<AssessmentQuestionCreateManyAssessmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AssessmentQuestionCreateManyQuestionInput = {
  assessmentId: Scalars['BigInt'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionCreateManyQuestionInputEnvelope = {
  data: Array<AssessmentQuestionCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AssessmentQuestionCreateNestedManyWithoutAssessmentInput = {
  connect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentQuestionCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<AssessmentQuestionCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<AssessmentQuestionCreateManyAssessmentInputEnvelope>;
};

export type AssessmentQuestionCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentQuestionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AssessmentQuestionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AssessmentQuestionCreateManyQuestionInputEnvelope>;
};

export type AssessmentQuestionCreateOrConnectWithoutAssessmentInput = {
  create: AssessmentQuestionCreateWithoutAssessmentInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionCreateOrConnectWithoutQuestionInput = {
  create: AssessmentQuestionCreateWithoutQuestionInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionCreateWithoutAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  question: QuestionCreateNestedOneWithoutAssessmentQuestionsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionCreateWithoutQuestionInput = {
  assessment: AssessmentCreateNestedOneWithoutAssessmentQuestionsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionListRelationFilter = {
  every?: InputMaybe<AssessmentQuestionWhereInput>;
  none?: InputMaybe<AssessmentQuestionWhereInput>;
  some?: InputMaybe<AssessmentQuestionWhereInput>;
};

export type AssessmentQuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AssessmentQuestionOrderByWithRelationInput = {
  assessment?: InputMaybe<AssessmentOrderByWithRelationInput>;
  assessmentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AssessmentQuestionScalarFieldEnum {
  AssessmentId = 'assessmentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  QuestionId = 'questionId',
  UpdatedAt = 'updatedAt',
}

export type AssessmentQuestionScalarWhereInput = {
  AND?: InputMaybe<Array<AssessmentQuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<AssessmentQuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<AssessmentQuestionScalarWhereInput>>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AssessmentQuestionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionUpdateManyWithWhereWithoutAssessmentInput = {
  data: AssessmentQuestionUpdateManyMutationInput;
  where: AssessmentQuestionScalarWhereInput;
};

export type AssessmentQuestionUpdateManyWithWhereWithoutQuestionInput = {
  data: AssessmentQuestionUpdateManyMutationInput;
  where: AssessmentQuestionScalarWhereInput;
};

export type AssessmentQuestionUpdateManyWithoutAssessmentNestedInput = {
  connect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentQuestionCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<AssessmentQuestionCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<AssessmentQuestionCreateManyAssessmentInputEnvelope>;
  delete?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssessmentQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<AssessmentQuestionUpdateWithWhereUniqueWithoutAssessmentInput>>;
  updateMany?: InputMaybe<Array<AssessmentQuestionUpdateManyWithWhereWithoutAssessmentInput>>;
  upsert?: InputMaybe<Array<AssessmentQuestionUpsertWithWhereUniqueWithoutAssessmentInput>>;
};

export type AssessmentQuestionUpdateManyWithoutQuestionNestedInput = {
  connect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssessmentQuestionCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<AssessmentQuestionCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<AssessmentQuestionCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssessmentQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<AssessmentQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<AssessmentQuestionUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<AssessmentQuestionUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<AssessmentQuestionUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type AssessmentQuestionUpdateWithWhereUniqueWithoutAssessmentInput = {
  data: AssessmentQuestionUpdateWithoutAssessmentInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionUpdateWithWhereUniqueWithoutQuestionInput = {
  data: AssessmentQuestionUpdateWithoutQuestionInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionUpdateWithoutAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutAssessmentQuestionsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionUpdateWithoutQuestionInput = {
  assessment?: InputMaybe<AssessmentUpdateOneRequiredWithoutAssessmentQuestionsNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentQuestionUpsertWithWhereUniqueWithoutAssessmentInput = {
  create: AssessmentQuestionCreateWithoutAssessmentInput;
  update: AssessmentQuestionUpdateWithoutAssessmentInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionUpsertWithWhereUniqueWithoutQuestionInput = {
  create: AssessmentQuestionCreateWithoutQuestionInput;
  update: AssessmentQuestionUpdateWithoutQuestionInput;
  where: AssessmentQuestionWhereUniqueInput;
};

export type AssessmentQuestionWhereInput = {
  AND?: InputMaybe<Array<AssessmentQuestionWhereInput>>;
  NOT?: InputMaybe<Array<AssessmentQuestionWhereInput>>;
  OR?: InputMaybe<Array<AssessmentQuestionWhereInput>>;
  assessment?: InputMaybe<AssessmentRelationFilter>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type AssessmentQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type AssessmentRelationFilter = {
  is?: InputMaybe<AssessmentWhereInput>;
  isNot?: InputMaybe<AssessmentWhereInput>;
};

export enum AssessmentScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  PassScore = 'passScore',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type AssessmentScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AssessmentScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AssessmentScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AssessmentScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  passScore?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type AssessmentSumAggregate = {
  __typename?: 'AssessmentSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  passScore?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type AssessmentSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  passScore?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type AssessmentUpdateInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionUpdateManyWithoutAssessmentNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentUpdateManyWithoutAssessmentNestedInput>;
  passScore?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordUpdateManyWithoutAssessmentNestedInput>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultUpdateManyWithoutAssessmentsNestedInput>;
};

export type AssessmentUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  passScore?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssessmentUpdateOneRequiredWithoutAssessmentQuestionsNestedInput = {
  connect?: InputMaybe<AssessmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssessmentCreateOrConnectWithoutAssessmentQuestionsInput>;
  create?: InputMaybe<AssessmentCreateWithoutAssessmentQuestionsInput>;
  update?: InputMaybe<AssessmentUpdateWithoutAssessmentQuestionsInput>;
  upsert?: InputMaybe<AssessmentUpsertWithoutAssessmentQuestionsInput>;
};

export type AssessmentUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput = {
  connect?: InputMaybe<AssessmentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssessmentCreateOrConnectWithoutUserAssessmentRecordsInput>;
  create?: InputMaybe<AssessmentCreateWithoutUserAssessmentRecordsInput>;
  update?: InputMaybe<AssessmentUpdateWithoutUserAssessmentRecordsInput>;
  upsert?: InputMaybe<AssessmentUpsertWithoutUserAssessmentRecordsInput>;
};

export type AssessmentUpdateWithoutAssessmentQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentUpdateManyWithoutAssessmentNestedInput>;
  passScore?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordUpdateManyWithoutAssessmentNestedInput>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultUpdateManyWithoutAssessmentsNestedInput>;
};

export type AssessmentUpdateWithoutUserAssessmentRecordsInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionUpdateManyWithoutAssessmentNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentUpdateManyWithoutAssessmentNestedInput>;
  passScore?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultUpdateManyWithoutAssessmentsNestedInput>;
};

export type AssessmentUpsertWithoutAssessmentQuestionsInput = {
  create: AssessmentCreateWithoutAssessmentQuestionsInput;
  update: AssessmentUpdateWithoutAssessmentQuestionsInput;
};

export type AssessmentUpsertWithoutUserAssessmentRecordsInput = {
  create: AssessmentCreateWithoutUserAssessmentRecordsInput;
  update: AssessmentUpdateWithoutUserAssessmentRecordsInput;
};

export type AssessmentWhereInput = {
  AND?: InputMaybe<Array<AssessmentWhereInput>>;
  NOT?: InputMaybe<Array<AssessmentWhereInput>>;
  OR?: InputMaybe<Array<AssessmentWhereInput>>;
  assessmentQuestions?: InputMaybe<AssessmentQuestionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassAssessments?: InputMaybe<LiveClassAssessmentListRelationFilter>;
  passScore?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordListRelationFilter>;
  userAssessmentResults?: InputMaybe<UserAssessmentResultListRelationFilter>;
};

export type AssessmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  expiresIn: Scalars['Float'];
  refreshExpiresIn: Scalars['Float'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type AuthUserUnion = AdminUser | User;

export type BannerType = {
  __typename?: 'BannerType';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BannerTypeAvgAggregate = {
  __typename?: 'BannerTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type BannerTypeAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type BannerTypeCountAggregate = {
  __typename?: 'BannerTypeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type BannerTypeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BannerTypeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerTypeCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerTypeGroupBy = {
  __typename?: 'BannerTypeGroupBy';
  _avg?: Maybe<BannerTypeAvgAggregate>;
  _count?: Maybe<BannerTypeCountAggregate>;
  _max?: Maybe<BannerTypeMaxAggregate>;
  _min?: Maybe<BannerTypeMinAggregate>;
  _sum?: Maybe<BannerTypeSumAggregate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BannerTypeMaxAggregate = {
  __typename?: 'BannerTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerTypeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BannerTypeMinAggregate = {
  __typename?: 'BannerTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BannerTypeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BannerTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<BannerTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<BannerTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<BannerTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<BannerTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<BannerTypeSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BannerTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum BannerTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type BannerTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<BannerTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<BannerTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<BannerTypeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type BannerTypeSumAggregate = {
  __typename?: 'BannerTypeSumAggregate';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type BannerTypeSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type BannerTypeUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerTypeUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerTypeWhereInput = {
  AND?: InputMaybe<Array<BannerTypeWhereInput>>;
  NOT?: InputMaybe<Array<BannerTypeWhereInput>>;
  OR?: InputMaybe<Array<BannerTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BannerTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type BigIntNullableFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type BigIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export enum BookmarkTargetType {
  Class = 'Class',
  Library = 'Library',
  Podcast = 'Podcast',
}

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type CmsBlock = {
  __typename?: 'CMSBlock';
  collectionId?: Maybe<CmsCollectionId>;
  itemType: CmsItemType;
  items?: Maybe<Array<CmsItem>>;
  settings: CmsBlockSettings;
  title: Scalars['String'];
  type: CmsBlockType;
};

export type CmsBlockSettings = {
  __typename?: 'CMSBlockSettings';
  shouldShowDescription: Scalars['Boolean'];
  shouldShowHeader: Scalars['Boolean'];
  shouldShowTitle: Scalars['Boolean'];
  shouldShowToNonSubsriberOnly: Scalars['Boolean'];
  shouldShowToSubsriberOnly: Scalars['Boolean'];
  shouldShowViewAll: Scalars['Boolean'];
};

export enum CmsBlockType {
  BannerVariant1 = 'BannerVariant1',
  BannerVariant2 = 'BannerVariant2',
  BannerVariant3 = 'BannerVariant3',
  CarouselVariant1 = 'CarouselVariant1',
  GridVariant1 = 'GridVariant1',
  GridVariant2 = 'GridVariant2',
}

export enum CmsCollectionId {
  TodayLive = 'TodayLive',
}

export type CmsItem = {
  __typename?: 'CMSItem';
  actionText?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  itemType: CmsItemType;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum CmsItemType {
  Image = 'Image',
  Library = 'Library',
  Link = 'Link',
  LiveClass = 'LiveClass',
  NormalClass = 'NormalClass',
  Podcast = 'Podcast',
}

export type CmsPage = {
  __typename?: 'CMSPage';
  category: CmsPageCategory;
  content: Array<CmsBlock>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  slug: Scalars['String'];
  status: Scalars['Int'];
  supportedLocales?: Maybe<Scalars['JSON']>;
  supportedPlatform: Platform;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum CmsPageCategory {
  Discover = 'Discover',
  Home = 'Home',
}

export type CmsPageOrderByWithRelationInput = {
  category?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supportedLocales?: InputMaybe<SortOrder>;
  supportedPlatform?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CmsPageWhereInput = {
  AND?: InputMaybe<Array<CmsPageWhereInput>>;
  NOT?: InputMaybe<Array<CmsPageWhereInput>>;
  OR?: InputMaybe<Array<CmsPageWhereInput>>;
  category?: InputMaybe<EnumCmsPageCategoryFilter>;
  content?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  supportedLocales?: InputMaybe<JsonNullableFilter>;
  supportedPlatform?: InputMaybe<EnumPlatformFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight: Scalars['Boolean'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['Int'];
  sequence?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryAvgAggregate = {
  __typename?: 'CategoryAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  parentId?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type CategoryAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
};

export type CategoryCountAggregate = {
  __typename?: 'CategoryCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  name: Scalars['Int'];
  parentId: Scalars['Int'];
  sequence: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CategoryCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight: Scalars['Boolean'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['Int'];
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight: Scalars['Boolean'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['Int'];
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryGroupBy = {
  __typename?: 'CategoryGroupBy';
  _avg?: Maybe<CategoryAvgAggregate>;
  _count?: Maybe<CategoryCountAggregate>;
  _max?: Maybe<CategoryMaxAggregate>;
  _min?: Maybe<CategoryMinAggregate>;
  _sum?: Maybe<CategorySumAggregate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  highlight: Scalars['Boolean'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['Int'];
  sequence?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryMaxAggregate = {
  __typename?: 'CategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryMinAggregate = {
  __typename?: 'CategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CategoryMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithAggregationInput = {
  _avg?: InputMaybe<CategoryAvgOrderByAggregateInput>;
  _count?: InputMaybe<CategoryCountOrderByAggregateInput>;
  _max?: InputMaybe<CategoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<CategoryMinOrderByAggregateInput>;
  _sum?: InputMaybe<CategorySumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CategoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CategoryScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Highlight = 'highlight',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  ParentId = 'parentId',
  Sequence = 'sequence',
  Slug = 'slug',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type CategoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CategoryScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  highlight?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  parentId?: InputMaybe<IntWithAggregatesFilter>;
  sequence?: InputMaybe<IntNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type CategorySumAggregate = {
  __typename?: 'CategorySumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  parentId?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
};

export type CategorySumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  parentId?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
};

export type CategoryUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryWhereInput = {
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  highlight?: InputMaybe<BoolFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentId?: InputMaybe<IntFilter>;
  sequence?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Certificate = {
  __typename?: 'Certificate';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  downloadCount: Scalars['Int'];
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  isCertFree?: Maybe<Scalars['Int']>;
  liveClass: LiveClass;
  liveClassId: Scalars['Int'];
  liveClassType?: Maybe<Scalars['Int']>;
  payPerView?: Maybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status: Scalars['Int'];
  teacherId?: Maybe<Scalars['Int']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['BigInt'];
};

export type CertificateAvgAggregate = {
  __typename?: 'CertificateAvgAggregate';
  downloadCount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  isCertFree?: Maybe<Scalars['Float']>;
  liveClassId?: Maybe<Scalars['Float']>;
  liveClassType?: Maybe<Scalars['Float']>;
  payPerView?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  teacherId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type CertificateAvgOrderByAggregateInput = {
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateCountAggregate = {
  __typename?: 'CertificateCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  date: Scalars['Int'];
  downloadCount: Scalars['Int'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  isCertFree: Scalars['Int'];
  liveClassId: Scalars['Int'];
  liveClassName: Scalars['Int'];
  liveClassType: Scalars['Int'];
  payPerView: Scalars['Int'];
  pdf: Scalars['Int'];
  serialNumber: Scalars['Int'];
  status: Scalars['Int'];
  teacherId: Scalars['Int'];
  teacherName: Scalars['Int'];
  thumbnailUrl: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userFullName: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CertificateCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassName?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  teacherName?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userFullName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClass: LiveClassCreateNestedOneWithoutIssuedCertificatesInput;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCertificatesInput;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassId: Scalars['Int'];
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
  userId: Scalars['BigInt'];
};

export type CertificateCreateManyLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
  userId: Scalars['BigInt'];
};

export type CertificateCreateManyLiveClassInputEnvelope = {
  data: Array<CertificateCreateManyLiveClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CertificateCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassId: Scalars['Int'];
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateCreateManyUserInputEnvelope = {
  data: Array<CertificateCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CertificateCreateNestedManyWithoutLiveClassInput = {
  connect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CertificateCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<CertificateCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<CertificateCreateManyLiveClassInputEnvelope>;
};

export type CertificateCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CertificateCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CertificateCreateWithoutUserInput>>;
  createMany?: InputMaybe<CertificateCreateManyUserInputEnvelope>;
};

export type CertificateCreateOrConnectWithoutLiveClassInput = {
  create: CertificateCreateWithoutLiveClassInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateCreateOrConnectWithoutUserInput = {
  create: CertificateCreateWithoutUserInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateCreateWithoutLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutCertificatesInput;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClass: LiveClassCreateNestedOneWithoutIssuedCertificatesInput;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateGroupBy = {
  __typename?: 'CertificateGroupBy';
  _avg?: Maybe<CertificateAvgAggregate>;
  _count?: Maybe<CertificateCountAggregate>;
  _max?: Maybe<CertificateMaxAggregate>;
  _min?: Maybe<CertificateMinAggregate>;
  _sum?: Maybe<CertificateSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  downloadCount: Scalars['Int'];
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  isCertFree?: Maybe<Scalars['Int']>;
  liveClassId: Scalars['Int'];
  liveClassName?: Maybe<Scalars['String']>;
  liveClassType?: Maybe<Scalars['Int']>;
  payPerView?: Maybe<Scalars['Int']>;
  pdf: Scalars['String'];
  serialNumber: Scalars['String'];
  status: Scalars['Int'];
  teacherId?: Maybe<Scalars['Int']>;
  teacherName?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userFullName?: Maybe<Scalars['String']>;
  userId: Scalars['BigInt'];
};

export type CertificateListRelationFilter = {
  every?: InputMaybe<CertificateWhereInput>;
  none?: InputMaybe<CertificateWhereInput>;
  some?: InputMaybe<CertificateWhereInput>;
};

export type CertificateMaxAggregate = {
  __typename?: 'CertificateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  downloadCount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  isCertFree?: Maybe<Scalars['Int']>;
  liveClassId?: Maybe<Scalars['Int']>;
  liveClassName?: Maybe<Scalars['String']>;
  liveClassType?: Maybe<Scalars['Int']>;
  payPerView?: Maybe<Scalars['Int']>;
  pdf?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  teacherName?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userFullName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type CertificateMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassName?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  teacherName?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userFullName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateMinAggregate = {
  __typename?: 'CertificateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  downloadCount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  isCertFree?: Maybe<Scalars['Int']>;
  liveClassId?: Maybe<Scalars['Int']>;
  liveClassName?: Maybe<Scalars['String']>;
  liveClassType?: Maybe<Scalars['Int']>;
  payPerView?: Maybe<Scalars['Int']>;
  pdf?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  teacherName?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userFullName?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type CertificateMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassName?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  teacherName?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userFullName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CertificateOrderByWithAggregationInput = {
  _avg?: InputMaybe<CertificateAvgOrderByAggregateInput>;
  _count?: InputMaybe<CertificateCountOrderByAggregateInput>;
  _max?: InputMaybe<CertificateMaxOrderByAggregateInput>;
  _min?: InputMaybe<CertificateMinOrderByAggregateInput>;
  _sum?: InputMaybe<CertificateSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassName?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  teacherName?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userFullName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClass?: InputMaybe<LiveClassOrderByWithRelationInput>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassName?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  serialNumber?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  teacherName?: InputMaybe<SortOrder>;
  thumbnailUrl?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userFullName?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum CertificateScalarFieldEnum {
  CreatedAt = 'createdAt',
  Date = 'date',
  DownloadCount = 'downloadCount',
  Duration = 'duration',
  Id = 'id',
  Image = 'image',
  IsCertFree = 'isCertFree',
  LiveClassId = 'liveClassId',
  LiveClassName = 'liveClassName',
  LiveClassType = 'liveClassType',
  PayPerView = 'payPerView',
  Pdf = 'pdf',
  SerialNumber = 'serialNumber',
  Status = 'status',
  TeacherId = 'teacherId',
  TeacherName = 'teacherName',
  ThumbnailUrl = 'thumbnailUrl',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserFullName = 'userFullName',
  UserId = 'userId',
}

export type CertificateScalarWhereInput = {
  AND?: InputMaybe<Array<CertificateScalarWhereInput>>;
  NOT?: InputMaybe<Array<CertificateScalarWhereInput>>;
  OR?: InputMaybe<Array<CertificateScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  downloadCount?: InputMaybe<IntFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCertFree?: InputMaybe<IntNullableFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  liveClassName?: InputMaybe<StringNullableFilter>;
  liveClassType?: InputMaybe<IntNullableFilter>;
  payPerView?: InputMaybe<IntNullableFilter>;
  pdf?: InputMaybe<StringFilter>;
  serialNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  teacherId?: InputMaybe<IntNullableFilter>;
  teacherName?: InputMaybe<StringNullableFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userFullName?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type CertificateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CertificateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CertificateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CertificateScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  date?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  downloadCount?: InputMaybe<IntWithAggregatesFilter>;
  duration?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  isCertFree?: InputMaybe<IntNullableWithAggregatesFilter>;
  liveClassId?: InputMaybe<IntWithAggregatesFilter>;
  liveClassName?: InputMaybe<StringNullableWithAggregatesFilter>;
  liveClassType?: InputMaybe<IntNullableWithAggregatesFilter>;
  payPerView?: InputMaybe<IntNullableWithAggregatesFilter>;
  pdf?: InputMaybe<StringWithAggregatesFilter>;
  serialNumber?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  teacherId?: InputMaybe<IntNullableWithAggregatesFilter>;
  teacherName?: InputMaybe<StringNullableWithAggregatesFilter>;
  thumbnailUrl?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userFullName?: InputMaybe<StringNullableWithAggregatesFilter>;
  userId?: InputMaybe<BigIntWithAggregatesFilter>;
};

export type CertificateSumAggregate = {
  __typename?: 'CertificateSumAggregate';
  downloadCount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['BigInt']>;
  isCertFree?: Maybe<Scalars['Int']>;
  liveClassId?: Maybe<Scalars['Int']>;
  liveClassType?: Maybe<Scalars['Int']>;
  payPerView?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type CertificateSumOrderByAggregateInput = {
  downloadCount?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  liveClassType?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CertificateUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClass?: InputMaybe<LiveClassUpdateOneRequiredWithoutIssuedCertificatesNestedInput>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCertificatesNestedInput>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateUpdateManyWithWhereWithoutLiveClassInput = {
  data: CertificateUpdateManyMutationInput;
  where: CertificateScalarWhereInput;
};

export type CertificateUpdateManyWithWhereWithoutUserInput = {
  data: CertificateUpdateManyMutationInput;
  where: CertificateScalarWhereInput;
};

export type CertificateUpdateManyWithoutLiveClassNestedInput = {
  connect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CertificateCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<CertificateCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<CertificateCreateManyLiveClassInputEnvelope>;
  delete?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CertificateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  set?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  update?: InputMaybe<Array<CertificateUpdateWithWhereUniqueWithoutLiveClassInput>>;
  updateMany?: InputMaybe<Array<CertificateUpdateManyWithWhereWithoutLiveClassInput>>;
  upsert?: InputMaybe<Array<CertificateUpsertWithWhereUniqueWithoutLiveClassInput>>;
};

export type CertificateUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CertificateCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CertificateCreateWithoutUserInput>>;
  createMany?: InputMaybe<CertificateCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CertificateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  set?: InputMaybe<Array<CertificateWhereUniqueInput>>;
  update?: InputMaybe<Array<CertificateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CertificateUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CertificateUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CertificateUpdateWithWhereUniqueWithoutLiveClassInput = {
  data: CertificateUpdateWithoutLiveClassInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateUpdateWithWhereUniqueWithoutUserInput = {
  data: CertificateUpdateWithoutUserInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateUpdateWithoutLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCertificatesNestedInput>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  downloadCount?: InputMaybe<Scalars['Int']>;
  duration?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClass?: InputMaybe<LiveClassUpdateOneRequiredWithoutIssuedCertificatesNestedInput>;
  liveClassName?: InputMaybe<Scalars['String']>;
  liveClassType?: InputMaybe<Scalars['Int']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  pdf?: InputMaybe<Scalars['String']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  teacherName?: InputMaybe<Scalars['String']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userFullName?: InputMaybe<Scalars['String']>;
};

export type CertificateUpsertWithWhereUniqueWithoutLiveClassInput = {
  create: CertificateCreateWithoutLiveClassInput;
  update: CertificateUpdateWithoutLiveClassInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateUpsertWithWhereUniqueWithoutUserInput = {
  create: CertificateCreateWithoutUserInput;
  update: CertificateUpdateWithoutUserInput;
  where: CertificateWhereUniqueInput;
};

export type CertificateWhereInput = {
  AND?: InputMaybe<Array<CertificateWhereInput>>;
  NOT?: InputMaybe<Array<CertificateWhereInput>>;
  OR?: InputMaybe<Array<CertificateWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  downloadCount?: InputMaybe<IntFilter>;
  duration?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCertFree?: InputMaybe<IntNullableFilter>;
  liveClass?: InputMaybe<LiveClassRelationFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  liveClassName?: InputMaybe<StringNullableFilter>;
  liveClassType?: InputMaybe<IntNullableFilter>;
  payPerView?: InputMaybe<IntNullableFilter>;
  pdf?: InputMaybe<StringFilter>;
  serialNumber?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  teacherId?: InputMaybe<IntNullableFilter>;
  teacherName?: InputMaybe<StringNullableFilter>;
  thumbnailUrl?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userFullName?: InputMaybe<StringNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type CertificateWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Channel = {
  __typename?: 'Channel';
  _count?: Maybe<ChannelCount>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  flag: Scalars['String'];
  id: Scalars['Int'];
  isOthers: Scalars['Int'];
  liveClasses: Array<LiveClass>;
  locale: Scalars['String'];
  name: Scalars['String'];
  normalClasses: Array<NormalClass>;
  podcasts: Array<Podcast>;
  setDefault: Scalars['Int'];
  sortValue: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ChannelLiveClassesArgs = {
  cursor?: InputMaybe<LiveClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<LiveClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInput>;
};

export type ChannelNormalClassesArgs = {
  cursor?: InputMaybe<NormalClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type ChannelPodcastsArgs = {
  cursor?: InputMaybe<PodcastWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type ChannelCount = {
  __typename?: 'ChannelCount';
  liveClasses: Scalars['Int'];
  normalClasses: Scalars['Int'];
  podcasts: Scalars['Int'];
};

export type ChannelCreateNestedOneWithoutLiveClassesInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutLiveClassesInput>;
  create?: InputMaybe<ChannelCreateWithoutLiveClassesInput>;
};

export type ChannelCreateNestedOneWithoutNormalClassesInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutNormalClassesInput>;
  create?: InputMaybe<ChannelCreateWithoutNormalClassesInput>;
};

export type ChannelCreateNestedOneWithoutPodcastsInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutPodcastsInput>;
  create?: InputMaybe<ChannelCreateWithoutPodcastsInput>;
};

export type ChannelCreateOrConnectWithoutLiveClassesInput = {
  create: ChannelCreateWithoutLiveClassesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutNormalClassesInput = {
  create: ChannelCreateWithoutNormalClassesInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateOrConnectWithoutPodcastsInput = {
  create: ChannelCreateWithoutPodcastsInput;
  where: ChannelWhereUniqueInput;
};

export type ChannelCreateWithoutLiveClassesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag: Scalars['String'];
  isOthers?: InputMaybe<Scalars['Int']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  normalClasses?: InputMaybe<NormalClassCreateNestedManyWithoutChannelInput>;
  podcasts?: InputMaybe<PodcastCreateNestedManyWithoutChannelInput>;
  setDefault: Scalars['Int'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutNormalClassesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag: Scalars['String'];
  isOthers?: InputMaybe<Scalars['Int']>;
  liveClasses?: InputMaybe<LiveClassCreateNestedManyWithoutChannelInput>;
  locale: Scalars['String'];
  name: Scalars['String'];
  podcasts?: InputMaybe<PodcastCreateNestedManyWithoutChannelInput>;
  setDefault: Scalars['Int'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelCreateWithoutPodcastsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag: Scalars['String'];
  isOthers?: InputMaybe<Scalars['Int']>;
  liveClasses?: InputMaybe<LiveClassCreateNestedManyWithoutChannelInput>;
  locale: Scalars['String'];
  name: Scalars['String'];
  normalClasses?: InputMaybe<NormalClassCreateNestedManyWithoutChannelInput>;
  setDefault: Scalars['Int'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  flag?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isOthers?: InputMaybe<SortOrder>;
  liveClasses?: InputMaybe<LiveClassOrderByRelationAggregateInput>;
  locale?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  normalClasses?: InputMaybe<NormalClassOrderByRelationAggregateInput>;
  podcasts?: InputMaybe<PodcastOrderByRelationAggregateInput>;
  setDefault?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ChannelRelationFilter = {
  is?: InputMaybe<ChannelWhereInput>;
  isNot?: InputMaybe<ChannelWhereInput>;
};

export type ChannelUpdateOneWithoutLiveClassesNestedInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutLiveClassesInput>;
  create?: InputMaybe<ChannelCreateWithoutLiveClassesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChannelUpdateWithoutLiveClassesInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutLiveClassesInput>;
};

export type ChannelUpdateOneWithoutNormalClassesNestedInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutNormalClassesInput>;
  create?: InputMaybe<ChannelCreateWithoutNormalClassesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChannelUpdateWithoutNormalClassesInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutNormalClassesInput>;
};

export type ChannelUpdateOneWithoutPodcastsNestedInput = {
  connect?: InputMaybe<ChannelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChannelCreateOrConnectWithoutPodcastsInput>;
  create?: InputMaybe<ChannelCreateWithoutPodcastsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ChannelUpdateWithoutPodcastsInput>;
  upsert?: InputMaybe<ChannelUpsertWithoutPodcastsInput>;
};

export type ChannelUpdateWithoutLiveClassesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag?: InputMaybe<Scalars['String']>;
  isOthers?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassUpdateManyWithoutChannelNestedInput>;
  podcasts?: InputMaybe<PodcastUpdateManyWithoutChannelNestedInput>;
  setDefault?: InputMaybe<Scalars['Int']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutNormalClassesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag?: InputMaybe<Scalars['String']>;
  isOthers?: InputMaybe<Scalars['Int']>;
  liveClasses?: InputMaybe<LiveClassUpdateManyWithoutChannelNestedInput>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  podcasts?: InputMaybe<PodcastUpdateManyWithoutChannelNestedInput>;
  setDefault?: InputMaybe<Scalars['Int']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelUpdateWithoutPodcastsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  flag?: InputMaybe<Scalars['String']>;
  isOthers?: InputMaybe<Scalars['Int']>;
  liveClasses?: InputMaybe<LiveClassUpdateManyWithoutChannelNestedInput>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassUpdateManyWithoutChannelNestedInput>;
  setDefault?: InputMaybe<Scalars['Int']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChannelUpsertWithoutLiveClassesInput = {
  create: ChannelCreateWithoutLiveClassesInput;
  update: ChannelUpdateWithoutLiveClassesInput;
};

export type ChannelUpsertWithoutNormalClassesInput = {
  create: ChannelCreateWithoutNormalClassesInput;
  update: ChannelUpdateWithoutNormalClassesInput;
};

export type ChannelUpsertWithoutPodcastsInput = {
  create: ChannelCreateWithoutPodcastsInput;
  update: ChannelUpdateWithoutPodcastsInput;
};

export type ChannelWhereInput = {
  AND?: InputMaybe<Array<ChannelWhereInput>>;
  NOT?: InputMaybe<Array<ChannelWhereInput>>;
  OR?: InputMaybe<Array<ChannelWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  flag?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isOthers?: InputMaybe<IntFilter>;
  liveClasses?: InputMaybe<LiveClassListRelationFilter>;
  locale?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  normalClasses?: InputMaybe<NormalClassListRelationFilter>;
  podcasts?: InputMaybe<PodcastListRelationFilter>;
  setDefault?: InputMaybe<IntFilter>;
  sortValue?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChannelWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ClassRating = {
  __typename?: 'ClassRating';
  classId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rating: Scalars['Decimal'];
  status: Scalars['Int'];
  teacherId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type ClassRatingAvgAggregate = {
  __typename?: 'ClassRatingAvgAggregate';
  classId?: Maybe<Scalars['Float']>;
  courseId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['Float']>;
  teacherId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type ClassRatingAvgOrderByAggregateInput = {
  classId?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClassRatingCountAggregate = {
  __typename?: 'ClassRatingCountAggregate';
  _all: Scalars['Int'];
  classId: Scalars['Int'];
  comment: Scalars['Int'];
  courseId: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  rating: Scalars['Int'];
  status: Scalars['Int'];
  teacherId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type ClassRatingCountOrderByAggregateInput = {
  classId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClassRatingCreateInput = {
  classId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  rating: Scalars['Decimal'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutClassRatingsInput;
};

export type ClassRatingCreateManyInput = {
  classId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  rating: Scalars['Decimal'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type ClassRatingCreateManyUserInput = {
  classId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  rating: Scalars['Decimal'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassRatingCreateManyUserInputEnvelope = {
  data: Array<ClassRatingCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClassRatingCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ClassRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassRatingCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ClassRatingCreateWithoutUserInput>>;
  createMany?: InputMaybe<ClassRatingCreateManyUserInputEnvelope>;
};

export type ClassRatingCreateOrConnectWithoutUserInput = {
  create: ClassRatingCreateWithoutUserInput;
  where: ClassRatingWhereUniqueInput;
};

export type ClassRatingCreateWithoutUserInput = {
  classId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  rating: Scalars['Decimal'];
  status?: InputMaybe<Scalars['Int']>;
  teacherId: Scalars['Int'];
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassRatingGroupBy = {
  __typename?: 'ClassRatingGroupBy';
  _avg?: Maybe<ClassRatingAvgAggregate>;
  _count?: Maybe<ClassRatingCountAggregate>;
  _max?: Maybe<ClassRatingMaxAggregate>;
  _min?: Maybe<ClassRatingMinAggregate>;
  _sum?: Maybe<ClassRatingSumAggregate>;
  classId: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  courseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rating: Scalars['Decimal'];
  status: Scalars['Int'];
  teacherId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['BigInt'];
};

export type ClassRatingListRelationFilter = {
  every?: InputMaybe<ClassRatingWhereInput>;
  none?: InputMaybe<ClassRatingWhereInput>;
  some?: InputMaybe<ClassRatingWhereInput>;
};

export type ClassRatingMaxAggregate = {
  __typename?: 'ClassRatingMaxAggregate';
  classId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type ClassRatingMaxOrderByAggregateInput = {
  classId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClassRatingMinAggregate = {
  __typename?: 'ClassRatingMinAggregate';
  classId?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  courseId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type ClassRatingMinOrderByAggregateInput = {
  classId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClassRatingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClassRatingOrderByWithAggregationInput = {
  _avg?: InputMaybe<ClassRatingAvgOrderByAggregateInput>;
  _count?: InputMaybe<ClassRatingCountOrderByAggregateInput>;
  _max?: InputMaybe<ClassRatingMaxOrderByAggregateInput>;
  _min?: InputMaybe<ClassRatingMinOrderByAggregateInput>;
  _sum?: InputMaybe<ClassRatingSumOrderByAggregateInput>;
  classId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClassRatingOrderByWithRelationInput = {
  classId?: InputMaybe<SortOrder>;
  comment?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClassRatingScalarFieldEnum {
  ClassId = 'classId',
  Comment = 'comment',
  CourseId = 'courseId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Rating = 'rating',
  Status = 'status',
  TeacherId = 'teacherId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type ClassRatingScalarWhereInput = {
  AND?: InputMaybe<Array<ClassRatingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClassRatingScalarWhereInput>>;
  OR?: InputMaybe<Array<ClassRatingScalarWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  rating?: InputMaybe<DecimalFilter>;
  status?: InputMaybe<IntFilter>;
  teacherId?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type ClassRatingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ClassRatingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ClassRatingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ClassRatingScalarWhereWithAggregatesInput>>;
  classId?: InputMaybe<IntWithAggregatesFilter>;
  comment?: InputMaybe<StringNullableWithAggregatesFilter>;
  courseId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  rating?: InputMaybe<DecimalWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  teacherId?: InputMaybe<IntWithAggregatesFilter>;
  type?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<BigIntWithAggregatesFilter>;
};

export type ClassRatingStatistic = {
  __typename?: 'ClassRatingStatistic';
  averageRating: Scalars['Decimal'];
  /** Display label of the rating eg: 4.93/5 */
  formattedRating: Scalars['String'];
  maxRating: Scalars['Decimal'];
  totalRatingCount: Scalars['Int'];
};

export type ClassRatingSumAggregate = {
  __typename?: 'ClassRatingSumAggregate';
  classId?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Decimal']>;
  status?: Maybe<Scalars['Int']>;
  teacherId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type ClassRatingSumOrderByAggregateInput = {
  classId?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  teacherId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClassRatingType {
  LiveClass = 'LIVE_CLASS',
  NormalClass = 'NORMAL_CLASS',
}

export type ClassRatingUpdateInput = {
  classId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  rating?: InputMaybe<Scalars['Decimal']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutClassRatingsNestedInput>;
};

export type ClassRatingUpdateManyMutationInput = {
  classId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  rating?: InputMaybe<Scalars['Decimal']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassRatingUpdateManyWithWhereWithoutUserInput = {
  data: ClassRatingUpdateManyMutationInput;
  where: ClassRatingScalarWhereInput;
};

export type ClassRatingUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<ClassRatingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassRatingCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ClassRatingCreateWithoutUserInput>>;
  createMany?: InputMaybe<ClassRatingCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ClassRatingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClassRatingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClassRatingWhereUniqueInput>>;
  set?: InputMaybe<Array<ClassRatingWhereUniqueInput>>;
  update?: InputMaybe<Array<ClassRatingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ClassRatingUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ClassRatingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ClassRatingUpdateWithWhereUniqueWithoutUserInput = {
  data: ClassRatingUpdateWithoutUserInput;
  where: ClassRatingWhereUniqueInput;
};

export type ClassRatingUpdateWithoutUserInput = {
  classId?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  rating?: InputMaybe<Scalars['Decimal']>;
  status?: InputMaybe<Scalars['Int']>;
  teacherId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassRatingUpsertWithWhereUniqueWithoutUserInput = {
  create: ClassRatingCreateWithoutUserInput;
  update: ClassRatingUpdateWithoutUserInput;
  where: ClassRatingWhereUniqueInput;
};

export type ClassRatingWhereInput = {
  AND?: InputMaybe<Array<ClassRatingWhereInput>>;
  NOT?: InputMaybe<Array<ClassRatingWhereInput>>;
  OR?: InputMaybe<Array<ClassRatingWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  rating?: InputMaybe<DecimalFilter>;
  status?: InputMaybe<IntFilter>;
  teacherId?: InputMaybe<IntFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type ClassRatingWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type ClassTagging = {
  __typename?: 'ClassTagging';
  class: NormalClass;
  classId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  tag: Tagging;
  tagId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ClassTaggingCreateManyClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tagId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingCreateManyClassInputEnvelope = {
  data: Array<ClassTaggingCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClassTaggingCreateManyTagInput = {
  classId: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingCreateManyTagInputEnvelope = {
  data: Array<ClassTaggingCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClassTaggingCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassTaggingCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<ClassTaggingCreateWithoutClassInput>>;
  createMany?: InputMaybe<ClassTaggingCreateManyClassInputEnvelope>;
};

export type ClassTaggingCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<ClassTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<ClassTaggingCreateManyTagInputEnvelope>;
};

export type ClassTaggingCreateOrConnectWithoutClassInput = {
  create: ClassTaggingCreateWithoutClassInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingCreateOrConnectWithoutTagInput = {
  create: ClassTaggingCreateWithoutTagInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingCreateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag: TaggingCreateNestedOneWithoutClassTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingCreateWithoutTagInput = {
  class: NormalClassCreateNestedOneWithoutClassTagsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingListRelationFilter = {
  every?: InputMaybe<ClassTaggingWhereInput>;
  none?: InputMaybe<ClassTaggingWhereInput>;
  some?: InputMaybe<ClassTaggingWhereInput>;
};

export type ClassTaggingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClassTaggingOrderByWithRelationInput = {
  class?: InputMaybe<NormalClassOrderByWithRelationInput>;
  classId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TaggingOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ClassTaggingScalarFieldEnum {
  ClassId = 'classId',
  CreatedAt = 'createdAt',
  Id = 'id',
  TagId = 'tagId',
  UpdatedAt = 'updatedAt',
}

export type ClassTaggingScalarWhereInput = {
  AND?: InputMaybe<Array<ClassTaggingScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClassTaggingScalarWhereInput>>;
  OR?: InputMaybe<Array<ClassTaggingScalarWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ClassTaggingUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingUpdateManyWithWhereWithoutClassInput = {
  data: ClassTaggingUpdateManyMutationInput;
  where: ClassTaggingScalarWhereInput;
};

export type ClassTaggingUpdateManyWithWhereWithoutTagInput = {
  data: ClassTaggingUpdateManyMutationInput;
  where: ClassTaggingScalarWhereInput;
};

export type ClassTaggingUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassTaggingCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<ClassTaggingCreateWithoutClassInput>>;
  createMany?: InputMaybe<ClassTaggingCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClassTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<ClassTaggingUpdateWithWhereUniqueWithoutClassInput>>;
  updateMany?: InputMaybe<Array<ClassTaggingUpdateManyWithWhereWithoutClassInput>>;
  upsert?: InputMaybe<Array<ClassTaggingUpsertWithWhereUniqueWithoutClassInput>>;
};

export type ClassTaggingUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<ClassTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<ClassTaggingCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClassTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<ClassTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<ClassTaggingUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<ClassTaggingUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<ClassTaggingUpsertWithWhereUniqueWithoutTagInput>>;
};

export type ClassTaggingUpdateWithWhereUniqueWithoutClassInput = {
  data: ClassTaggingUpdateWithoutClassInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingUpdateWithWhereUniqueWithoutTagInput = {
  data: ClassTaggingUpdateWithoutTagInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingUpdateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag?: InputMaybe<TaggingUpdateOneRequiredWithoutClassTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingUpdateWithoutTagInput = {
  class?: InputMaybe<NormalClassUpdateOneRequiredWithoutClassTagsNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClassTaggingUpsertWithWhereUniqueWithoutClassInput = {
  create: ClassTaggingCreateWithoutClassInput;
  update: ClassTaggingUpdateWithoutClassInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingUpsertWithWhereUniqueWithoutTagInput = {
  create: ClassTaggingCreateWithoutTagInput;
  update: ClassTaggingUpdateWithoutTagInput;
  where: ClassTaggingWhereUniqueInput;
};

export type ClassTaggingWhereInput = {
  AND?: InputMaybe<Array<ClassTaggingWhereInput>>;
  NOT?: InputMaybe<Array<ClassTaggingWhereInput>>;
  OR?: InputMaybe<Array<ClassTaggingWhereInput>>;
  class?: InputMaybe<NormalClassRelationFilter>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  tag?: InputMaybe<TaggingRelationFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ClassTaggingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type ClassesBookmarks = {
  __typename?: 'ClassesBookmarks';
  class: NormalClass;
  classId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type ClassesBookmarksCreateManyClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type ClassesBookmarksCreateManyClassInputEnvelope = {
  data: Array<ClassesBookmarksCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClassesBookmarksCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<ClassesBookmarksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassesBookmarksCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<ClassesBookmarksCreateWithoutClassInput>>;
  createMany?: InputMaybe<ClassesBookmarksCreateManyClassInputEnvelope>;
};

export type ClassesBookmarksCreateOrConnectWithoutClassInput = {
  create: ClassesBookmarksCreateWithoutClassInput;
  where: ClassesBookmarksWhereUniqueInput;
};

export type ClassesBookmarksCreateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type ClassesBookmarksListRelationFilter = {
  every?: InputMaybe<ClassesBookmarksWhereInput>;
  none?: InputMaybe<ClassesBookmarksWhereInput>;
  some?: InputMaybe<ClassesBookmarksWhereInput>;
};

export type ClassesBookmarksOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClassesBookmarksOrderByWithRelationInput = {
  class?: InputMaybe<NormalClassOrderByWithRelationInput>;
  classId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClassesBookmarksScalarFieldEnum {
  ClassId = 'classId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type ClassesBookmarksScalarWhereInput = {
  AND?: InputMaybe<Array<ClassesBookmarksScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClassesBookmarksScalarWhereInput>>;
  OR?: InputMaybe<Array<ClassesBookmarksScalarWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type ClassesBookmarksUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type ClassesBookmarksUpdateManyWithWhereWithoutClassInput = {
  data: ClassesBookmarksUpdateManyMutationInput;
  where: ClassesBookmarksScalarWhereInput;
};

export type ClassesBookmarksUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<ClassesBookmarksWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClassesBookmarksCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<ClassesBookmarksCreateWithoutClassInput>>;
  createMany?: InputMaybe<ClassesBookmarksCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<ClassesBookmarksWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClassesBookmarksScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClassesBookmarksWhereUniqueInput>>;
  set?: InputMaybe<Array<ClassesBookmarksWhereUniqueInput>>;
  update?: InputMaybe<Array<ClassesBookmarksUpdateWithWhereUniqueWithoutClassInput>>;
  updateMany?: InputMaybe<Array<ClassesBookmarksUpdateManyWithWhereWithoutClassInput>>;
  upsert?: InputMaybe<Array<ClassesBookmarksUpsertWithWhereUniqueWithoutClassInput>>;
};

export type ClassesBookmarksUpdateWithWhereUniqueWithoutClassInput = {
  data: ClassesBookmarksUpdateWithoutClassInput;
  where: ClassesBookmarksWhereUniqueInput;
};

export type ClassesBookmarksUpdateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type ClassesBookmarksUpsertWithWhereUniqueWithoutClassInput = {
  create: ClassesBookmarksCreateWithoutClassInput;
  update: ClassesBookmarksUpdateWithoutClassInput;
  where: ClassesBookmarksWhereUniqueInput;
};

export type ClassesBookmarksUserIdClassIdCompoundUniqueInput = {
  classId: Scalars['Int'];
  userId: Scalars['BigInt'];
};

export type ClassesBookmarksWhereInput = {
  AND?: InputMaybe<Array<ClassesBookmarksWhereInput>>;
  NOT?: InputMaybe<Array<ClassesBookmarksWhereInput>>;
  OR?: InputMaybe<Array<ClassesBookmarksWhereInput>>;
  class?: InputMaybe<NormalClassRelationFilter>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type ClassesBookmarksWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId_classId?: InputMaybe<ClassesBookmarksUserIdClassIdCompoundUniqueInput>;
};

export type Coach = {
  __typename?: 'Coach';
  _count?: Maybe<CoachCount>;
  adminRating?: Maybe<Scalars['Int']>;
  avatar?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expertise?: Maybe<Scalars['String']>;
  followedBy: Array<TeacherFollower>;
  followerCount: Scalars['Float'];
  hasFollowed: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['Int'];
  isAdminRate: Scalars['String'];
  liveClasses: Array<LiveClass>;
  name: Scalars['String'];
  nationality?: Maybe<Scalars['String']>;
  normalClasses: Array<NormalClass>;
  podcastTeachers: Array<PodcastTeacher>;
  resume?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  shouldShowVerifiedBadge: Scalars['Boolean'];
  signature?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  tierName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['Int']>;
  userRating: Scalars['Decimal'];
  yearOfExperience?: Maybe<Scalars['Int']>;
};

export type CoachFollowedByArgs = {
  cursor?: InputMaybe<TeacherFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeacherFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeacherFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeacherFollowerWhereInput>;
};

export type CoachLiveClassesArgs = {
  cursor?: InputMaybe<LiveClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<LiveClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInput>;
};

export type CoachNormalClassesArgs = {
  cursor?: InputMaybe<NormalClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type CoachPodcastTeachersArgs = {
  cursor?: InputMaybe<PodcastTeacherWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastTeacherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastTeacherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastTeacherWhereInput>;
};

export type CoachCount = {
  __typename?: 'CoachCount';
  followedBy: Scalars['Int'];
  liveClasses: Scalars['Int'];
  normalClasses: Scalars['Int'];
  podcastTeachers: Scalars['Int'];
};

export type CoachCreateNestedOneWithoutFollowedByInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutFollowedByInput>;
  create?: InputMaybe<CoachCreateWithoutFollowedByInput>;
};

export type CoachCreateNestedOneWithoutLiveClassesInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutLiveClassesInput>;
  create?: InputMaybe<CoachCreateWithoutLiveClassesInput>;
};

export type CoachCreateNestedOneWithoutNormalClassesInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutNormalClassesInput>;
  create?: InputMaybe<CoachCreateWithoutNormalClassesInput>;
};

export type CoachCreateNestedOneWithoutPodcastTeachersInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutPodcastTeachersInput>;
  create?: InputMaybe<CoachCreateWithoutPodcastTeachersInput>;
};

export type CoachCreateOrConnectWithoutFollowedByInput = {
  create: CoachCreateWithoutFollowedByInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateOrConnectWithoutLiveClassesInput = {
  create: CoachCreateWithoutLiveClassesInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateOrConnectWithoutNormalClassesInput = {
  create: CoachCreateWithoutNormalClassesInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateOrConnectWithoutPodcastTeachersInput = {
  create: CoachCreateWithoutPodcastTeachersInput;
  where: CoachWhereUniqueInput;
};

export type CoachCreateWithoutFollowedByInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expertise?: InputMaybe<Scalars['String']>;
  highlight: Scalars['Boolean'];
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassCreateNestedManyWithoutCoachInput>;
  name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassCreateNestedManyWithoutCoachInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutTeacherInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  signature?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachCreateWithoutLiveClassesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerCreateNestedManyWithoutTeacherInput>;
  highlight: Scalars['Boolean'];
  isAdminRate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassCreateNestedManyWithoutCoachInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutTeacherInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  signature?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachCreateWithoutNormalClassesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerCreateNestedManyWithoutTeacherInput>;
  highlight: Scalars['Boolean'];
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassCreateNestedManyWithoutCoachInput>;
  name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutTeacherInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  signature?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachCreateWithoutPodcastTeachersInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerCreateNestedManyWithoutTeacherInput>;
  highlight: Scalars['Boolean'];
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassCreateNestedManyWithoutCoachInput>;
  name: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassCreateNestedManyWithoutCoachInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  signature?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachOrderByWithRelationInput = {
  adminRating?: InputMaybe<SortOrder>;
  avatar?: InputMaybe<SortOrder>;
  coverImage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  expertise?: InputMaybe<SortOrder>;
  followedBy?: InputMaybe<TeacherFollowerOrderByRelationAggregateInput>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isAdminRate?: InputMaybe<SortOrder>;
  liveClasses?: InputMaybe<LiveClassOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  nationality?: InputMaybe<SortOrder>;
  normalClasses?: InputMaybe<NormalClassOrderByRelationAggregateInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherOrderByRelationAggregateInput>;
  resume?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  signature?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRating?: InputMaybe<SortOrder>;
  yearOfExperience?: InputMaybe<SortOrder>;
};

export type CoachRelationFilter = {
  is?: InputMaybe<CoachWhereInput>;
  isNot?: InputMaybe<CoachWhereInput>;
};

export type CoachUpdateOneRequiredWithoutFollowedByNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutFollowedByInput>;
  create?: InputMaybe<CoachCreateWithoutFollowedByInput>;
  update?: InputMaybe<CoachUpdateWithoutFollowedByInput>;
  upsert?: InputMaybe<CoachUpsertWithoutFollowedByInput>;
};

export type CoachUpdateOneRequiredWithoutPodcastTeachersNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutPodcastTeachersInput>;
  create?: InputMaybe<CoachCreateWithoutPodcastTeachersInput>;
  update?: InputMaybe<CoachUpdateWithoutPodcastTeachersInput>;
  upsert?: InputMaybe<CoachUpsertWithoutPodcastTeachersInput>;
};

export type CoachUpdateOneWithoutLiveClassesNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutLiveClassesInput>;
  create?: InputMaybe<CoachCreateWithoutLiveClassesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateWithoutLiveClassesInput>;
  upsert?: InputMaybe<CoachUpsertWithoutLiveClassesInput>;
};

export type CoachUpdateOneWithoutNormalClassesNestedInput = {
  connect?: InputMaybe<CoachWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CoachCreateOrConnectWithoutNormalClassesInput>;
  create?: InputMaybe<CoachCreateWithoutNormalClassesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CoachUpdateWithoutNormalClassesInput>;
  upsert?: InputMaybe<CoachUpsertWithoutNormalClassesInput>;
};

export type CoachUpdateWithoutFollowedByInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  expertise?: InputMaybe<Scalars['String']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassUpdateManyWithoutCoachNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassUpdateManyWithoutCoachNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutTeacherNestedInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachUpdateWithoutLiveClassesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerUpdateManyWithoutTeacherNestedInput>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAdminRate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassUpdateManyWithoutCoachNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutTeacherNestedInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachUpdateWithoutNormalClassesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerUpdateManyWithoutTeacherNestedInput>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassUpdateManyWithoutCoachNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutTeacherNestedInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachUpdateWithoutPodcastTeachersInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  avatar?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  expertise?: InputMaybe<Scalars['String']>;
  followedBy?: InputMaybe<TeacherFollowerUpdateManyWithoutTeacherNestedInput>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAdminRate?: InputMaybe<Scalars['String']>;
  liveClasses?: InputMaybe<LiveClassUpdateManyWithoutCoachNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  normalClasses?: InputMaybe<NormalClassUpdateManyWithoutCoachNestedInput>;
  resume?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  signature?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['Int']>;
  userRating?: InputMaybe<Scalars['Decimal']>;
  yearOfExperience?: InputMaybe<Scalars['Int']>;
};

export type CoachUpsertWithoutFollowedByInput = {
  create: CoachCreateWithoutFollowedByInput;
  update: CoachUpdateWithoutFollowedByInput;
};

export type CoachUpsertWithoutLiveClassesInput = {
  create: CoachCreateWithoutLiveClassesInput;
  update: CoachUpdateWithoutLiveClassesInput;
};

export type CoachUpsertWithoutNormalClassesInput = {
  create: CoachCreateWithoutNormalClassesInput;
  update: CoachUpdateWithoutNormalClassesInput;
};

export type CoachUpsertWithoutPodcastTeachersInput = {
  create: CoachCreateWithoutPodcastTeachersInput;
  update: CoachUpdateWithoutPodcastTeachersInput;
};

export type CoachWhereInput = {
  AND?: InputMaybe<Array<CoachWhereInput>>;
  NOT?: InputMaybe<Array<CoachWhereInput>>;
  OR?: InputMaybe<Array<CoachWhereInput>>;
  adminRating?: InputMaybe<IntNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  coverImage?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  expertise?: InputMaybe<StringNullableFilter>;
  followedBy?: InputMaybe<TeacherFollowerListRelationFilter>;
  highlight?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  isAdminRate?: InputMaybe<StringFilter>;
  liveClasses?: InputMaybe<LiveClassListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  normalClasses?: InputMaybe<NormalClassListRelationFilter>;
  podcastTeachers?: InputMaybe<PodcastTeacherListRelationFilter>;
  resume?: InputMaybe<StringNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  signature?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<IntNullableFilter>;
  userRating?: InputMaybe<DecimalFilter>;
  yearOfExperience?: InputMaybe<IntNullableFilter>;
};

export type CoachWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Course = {
  __typename?: 'Course';
  contentType: Scalars['Int'];
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree: Scalars['Int'];
  highlight: Scalars['Boolean'];
  id: Scalars['Int'];
  images?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  viewCount?: Maybe<Scalars['Int']>;
  whatYouWillGet?: Maybe<Scalars['JSON']>;
  whatYouWillLearn?: Maybe<Scalars['JSON']>;
};

export type CourseAvgAggregate = {
  __typename?: 'CourseAvgAggregate';
  contentType?: Maybe<Scalars['Float']>;
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  enableFree?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  viewCount?: Maybe<Scalars['Float']>;
};

export type CourseAvgOrderByAggregateInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
};

export type CourseCountAggregate = {
  __typename?: 'CourseCountAggregate';
  _all: Scalars['Int'];
  contentType: Scalars['Int'];
  courseTotalRating: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  enableFree: Scalars['Int'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  images: Scalars['Int'];
  name: Scalars['Int'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  viewCount: Scalars['Int'];
  whatYouWillGet: Scalars['Int'];
  whatYouWillLearn: Scalars['Int'];
};

export type CourseCountOrderByAggregateInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
  whatYouWillGet?: InputMaybe<SortOrder>;
  whatYouWillLearn?: InputMaybe<SortOrder>;
};

export type CourseCreateInput = {
  contentType?: InputMaybe<Scalars['Int']>;
  courseTotalRating?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Boolean'];
  images?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
  whatYouWillGet?: InputMaybe<Scalars['JSON']>;
  whatYouWillLearn?: InputMaybe<Scalars['JSON']>;
};

export type CourseCreateManyInput = {
  contentType?: InputMaybe<Scalars['Int']>;
  courseTotalRating?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
  whatYouWillGet?: InputMaybe<Scalars['JSON']>;
  whatYouWillLearn?: InputMaybe<Scalars['JSON']>;
};

export type CourseGroupBy = {
  __typename?: 'CourseGroupBy';
  _avg?: Maybe<CourseAvgAggregate>;
  _count?: Maybe<CourseCountAggregate>;
  _max?: Maybe<CourseMaxAggregate>;
  _min?: Maybe<CourseMinAggregate>;
  _sum?: Maybe<CourseSumAggregate>;
  contentType: Scalars['Int'];
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree: Scalars['Int'];
  highlight: Scalars['Boolean'];
  id: Scalars['Int'];
  images?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  viewCount?: Maybe<Scalars['Int']>;
  whatYouWillGet?: Maybe<Scalars['JSON']>;
  whatYouWillLearn?: Maybe<Scalars['JSON']>;
};

export type CourseMaxAggregate = {
  __typename?: 'CourseMaxAggregate';
  contentType?: Maybe<Scalars['Int']>;
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enableFree?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type CourseMaxOrderByAggregateInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
};

export type CourseMinAggregate = {
  __typename?: 'CourseMinAggregate';
  contentType?: Maybe<Scalars['Int']>;
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enableFree?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type CourseMinOrderByAggregateInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
};

export type CourseOrderByWithAggregationInput = {
  _avg?: InputMaybe<CourseAvgOrderByAggregateInput>;
  _count?: InputMaybe<CourseCountOrderByAggregateInput>;
  _max?: InputMaybe<CourseMaxOrderByAggregateInput>;
  _min?: InputMaybe<CourseMinOrderByAggregateInput>;
  _sum?: InputMaybe<CourseSumOrderByAggregateInput>;
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
  whatYouWillGet?: InputMaybe<SortOrder>;
  whatYouWillLearn?: InputMaybe<SortOrder>;
};

export type CourseOrderByWithRelationInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
  whatYouWillGet?: InputMaybe<SortOrder>;
  whatYouWillLearn?: InputMaybe<SortOrder>;
};

export enum CourseScalarFieldEnum {
  ContentType = 'contentType',
  CourseTotalRating = 'courseTotalRating',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  EnableFree = 'enableFree',
  Highlight = 'highlight',
  Id = 'id',
  Images = 'images',
  Name = 'name',
  Sequence = 'sequence',
  ShortDescription = 'shortDescription',
  Slug = 'slug',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  ViewCount = 'viewCount',
  WhatYouWillGet = 'whatYouWillGet',
  WhatYouWillLearn = 'whatYouWillLearn',
}

export type CourseScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CourseScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CourseScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CourseScalarWhereWithAggregatesInput>>;
  contentType?: InputMaybe<IntWithAggregatesFilter>;
  courseTotalRating?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  enableFree?: InputMaybe<IntWithAggregatesFilter>;
  highlight?: InputMaybe<BoolWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  images?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  sequence?: InputMaybe<IntWithAggregatesFilter>;
  shortDescription?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  viewCount?: InputMaybe<IntNullableWithAggregatesFilter>;
  whatYouWillGet?: InputMaybe<JsonNullableWithAggregatesFilter>;
  whatYouWillLearn?: InputMaybe<JsonNullableWithAggregatesFilter>;
};

export type CourseSumAggregate = {
  __typename?: 'CourseSumAggregate';
  contentType?: Maybe<Scalars['Int']>;
  courseTotalRating?: Maybe<Scalars['Decimal']>;
  enableFree?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  viewCount?: Maybe<Scalars['Int']>;
};

export type CourseSumOrderByAggregateInput = {
  contentType?: InputMaybe<SortOrder>;
  courseTotalRating?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  viewCount?: InputMaybe<SortOrder>;
};

export type CourseUpdateInput = {
  contentType?: InputMaybe<Scalars['Int']>;
  courseTotalRating?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
  whatYouWillGet?: InputMaybe<Scalars['JSON']>;
  whatYouWillLearn?: InputMaybe<Scalars['JSON']>;
};

export type CourseUpdateManyMutationInput = {
  contentType?: InputMaybe<Scalars['Int']>;
  courseTotalRating?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  images?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewCount?: InputMaybe<Scalars['Int']>;
  whatYouWillGet?: InputMaybe<Scalars['JSON']>;
  whatYouWillLearn?: InputMaybe<Scalars['JSON']>;
};

export type CourseWhereInput = {
  AND?: InputMaybe<Array<CourseWhereInput>>;
  NOT?: InputMaybe<Array<CourseWhereInput>>;
  OR?: InputMaybe<Array<CourseWhereInput>>;
  contentType?: InputMaybe<IntFilter>;
  courseTotalRating?: InputMaybe<DecimalNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  viewCount?: InputMaybe<IntNullableFilter>;
  whatYouWillGet?: InputMaybe<JsonNullableFilter>;
  whatYouWillLearn?: InputMaybe<JsonNullableFilter>;
};

export type CourseWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type CreatePaymentRequestInput = {
  packageCode: Scalars['String'];
};

export type CreateViewHistoryInput = {
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DiscoverCoachMetadata = {
  __typename?: 'DiscoverCoachMetadata';
  avatar: Scalars['String'];
  coverImage: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  userId: Scalars['Int'];
};

export type DiscoverContentItemDto = {
  __typename?: 'DiscoverContentItemDto';
  category: Category;
  coach: DiscoverCoachMetadata;
  contentItemType: DiscoverContentItemType;
  description: Scalars['String'];
  duration: Scalars['Int'];
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  liveClassMetadata?: Maybe<DiscoverLiveClassMetadata>;
  portraitImages: Array<Scalars['String']>;
  shortDescription: Scalars['String'];
  title: Scalars['String'];
};

export enum DiscoverContentItemType {
  LiveClass = 'LiveClass',
  NormalClass = 'NormalClass',
}

export type DiscoverContentsDto = {
  __typename?: 'DiscoverContentsDto';
  channelId?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  items: Array<DiscoverContentItemDto>;
  verticalSlug?: Maybe<Scalars['String']>;
};

export type DiscoverLiveClassMetadata = {
  __typename?: 'DiscoverLiveClassMetadata';
  endTime: Scalars['DateTime'];
  specialEvent: Scalars['Boolean'];
  startTime: Scalars['DateTime'];
};

export type EnumBookmarkTargetTypeFilter = {
  equals?: InputMaybe<BookmarkTargetType>;
  in?: InputMaybe<Array<BookmarkTargetType>>;
  not?: InputMaybe<NestedEnumBookmarkTargetTypeFilter>;
  notIn?: InputMaybe<Array<BookmarkTargetType>>;
};

export type EnumCmsPageCategoryFilter = {
  equals?: InputMaybe<CmsPageCategory>;
  in?: InputMaybe<Array<CmsPageCategory>>;
  not?: InputMaybe<NestedEnumCmsPageCategoryFilter>;
  notIn?: InputMaybe<Array<CmsPageCategory>>;
};

export type EnumIncentiveTypeFilter = {
  equals?: InputMaybe<IncentiveType>;
  in?: InputMaybe<Array<IncentiveType>>;
  not?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  notIn?: InputMaybe<Array<IncentiveType>>;
};

export type EnumIncentiveTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  _min?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  equals?: InputMaybe<IncentiveType>;
  in?: InputMaybe<Array<IncentiveType>>;
  not?: InputMaybe<NestedEnumIncentiveTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<IncentiveType>>;
};

export type EnumLeaderboardPeriodFilter = {
  equals: LeaderboardPeriod;
};

export type EnumLiveClassTypeFilter = {
  equals?: InputMaybe<LiveClassType>;
};

export type EnumPlatformFilter = {
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type EnumQrCodeChallengeStatusFilter = {
  equals?: InputMaybe<QrCodeChallengeStatus>;
  in?: InputMaybe<Array<QrCodeChallengeStatus>>;
  not?: InputMaybe<NestedEnumQrCodeChallengeStatusFilter>;
  notIn?: InputMaybe<Array<QrCodeChallengeStatus>>;
};

export type EnumSubscriptionStatusFilter = {
  equals?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
};

export type EnumUserInformationTypeFilter = {
  equals?: InputMaybe<UserInformationType>;
  in?: InputMaybe<Array<UserInformationType>>;
  not?: InputMaybe<NestedEnumUserInformationTypeFilter>;
  notIn?: InputMaybe<Array<UserInformationType>>;
};

export type EnumViewTargetTypeFilter = {
  equals?: InputMaybe<ViewTargetType>;
  in?: InputMaybe<Array<ViewTargetType>>;
  not?: InputMaybe<NestedEnumViewTargetTypeFilter>;
  notIn?: InputMaybe<Array<ViewTargetType>>;
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  isQRCodeAuthEnabled: Scalars['Boolean'];
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FollowCoachInput = {
  id: Scalars['Int'];
};

export type GeneratePresignedFileInput = {
  fileName: Scalars['String'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
};

export type GetMyRefereesSummary = {
  __typename?: 'GetMyRefereesSummary';
  totalReferees: Scalars['Float'];
  totalRefereesSubscribed: Scalars['Float'];
};

export type GetMyRefereesSummaryInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
};

export type Incentive = {
  __typename?: 'Incentive';
  _count?: Maybe<IncentiveCount>;
  amount: Scalars['Decimal'];
  byAdminId?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  status: Scalars['Int'];
  type: IncentiveType;
  updatedAt: Scalars['DateTime'];
  userIncentives: Array<UserIncentive>;
};

export type IncentiveUserIncentivesArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserIncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type IncentiveAvgAggregate = {
  __typename?: 'IncentiveAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  byAdminId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type IncentiveAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type IncentiveCount = {
  __typename?: 'IncentiveCount';
  userIncentives: Scalars['Int'];
};

export type IncentiveCountAggregate = {
  __typename?: 'IncentiveCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  byAdminId: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type IncentiveCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type IncentiveCreateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutIncentiveInput>;
};

export type IncentiveCreateManyInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IncentiveCreateNestedOneWithoutUserIncentivesInput = {
  connect?: InputMaybe<IncentiveWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IncentiveCreateOrConnectWithoutUserIncentivesInput>;
  create?: InputMaybe<IncentiveCreateWithoutUserIncentivesInput>;
};

export type IncentiveCreateOrConnectWithoutUserIncentivesInput = {
  create: IncentiveCreateWithoutUserIncentivesInput;
  where: IncentiveWhereUniqueInput;
};

export type IncentiveCreateWithoutUserIncentivesInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IncentiveGroupBy = {
  __typename?: 'IncentiveGroupBy';
  _avg?: Maybe<IncentiveAvgAggregate>;
  _count?: Maybe<IncentiveCountAggregate>;
  _max?: Maybe<IncentiveMaxAggregate>;
  _min?: Maybe<IncentiveMinAggregate>;
  _sum?: Maybe<IncentiveSumAggregate>;
  amount: Scalars['Decimal'];
  byAdminId?: Maybe<Scalars['Int']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  name: Scalars['String'];
  status: Scalars['Int'];
  type: IncentiveType;
  updatedAt: Scalars['DateTime'];
};

export type IncentiveMaxAggregate = {
  __typename?: 'IncentiveMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  byAdminId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<IncentiveType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IncentiveMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type IncentiveMinAggregate = {
  __typename?: 'IncentiveMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  byAdminId?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<IncentiveType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IncentiveMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type IncentiveOrderByWithAggregationInput = {
  _avg?: InputMaybe<IncentiveAvgOrderByAggregateInput>;
  _count?: InputMaybe<IncentiveCountOrderByAggregateInput>;
  _max?: InputMaybe<IncentiveMaxOrderByAggregateInput>;
  _min?: InputMaybe<IncentiveMinOrderByAggregateInput>;
  _sum?: InputMaybe<IncentiveSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type IncentiveOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userIncentives?: InputMaybe<UserIncentiveOrderByRelationAggregateInput>;
};

export type IncentiveRelationFilter = {
  is?: InputMaybe<IncentiveWhereInput>;
  isNot?: InputMaybe<IncentiveWhereInput>;
};

export enum IncentiveScalarFieldEnum {
  Amount = 'amount',
  ByAdminId = 'byAdminId',
  Code = 'code',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type IncentiveScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<IncentiveScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<IncentiveScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<IncentiveScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  byAdminId?: InputMaybe<IntNullableWithAggregatesFilter>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currency?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  type?: InputMaybe<EnumIncentiveTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type IncentiveSumAggregate = {
  __typename?: 'IncentiveSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  byAdminId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
};

export type IncentiveSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  byAdminId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export enum IncentiveType {
  Cash = 'Cash',
  RewardPoints = 'RewardPoints',
}

export type IncentiveUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutIncentiveNestedInput>;
};

export type IncentiveUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IncentiveUpdateOneRequiredWithoutUserIncentivesNestedInput = {
  connect?: InputMaybe<IncentiveWhereUniqueInput>;
  connectOrCreate?: InputMaybe<IncentiveCreateOrConnectWithoutUserIncentivesInput>;
  create?: InputMaybe<IncentiveCreateWithoutUserIncentivesInput>;
  update?: InputMaybe<IncentiveUpdateWithoutUserIncentivesInput>;
  upsert?: InputMaybe<IncentiveUpsertWithoutUserIncentivesInput>;
};

export type IncentiveUpdateWithoutUserIncentivesInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  byAdminId?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type IncentiveUpsertWithoutUserIncentivesInput = {
  create: IncentiveCreateWithoutUserIncentivesInput;
  update: IncentiveUpdateWithoutUserIncentivesInput;
};

export type IncentiveWhereInput = {
  AND?: InputMaybe<Array<IncentiveWhereInput>>;
  NOT?: InputMaybe<Array<IncentiveWhereInput>>;
  OR?: InputMaybe<Array<IncentiveWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  byAdminId?: InputMaybe<IntNullableFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumIncentiveTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userIncentives?: InputMaybe<UserIncentiveListRelationFilter>;
};

export type IncentiveWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InterestTagging = {
  __typename?: 'InterestTagging';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  interestType: InterestType;
  interestTypeId: Scalars['BigInt'];
  tag: Tagging;
  tagId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InterestTaggingCreateManyInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tagId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingCreateManyInterestTypeInputEnvelope = {
  data: Array<InterestTaggingCreateManyInterestTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InterestTaggingCreateManyTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTypeId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingCreateManyTagInputEnvelope = {
  data: Array<InterestTaggingCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InterestTaggingCreateNestedManyWithoutInterestTypeInput = {
  connect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTaggingCreateOrConnectWithoutInterestTypeInput>>;
  create?: InputMaybe<Array<InterestTaggingCreateWithoutInterestTypeInput>>;
  createMany?: InputMaybe<InterestTaggingCreateManyInterestTypeInputEnvelope>;
};

export type InterestTaggingCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<InterestTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<InterestTaggingCreateManyTagInputEnvelope>;
};

export type InterestTaggingCreateOrConnectWithoutInterestTypeInput = {
  create: InterestTaggingCreateWithoutInterestTypeInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingCreateOrConnectWithoutTagInput = {
  create: InterestTaggingCreateWithoutTagInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingCreateWithoutInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag: TaggingCreateNestedOneWithoutInterestTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingCreateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestType: InterestTypeCreateNestedOneWithoutInterestTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingListRelationFilter = {
  every?: InputMaybe<InterestTaggingWhereInput>;
  none?: InputMaybe<InterestTaggingWhereInput>;
  some?: InputMaybe<InterestTaggingWhereInput>;
};

export type InterestTaggingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InterestTaggingOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  interestType?: InputMaybe<InterestTypeOrderByWithRelationInput>;
  interestTypeId?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TaggingOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum InterestTaggingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterestTypeId = 'interestTypeId',
  TagId = 'tagId',
  UpdatedAt = 'updatedAt',
}

export type InterestTaggingScalarWhereInput = {
  AND?: InputMaybe<Array<InterestTaggingScalarWhereInput>>;
  NOT?: InputMaybe<Array<InterestTaggingScalarWhereInput>>;
  OR?: InputMaybe<Array<InterestTaggingScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  interestTypeId?: InputMaybe<BigIntFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type InterestTaggingUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingUpdateManyWithWhereWithoutInterestTypeInput = {
  data: InterestTaggingUpdateManyMutationInput;
  where: InterestTaggingScalarWhereInput;
};

export type InterestTaggingUpdateManyWithWhereWithoutTagInput = {
  data: InterestTaggingUpdateManyMutationInput;
  where: InterestTaggingScalarWhereInput;
};

export type InterestTaggingUpdateManyWithoutInterestTypeNestedInput = {
  connect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTaggingCreateOrConnectWithoutInterestTypeInput>>;
  create?: InputMaybe<Array<InterestTaggingCreateWithoutInterestTypeInput>>;
  createMany?: InputMaybe<InterestTaggingCreateManyInterestTypeInputEnvelope>;
  delete?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InterestTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<InterestTaggingUpdateWithWhereUniqueWithoutInterestTypeInput>>;
  updateMany?: InputMaybe<Array<InterestTaggingUpdateManyWithWhereWithoutInterestTypeInput>>;
  upsert?: InputMaybe<Array<InterestTaggingUpsertWithWhereUniqueWithoutInterestTypeInput>>;
};

export type InterestTaggingUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<InterestTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<InterestTaggingCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InterestTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<InterestTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<InterestTaggingUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<InterestTaggingUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<InterestTaggingUpsertWithWhereUniqueWithoutTagInput>>;
};

export type InterestTaggingUpdateWithWhereUniqueWithoutInterestTypeInput = {
  data: InterestTaggingUpdateWithoutInterestTypeInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingUpdateWithWhereUniqueWithoutTagInput = {
  data: InterestTaggingUpdateWithoutTagInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingUpdateWithoutInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag?: InputMaybe<TaggingUpdateOneRequiredWithoutInterestTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingUpdateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestType?: InputMaybe<InterestTypeUpdateOneRequiredWithoutInterestTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTaggingUpsertWithWhereUniqueWithoutInterestTypeInput = {
  create: InterestTaggingCreateWithoutInterestTypeInput;
  update: InterestTaggingUpdateWithoutInterestTypeInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingUpsertWithWhereUniqueWithoutTagInput = {
  create: InterestTaggingCreateWithoutTagInput;
  update: InterestTaggingUpdateWithoutTagInput;
  where: InterestTaggingWhereUniqueInput;
};

export type InterestTaggingWhereInput = {
  AND?: InputMaybe<Array<InterestTaggingWhereInput>>;
  NOT?: InputMaybe<Array<InterestTaggingWhereInput>>;
  OR?: InputMaybe<Array<InterestTaggingWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  interestType?: InputMaybe<InterestTypeRelationFilter>;
  interestTypeId?: InputMaybe<BigIntFilter>;
  tag?: InputMaybe<TaggingRelationFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type InterestTaggingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type InterestType = {
  __typename?: 'InterestType';
  _count?: Maybe<InterestTypeCount>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  hasPreferred: Scalars['Boolean'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  interestTags: Array<InterestTagging>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userInterests: Array<UserInterest>;
  vertical?: Maybe<Vertical>;
  verticalId?: Maybe<Scalars['Int']>;
};

export type InterestTypeInterestTagsArgs = {
  cursor?: InputMaybe<InterestTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterestTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterestTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTaggingWhereInput>;
};

export type InterestTypeUserInterestsArgs = {
  cursor?: InputMaybe<UserInterestWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserInterestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserInterestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserInterestWhereInput>;
};

export type InterestTypeAvgAggregate = {
  __typename?: 'InterestTypeAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  verticalId?: Maybe<Scalars['Float']>;
};

export type InterestTypeAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeCount = {
  __typename?: 'InterestTypeCount';
  interestTags: Scalars['Int'];
  userInterests: Scalars['Int'];
};

export type InterestTypeCountAggregate = {
  __typename?: 'InterestTypeCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  name: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  verticalId: Scalars['Int'];
};

export type InterestTypeCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutInterestTypeInput>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutInterestTypeInput>;
  vertical?: InputMaybe<VerticalCreateNestedOneWithoutInterestTypesInput>;
};

export type InterestTypeCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verticalId?: InputMaybe<Scalars['Int']>;
};

export type InterestTypeCreateManyVerticalInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTypeCreateManyVerticalInputEnvelope = {
  data: Array<InterestTypeCreateManyVerticalInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InterestTypeCreateNestedManyWithoutVerticalInput = {
  connect?: InputMaybe<Array<InterestTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTypeCreateOrConnectWithoutVerticalInput>>;
  create?: InputMaybe<Array<InterestTypeCreateWithoutVerticalInput>>;
  createMany?: InputMaybe<InterestTypeCreateManyVerticalInputEnvelope>;
};

export type InterestTypeCreateNestedOneWithoutInterestTagsInput = {
  connect?: InputMaybe<InterestTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InterestTypeCreateOrConnectWithoutInterestTagsInput>;
  create?: InputMaybe<InterestTypeCreateWithoutInterestTagsInput>;
};

export type InterestTypeCreateNestedOneWithoutUserInterestsInput = {
  connect?: InputMaybe<InterestTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InterestTypeCreateOrConnectWithoutUserInterestsInput>;
  create?: InputMaybe<InterestTypeCreateWithoutUserInterestsInput>;
};

export type InterestTypeCreateOrConnectWithoutInterestTagsInput = {
  create: InterestTypeCreateWithoutInterestTagsInput;
  where: InterestTypeWhereUniqueInput;
};

export type InterestTypeCreateOrConnectWithoutUserInterestsInput = {
  create: InterestTypeCreateWithoutUserInterestsInput;
  where: InterestTypeWhereUniqueInput;
};

export type InterestTypeCreateOrConnectWithoutVerticalInput = {
  create: InterestTypeCreateWithoutVerticalInput;
  where: InterestTypeWhereUniqueInput;
};

export type InterestTypeCreateWithoutInterestTagsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutInterestTypeInput>;
  vertical?: InputMaybe<VerticalCreateNestedOneWithoutInterestTypesInput>;
};

export type InterestTypeCreateWithoutUserInterestsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutInterestTypeInput>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vertical?: InputMaybe<VerticalCreateNestedOneWithoutInterestTypesInput>;
};

export type InterestTypeCreateWithoutVerticalInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutInterestTypeInput>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutInterestTypeInput>;
};

export type InterestTypeGroupBy = {
  __typename?: 'InterestTypeGroupBy';
  _avg?: Maybe<InterestTypeAvgAggregate>;
  _count?: Maybe<InterestTypeCountAggregate>;
  _max?: Maybe<InterestTypeMaxAggregate>;
  _min?: Maybe<InterestTypeMinAggregate>;
  _sum?: Maybe<InterestTypeSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  verticalId?: Maybe<Scalars['Int']>;
};

export type InterestTypeListRelationFilter = {
  every?: InputMaybe<InterestTypeWhereInput>;
  none?: InputMaybe<InterestTypeWhereInput>;
  some?: InputMaybe<InterestTypeWhereInput>;
};

export type InterestTypeMaxAggregate = {
  __typename?: 'InterestTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  verticalId?: Maybe<Scalars['Int']>;
};

export type InterestTypeMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeMinAggregate = {
  __typename?: 'InterestTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  verticalId?: Maybe<Scalars['Int']>;
};

export type InterestTypeMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InterestTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<InterestTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<InterestTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<InterestTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<InterestTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<InterestTypeSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  interestTags?: InputMaybe<InterestTaggingOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userInterests?: InputMaybe<UserInterestOrderByRelationAggregateInput>;
  vertical?: InputMaybe<VerticalOrderByWithRelationInput>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeRelationFilter = {
  is?: InputMaybe<InterestTypeWhereInput>;
  isNot?: InputMaybe<InterestTypeWhereInput>;
};

export enum InterestTypeScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VerticalId = 'verticalId',
}

export type InterestTypeScalarWhereInput = {
  AND?: InputMaybe<Array<InterestTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<InterestTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<InterestTypeScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  verticalId?: InputMaybe<IntNullableFilter>;
};

export type InterestTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<InterestTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<InterestTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<InterestTypeScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  verticalId?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type InterestTypeSumAggregate = {
  __typename?: 'InterestTypeSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  verticalId?: Maybe<Scalars['Int']>;
};

export type InterestTypeSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  verticalId?: InputMaybe<SortOrder>;
};

export type InterestTypeUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutInterestTypeNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutInterestTypeNestedInput>;
  vertical?: InputMaybe<VerticalUpdateOneWithoutInterestTypesNestedInput>;
};

export type InterestTypeUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InterestTypeUpdateManyWithWhereWithoutVerticalInput = {
  data: InterestTypeUpdateManyMutationInput;
  where: InterestTypeScalarWhereInput;
};

export type InterestTypeUpdateManyWithoutVerticalNestedInput = {
  connect?: InputMaybe<Array<InterestTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InterestTypeCreateOrConnectWithoutVerticalInput>>;
  create?: InputMaybe<Array<InterestTypeCreateWithoutVerticalInput>>;
  createMany?: InputMaybe<InterestTypeCreateManyVerticalInputEnvelope>;
  delete?: InputMaybe<Array<InterestTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InterestTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InterestTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<InterestTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<InterestTypeUpdateWithWhereUniqueWithoutVerticalInput>>;
  updateMany?: InputMaybe<Array<InterestTypeUpdateManyWithWhereWithoutVerticalInput>>;
  upsert?: InputMaybe<Array<InterestTypeUpsertWithWhereUniqueWithoutVerticalInput>>;
};

export type InterestTypeUpdateOneRequiredWithoutInterestTagsNestedInput = {
  connect?: InputMaybe<InterestTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InterestTypeCreateOrConnectWithoutInterestTagsInput>;
  create?: InputMaybe<InterestTypeCreateWithoutInterestTagsInput>;
  update?: InputMaybe<InterestTypeUpdateWithoutInterestTagsInput>;
  upsert?: InputMaybe<InterestTypeUpsertWithoutInterestTagsInput>;
};

export type InterestTypeUpdateOneRequiredWithoutUserInterestsNestedInput = {
  connect?: InputMaybe<InterestTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<InterestTypeCreateOrConnectWithoutUserInterestsInput>;
  create?: InputMaybe<InterestTypeCreateWithoutUserInterestsInput>;
  update?: InputMaybe<InterestTypeUpdateWithoutUserInterestsInput>;
  upsert?: InputMaybe<InterestTypeUpsertWithoutUserInterestsInput>;
};

export type InterestTypeUpdateWithWhereUniqueWithoutVerticalInput = {
  data: InterestTypeUpdateWithoutVerticalInput;
  where: InterestTypeWhereUniqueInput;
};

export type InterestTypeUpdateWithoutInterestTagsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutInterestTypeNestedInput>;
  vertical?: InputMaybe<VerticalUpdateOneWithoutInterestTypesNestedInput>;
};

export type InterestTypeUpdateWithoutUserInterestsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutInterestTypeNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vertical?: InputMaybe<VerticalUpdateOneWithoutInterestTypesNestedInput>;
};

export type InterestTypeUpdateWithoutVerticalInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutInterestTypeNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutInterestTypeNestedInput>;
};

export type InterestTypeUpsertWithWhereUniqueWithoutVerticalInput = {
  create: InterestTypeCreateWithoutVerticalInput;
  update: InterestTypeUpdateWithoutVerticalInput;
  where: InterestTypeWhereUniqueInput;
};

export type InterestTypeUpsertWithoutInterestTagsInput = {
  create: InterestTypeCreateWithoutInterestTagsInput;
  update: InterestTypeUpdateWithoutInterestTagsInput;
};

export type InterestTypeUpsertWithoutUserInterestsInput = {
  create: InterestTypeCreateWithoutUserInterestsInput;
  update: InterestTypeUpdateWithoutUserInterestsInput;
};

export type InterestTypeWhereInput = {
  AND?: InputMaybe<Array<InterestTypeWhereInput>>;
  NOT?: InputMaybe<Array<InterestTypeWhereInput>>;
  OR?: InputMaybe<Array<InterestTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  interestTags?: InputMaybe<InterestTaggingListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userInterests?: InputMaybe<UserInterestListRelationFilter>;
  vertical?: InputMaybe<VerticalRelationFilter>;
  verticalId?: InputMaybe<IntNullableFilter>;
};

export type InterestTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Scalars['String']>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Scalars['String']>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type Leaderboard = {
  __typename?: 'Leaderboard';
  totalCerts: Scalars['Int'];
  user: User;
  userId: Scalars['BigInt'];
};

export enum LeaderboardPeriod {
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY',
}

export type LeaderboardWhereInput = {
  period: EnumLeaderboardPeriodFilter;
};

export type Learning = {
  __typename?: 'Learning';
  liveClass?: Maybe<LiveClass>;
  normalClass?: Maybe<NormalClass>;
};

export type LearningProfile = {
  __typename?: 'LearningProfile';
  totalCertificateCount: Scalars['Float'];
  totalClassAttended: Scalars['Float'];
  totalMinutesSpent: Scalars['Float'];
  totalOnLiveStreak: Scalars['Float'];
};

export type Library = {
  __typename?: 'Library';
  _count?: Maybe<NormalClassCount>;
  adminRating: Scalars['Int'];
  category?: Maybe<Category>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  classBookmarked: Array<UserBookmark>;
  classTags: Array<ClassTagging>;
  classViewed: Array<UserViewHistory>;
  classesBookmarks: Array<ClassesBookmarks>;
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['Int']>;
  contentType: Scalars['Int'];
  courseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree: Scalars['Int'];
  hasRated: Scalars['Boolean'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
  isViewed: Scalars['Boolean'];
  libraryBookmarked: Array<UserBookmark>;
  libraryViewed: Array<UserViewHistory>;
  likedBy: Array<NormalClassLike>;
  materials: Array<Material>;
  missionStatus: Scalars['Int'];
  missionTime: Scalars['Int'];
  portraitImages: Array<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingStatistic: ClassRatingStatistic;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: Maybe<Scalars['String']>;
  tags: Array<Tagging>;
  title: Scalars['String'];
  totalDuration: Scalars['Int'];
  totalShare: Scalars['BigInt'];
  totalView: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  videoRepoId: Scalars['String'];
  videoRepos: Array<VideoRepo>;
};

export type LibraryClassBookmarkedArgs = {
  cursor?: InputMaybe<UserBookmarkWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserBookmarkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserBookmarkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type LibraryClassTagsArgs = {
  cursor?: InputMaybe<ClassTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassTaggingWhereInput>;
};

export type LibraryClassViewedArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type LibraryClassesBookmarksArgs = {
  cursor?: InputMaybe<ClassesBookmarksWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassesBookmarksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassesBookmarksOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassesBookmarksWhereInput>;
};

export type LibraryLibraryBookmarkedArgs = {
  cursor?: InputMaybe<UserBookmarkWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserBookmarkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserBookmarkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type LibraryLibraryViewedArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type LibraryLikedByArgs = {
  cursor?: InputMaybe<NormalClassLikeWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassLikeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassLikeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassLikeWhereInput>;
};

export type LibraryOrderByWithRelationInput = {
  adminRating?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  classBookmarked?: InputMaybe<UserBookmarkOrderByRelationAggregateInput>;
  classTags?: InputMaybe<ClassTaggingOrderByRelationAggregateInput>;
  classViewed?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksOrderByRelationAggregateInput>;
  coach?: InputMaybe<CoachOrderByWithRelationInput>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  libraryBookmarked?: InputMaybe<UserBookmarkOrderByRelationAggregateInput>;
  libraryViewed?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
  likedBy?: InputMaybe<NormalClassLikeOrderByRelationAggregateInput>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type LibraryWhereInput = {
  AND?: InputMaybe<Array<NormalClassWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassWhereInput>>;
  OR?: InputMaybe<Array<NormalClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  classViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  classesBookmarks?: InputMaybe<ClassesBookmarksListRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  libraryBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  libraryViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  likedBy?: InputMaybe<NormalClassLikeListRelationFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoRepoId?: InputMaybe<StringFilter>;
};

export type LiveClass = {
  __typename?: 'LiveClass';
  _count?: Maybe<LiveClassCount>;
  adminRating: Scalars['Int'];
  category?: Maybe<Category>;
  certClaimableStatus: LiveClassCertClaimableStatus;
  certPrice: Scalars['Decimal'];
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  classId?: Maybe<Scalars['Int']>;
  classType: LiveClassType;
  closeTime?: Maybe<Scalars['DateTime']>;
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  contentType: Scalars['Int'];
  courseId: Scalars['Int'];
  createBy: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customDuration: Scalars['Int'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree: Scalars['Int'];
  endTime: Scalars['DateTime'];
  formattedEndDate: Scalars['String'];
  formattedScheduleTime: Scalars['String'];
  formattedStartDate: Scalars['String'];
  hasRated: Scalars['Boolean'];
  hasRegistered: Scalars['Boolean'];
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  isCertFree: Scalars['Int'];
  isViewed: Scalars['Boolean'];
  issuedCertificates: Array<Certificate>;
  joinableStatus: LiveClassJoinableStatus;
  liveClassRegistrations: Array<UserLiveClassRegistration>;
  liveClassTags: Array<LiveClassTagging>;
  materials: Array<Material>;
  payPerView: Scalars['Int'];
  payPerViewAmount: Scalars['Float'];
  portraitImages: Array<Scalars['String']>;
  previewId?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  ratingStatistic: ClassRatingStatistic;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: Maybe<Scalars['String']>;
  tags: Array<Tagging>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userViewHistories: Array<UserViewHistory>;
  videoEmbed?: Maybe<Scalars['String']>;
};

export type LiveClassFormattedEndDateArgs = {
  timezone: Scalars['String'];
};

export type LiveClassFormattedScheduleTimeArgs = {
  timezone: Scalars['String'];
};

export type LiveClassFormattedStartDateArgs = {
  timezone: Scalars['String'];
};

export type LiveClassIssuedCertificatesArgs = {
  cursor?: InputMaybe<CertificateWhereUniqueInput>;
  distinct?: InputMaybe<Array<CertificateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type LiveClassLiveClassRegistrationsArgs = {
  cursor?: InputMaybe<UserLiveClassRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLiveClassRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLiveClassRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserLiveClassRegistrationWhereInput>;
};

export type LiveClassLiveClassTagsArgs = {
  cursor?: InputMaybe<LiveClassTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<LiveClassTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LiveClassTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassTaggingWhereInput>;
};

export type LiveClassUserViewHistoriesArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type LiveClassAssessment = {
  __typename?: 'LiveClassAssessment';
  assessment: Assessment;
  assessmentId: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  liveClassId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LiveClassAssessmentCreateManyAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassAssessmentCreateManyAssessmentInputEnvelope = {
  data: Array<LiveClassAssessmentCreateManyAssessmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LiveClassAssessmentCreateNestedManyWithoutAssessmentInput = {
  connect?: InputMaybe<Array<LiveClassAssessmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassAssessmentCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<LiveClassAssessmentCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<LiveClassAssessmentCreateManyAssessmentInputEnvelope>;
};

export type LiveClassAssessmentCreateOrConnectWithoutAssessmentInput = {
  create: LiveClassAssessmentCreateWithoutAssessmentInput;
  where: LiveClassAssessmentWhereUniqueInput;
};

export type LiveClassAssessmentCreateWithoutAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassAssessmentListRelationFilter = {
  every?: InputMaybe<LiveClassAssessmentWhereInput>;
  none?: InputMaybe<LiveClassAssessmentWhereInput>;
  some?: InputMaybe<LiveClassAssessmentWhereInput>;
};

export type LiveClassAssessmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LiveClassAssessmentOrderByWithRelationInput = {
  assessment?: InputMaybe<AssessmentOrderByWithRelationInput>;
  assessmentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LiveClassAssessmentScalarFieldEnum {
  AssessmentId = 'assessmentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  LiveClassId = 'liveClassId',
  UpdatedAt = 'updatedAt',
}

export type LiveClassAssessmentScalarWhereInput = {
  AND?: InputMaybe<Array<LiveClassAssessmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassAssessmentScalarWhereInput>>;
  OR?: InputMaybe<Array<LiveClassAssessmentScalarWhereInput>>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LiveClassAssessmentUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassAssessmentUpdateManyWithWhereWithoutAssessmentInput = {
  data: LiveClassAssessmentUpdateManyMutationInput;
  where: LiveClassAssessmentScalarWhereInput;
};

export type LiveClassAssessmentUpdateManyWithoutAssessmentNestedInput = {
  connect?: InputMaybe<Array<LiveClassAssessmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassAssessmentCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<LiveClassAssessmentCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<LiveClassAssessmentCreateManyAssessmentInputEnvelope>;
  delete?: InputMaybe<Array<LiveClassAssessmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveClassAssessmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveClassAssessmentWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveClassAssessmentWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveClassAssessmentUpdateWithWhereUniqueWithoutAssessmentInput>>;
  updateMany?: InputMaybe<Array<LiveClassAssessmentUpdateManyWithWhereWithoutAssessmentInput>>;
  upsert?: InputMaybe<Array<LiveClassAssessmentUpsertWithWhereUniqueWithoutAssessmentInput>>;
};

export type LiveClassAssessmentUpdateWithWhereUniqueWithoutAssessmentInput = {
  data: LiveClassAssessmentUpdateWithoutAssessmentInput;
  where: LiveClassAssessmentWhereUniqueInput;
};

export type LiveClassAssessmentUpdateWithoutAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassAssessmentUpsertWithWhereUniqueWithoutAssessmentInput = {
  create: LiveClassAssessmentCreateWithoutAssessmentInput;
  update: LiveClassAssessmentUpdateWithoutAssessmentInput;
  where: LiveClassAssessmentWhereUniqueInput;
};

export type LiveClassAssessmentWhereInput = {
  AND?: InputMaybe<Array<LiveClassAssessmentWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassAssessmentWhereInput>>;
  OR?: InputMaybe<Array<LiveClassAssessmentWhereInput>>;
  assessment?: InputMaybe<AssessmentRelationFilter>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LiveClassAssessmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export enum LiveClassCertClaimableStatus {
  Claimable = 'CLAIMABLE',
  Claimed = 'CLAIMED',
  Expired = 'EXPIRED',
  NotAvailable = 'NOT_AVAILABLE',
}

export type LiveClassCoachesWhereInput = {
  AND?: InputMaybe<Array<CoachWhereInput>>;
  NOT?: InputMaybe<Array<CoachWhereInput>>;
  OR?: InputMaybe<Array<CoachWhereInput>>;
  adminRating?: InputMaybe<IntNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  classType?: InputMaybe<EnumLiveClassTypeFilter>;
  coverImage?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  expertise?: InputMaybe<StringNullableFilter>;
  followedBy?: InputMaybe<TeacherFollowerListRelationFilter>;
  highlight?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  isAdminRate?: InputMaybe<StringFilter>;
  liveClasses?: InputMaybe<LiveClassListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  nationality?: InputMaybe<StringNullableFilter>;
  normalClasses?: InputMaybe<NormalClassListRelationFilter>;
  podcastTeachers?: InputMaybe<PodcastTeacherListRelationFilter>;
  resume?: InputMaybe<StringNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  signature?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<IntNullableFilter>;
  userRating?: InputMaybe<DecimalFilter>;
  verticalId?: InputMaybe<Scalars['Int']>;
  yearOfExperience?: InputMaybe<IntNullableFilter>;
};

export type LiveClassCount = {
  __typename?: 'LiveClassCount';
  issuedCertificates: Scalars['Int'];
  liveClassRegistrations: Scalars['Int'];
  liveClassTags: Scalars['Int'];
  userViewHistories: Scalars['Int'];
};

export type LiveClassCreateManyChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coachId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateManyChannelInputEnvelope = {
  data: Array<LiveClassCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LiveClassCreateManyCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channelId?: InputMaybe<Scalars['Int']>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateManyCoachInputEnvelope = {
  data: Array<LiveClassCreateManyCoachInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LiveClassCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<LiveClassCreateWithoutChannelInput>>;
  createMany?: InputMaybe<LiveClassCreateManyChannelInputEnvelope>;
};

export type LiveClassCreateNestedManyWithoutCoachInput = {
  connect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<LiveClassCreateWithoutCoachInput>>;
  createMany?: InputMaybe<LiveClassCreateManyCoachInputEnvelope>;
};

export type LiveClassCreateNestedOneWithoutIssuedCertificatesInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutIssuedCertificatesInput>;
  create?: InputMaybe<LiveClassCreateWithoutIssuedCertificatesInput>;
};

export type LiveClassCreateNestedOneWithoutLiveClassTagsInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutLiveClassTagsInput>;
  create?: InputMaybe<LiveClassCreateWithoutLiveClassTagsInput>;
};

export type LiveClassCreateNestedOneWithoutUserViewHistoriesInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutUserViewHistoriesInput>;
  create?: InputMaybe<LiveClassCreateWithoutUserViewHistoriesInput>;
};

export type LiveClassCreateOrConnectWithoutChannelInput = {
  create: LiveClassCreateWithoutChannelInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassCreateOrConnectWithoutCoachInput = {
  create: LiveClassCreateWithoutCoachInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassCreateOrConnectWithoutIssuedCertificatesInput = {
  create: LiveClassCreateWithoutIssuedCertificatesInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassCreateOrConnectWithoutLiveClassTagsInput = {
  create: LiveClassCreateWithoutLiveClassTagsInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassCreateOrConnectWithoutUserViewHistoriesInput = {
  create: LiveClassCreateWithoutUserViewHistoriesInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassCreateWithoutChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutLiveClassesInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateCreateNestedManyWithoutLiveClassInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutLiveClassInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLiveClassInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateWithoutCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutLiveClassesInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateCreateNestedManyWithoutLiveClassInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutLiveClassInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLiveClassInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateWithoutIssuedCertificatesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutLiveClassesInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutLiveClassesInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutLiveClassInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLiveClassInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateWithoutLiveClassTagsInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutLiveClassesInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutLiveClassesInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateCreateNestedManyWithoutLiveClassInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLiveClassInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassCreateWithoutUserViewHistoriesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutLiveClassesInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutLiveClassesInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  durationTime: Scalars['Int'];
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime: Scalars['DateTime'];
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateCreateNestedManyWithoutLiveClassInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutLiveClassInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId: Scalars['String'];
  roomPasscode: Scalars['String'];
  sequence: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime: Scalars['DateTime'];
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export enum LiveClassJoinableStatus {
  ClassEnded = 'CLASS_ENDED',
  JoinNow = 'JOIN_NOW',
  NotRegistered = 'NOT_REGISTERED',
  Registered = 'REGISTERED',
}

export type LiveClassListRelationFilter = {
  every?: InputMaybe<LiveClassWhereInput>;
  none?: InputMaybe<LiveClassWhereInput>;
  some?: InputMaybe<LiveClassWhereInput>;
};

export type LiveClassOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LiveClassOrderByWithRelationInput = {
  adminRating?: InputMaybe<SortOrder>;
  certPrice?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  classId?: InputMaybe<SortOrder>;
  classType?: InputMaybe<SortOrder>;
  closeTime?: InputMaybe<SortOrder>;
  coach?: InputMaybe<CoachOrderByWithRelationInput>;
  coachId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createBy?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customDuration?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  durationTime?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  endTime?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  isCertFree?: InputMaybe<SortOrder>;
  issuedCertificates?: InputMaybe<CertificateOrderByRelationAggregateInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationOrderByRelationAggregateInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingOrderByRelationAggregateInput>;
  materialIds?: InputMaybe<SortOrder>;
  payPerView?: InputMaybe<SortOrder>;
  payPerViewAmount?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  previewId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  roomId?: InputMaybe<SortOrder>;
  roomPasscode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  specialEvent?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userViewHistories?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
  videoEmbed?: InputMaybe<SortOrder>;
};

export type LiveClassRelationFilter = {
  is?: InputMaybe<LiveClassWhereInput>;
  isNot?: InputMaybe<LiveClassWhereInput>;
};

export enum LiveClassScalarFieldEnum {
  AdminRating = 'adminRating',
  CertPrice = 'certPrice',
  ChannelId = 'channelId',
  ClassId = 'classId',
  ClassType = 'classType',
  CloseTime = 'closeTime',
  CoachId = 'coachId',
  CompanyId = 'companyId',
  ContentType = 'contentType',
  CourseId = 'courseId',
  CreateBy = 'createBy',
  CreatedAt = 'createdAt',
  CustomDuration = 'customDuration',
  DeletedAt = 'deletedAt',
  Description = 'description',
  DurationTime = 'durationTime',
  EnableFree = 'enableFree',
  EndTime = 'endTime',
  Id = 'id',
  Images = 'images',
  IsCertFree = 'isCertFree',
  MaterialIds = 'materialIds',
  PayPerView = 'payPerView',
  PayPerViewAmount = 'payPerViewAmount',
  PortraitImages = 'portraitImages',
  PreviewId = 'previewId',
  Rating = 'rating',
  RoomId = 'roomId',
  RoomPasscode = 'roomPasscode',
  Sequence = 'sequence',
  ShortDescription = 'shortDescription',
  Slug = 'slug',
  SpecialEvent = 'specialEvent',
  StartTime = 'startTime',
  Status = 'status',
  TagId = 'tagId',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  VideoEmbed = 'videoEmbed',
}

export type LiveClassScalarWhereInput = {
  AND?: InputMaybe<Array<LiveClassScalarWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassScalarWhereInput>>;
  OR?: InputMaybe<Array<LiveClassScalarWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  certPrice?: InputMaybe<DecimalFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  classType?: InputMaybe<IntFilter>;
  closeTime?: InputMaybe<DateTimeNullableFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createBy?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customDuration?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  durationTime?: InputMaybe<IntFilter>;
  enableFree?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringFilter>;
  isCertFree?: InputMaybe<IntFilter>;
  materialIds?: InputMaybe<StringNullableFilter>;
  payPerView?: InputMaybe<IntFilter>;
  payPerViewAmount?: InputMaybe<FloatFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  previewId?: InputMaybe<IntNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  roomId?: InputMaybe<StringFilter>;
  roomPasscode?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  specialEvent?: InputMaybe<BoolNullableFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoEmbed?: InputMaybe<StringNullableFilter>;
};

export type LiveClassTagging = {
  __typename?: 'LiveClassTagging';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  liveClass: LiveClass;
  liveClassId: Scalars['Int'];
  tag: Tagging;
  tagId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LiveClassTaggingCreateManyLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tagId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingCreateManyLiveClassInputEnvelope = {
  data: Array<LiveClassTaggingCreateManyLiveClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LiveClassTaggingCreateManyTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingCreateManyTagInputEnvelope = {
  data: Array<LiveClassTaggingCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LiveClassTaggingCreateNestedManyWithoutLiveClassInput = {
  connect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassTaggingCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<LiveClassTaggingCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<LiveClassTaggingCreateManyLiveClassInputEnvelope>;
};

export type LiveClassTaggingCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<LiveClassTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<LiveClassTaggingCreateManyTagInputEnvelope>;
};

export type LiveClassTaggingCreateOrConnectWithoutLiveClassInput = {
  create: LiveClassTaggingCreateWithoutLiveClassInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingCreateOrConnectWithoutTagInput = {
  create: LiveClassTaggingCreateWithoutTagInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingCreateWithoutLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag: TaggingCreateNestedOneWithoutLiveClassTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingCreateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClass: LiveClassCreateNestedOneWithoutLiveClassTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingListRelationFilter = {
  every?: InputMaybe<LiveClassTaggingWhereInput>;
  none?: InputMaybe<LiveClassTaggingWhereInput>;
  some?: InputMaybe<LiveClassTaggingWhereInput>;
};

export type LiveClassTaggingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LiveClassTaggingOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  liveClass?: InputMaybe<LiveClassOrderByWithRelationInput>;
  liveClassId?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TaggingOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LiveClassTaggingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  LiveClassId = 'liveClassId',
  TagId = 'tagId',
  UpdatedAt = 'updatedAt',
}

export type LiveClassTaggingScalarWhereInput = {
  AND?: InputMaybe<Array<LiveClassTaggingScalarWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassTaggingScalarWhereInput>>;
  OR?: InputMaybe<Array<LiveClassTaggingScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LiveClassTaggingUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingUpdateManyWithWhereWithoutLiveClassInput = {
  data: LiveClassTaggingUpdateManyMutationInput;
  where: LiveClassTaggingScalarWhereInput;
};

export type LiveClassTaggingUpdateManyWithWhereWithoutTagInput = {
  data: LiveClassTaggingUpdateManyMutationInput;
  where: LiveClassTaggingScalarWhereInput;
};

export type LiveClassTaggingUpdateManyWithoutLiveClassNestedInput = {
  connect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassTaggingCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<LiveClassTaggingCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<LiveClassTaggingCreateManyLiveClassInputEnvelope>;
  delete?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveClassTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveClassTaggingUpdateWithWhereUniqueWithoutLiveClassInput>>;
  updateMany?: InputMaybe<Array<LiveClassTaggingUpdateManyWithWhereWithoutLiveClassInput>>;
  upsert?: InputMaybe<Array<LiveClassTaggingUpsertWithWhereUniqueWithoutLiveClassInput>>;
};

export type LiveClassTaggingUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassTaggingCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<LiveClassTaggingCreateWithoutTagInput>>;
  createMany?: InputMaybe<LiveClassTaggingCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveClassTaggingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveClassTaggingWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveClassTaggingUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<LiveClassTaggingUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<LiveClassTaggingUpsertWithWhereUniqueWithoutTagInput>>;
};

export type LiveClassTaggingUpdateWithWhereUniqueWithoutLiveClassInput = {
  data: LiveClassTaggingUpdateWithoutLiveClassInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingUpdateWithWhereUniqueWithoutTagInput = {
  data: LiveClassTaggingUpdateWithoutTagInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingUpdateWithoutLiveClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag?: InputMaybe<TaggingUpdateOneRequiredWithoutLiveClassTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingUpdateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClass?: InputMaybe<LiveClassUpdateOneRequiredWithoutLiveClassTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LiveClassTaggingUpsertWithWhereUniqueWithoutLiveClassInput = {
  create: LiveClassTaggingCreateWithoutLiveClassInput;
  update: LiveClassTaggingUpdateWithoutLiveClassInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingUpsertWithWhereUniqueWithoutTagInput = {
  create: LiveClassTaggingCreateWithoutTagInput;
  update: LiveClassTaggingUpdateWithoutTagInput;
  where: LiveClassTaggingWhereUniqueInput;
};

export type LiveClassTaggingWhereInput = {
  AND?: InputMaybe<Array<LiveClassTaggingWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassTaggingWhereInput>>;
  OR?: InputMaybe<Array<LiveClassTaggingWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClass?: InputMaybe<LiveClassRelationFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  tag?: InputMaybe<TaggingRelationFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type LiveClassTaggingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export enum LiveClassType {
  Onference = 'ONFERENCE',
  Onlive = 'ONLIVE',
  Onstage = 'ONSTAGE',
  Ppe = 'PPE',
  Special = 'SPECIAL',
}

export type LiveClassUpdateManyMutationInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpdateManyWithWhereWithoutChannelInput = {
  data: LiveClassUpdateManyMutationInput;
  where: LiveClassScalarWhereInput;
};

export type LiveClassUpdateManyWithWhereWithoutCoachInput = {
  data: LiveClassUpdateManyMutationInput;
  where: LiveClassScalarWhereInput;
};

export type LiveClassUpdateManyWithoutChannelNestedInput = {
  connect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<LiveClassCreateWithoutChannelInput>>;
  createMany?: InputMaybe<LiveClassCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveClassUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<LiveClassUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<LiveClassUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type LiveClassUpdateManyWithoutCoachNestedInput = {
  connect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LiveClassCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<LiveClassCreateWithoutCoachInput>>;
  createMany?: InputMaybe<LiveClassCreateManyCoachInputEnvelope>;
  delete?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LiveClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  set?: InputMaybe<Array<LiveClassWhereUniqueInput>>;
  update?: InputMaybe<Array<LiveClassUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<LiveClassUpdateManyWithWhereWithoutCoachInput>>;
  upsert?: InputMaybe<Array<LiveClassUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type LiveClassUpdateOneRequiredWithoutIssuedCertificatesNestedInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutIssuedCertificatesInput>;
  create?: InputMaybe<LiveClassCreateWithoutIssuedCertificatesInput>;
  update?: InputMaybe<LiveClassUpdateWithoutIssuedCertificatesInput>;
  upsert?: InputMaybe<LiveClassUpsertWithoutIssuedCertificatesInput>;
};

export type LiveClassUpdateOneRequiredWithoutLiveClassTagsNestedInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutLiveClassTagsInput>;
  create?: InputMaybe<LiveClassCreateWithoutLiveClassTagsInput>;
  update?: InputMaybe<LiveClassUpdateWithoutLiveClassTagsInput>;
  upsert?: InputMaybe<LiveClassUpsertWithoutLiveClassTagsInput>;
};

export type LiveClassUpdateOneWithoutUserViewHistoriesNestedInput = {
  connect?: InputMaybe<LiveClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LiveClassCreateOrConnectWithoutUserViewHistoriesInput>;
  create?: InputMaybe<LiveClassCreateWithoutUserViewHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LiveClassUpdateWithoutUserViewHistoriesInput>;
  upsert?: InputMaybe<LiveClassUpsertWithoutUserViewHistoriesInput>;
};

export type LiveClassUpdateWithWhereUniqueWithoutChannelInput = {
  data: LiveClassUpdateWithoutChannelInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassUpdateWithWhereUniqueWithoutCoachInput = {
  data: LiveClassUpdateWithoutCoachInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassUpdateWithoutChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachUpdateOneWithoutLiveClassesNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateUpdateManyWithoutLiveClassNestedInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutLiveClassNestedInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutLiveClassNestedInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpdateWithoutCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutLiveClassesNestedInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateUpdateManyWithoutLiveClassNestedInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutLiveClassNestedInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutLiveClassNestedInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpdateWithoutIssuedCertificatesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutLiveClassesNestedInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachUpdateOneWithoutLiveClassesNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutLiveClassNestedInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutLiveClassNestedInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpdateWithoutLiveClassTagsInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutLiveClassesNestedInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachUpdateOneWithoutLiveClassesNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateUpdateManyWithoutLiveClassNestedInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutLiveClassNestedInput>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpdateWithoutUserViewHistoriesInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  certPrice?: InputMaybe<Scalars['Decimal']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutLiveClassesNestedInput>;
  classId?: InputMaybe<Scalars['Int']>;
  classType?: InputMaybe<Scalars['Int']>;
  closeTime?: InputMaybe<Scalars['DateTime']>;
  coach?: InputMaybe<CoachUpdateOneWithoutLiveClassesNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createBy?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customDuration?: InputMaybe<Scalars['Int']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  durationTime?: InputMaybe<Scalars['Int']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  images?: InputMaybe<Scalars['String']>;
  isCertFree?: InputMaybe<Scalars['Int']>;
  issuedCertificates?: InputMaybe<CertificateUpdateManyWithoutLiveClassNestedInput>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutLiveClassNestedInput>;
  materialIds?: InputMaybe<Scalars['String']>;
  payPerView?: InputMaybe<Scalars['Int']>;
  payPerViewAmount?: InputMaybe<Scalars['Float']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  previewId?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['Int']>;
  roomId?: InputMaybe<Scalars['String']>;
  roomPasscode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  specialEvent?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoEmbed?: InputMaybe<Scalars['String']>;
};

export type LiveClassUpsertWithWhereUniqueWithoutChannelInput = {
  create: LiveClassCreateWithoutChannelInput;
  update: LiveClassUpdateWithoutChannelInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassUpsertWithWhereUniqueWithoutCoachInput = {
  create: LiveClassCreateWithoutCoachInput;
  update: LiveClassUpdateWithoutCoachInput;
  where: LiveClassWhereUniqueInput;
};

export type LiveClassUpsertWithoutIssuedCertificatesInput = {
  create: LiveClassCreateWithoutIssuedCertificatesInput;
  update: LiveClassUpdateWithoutIssuedCertificatesInput;
};

export type LiveClassUpsertWithoutLiveClassTagsInput = {
  create: LiveClassCreateWithoutLiveClassTagsInput;
  update: LiveClassUpdateWithoutLiveClassTagsInput;
};

export type LiveClassUpsertWithoutUserViewHistoriesInput = {
  create: LiveClassCreateWithoutUserViewHistoriesInput;
  update: LiveClassUpdateWithoutUserViewHistoriesInput;
};

export type LiveClassWhereInput = {
  AND?: InputMaybe<Array<LiveClassWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassWhereInput>>;
  OR?: InputMaybe<Array<LiveClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  certPrice?: InputMaybe<DecimalFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  classType?: InputMaybe<IntFilter>;
  closeTime?: InputMaybe<DateTimeNullableFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createBy?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customDuration?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  durationTime?: InputMaybe<IntFilter>;
  enableFree?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringFilter>;
  isCertFree?: InputMaybe<IntFilter>;
  issuedCertificates?: InputMaybe<CertificateListRelationFilter>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationListRelationFilter>;
  liveClassTags?: InputMaybe<LiveClassTaggingListRelationFilter>;
  materialIds?: InputMaybe<StringNullableFilter>;
  payPerView?: InputMaybe<IntFilter>;
  payPerViewAmount?: InputMaybe<FloatFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  previewId?: InputMaybe<IntNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  roomId?: InputMaybe<StringFilter>;
  roomPasscode?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  specialEvent?: InputMaybe<BoolNullableFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
  videoEmbed?: InputMaybe<StringNullableFilter>;
};

export type LiveClassWhereInputV2 = {
  AND?: InputMaybe<Array<LiveClassWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassWhereInput>>;
  OR?: InputMaybe<Array<LiveClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  certPrice?: InputMaybe<DecimalFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  classType?: InputMaybe<EnumLiveClassTypeFilter>;
  closeTime?: InputMaybe<DateTimeNullableFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createBy?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customDuration?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  durationTime?: InputMaybe<IntFilter>;
  enableFree?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringFilter>;
  isCertFree?: InputMaybe<IntFilter>;
  issuedCertificates?: InputMaybe<CertificateListRelationFilter>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationListRelationFilter>;
  liveClassTags?: InputMaybe<LiveClassTaggingListRelationFilter>;
  materialIds?: InputMaybe<StringNullableFilter>;
  payPerView?: InputMaybe<IntFilter>;
  payPerViewAmount?: InputMaybe<FloatFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  previewId?: InputMaybe<IntNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  roomId?: InputMaybe<StringFilter>;
  roomPasscode?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  specialEvent?: InputMaybe<BoolNullableFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
  videoEmbed?: InputMaybe<StringNullableFilter>;
};

export type LiveClassWhereUniqueInput = {
  classId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type LoginInput = {
  encrypt?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type Material = {
  __typename?: 'Material';
  authorName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  status: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MaterialAvgAggregate = {
  __typename?: 'MaterialAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type MaterialAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type MaterialCountAggregate = {
  __typename?: 'MaterialCountAggregate';
  _all: Scalars['Int'];
  authorName: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  filePath: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MaterialCountOrderByAggregateInput = {
  authorName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  filePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaterialCreateInput = {
  authorName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MaterialCreateManyInput = {
  authorName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MaterialGroupBy = {
  __typename?: 'MaterialGroupBy';
  _avg?: Maybe<MaterialAvgAggregate>;
  _count?: Maybe<MaterialCountAggregate>;
  _max?: Maybe<MaterialMaxAggregate>;
  _min?: Maybe<MaterialMinAggregate>;
  _sum?: Maybe<MaterialSumAggregate>;
  authorName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  status: Scalars['Boolean'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MaterialMaxAggregate = {
  __typename?: 'MaterialMaxAggregate';
  authorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MaterialMaxOrderByAggregateInput = {
  authorName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  filePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaterialMinAggregate = {
  __typename?: 'MaterialMinAggregate';
  authorName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  filePath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MaterialMinOrderByAggregateInput = {
  authorName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  filePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaterialOrderByWithAggregationInput = {
  _avg?: InputMaybe<MaterialAvgOrderByAggregateInput>;
  _count?: InputMaybe<MaterialCountOrderByAggregateInput>;
  _max?: InputMaybe<MaterialMaxOrderByAggregateInput>;
  _min?: InputMaybe<MaterialMinOrderByAggregateInput>;
  _sum?: InputMaybe<MaterialSumOrderByAggregateInput>;
  authorName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  filePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MaterialOrderByWithRelationInput = {
  authorName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  filePath?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MaterialScalarFieldEnum {
  AuthorName = 'authorName',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  FilePath = 'filePath',
  Id = 'id',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type MaterialScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MaterialScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MaterialScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MaterialScalarWhereWithAggregatesInput>>;
  authorName?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  filePath?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<BoolWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MaterialSumAggregate = {
  __typename?: 'MaterialSumAggregate';
  id?: Maybe<Scalars['Int']>;
};

export type MaterialSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type MaterialUpdateInput = {
  authorName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MaterialUpdateManyMutationInput = {
  authorName?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MaterialWhereInput = {
  AND?: InputMaybe<Array<MaterialWhereInput>>;
  NOT?: InputMaybe<Array<MaterialWhereInput>>;
  OR?: InputMaybe<Array<MaterialWhereInput>>;
  authorName?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  filePath?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  status?: InputMaybe<BoolFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MaterialWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptQRCodeChallenge: Scalars['Boolean'];
  adminLogin: Auth;
  cancelQRCodeChallenge: Scalars['Boolean'];
  createManyAssessment: AffectedRowsOutput;
  createManyBannerType: AffectedRowsOutput;
  createManyCategory: AffectedRowsOutput;
  createManyCertificate: AffectedRowsOutput;
  createManyClassRating: AffectedRowsOutput;
  createManyCourse: AffectedRowsOutput;
  createManyIncentive: AffectedRowsOutput;
  createManyInterestType: AffectedRowsOutput;
  createManyMaterial: AffectedRowsOutput;
  createManyNewsUpdate: AffectedRowsOutput;
  createManyNormalClass: AffectedRowsOutput;
  createManyPackageType: AffectedRowsOutput;
  createManyPodcast: AffectedRowsOutput;
  createManyPopupBanner: AffectedRowsOutput;
  createManyPushNotification: AffectedRowsOutput;
  createManyQuestion: AffectedRowsOutput;
  createManySystemConfig: AffectedRowsOutput;
  createManyTagging: AffectedRowsOutput;
  createManyTransaction: AffectedRowsOutput;
  createManyUserIncentive: AffectedRowsOutput;
  createManyUserSetting: AffectedRowsOutput;
  createManyVertical: AffectedRowsOutput;
  createManyVideoRepo: AffectedRowsOutput;
  createOneAssessment: Assessment;
  createOneBannerType: BannerType;
  createOneCategory: Category;
  createOneCertificate: Certificate;
  createOneClassRating: ClassRating;
  createOneCourse: Course;
  createOneIncentive: Incentive;
  createOneInterestType: InterestType;
  createOneMaterial: Material;
  createOneNewsUpdate: NewsUpdate;
  createOneNormalClass: NormalClass;
  createOnePackageType: PackageType;
  createOnePodcast: Podcast;
  createOnePopupBanner: PopupBanner;
  createOnePushNotification: PushNotification;
  createOneQuestion: Question;
  createOneSystemConfig: SystemConfig;
  createOneTagging: Tagging;
  createOneTransaction: Transaction;
  createOneUserIncentive: UserIncentive;
  createOneUserSetting: UserSetting;
  createOneVertical: Vertical;
  createOneVideoRepo: VideoRepo;
  createPaymentRequest: PaymentRequest;
  createViewHistory: StatusResponse;
  deactivateAccount: StatusResponse;
  deleteManyAssessment: AffectedRowsOutput;
  deleteManyBannerType: AffectedRowsOutput;
  deleteManyCategory: AffectedRowsOutput;
  deleteManyCertificate: AffectedRowsOutput;
  deleteManyClassRating: AffectedRowsOutput;
  deleteManyCourse: AffectedRowsOutput;
  deleteManyIncentive: AffectedRowsOutput;
  deleteManyInterestType: AffectedRowsOutput;
  deleteManyMaterial: AffectedRowsOutput;
  deleteManyNewsUpdate: AffectedRowsOutput;
  deleteManyNormalClass: AffectedRowsOutput;
  deleteManyPackageType: AffectedRowsOutput;
  deleteManyPodcast: AffectedRowsOutput;
  deleteManyPopupBanner: AffectedRowsOutput;
  deleteManyPushNotification: AffectedRowsOutput;
  deleteManyQuestion: AffectedRowsOutput;
  deleteManySystemConfig: AffectedRowsOutput;
  deleteManyTagging: AffectedRowsOutput;
  deleteManyTransaction: AffectedRowsOutput;
  deleteManyUserIncentive: AffectedRowsOutput;
  deleteManyUserSetting: AffectedRowsOutput;
  deleteManyVertical: AffectedRowsOutput;
  deleteManyVideoRepo: AffectedRowsOutput;
  deleteOneAssessment?: Maybe<Assessment>;
  deleteOneBannerType?: Maybe<BannerType>;
  deleteOneCategory?: Maybe<Category>;
  deleteOneCertificate?: Maybe<Certificate>;
  deleteOneClassRating?: Maybe<ClassRating>;
  deleteOneCourse?: Maybe<Course>;
  deleteOneIncentive?: Maybe<Incentive>;
  deleteOneInterestType?: Maybe<InterestType>;
  deleteOneMaterial?: Maybe<Material>;
  deleteOneNewsUpdate?: Maybe<NewsUpdate>;
  deleteOneNormalClass?: Maybe<NormalClass>;
  deleteOnePackageType?: Maybe<PackageType>;
  deleteOnePodcast?: Maybe<Podcast>;
  deleteOnePopupBanner?: Maybe<PopupBanner>;
  deleteOnePushNotification?: Maybe<PushNotification>;
  deleteOneQuestion?: Maybe<Question>;
  deleteOneSystemConfig?: Maybe<SystemConfig>;
  deleteOneTagging?: Maybe<Tagging>;
  deleteOneTransaction?: Maybe<Transaction>;
  deleteOneUserIncentive?: Maybe<UserIncentive>;
  deleteOneUserSetting?: Maybe<UserSetting>;
  deleteOneVertical?: Maybe<Vertical>;
  deleteOneVideoRepo?: Maybe<VideoRepo>;
  followCoach: StatusResponse;
  generatePresignedFile: SignedFile;
  generateQRCodeChallenge: QrCodeChallenge;
  login: Auth;
  loginWithQRCode: Auth;
  preferManyInterestTypes: Scalars['Boolean'];
  preferManyVerticals: Scalars['Boolean'];
  refreshSubscription?: Maybe<Subscription>;
  refreshToken: Auth;
  register: Auth;
  registerLiveClass: StatusResponse;
  requestOtp: StatusResponse;
  requestUserInformationChange: StatusResponse;
  sendPushNotification: SendPushNotificationResponse;
  socialLogin: Auth;
  socialRegister: Auth;
  submitLiveClassAssessment: SubmitAssessmentResponse;
  toggleBookmark: StatusResponse;
  toggleNotificationSetting: StatusResponse;
  unfollowCoach: StatusResponse;
  unpreferManyInterestTypes: Scalars['Boolean'];
  updateManyAssessment: AffectedRowsOutput;
  updateManyBannerType: AffectedRowsOutput;
  updateManyCategory: AffectedRowsOutput;
  updateManyCertificate: AffectedRowsOutput;
  updateManyClassRating: AffectedRowsOutput;
  updateManyCourse: AffectedRowsOutput;
  updateManyIncentive: AffectedRowsOutput;
  updateManyInterestType: AffectedRowsOutput;
  updateManyMaterial: AffectedRowsOutput;
  updateManyNewsUpdate: AffectedRowsOutput;
  updateManyNormalClass: AffectedRowsOutput;
  updateManyPackageType: AffectedRowsOutput;
  updateManyPodcast: AffectedRowsOutput;
  updateManyPopupBanner: AffectedRowsOutput;
  updateManyPushNotification: AffectedRowsOutput;
  updateManyQuestion: AffectedRowsOutput;
  updateManySystemConfig: AffectedRowsOutput;
  updateManyTagging: AffectedRowsOutput;
  updateManyTransaction: AffectedRowsOutput;
  updateManyUserIncentive: AffectedRowsOutput;
  updateManyUserSetting: AffectedRowsOutput;
  updateManyVertical: AffectedRowsOutput;
  updateManyVideoRepo: AffectedRowsOutput;
  updateMyInterestTypes: Scalars['Boolean'];
  updateOneAssessment?: Maybe<Assessment>;
  updateOneBannerType?: Maybe<BannerType>;
  updateOneCategory?: Maybe<Category>;
  updateOneCertificate?: Maybe<Certificate>;
  updateOneClassRating?: Maybe<ClassRating>;
  updateOneCourse?: Maybe<Course>;
  updateOneIncentive?: Maybe<Incentive>;
  updateOneInterestType?: Maybe<InterestType>;
  updateOneMaterial?: Maybe<Material>;
  updateOneNewsUpdate?: Maybe<NewsUpdate>;
  updateOneNormalClass?: Maybe<NormalClass>;
  updateOnePackageType?: Maybe<PackageType>;
  updateOnePodcast?: Maybe<Podcast>;
  updateOnePopupBanner?: Maybe<PopupBanner>;
  updateOnePushNotification?: Maybe<PushNotification>;
  updateOneQuestion?: Maybe<Question>;
  updateOneSystemConfig?: Maybe<SystemConfig>;
  updateOneTagging?: Maybe<Tagging>;
  updateOneTransaction?: Maybe<Transaction>;
  updateOneUserIncentive?: Maybe<UserIncentive>;
  updateOneUserSetting?: Maybe<UserSetting>;
  updateOneVertical?: Maybe<Vertical>;
  updateOneVideoRepo?: Maybe<VideoRepo>;
  updateUserProfile: User;
  upsertOneAssessment: Assessment;
  upsertOneBannerType: BannerType;
  upsertOneCategory: Category;
  upsertOneCertificate: Certificate;
  upsertOneClassRating: ClassRating;
  upsertOneCourse: Course;
  upsertOneIncentive: Incentive;
  upsertOneInterestType: InterestType;
  upsertOneMaterial: Material;
  upsertOneNewsUpdate: NewsUpdate;
  upsertOneNormalClass: NormalClass;
  upsertOnePackageType: PackageType;
  upsertOnePodcast: Podcast;
  upsertOnePopupBanner: PopupBanner;
  upsertOnePushNotification: PushNotification;
  upsertOneQuestion: Question;
  upsertOneSystemConfig: SystemConfig;
  upsertOneTagging: Tagging;
  upsertOneTransaction: Transaction;
  upsertOneUserIncentive: UserIncentive;
  upsertOneUserSetting: UserSetting;
  upsertOneVertical: Vertical;
  upsertOneVideoRepo: VideoRepo;
  userCreateClassRating: ClassRating;
  userResetPassword: StatusResponse;
  verifyOtp: StatusResponse;
};

export type MutationAcceptQrCodeChallengeArgs = {
  challengeId: Scalars['String'];
};

export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};

export type MutationCancelQrCodeChallengeArgs = {
  challengeId: Scalars['String'];
};

export type MutationCreateManyAssessmentArgs = {
  data: Array<AssessmentCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyBannerTypeArgs = {
  data: Array<BannerTypeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyCategoryArgs = {
  data: Array<CategoryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyCertificateArgs = {
  data: Array<CertificateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyClassRatingArgs = {
  data: Array<ClassRatingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyCourseArgs = {
  data: Array<CourseCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyIncentiveArgs = {
  data: Array<IncentiveCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyInterestTypeArgs = {
  data: Array<InterestTypeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyMaterialArgs = {
  data: Array<MaterialCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyNewsUpdateArgs = {
  data: Array<NewsUpdateCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyNormalClassArgs = {
  data: Array<NormalClassCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyPackageTypeArgs = {
  data: Array<PackageTypeCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyPodcastArgs = {
  data: Array<PodcastCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyPopupBannerArgs = {
  data: Array<PopupBannerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyPushNotificationArgs = {
  data: Array<PushNotificationCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyQuestionArgs = {
  data: Array<QuestionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManySystemConfigArgs = {
  data: Array<SystemConfigCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyTaggingArgs = {
  data: Array<TaggingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyTransactionArgs = {
  data: Array<TransactionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyUserIncentiveArgs = {
  data: Array<UserIncentiveCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyUserSettingArgs = {
  data: Array<UserSettingCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyVerticalArgs = {
  data: Array<VerticalCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateManyVideoRepoArgs = {
  data: Array<VideoRepoCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MutationCreateOneAssessmentArgs = {
  data: AssessmentCreateInput;
};

export type MutationCreateOneBannerTypeArgs = {
  data: BannerTypeCreateInput;
};

export type MutationCreateOneCategoryArgs = {
  data: CategoryCreateInput;
};

export type MutationCreateOneCertificateArgs = {
  data: CertificateCreateInput;
};

export type MutationCreateOneClassRatingArgs = {
  data: ClassRatingCreateInput;
};

export type MutationCreateOneCourseArgs = {
  data: CourseCreateInput;
};

export type MutationCreateOneIncentiveArgs = {
  data: IncentiveCreateInput;
};

export type MutationCreateOneInterestTypeArgs = {
  data: InterestTypeCreateInput;
};

export type MutationCreateOneMaterialArgs = {
  data: MaterialCreateInput;
};

export type MutationCreateOneNewsUpdateArgs = {
  data: NewsUpdateCreateInput;
};

export type MutationCreateOneNormalClassArgs = {
  data: NormalClassCreateInput;
};

export type MutationCreateOnePackageTypeArgs = {
  data: PackageTypeCreateInput;
};

export type MutationCreateOnePodcastArgs = {
  data: PodcastCreateInput;
};

export type MutationCreateOnePopupBannerArgs = {
  data: PopupBannerCreateInput;
};

export type MutationCreateOnePushNotificationArgs = {
  data: PushNotificationCreateInput;
};

export type MutationCreateOneQuestionArgs = {
  data: QuestionCreateInput;
};

export type MutationCreateOneSystemConfigArgs = {
  data: SystemConfigCreateInput;
};

export type MutationCreateOneTaggingArgs = {
  data: TaggingCreateInput;
};

export type MutationCreateOneTransactionArgs = {
  data: TransactionCreateInput;
};

export type MutationCreateOneUserIncentiveArgs = {
  data: UserIncentiveCreateInput;
};

export type MutationCreateOneUserSettingArgs = {
  data: UserSettingCreateInput;
};

export type MutationCreateOneVerticalArgs = {
  data: VerticalCreateInput;
};

export type MutationCreateOneVideoRepoArgs = {
  data: VideoRepoCreateInput;
};

export type MutationCreatePaymentRequestArgs = {
  data: CreatePaymentRequestInput;
};

export type MutationCreateViewHistoryArgs = {
  data: CreateViewHistoryInput;
};

export type MutationDeactivateAccountArgs = {
  reason?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteManyAssessmentArgs = {
  where?: InputMaybe<AssessmentWhereInput>;
};

export type MutationDeleteManyBannerTypeArgs = {
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type MutationDeleteManyCategoryArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};

export type MutationDeleteManyCertificateArgs = {
  where?: InputMaybe<CertificateWhereInput>;
};

export type MutationDeleteManyClassRatingArgs = {
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type MutationDeleteManyCourseArgs = {
  where?: InputMaybe<CourseWhereInput>;
};

export type MutationDeleteManyIncentiveArgs = {
  where?: InputMaybe<IncentiveWhereInput>;
};

export type MutationDeleteManyInterestTypeArgs = {
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type MutationDeleteManyMaterialArgs = {
  where?: InputMaybe<MaterialWhereInput>;
};

export type MutationDeleteManyNewsUpdateArgs = {
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type MutationDeleteManyNormalClassArgs = {
  where?: InputMaybe<NormalClassWhereInput>;
};

export type MutationDeleteManyPackageTypeArgs = {
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type MutationDeleteManyPodcastArgs = {
  where?: InputMaybe<PodcastWhereInput>;
};

export type MutationDeleteManyPopupBannerArgs = {
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type MutationDeleteManyPushNotificationArgs = {
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type MutationDeleteManyQuestionArgs = {
  where?: InputMaybe<QuestionWhereInput>;
};

export type MutationDeleteManySystemConfigArgs = {
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type MutationDeleteManyTaggingArgs = {
  where?: InputMaybe<TaggingWhereInput>;
};

export type MutationDeleteManyTransactionArgs = {
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationDeleteManyUserIncentiveArgs = {
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type MutationDeleteManyUserSettingArgs = {
  where?: InputMaybe<UserSettingWhereInput>;
};

export type MutationDeleteManyVerticalArgs = {
  where?: InputMaybe<VerticalWhereInput>;
};

export type MutationDeleteManyVideoRepoArgs = {
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type MutationDeleteOneAssessmentArgs = {
  where: AssessmentWhereUniqueInput;
};

export type MutationDeleteOneBannerTypeArgs = {
  where: BannerTypeWhereUniqueInput;
};

export type MutationDeleteOneCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type MutationDeleteOneCertificateArgs = {
  where: CertificateWhereUniqueInput;
};

export type MutationDeleteOneClassRatingArgs = {
  where: ClassRatingWhereUniqueInput;
};

export type MutationDeleteOneCourseArgs = {
  where: CourseWhereUniqueInput;
};

export type MutationDeleteOneIncentiveArgs = {
  where: IncentiveWhereUniqueInput;
};

export type MutationDeleteOneInterestTypeArgs = {
  where: InterestTypeWhereUniqueInput;
};

export type MutationDeleteOneMaterialArgs = {
  where: MaterialWhereUniqueInput;
};

export type MutationDeleteOneNewsUpdateArgs = {
  where: NewsUpdateWhereUniqueInput;
};

export type MutationDeleteOneNormalClassArgs = {
  where: NormalClassWhereUniqueInput;
};

export type MutationDeleteOnePackageTypeArgs = {
  where: PackageTypeWhereUniqueInput;
};

export type MutationDeleteOnePodcastArgs = {
  where: PodcastWhereUniqueInput;
};

export type MutationDeleteOnePopupBannerArgs = {
  where: PopupBannerWhereUniqueInput;
};

export type MutationDeleteOnePushNotificationArgs = {
  where: PushNotificationWhereUniqueInput;
};

export type MutationDeleteOneQuestionArgs = {
  where: QuestionWhereUniqueInput;
};

export type MutationDeleteOneSystemConfigArgs = {
  where: SystemConfigWhereUniqueInput;
};

export type MutationDeleteOneTaggingArgs = {
  where: TaggingWhereUniqueInput;
};

export type MutationDeleteOneTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type MutationDeleteOneUserIncentiveArgs = {
  where: UserIncentiveWhereUniqueInput;
};

export type MutationDeleteOneUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};

export type MutationDeleteOneVerticalArgs = {
  where: VerticalWhereUniqueInput;
};

export type MutationDeleteOneVideoRepoArgs = {
  where: VideoRepoWhereUniqueInput;
};

export type MutationFollowCoachArgs = {
  data: FollowCoachInput;
};

export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationLoginWithQrCodeArgs = {
  challengeId: Scalars['String'];
  loginToken: Scalars['String'];
};

export type MutationPreferManyInterestTypesArgs = {
  data: PreferManyInterestTypesInput;
};

export type MutationPreferManyVerticalsArgs = {
  data: PreferManyVerticalsInput;
};

export type MutationRefreshSubscriptionArgs = {
  quoversionUserId?: InputMaybe<Scalars['String']>;
  transactionId?: InputMaybe<Scalars['String']>;
};

export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};

export type MutationRegisterArgs = {
  data: RegisterInput;
};

export type MutationRegisterLiveClassArgs = {
  data: RegisterLiveClassInput;
};

export type MutationRequestOtpArgs = {
  data: RequestOtpInput;
};

export type MutationRequestUserInformationChangeArgs = {
  fullName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type MutationSendPushNotificationArgs = {
  data: SendPushNotificationInput;
  where: PushNotificationWhereInput;
};

export type MutationSocialLoginArgs = {
  data: SocialLoginInput;
};

export type MutationSocialRegisterArgs = {
  data: SocialRegisterInput;
};

export type MutationSubmitLiveClassAssessmentArgs = {
  data: SubmitLiveClassAssessmentInput;
};

export type MutationToggleBookmarkArgs = {
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
};

export type MutationToggleNotificationSettingArgs = {
  settingType: NotificationSettingType;
};

export type MutationUnfollowCoachArgs = {
  data: UnfollowCoachInput;
};

export type MutationUnpreferManyInterestTypesArgs = {
  data: UnpreferManyInterestTypesInput;
};

export type MutationUpdateManyAssessmentArgs = {
  data: AssessmentUpdateManyMutationInput;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type MutationUpdateManyBannerTypeArgs = {
  data: BannerTypeUpdateManyMutationInput;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type MutationUpdateManyCategoryArgs = {
  data: CategoryUpdateManyMutationInput;
  where?: InputMaybe<CategoryWhereInput>;
};

export type MutationUpdateManyCertificateArgs = {
  data: CertificateUpdateManyMutationInput;
  where?: InputMaybe<CertificateWhereInput>;
};

export type MutationUpdateManyClassRatingArgs = {
  data: ClassRatingUpdateManyMutationInput;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type MutationUpdateManyCourseArgs = {
  data: CourseUpdateManyMutationInput;
  where?: InputMaybe<CourseWhereInput>;
};

export type MutationUpdateManyIncentiveArgs = {
  data: IncentiveUpdateManyMutationInput;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type MutationUpdateManyInterestTypeArgs = {
  data: InterestTypeUpdateManyMutationInput;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type MutationUpdateManyMaterialArgs = {
  data: MaterialUpdateManyMutationInput;
  where?: InputMaybe<MaterialWhereInput>;
};

export type MutationUpdateManyNewsUpdateArgs = {
  data: NewsUpdateUpdateManyMutationInput;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type MutationUpdateManyNormalClassArgs = {
  data: NormalClassUpdateManyMutationInput;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type MutationUpdateManyPackageTypeArgs = {
  data: PackageTypeUpdateManyMutationInput;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type MutationUpdateManyPodcastArgs = {
  data: PodcastUpdateManyMutationInput;
  where?: InputMaybe<PodcastWhereInput>;
};

export type MutationUpdateManyPopupBannerArgs = {
  data: PopupBannerUpdateManyMutationInput;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type MutationUpdateManyPushNotificationArgs = {
  data: PushNotificationUpdateManyMutationInput;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type MutationUpdateManyQuestionArgs = {
  data: QuestionUpdateManyMutationInput;
  where?: InputMaybe<QuestionWhereInput>;
};

export type MutationUpdateManySystemConfigArgs = {
  data: SystemConfigUpdateManyMutationInput;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type MutationUpdateManyTaggingArgs = {
  data: TaggingUpdateManyMutationInput;
  where?: InputMaybe<TaggingWhereInput>;
};

export type MutationUpdateManyTransactionArgs = {
  data: TransactionUpdateManyMutationInput;
  where?: InputMaybe<TransactionWhereInput>;
};

export type MutationUpdateManyUserIncentiveArgs = {
  data: UserIncentiveUpdateManyMutationInput;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type MutationUpdateManyUserSettingArgs = {
  data: UserSettingUpdateManyMutationInput;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type MutationUpdateManyVerticalArgs = {
  data: VerticalUpdateManyMutationInput;
  where?: InputMaybe<VerticalWhereInput>;
};

export type MutationUpdateManyVideoRepoArgs = {
  data: VideoRepoUpdateManyMutationInput;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type MutationUpdateMyInterestTypesArgs = {
  data: UpdateUserInterestTypesInput;
};

export type MutationUpdateOneAssessmentArgs = {
  data: AssessmentUpdateInput;
  where: AssessmentWhereUniqueInput;
};

export type MutationUpdateOneBannerTypeArgs = {
  data: BannerTypeUpdateInput;
  where: BannerTypeWhereUniqueInput;
};

export type MutationUpdateOneCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpdateOneCertificateArgs = {
  data: CertificateUpdateInput;
  where: CertificateWhereUniqueInput;
};

export type MutationUpdateOneClassRatingArgs = {
  data: ClassRatingUpdateInput;
  where: ClassRatingWhereUniqueInput;
};

export type MutationUpdateOneCourseArgs = {
  data: CourseUpdateInput;
  where: CourseWhereUniqueInput;
};

export type MutationUpdateOneIncentiveArgs = {
  data: IncentiveUpdateInput;
  where: IncentiveWhereUniqueInput;
};

export type MutationUpdateOneInterestTypeArgs = {
  data: InterestTypeUpdateInput;
  where: InterestTypeWhereUniqueInput;
};

export type MutationUpdateOneMaterialArgs = {
  data: MaterialUpdateInput;
  where: MaterialWhereUniqueInput;
};

export type MutationUpdateOneNewsUpdateArgs = {
  data: NewsUpdateUpdateInput;
  where: NewsUpdateWhereUniqueInput;
};

export type MutationUpdateOneNormalClassArgs = {
  data: NormalClassUpdateInput;
  where: NormalClassWhereUniqueInput;
};

export type MutationUpdateOnePackageTypeArgs = {
  data: PackageTypeUpdateInput;
  where: PackageTypeWhereUniqueInput;
};

export type MutationUpdateOnePodcastArgs = {
  data: PodcastUpdateInput;
  where: PodcastWhereUniqueInput;
};

export type MutationUpdateOnePopupBannerArgs = {
  data: PopupBannerUpdateInput;
  where: PopupBannerWhereUniqueInput;
};

export type MutationUpdateOnePushNotificationArgs = {
  data: PushNotificationUpdateInput;
  where: PushNotificationWhereUniqueInput;
};

export type MutationUpdateOneQuestionArgs = {
  data: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};

export type MutationUpdateOneSystemConfigArgs = {
  data: SystemConfigUpdateInput;
  where: SystemConfigWhereUniqueInput;
};

export type MutationUpdateOneTaggingArgs = {
  data: TaggingUpdateInput;
  where: TaggingWhereUniqueInput;
};

export type MutationUpdateOneTransactionArgs = {
  data: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpdateOneUserIncentiveArgs = {
  data: UserIncentiveUpdateInput;
  where: UserIncentiveWhereUniqueInput;
};

export type MutationUpdateOneUserSettingArgs = {
  data: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};

export type MutationUpdateOneVerticalArgs = {
  data: VerticalUpdateInput;
  where: VerticalWhereUniqueInput;
};

export type MutationUpdateOneVideoRepoArgs = {
  data: VideoRepoUpdateInput;
  where: VideoRepoWhereUniqueInput;
};

export type MutationUpdateUserProfileArgs = {
  data: UserProfileUpdateInput;
};

export type MutationUpsertOneAssessmentArgs = {
  create: AssessmentCreateInput;
  update: AssessmentUpdateInput;
  where: AssessmentWhereUniqueInput;
};

export type MutationUpsertOneBannerTypeArgs = {
  create: BannerTypeCreateInput;
  update: BannerTypeUpdateInput;
  where: BannerTypeWhereUniqueInput;
};

export type MutationUpsertOneCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpsertOneCertificateArgs = {
  create: CertificateCreateInput;
  update: CertificateUpdateInput;
  where: CertificateWhereUniqueInput;
};

export type MutationUpsertOneClassRatingArgs = {
  create: ClassRatingCreateInput;
  update: ClassRatingUpdateInput;
  where: ClassRatingWhereUniqueInput;
};

export type MutationUpsertOneCourseArgs = {
  create: CourseCreateInput;
  update: CourseUpdateInput;
  where: CourseWhereUniqueInput;
};

export type MutationUpsertOneIncentiveArgs = {
  create: IncentiveCreateInput;
  update: IncentiveUpdateInput;
  where: IncentiveWhereUniqueInput;
};

export type MutationUpsertOneInterestTypeArgs = {
  create: InterestTypeCreateInput;
  update: InterestTypeUpdateInput;
  where: InterestTypeWhereUniqueInput;
};

export type MutationUpsertOneMaterialArgs = {
  create: MaterialCreateInput;
  update: MaterialUpdateInput;
  where: MaterialWhereUniqueInput;
};

export type MutationUpsertOneNewsUpdateArgs = {
  create: NewsUpdateCreateInput;
  update: NewsUpdateUpdateInput;
  where: NewsUpdateWhereUniqueInput;
};

export type MutationUpsertOneNormalClassArgs = {
  create: NormalClassCreateInput;
  update: NormalClassUpdateInput;
  where: NormalClassWhereUniqueInput;
};

export type MutationUpsertOnePackageTypeArgs = {
  create: PackageTypeCreateInput;
  update: PackageTypeUpdateInput;
  where: PackageTypeWhereUniqueInput;
};

export type MutationUpsertOnePodcastArgs = {
  create: PodcastCreateInput;
  update: PodcastUpdateInput;
  where: PodcastWhereUniqueInput;
};

export type MutationUpsertOnePopupBannerArgs = {
  create: PopupBannerCreateInput;
  update: PopupBannerUpdateInput;
  where: PopupBannerWhereUniqueInput;
};

export type MutationUpsertOnePushNotificationArgs = {
  create: PushNotificationCreateInput;
  update: PushNotificationUpdateInput;
  where: PushNotificationWhereUniqueInput;
};

export type MutationUpsertOneQuestionArgs = {
  create: QuestionCreateInput;
  update: QuestionUpdateInput;
  where: QuestionWhereUniqueInput;
};

export type MutationUpsertOneSystemConfigArgs = {
  create: SystemConfigCreateInput;
  update: SystemConfigUpdateInput;
  where: SystemConfigWhereUniqueInput;
};

export type MutationUpsertOneTaggingArgs = {
  create: TaggingCreateInput;
  update: TaggingUpdateInput;
  where: TaggingWhereUniqueInput;
};

export type MutationUpsertOneTransactionArgs = {
  create: TransactionCreateInput;
  update: TransactionUpdateInput;
  where: TransactionWhereUniqueInput;
};

export type MutationUpsertOneUserIncentiveArgs = {
  create: UserIncentiveCreateInput;
  update: UserIncentiveUpdateInput;
  where: UserIncentiveWhereUniqueInput;
};

export type MutationUpsertOneUserSettingArgs = {
  create: UserSettingCreateInput;
  update: UserSettingUpdateInput;
  where: UserSettingWhereUniqueInput;
};

export type MutationUpsertOneVerticalArgs = {
  create: VerticalCreateInput;
  update: VerticalUpdateInput;
  where: VerticalWhereUniqueInput;
};

export type MutationUpsertOneVideoRepoArgs = {
  create: VideoRepoCreateInput;
  update: VideoRepoUpdateInput;
  where: VideoRepoWhereUniqueInput;
};

export type MutationUserCreateClassRatingArgs = {
  data: UserCreateClassRatingInput;
};

export type MutationUserResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type NestedBigIntNullableFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type NestedBigIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDecimalNullableFilter>;
  _min?: InputMaybe<NestedDecimalNullableFilter>;
  _sum?: InputMaybe<NestedDecimalNullableFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalWithAggregatesFilter = {
  _avg?: InputMaybe<NestedDecimalFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDecimalFilter>;
  _min?: InputMaybe<NestedDecimalFilter>;
  _sum?: InputMaybe<NestedDecimalFilter>;
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumBookmarkTargetTypeFilter = {
  equals?: InputMaybe<BookmarkTargetType>;
  in?: InputMaybe<Array<BookmarkTargetType>>;
  not?: InputMaybe<NestedEnumBookmarkTargetTypeFilter>;
  notIn?: InputMaybe<Array<BookmarkTargetType>>;
};

export type NestedEnumCmsPageCategoryFilter = {
  equals?: InputMaybe<CmsPageCategory>;
  in?: InputMaybe<Array<CmsPageCategory>>;
  not?: InputMaybe<NestedEnumCmsPageCategoryFilter>;
  notIn?: InputMaybe<Array<CmsPageCategory>>;
};

export type NestedEnumIncentiveTypeFilter = {
  equals?: InputMaybe<IncentiveType>;
  in?: InputMaybe<Array<IncentiveType>>;
  not?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  notIn?: InputMaybe<Array<IncentiveType>>;
};

export type NestedEnumIncentiveTypeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  _min?: InputMaybe<NestedEnumIncentiveTypeFilter>;
  equals?: InputMaybe<IncentiveType>;
  in?: InputMaybe<Array<IncentiveType>>;
  not?: InputMaybe<NestedEnumIncentiveTypeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<IncentiveType>>;
};

export type NestedEnumPlatformFilter = {
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type NestedEnumQrCodeChallengeStatusFilter = {
  equals?: InputMaybe<QrCodeChallengeStatus>;
  in?: InputMaybe<Array<QrCodeChallengeStatus>>;
  not?: InputMaybe<NestedEnumQrCodeChallengeStatusFilter>;
  notIn?: InputMaybe<Array<QrCodeChallengeStatus>>;
};

export type NestedEnumSubscriptionStatusFilter = {
  equals?: InputMaybe<SubscriptionStatus>;
  in?: InputMaybe<Array<SubscriptionStatus>>;
  not?: InputMaybe<NestedEnumSubscriptionStatusFilter>;
  notIn?: InputMaybe<Array<SubscriptionStatus>>;
};

export type NestedEnumUserInformationTypeFilter = {
  equals?: InputMaybe<UserInformationType>;
  in?: InputMaybe<Array<UserInformationType>>;
  not?: InputMaybe<NestedEnumUserInformationTypeFilter>;
  notIn?: InputMaybe<Array<UserInformationType>>;
};

export type NestedEnumViewTargetTypeFilter = {
  equals?: InputMaybe<ViewTargetType>;
  in?: InputMaybe<Array<ViewTargetType>>;
  not?: InputMaybe<NestedEnumViewTargetTypeFilter>;
  notIn?: InputMaybe<Array<ViewTargetType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedFloatNullableFilter>;
  _min?: InputMaybe<NestedFloatNullableFilter>;
  _sum?: InputMaybe<NestedFloatNullableFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedFloatFilter>;
  _min?: InputMaybe<NestedFloatFilter>;
  _sum?: InputMaybe<NestedFloatFilter>;
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Scalars['String']>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NewsUpdate = {
  __typename?: 'NewsUpdate';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  html_content: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  thumb?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NewsUpdateAvgAggregate = {
  __typename?: 'NewsUpdateAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type NewsUpdateAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type NewsUpdateCountAggregate = {
  __typename?: 'NewsUpdateCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  html_content: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  thumb: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type NewsUpdateCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  html_content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsUpdateCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  html_content: Scalars['String'];
  status: Scalars['Int'];
  thumb?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsUpdateCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  html_content: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  status: Scalars['Int'];
  thumb?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsUpdateGroupBy = {
  __typename?: 'NewsUpdateGroupBy';
  _avg?: Maybe<NewsUpdateAvgAggregate>;
  _count?: Maybe<NewsUpdateCountAggregate>;
  _max?: Maybe<NewsUpdateMaxAggregate>;
  _min?: Maybe<NewsUpdateMinAggregate>;
  _sum?: Maybe<NewsUpdateSumAggregate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  html_content: Scalars['String'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  thumb?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NewsUpdateMaxAggregate = {
  __typename?: 'NewsUpdateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  html_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  thumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsUpdateMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  html_content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsUpdateMinAggregate = {
  __typename?: 'NewsUpdateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  html_content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  thumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewsUpdateMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  html_content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsUpdateOrderByWithAggregationInput = {
  _avg?: InputMaybe<NewsUpdateAvgOrderByAggregateInput>;
  _count?: InputMaybe<NewsUpdateCountOrderByAggregateInput>;
  _max?: InputMaybe<NewsUpdateMaxOrderByAggregateInput>;
  _min?: InputMaybe<NewsUpdateMinOrderByAggregateInput>;
  _sum?: InputMaybe<NewsUpdateSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  html_content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type NewsUpdateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  html_content?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  thumb?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum NewsUpdateScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  HtmlContent = 'html_content',
  Id = 'id',
  Status = 'status',
  Thumb = 'thumb',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type NewsUpdateScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NewsUpdateScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NewsUpdateScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NewsUpdateScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  html_content?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  thumb?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type NewsUpdateSumAggregate = {
  __typename?: 'NewsUpdateSumAggregate';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type NewsUpdateSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type NewsUpdateUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  html_content?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  thumb?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsUpdateUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  html_content?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  thumb?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NewsUpdateWhereInput = {
  AND?: InputMaybe<Array<NewsUpdateWhereInput>>;
  NOT?: InputMaybe<Array<NewsUpdateWhereInput>>;
  OR?: InputMaybe<Array<NewsUpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  html_content?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  thumb?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type NewsUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type NormalClass = {
  __typename?: 'NormalClass';
  _count?: Maybe<NormalClassCount>;
  adminRating: Scalars['Int'];
  category?: Maybe<Category>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  classBookmarked: Array<UserBookmark>;
  classTags: Array<ClassTagging>;
  classViewed: Array<UserViewHistory>;
  classesBookmarks: Array<ClassesBookmarks>;
  coach?: Maybe<Coach>;
  coachId?: Maybe<Scalars['Int']>;
  contentType: Scalars['Int'];
  courseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree: Scalars['Int'];
  hasRated: Scalars['Boolean'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  images: Array<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
  isViewed: Scalars['Boolean'];
  libraryBookmarked: Array<UserBookmark>;
  libraryViewed: Array<UserViewHistory>;
  likedBy: Array<NormalClassLike>;
  materials: Array<Material>;
  missionStatus: Scalars['Int'];
  missionTime: Scalars['Int'];
  portraitImages: Array<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  ratingStatistic: ClassRatingStatistic;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: Maybe<Scalars['String']>;
  tags: Array<Tagging>;
  title: Scalars['String'];
  totalDuration: Scalars['Int'];
  totalShare: Scalars['BigInt'];
  totalView: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  videoRepoId: Scalars['String'];
  videoRepos: Array<VideoRepo>;
};

export type NormalClassClassBookmarkedArgs = {
  cursor?: InputMaybe<UserBookmarkWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserBookmarkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserBookmarkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type NormalClassClassTagsArgs = {
  cursor?: InputMaybe<ClassTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassTaggingWhereInput>;
};

export type NormalClassClassViewedArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type NormalClassClassesBookmarksArgs = {
  cursor?: InputMaybe<ClassesBookmarksWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassesBookmarksScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassesBookmarksOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassesBookmarksWhereInput>;
};

export type NormalClassLibraryBookmarkedArgs = {
  cursor?: InputMaybe<UserBookmarkWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserBookmarkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserBookmarkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type NormalClassLibraryViewedArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type NormalClassLikedByArgs = {
  cursor?: InputMaybe<NormalClassLikeWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassLikeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassLikeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassLikeWhereInput>;
};

export type NormalClassAvgAggregate = {
  __typename?: 'NormalClassAvgAggregate';
  adminRating?: Maybe<Scalars['Float']>;
  channelId?: Maybe<Scalars['Float']>;
  coachId?: Maybe<Scalars['Float']>;
  contentType?: Maybe<Scalars['Float']>;
  courseId?: Maybe<Scalars['Float']>;
  enableFree?: Maybe<Scalars['Float']>;
  highlight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missionStatus?: Maybe<Scalars['Float']>;
  missionTime?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  totalDuration?: Maybe<Scalars['Float']>;
  totalShare?: Maybe<Scalars['Float']>;
  totalView?: Maybe<Scalars['Float']>;
};

export type NormalClassAvgOrderByAggregateInput = {
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
};

export type NormalClassCount = {
  __typename?: 'NormalClassCount';
  classBookmarked: Scalars['Int'];
  classTags: Scalars['Int'];
  classViewed: Scalars['Int'];
  classesBookmarks: Scalars['Int'];
  libraryBookmarked: Scalars['Int'];
  libraryViewed: Scalars['Int'];
  likedBy: Scalars['Int'];
};

export type NormalClassCountAggregate = {
  __typename?: 'NormalClassCountAggregate';
  _all: Scalars['Int'];
  adminRating: Scalars['Int'];
  channelId: Scalars['Int'];
  coachId: Scalars['Int'];
  contentType: Scalars['Int'];
  courseId: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  description: Scalars['Int'];
  enableFree: Scalars['Int'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  images: Scalars['Int'];
  materialId: Scalars['Int'];
  missionStatus: Scalars['Int'];
  missionTime: Scalars['Int'];
  portraitImages: Scalars['Int'];
  rating: Scalars['Int'];
  sequence: Scalars['Int'];
  shortDescription: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  tagId: Scalars['Int'];
  title: Scalars['Int'];
  totalDuration: Scalars['Int'];
  totalShare: Scalars['Int'];
  totalView: Scalars['Int'];
  updatedAt: Scalars['Int'];
  videoRepoId: Scalars['Int'];
};

export type NormalClassCountOrderByAggregateInput = {
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type NormalClassCreateInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateManyChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  coachId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateManyChannelInputEnvelope = {
  data: Array<NormalClassCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NormalClassCreateManyCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channelId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateManyCoachInputEnvelope = {
  data: Array<NormalClassCreateManyCoachInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NormalClassCreateManyInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channelId?: InputMaybe<Scalars['Int']>;
  coachId?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<NormalClassCreateWithoutChannelInput>>;
  createMany?: InputMaybe<NormalClassCreateManyChannelInputEnvelope>;
};

export type NormalClassCreateNestedManyWithoutCoachInput = {
  connect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<NormalClassCreateWithoutCoachInput>>;
  createMany?: InputMaybe<NormalClassCreateManyCoachInputEnvelope>;
};

export type NormalClassCreateNestedOneWithoutClassBookmarkedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassBookmarkedInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassBookmarkedInput>;
};

export type NormalClassCreateNestedOneWithoutClassTagsInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassTagsInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassTagsInput>;
};

export type NormalClassCreateNestedOneWithoutClassViewedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassViewedInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassViewedInput>;
};

export type NormalClassCreateNestedOneWithoutLibraryBookmarkedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutLibraryBookmarkedInput>;
  create?: InputMaybe<NormalClassCreateWithoutLibraryBookmarkedInput>;
};

export type NormalClassCreateNestedOneWithoutLibraryViewedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutLibraryViewedInput>;
  create?: InputMaybe<NormalClassCreateWithoutLibraryViewedInput>;
};

export type NormalClassCreateOrConnectWithoutChannelInput = {
  create: NormalClassCreateWithoutChannelInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutClassBookmarkedInput = {
  create: NormalClassCreateWithoutClassBookmarkedInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutClassTagsInput = {
  create: NormalClassCreateWithoutClassTagsInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutClassViewedInput = {
  create: NormalClassCreateWithoutClassViewedInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutCoachInput = {
  create: NormalClassCreateWithoutCoachInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutLibraryBookmarkedInput = {
  create: NormalClassCreateWithoutLibraryBookmarkedInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateOrConnectWithoutLibraryViewedInput = {
  create: NormalClassCreateWithoutLibraryViewedInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassCreateWithoutChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutClassBookmarkedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutClassTagsInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutClassViewedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutLibraryBookmarkedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassCreateWithoutLibraryViewedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelCreateNestedOneWithoutNormalClassesInput>;
  classBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutClassInput>;
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutClassInput>;
  classViewed?: InputMaybe<UserViewHistoryCreateNestedManyWithoutClassInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksCreateNestedManyWithoutClassInput>;
  coach?: InputMaybe<CoachCreateNestedOneWithoutNormalClassesInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight: Scalars['Int'];
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkCreateNestedManyWithoutLibraryInput>;
  likedBy?: InputMaybe<NormalClassLikeCreateNestedManyWithoutNormalClassInput>;
  materialId: Scalars['String'];
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassGroupBy = {
  __typename?: 'NormalClassGroupBy';
  _avg?: Maybe<NormalClassAvgAggregate>;
  _count?: Maybe<NormalClassCountAggregate>;
  _max?: Maybe<NormalClassMaxAggregate>;
  _min?: Maybe<NormalClassMinAggregate>;
  _sum?: Maybe<NormalClassSumAggregate>;
  adminRating: Scalars['Int'];
  channelId?: Maybe<Scalars['Int']>;
  coachId?: Maybe<Scalars['Int']>;
  contentType: Scalars['Int'];
  courseId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  enableFree: Scalars['Int'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  images?: Maybe<Scalars['String']>;
  materialId: Scalars['String'];
  missionStatus: Scalars['Int'];
  missionTime: Scalars['Int'];
  portraitImages?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  sequence: Scalars['Int'];
  shortDescription: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  tagId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  totalDuration: Scalars['Int'];
  totalShare: Scalars['BigInt'];
  totalView: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  videoRepoId: Scalars['String'];
};

export type NormalClassLike = {
  __typename?: 'NormalClassLike';
  classId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  normalClass: NormalClass;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type NormalClassLikeCreateManyNormalClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type NormalClassLikeCreateManyNormalClassInputEnvelope = {
  data: Array<NormalClassLikeCreateManyNormalClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NormalClassLikeCreateNestedManyWithoutNormalClassInput = {
  connect?: InputMaybe<Array<NormalClassLikeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassLikeCreateOrConnectWithoutNormalClassInput>>;
  create?: InputMaybe<Array<NormalClassLikeCreateWithoutNormalClassInput>>;
  createMany?: InputMaybe<NormalClassLikeCreateManyNormalClassInputEnvelope>;
};

export type NormalClassLikeCreateOrConnectWithoutNormalClassInput = {
  create: NormalClassLikeCreateWithoutNormalClassInput;
  where: NormalClassLikeWhereUniqueInput;
};

export type NormalClassLikeCreateWithoutNormalClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type NormalClassLikeListRelationFilter = {
  every?: InputMaybe<NormalClassLikeWhereInput>;
  none?: InputMaybe<NormalClassLikeWhereInput>;
  some?: InputMaybe<NormalClassLikeWhereInput>;
};

export type NormalClassLikeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NormalClassLikeOrderByWithRelationInput = {
  classId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  normalClass?: InputMaybe<NormalClassOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum NormalClassLikeScalarFieldEnum {
  ClassId = 'classId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type NormalClassLikeScalarWhereInput = {
  AND?: InputMaybe<Array<NormalClassLikeScalarWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassLikeScalarWhereInput>>;
  OR?: InputMaybe<Array<NormalClassLikeScalarWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type NormalClassLikeUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type NormalClassLikeUpdateManyWithWhereWithoutNormalClassInput = {
  data: NormalClassLikeUpdateManyMutationInput;
  where: NormalClassLikeScalarWhereInput;
};

export type NormalClassLikeUpdateManyWithoutNormalClassNestedInput = {
  connect?: InputMaybe<Array<NormalClassLikeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassLikeCreateOrConnectWithoutNormalClassInput>>;
  create?: InputMaybe<Array<NormalClassLikeCreateWithoutNormalClassInput>>;
  createMany?: InputMaybe<NormalClassLikeCreateManyNormalClassInputEnvelope>;
  delete?: InputMaybe<Array<NormalClassLikeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NormalClassLikeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NormalClassLikeWhereUniqueInput>>;
  set?: InputMaybe<Array<NormalClassLikeWhereUniqueInput>>;
  update?: InputMaybe<Array<NormalClassLikeUpdateWithWhereUniqueWithoutNormalClassInput>>;
  updateMany?: InputMaybe<Array<NormalClassLikeUpdateManyWithWhereWithoutNormalClassInput>>;
  upsert?: InputMaybe<Array<NormalClassLikeUpsertWithWhereUniqueWithoutNormalClassInput>>;
};

export type NormalClassLikeUpdateWithWhereUniqueWithoutNormalClassInput = {
  data: NormalClassLikeUpdateWithoutNormalClassInput;
  where: NormalClassLikeWhereUniqueInput;
};

export type NormalClassLikeUpdateWithoutNormalClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type NormalClassLikeUpsertWithWhereUniqueWithoutNormalClassInput = {
  create: NormalClassLikeCreateWithoutNormalClassInput;
  update: NormalClassLikeUpdateWithoutNormalClassInput;
  where: NormalClassLikeWhereUniqueInput;
};

export type NormalClassLikeUserIdClassIdCompoundUniqueInput = {
  classId: Scalars['Int'];
  userId: Scalars['BigInt'];
};

export type NormalClassLikeWhereInput = {
  AND?: InputMaybe<Array<NormalClassLikeWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassLikeWhereInput>>;
  OR?: InputMaybe<Array<NormalClassLikeWhereInput>>;
  classId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  normalClass?: InputMaybe<NormalClassRelationFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type NormalClassLikeWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId_classId?: InputMaybe<NormalClassLikeUserIdClassIdCompoundUniqueInput>;
};

export type NormalClassListRelationFilter = {
  every?: InputMaybe<NormalClassWhereInput>;
  none?: InputMaybe<NormalClassWhereInput>;
  some?: InputMaybe<NormalClassWhereInput>;
};

export type NormalClassMaxAggregate = {
  __typename?: 'NormalClassMaxAggregate';
  adminRating?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  coachId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enableFree?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  missionStatus?: Maybe<Scalars['Int']>;
  missionTime?: Maybe<Scalars['Int']>;
  portraitImages?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalDuration?: Maybe<Scalars['Int']>;
  totalShare?: Maybe<Scalars['BigInt']>;
  totalView?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoRepoId?: Maybe<Scalars['String']>;
};

export type NormalClassMaxOrderByAggregateInput = {
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type NormalClassMinAggregate = {
  __typename?: 'NormalClassMinAggregate';
  adminRating?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  coachId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enableFree?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Scalars['String']>;
  materialId?: Maybe<Scalars['String']>;
  missionStatus?: Maybe<Scalars['Int']>;
  missionTime?: Maybe<Scalars['Int']>;
  portraitImages?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  tagId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalDuration?: Maybe<Scalars['Int']>;
  totalShare?: Maybe<Scalars['BigInt']>;
  totalView?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoRepoId?: Maybe<Scalars['String']>;
};

export type NormalClassMinOrderByAggregateInput = {
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type NormalClassOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NormalClassOrderByWithAggregationInput = {
  _avg?: InputMaybe<NormalClassAvgOrderByAggregateInput>;
  _count?: InputMaybe<NormalClassCountOrderByAggregateInput>;
  _max?: InputMaybe<NormalClassMaxOrderByAggregateInput>;
  _min?: InputMaybe<NormalClassMinOrderByAggregateInput>;
  _sum?: InputMaybe<NormalClassSumOrderByAggregateInput>;
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type NormalClassOrderByWithRelationInput = {
  adminRating?: InputMaybe<SortOrder>;
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  classBookmarked?: InputMaybe<UserBookmarkOrderByRelationAggregateInput>;
  classTags?: InputMaybe<ClassTaggingOrderByRelationAggregateInput>;
  classViewed?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksOrderByRelationAggregateInput>;
  coach?: InputMaybe<CoachOrderByWithRelationInput>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  libraryBookmarked?: InputMaybe<UserBookmarkOrderByRelationAggregateInput>;
  libraryViewed?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
  likedBy?: InputMaybe<NormalClassLikeOrderByRelationAggregateInput>;
  materialId?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  portraitImages?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  shortDescription?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoRepoId?: InputMaybe<SortOrder>;
};

export type NormalClassRelationFilter = {
  is?: InputMaybe<NormalClassWhereInput>;
  isNot?: InputMaybe<NormalClassWhereInput>;
};

export enum NormalClassScalarFieldEnum {
  AdminRating = 'adminRating',
  ChannelId = 'channelId',
  CoachId = 'coachId',
  ContentType = 'contentType',
  CourseId = 'courseId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  EnableFree = 'enableFree',
  Highlight = 'highlight',
  Id = 'id',
  Images = 'images',
  MaterialId = 'materialId',
  MissionStatus = 'missionStatus',
  MissionTime = 'missionTime',
  PortraitImages = 'portraitImages',
  Rating = 'rating',
  Sequence = 'sequence',
  ShortDescription = 'shortDescription',
  Slug = 'slug',
  Status = 'status',
  TagId = 'tagId',
  Title = 'title',
  TotalDuration = 'totalDuration',
  TotalShare = 'totalShare',
  TotalView = 'totalView',
  UpdatedAt = 'updatedAt',
  VideoRepoId = 'videoRepoId',
}

export type NormalClassScalarWhereInput = {
  AND?: InputMaybe<Array<NormalClassScalarWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassScalarWhereInput>>;
  OR?: InputMaybe<Array<NormalClassScalarWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoRepoId?: InputMaybe<StringFilter>;
};

export type NormalClassScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<NormalClassScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<NormalClassScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<NormalClassScalarWhereWithAggregatesInput>>;
  adminRating?: InputMaybe<IntWithAggregatesFilter>;
  channelId?: InputMaybe<IntNullableWithAggregatesFilter>;
  coachId?: InputMaybe<IntNullableWithAggregatesFilter>;
  contentType?: InputMaybe<IntWithAggregatesFilter>;
  courseId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  enableFree?: InputMaybe<IntWithAggregatesFilter>;
  highlight?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  images?: InputMaybe<StringNullableWithAggregatesFilter>;
  materialId?: InputMaybe<StringWithAggregatesFilter>;
  missionStatus?: InputMaybe<IntWithAggregatesFilter>;
  missionTime?: InputMaybe<IntWithAggregatesFilter>;
  portraitImages?: InputMaybe<StringNullableWithAggregatesFilter>;
  rating?: InputMaybe<IntNullableWithAggregatesFilter>;
  sequence?: InputMaybe<IntWithAggregatesFilter>;
  shortDescription?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  tagId?: InputMaybe<StringNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  totalDuration?: InputMaybe<IntWithAggregatesFilter>;
  totalShare?: InputMaybe<BigIntWithAggregatesFilter>;
  totalView?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  videoRepoId?: InputMaybe<StringWithAggregatesFilter>;
};

export type NormalClassSumAggregate = {
  __typename?: 'NormalClassSumAggregate';
  adminRating?: Maybe<Scalars['Int']>;
  channelId?: Maybe<Scalars['Int']>;
  coachId?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['Int']>;
  enableFree?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  missionStatus?: Maybe<Scalars['Int']>;
  missionTime?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  sequence?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  totalDuration?: Maybe<Scalars['Int']>;
  totalShare?: Maybe<Scalars['BigInt']>;
  totalView?: Maybe<Scalars['Int']>;
};

export type NormalClassSumOrderByAggregateInput = {
  adminRating?: InputMaybe<SortOrder>;
  channelId?: InputMaybe<SortOrder>;
  coachId?: InputMaybe<SortOrder>;
  contentType?: InputMaybe<SortOrder>;
  courseId?: InputMaybe<SortOrder>;
  enableFree?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  missionStatus?: InputMaybe<SortOrder>;
  missionTime?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalDuration?: InputMaybe<SortOrder>;
  totalShare?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
};

export type NormalClassUpdateInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateManyMutationInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateManyWithWhereWithoutChannelInput = {
  data: NormalClassUpdateManyMutationInput;
  where: NormalClassScalarWhereInput;
};

export type NormalClassUpdateManyWithWhereWithoutCoachInput = {
  data: NormalClassUpdateManyMutationInput;
  where: NormalClassScalarWhereInput;
};

export type NormalClassUpdateManyWithoutChannelNestedInput = {
  connect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<NormalClassCreateWithoutChannelInput>>;
  createMany?: InputMaybe<NormalClassCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NormalClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  set?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  update?: InputMaybe<Array<NormalClassUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<NormalClassUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<NormalClassUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type NormalClassUpdateManyWithoutCoachNestedInput = {
  connect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NormalClassCreateOrConnectWithoutCoachInput>>;
  create?: InputMaybe<Array<NormalClassCreateWithoutCoachInput>>;
  createMany?: InputMaybe<NormalClassCreateManyCoachInputEnvelope>;
  delete?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NormalClassScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  set?: InputMaybe<Array<NormalClassWhereUniqueInput>>;
  update?: InputMaybe<Array<NormalClassUpdateWithWhereUniqueWithoutCoachInput>>;
  updateMany?: InputMaybe<Array<NormalClassUpdateManyWithWhereWithoutCoachInput>>;
  upsert?: InputMaybe<Array<NormalClassUpsertWithWhereUniqueWithoutCoachInput>>;
};

export type NormalClassUpdateOneRequiredWithoutClassTagsNestedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassTagsInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassTagsInput>;
  update?: InputMaybe<NormalClassUpdateWithoutClassTagsInput>;
  upsert?: InputMaybe<NormalClassUpsertWithoutClassTagsInput>;
};

export type NormalClassUpdateOneWithoutClassBookmarkedNestedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassBookmarkedInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassBookmarkedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NormalClassUpdateWithoutClassBookmarkedInput>;
  upsert?: InputMaybe<NormalClassUpsertWithoutClassBookmarkedInput>;
};

export type NormalClassUpdateOneWithoutClassViewedNestedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutClassViewedInput>;
  create?: InputMaybe<NormalClassCreateWithoutClassViewedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NormalClassUpdateWithoutClassViewedInput>;
  upsert?: InputMaybe<NormalClassUpsertWithoutClassViewedInput>;
};

export type NormalClassUpdateOneWithoutLibraryBookmarkedNestedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutLibraryBookmarkedInput>;
  create?: InputMaybe<NormalClassCreateWithoutLibraryBookmarkedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NormalClassUpdateWithoutLibraryBookmarkedInput>;
  upsert?: InputMaybe<NormalClassUpsertWithoutLibraryBookmarkedInput>;
};

export type NormalClassUpdateOneWithoutLibraryViewedNestedInput = {
  connect?: InputMaybe<NormalClassWhereUniqueInput>;
  connectOrCreate?: InputMaybe<NormalClassCreateOrConnectWithoutLibraryViewedInput>;
  create?: InputMaybe<NormalClassCreateWithoutLibraryViewedInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<NormalClassUpdateWithoutLibraryViewedInput>;
  upsert?: InputMaybe<NormalClassUpsertWithoutLibraryViewedInput>;
};

export type NormalClassUpdateWithWhereUniqueWithoutChannelInput = {
  data: NormalClassUpdateWithoutChannelInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassUpdateWithWhereUniqueWithoutCoachInput = {
  data: NormalClassUpdateWithoutCoachInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassUpdateWithoutChannelInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutClassBookmarkedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutClassTagsInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutClassViewedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutCoachInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutLibraryBookmarkedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpdateWithoutLibraryViewedInput = {
  adminRating?: InputMaybe<Scalars['Int']>;
  channel?: InputMaybe<ChannelUpdateOneWithoutNormalClassesNestedInput>;
  classBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutClassNestedInput>;
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutClassNestedInput>;
  classViewed?: InputMaybe<UserViewHistoryUpdateManyWithoutClassNestedInput>;
  classesBookmarks?: InputMaybe<ClassesBookmarksUpdateManyWithoutClassNestedInput>;
  coach?: InputMaybe<CoachUpdateOneWithoutNormalClassesNestedInput>;
  contentType?: InputMaybe<Scalars['Int']>;
  courseId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  enableFree?: InputMaybe<Scalars['Int']>;
  highlight?: InputMaybe<Scalars['Int']>;
  images?: InputMaybe<Scalars['String']>;
  libraryBookmarked?: InputMaybe<UserBookmarkUpdateManyWithoutLibraryNestedInput>;
  likedBy?: InputMaybe<NormalClassLikeUpdateManyWithoutNormalClassNestedInput>;
  materialId?: InputMaybe<Scalars['String']>;
  missionStatus?: InputMaybe<Scalars['Int']>;
  missionTime?: InputMaybe<Scalars['Int']>;
  portraitImages?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  sequence?: InputMaybe<Scalars['Int']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  totalDuration?: InputMaybe<Scalars['Int']>;
  totalShare?: InputMaybe<Scalars['BigInt']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoRepoId?: InputMaybe<Scalars['String']>;
};

export type NormalClassUpsertWithWhereUniqueWithoutChannelInput = {
  create: NormalClassCreateWithoutChannelInput;
  update: NormalClassUpdateWithoutChannelInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassUpsertWithWhereUniqueWithoutCoachInput = {
  create: NormalClassCreateWithoutCoachInput;
  update: NormalClassUpdateWithoutCoachInput;
  where: NormalClassWhereUniqueInput;
};

export type NormalClassUpsertWithoutClassBookmarkedInput = {
  create: NormalClassCreateWithoutClassBookmarkedInput;
  update: NormalClassUpdateWithoutClassBookmarkedInput;
};

export type NormalClassUpsertWithoutClassTagsInput = {
  create: NormalClassCreateWithoutClassTagsInput;
  update: NormalClassUpdateWithoutClassTagsInput;
};

export type NormalClassUpsertWithoutClassViewedInput = {
  create: NormalClassCreateWithoutClassViewedInput;
  update: NormalClassUpdateWithoutClassViewedInput;
};

export type NormalClassUpsertWithoutLibraryBookmarkedInput = {
  create: NormalClassCreateWithoutLibraryBookmarkedInput;
  update: NormalClassUpdateWithoutLibraryBookmarkedInput;
};

export type NormalClassUpsertWithoutLibraryViewedInput = {
  create: NormalClassCreateWithoutLibraryViewedInput;
  update: NormalClassUpdateWithoutLibraryViewedInput;
};

export type NormalClassWhereInput = {
  AND?: InputMaybe<Array<NormalClassWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassWhereInput>>;
  OR?: InputMaybe<Array<NormalClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  classViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  classesBookmarks?: InputMaybe<ClassesBookmarksListRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  libraryBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  libraryViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  likedBy?: InputMaybe<NormalClassLikeListRelationFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoRepoId?: InputMaybe<StringFilter>;
};

export type NormalClassWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum NotificationSettingType {
  EmailNotification = 'EmailNotification',
  PushNotification = 'PushNotification',
}

export type PackageSetting = {
  __typename?: 'PackageSetting';
  canViewPremiumClass: Scalars['Int'];
  canViewPremiumLiveClass: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  getCertification: Scalars['Int'];
  id: Scalars['BigInt'];
  joinLiveClass: Scalars['Int'];
  joinLiveClassCount: Scalars['Int'];
  package: PackageType;
  packageId: Scalars['Int'];
  takeAssessment: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PackageSettingCreateManyPackageInput = {
  canViewPremiumClass: Scalars['Int'];
  canViewPremiumLiveClass: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  getCertification: Scalars['Int'];
  id?: InputMaybe<Scalars['BigInt']>;
  joinLiveClass: Scalars['Int'];
  joinLiveClassCount: Scalars['Int'];
  takeAssessment: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PackageSettingCreateManyPackageInputEnvelope = {
  data: Array<PackageSettingCreateManyPackageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PackageSettingCreateNestedManyWithoutPackageInput = {
  connect?: InputMaybe<Array<PackageSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSettingCreateOrConnectWithoutPackageInput>>;
  create?: InputMaybe<Array<PackageSettingCreateWithoutPackageInput>>;
  createMany?: InputMaybe<PackageSettingCreateManyPackageInputEnvelope>;
};

export type PackageSettingCreateOrConnectWithoutPackageInput = {
  create: PackageSettingCreateWithoutPackageInput;
  where: PackageSettingWhereUniqueInput;
};

export type PackageSettingCreateWithoutPackageInput = {
  canViewPremiumClass: Scalars['Int'];
  canViewPremiumLiveClass: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  getCertification: Scalars['Int'];
  id?: InputMaybe<Scalars['BigInt']>;
  joinLiveClass: Scalars['Int'];
  joinLiveClassCount: Scalars['Int'];
  takeAssessment: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PackageSettingListRelationFilter = {
  every?: InputMaybe<PackageSettingWhereInput>;
  none?: InputMaybe<PackageSettingWhereInput>;
  some?: InputMaybe<PackageSettingWhereInput>;
};

export type PackageSettingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PackageSettingOrderByWithRelationInput = {
  canViewPremiumClass?: InputMaybe<SortOrder>;
  canViewPremiumLiveClass?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  getCertification?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  joinLiveClass?: InputMaybe<SortOrder>;
  joinLiveClassCount?: InputMaybe<SortOrder>;
  package?: InputMaybe<PackageTypeOrderByWithRelationInput>;
  packageId?: InputMaybe<SortOrder>;
  takeAssessment?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PackageSettingScalarFieldEnum {
  CanViewPremiumClass = 'canViewPremiumClass',
  CanViewPremiumLiveClass = 'canViewPremiumLiveClass',
  CreatedAt = 'createdAt',
  GetCertification = 'getCertification',
  Id = 'id',
  JoinLiveClass = 'joinLiveClass',
  JoinLiveClassCount = 'joinLiveClassCount',
  PackageId = 'packageId',
  TakeAssessment = 'takeAssessment',
  UpdatedAt = 'updatedAt',
}

export type PackageSettingScalarWhereInput = {
  AND?: InputMaybe<Array<PackageSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<PackageSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<PackageSettingScalarWhereInput>>;
  canViewPremiumClass?: InputMaybe<IntFilter>;
  canViewPremiumLiveClass?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  getCertification?: InputMaybe<IntFilter>;
  id?: InputMaybe<BigIntFilter>;
  joinLiveClass?: InputMaybe<IntFilter>;
  joinLiveClassCount?: InputMaybe<IntFilter>;
  packageId?: InputMaybe<IntFilter>;
  takeAssessment?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PackageSettingUpdateManyMutationInput = {
  canViewPremiumClass?: InputMaybe<Scalars['Int']>;
  canViewPremiumLiveClass?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  getCertification?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  joinLiveClass?: InputMaybe<Scalars['Int']>;
  joinLiveClassCount?: InputMaybe<Scalars['Int']>;
  takeAssessment?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PackageSettingUpdateManyWithWhereWithoutPackageInput = {
  data: PackageSettingUpdateManyMutationInput;
  where: PackageSettingScalarWhereInput;
};

export type PackageSettingUpdateManyWithoutPackageNestedInput = {
  connect?: InputMaybe<Array<PackageSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PackageSettingCreateOrConnectWithoutPackageInput>>;
  create?: InputMaybe<Array<PackageSettingCreateWithoutPackageInput>>;
  createMany?: InputMaybe<PackageSettingCreateManyPackageInputEnvelope>;
  delete?: InputMaybe<Array<PackageSettingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PackageSettingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PackageSettingWhereUniqueInput>>;
  set?: InputMaybe<Array<PackageSettingWhereUniqueInput>>;
  update?: InputMaybe<Array<PackageSettingUpdateWithWhereUniqueWithoutPackageInput>>;
  updateMany?: InputMaybe<Array<PackageSettingUpdateManyWithWhereWithoutPackageInput>>;
  upsert?: InputMaybe<Array<PackageSettingUpsertWithWhereUniqueWithoutPackageInput>>;
};

export type PackageSettingUpdateWithWhereUniqueWithoutPackageInput = {
  data: PackageSettingUpdateWithoutPackageInput;
  where: PackageSettingWhereUniqueInput;
};

export type PackageSettingUpdateWithoutPackageInput = {
  canViewPremiumClass?: InputMaybe<Scalars['Int']>;
  canViewPremiumLiveClass?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  getCertification?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  joinLiveClass?: InputMaybe<Scalars['Int']>;
  joinLiveClassCount?: InputMaybe<Scalars['Int']>;
  takeAssessment?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PackageSettingUpsertWithWhereUniqueWithoutPackageInput = {
  create: PackageSettingCreateWithoutPackageInput;
  update: PackageSettingUpdateWithoutPackageInput;
  where: PackageSettingWhereUniqueInput;
};

export type PackageSettingWhereInput = {
  AND?: InputMaybe<Array<PackageSettingWhereInput>>;
  NOT?: InputMaybe<Array<PackageSettingWhereInput>>;
  OR?: InputMaybe<Array<PackageSettingWhereInput>>;
  canViewPremiumClass?: InputMaybe<IntFilter>;
  canViewPremiumLiveClass?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  getCertification?: InputMaybe<IntFilter>;
  id?: InputMaybe<BigIntFilter>;
  joinLiveClass?: InputMaybe<IntFilter>;
  joinLiveClassCount?: InputMaybe<IntFilter>;
  package?: InputMaybe<PackageTypeRelationFilter>;
  packageId?: InputMaybe<IntFilter>;
  takeAssessment?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PackageSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type PackageSettings = {
  __typename?: 'PackageSettings';
  formattedAmountInMYR: Scalars['String'];
  formattedAmountInUSD: Scalars['String'];
  iapProductId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  packageCode: Scalars['String'];
  packageDescription: Scalars['String'];
  packageName: Scalars['String'];
};

export type PackageType = {
  __typename?: 'PackageType';
  _count?: Maybe<PackageTypeCount>;
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  iosCode?: Maybe<Scalars['String']>;
  maxCap: Scalars['Int'];
  packageAmount: Scalars['Decimal'];
  packageAmountRinggit: Scalars['Decimal'];
  packageDescription: Scalars['String'];
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  packageName: Scalars['String'];
  packageRank: Scalars['String'];
  packageSettings: Array<PackageSetting>;
  packageShortcode: Scalars['String'];
  productCode?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  validDuration?: Maybe<Scalars['Int']>;
};

export type PackageTypePackageSettingsArgs = {
  cursor?: InputMaybe<PackageSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageSettingWhereInput>;
};

export type PackageTypeAvgAggregate = {
  __typename?: 'PackageTypeAvgAggregate';
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId?: Maybe<Scalars['Float']>;
  highlight?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  maxCap?: Maybe<Scalars['Float']>;
  packageAmount?: Maybe<Scalars['Decimal']>;
  packageAmountRinggit?: Maybe<Scalars['Decimal']>;
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  validDuration?: Maybe<Scalars['Float']>;
};

export type PackageTypeAvgOrderByAggregateInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeCount = {
  __typename?: 'PackageTypeCount';
  packageSettings: Scalars['Int'];
};

export type PackageTypeCountAggregate = {
  __typename?: 'PackageTypeCountAggregate';
  _all: Scalars['Int'];
  bvValue: Scalars['Int'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Int'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  iosCode: Scalars['Int'];
  maxCap: Scalars['Int'];
  packageAmount: Scalars['Int'];
  packageAmountRinggit: Scalars['Int'];
  packageDescription: Scalars['Int'];
  packageDiscountAmount: Scalars['Int'];
  packageDiscountAmountRinggit: Scalars['Int'];
  packageName: Scalars['Int'];
  packageRank: Scalars['Int'];
  packageShortcode: Scalars['Int'];
  productCode: Scalars['Int'];
  sequence: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
  validDuration: Scalars['Int'];
};

export type PackageTypeCountOrderByAggregateInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosCode?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  packageName?: InputMaybe<SortOrder>;
  packageRank?: InputMaybe<SortOrder>;
  packageShortcode?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeCreateInput = {
  bvValue?: InputMaybe<Scalars['Decimal']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Int']>;
  iosCode?: InputMaybe<Scalars['String']>;
  maxCap: Scalars['Int'];
  packageAmount: Scalars['Decimal'];
  packageAmountRinggit: Scalars['Decimal'];
  packageDescription: Scalars['String'];
  packageDiscountAmount?: InputMaybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: InputMaybe<Scalars['Float']>;
  packageName: Scalars['String'];
  packageRank: Scalars['String'];
  packageSettings?: InputMaybe<PackageSettingCreateNestedManyWithoutPackageInput>;
  packageShortcode: Scalars['String'];
  productCode?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validDuration?: InputMaybe<Scalars['Int']>;
};

export type PackageTypeCreateManyInput = {
  bvValue?: InputMaybe<Scalars['Decimal']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  iosCode?: InputMaybe<Scalars['String']>;
  maxCap: Scalars['Int'];
  packageAmount: Scalars['Decimal'];
  packageAmountRinggit: Scalars['Decimal'];
  packageDescription: Scalars['String'];
  packageDiscountAmount?: InputMaybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: InputMaybe<Scalars['Float']>;
  packageName: Scalars['String'];
  packageRank: Scalars['String'];
  packageShortcode: Scalars['String'];
  productCode?: InputMaybe<Scalars['String']>;
  sequence: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validDuration?: InputMaybe<Scalars['Int']>;
};

export type PackageTypeGroupBy = {
  __typename?: 'PackageTypeGroupBy';
  _avg?: Maybe<PackageTypeAvgAggregate>;
  _count?: Maybe<PackageTypeCountAggregate>;
  _max?: Maybe<PackageTypeMaxAggregate>;
  _min?: Maybe<PackageTypeMinAggregate>;
  _sum?: Maybe<PackageTypeSumAggregate>;
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  highlight: Scalars['Int'];
  id: Scalars['Int'];
  iosCode?: Maybe<Scalars['String']>;
  maxCap: Scalars['Int'];
  packageAmount: Scalars['Decimal'];
  packageAmountRinggit: Scalars['Decimal'];
  packageDescription: Scalars['String'];
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  packageName: Scalars['String'];
  packageRank: Scalars['String'];
  packageShortcode: Scalars['String'];
  productCode?: Maybe<Scalars['String']>;
  sequence: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  validDuration?: Maybe<Scalars['Int']>;
};

export type PackageTypeMaxAggregate = {
  __typename?: 'PackageTypeMaxAggregate';
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  iosCode?: Maybe<Scalars['String']>;
  maxCap?: Maybe<Scalars['Int']>;
  packageAmount?: Maybe<Scalars['Decimal']>;
  packageAmountRinggit?: Maybe<Scalars['Decimal']>;
  packageDescription?: Maybe<Scalars['String']>;
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  packageName?: Maybe<Scalars['String']>;
  packageRank?: Maybe<Scalars['String']>;
  packageShortcode?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validDuration?: Maybe<Scalars['Int']>;
};

export type PackageTypeMaxOrderByAggregateInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosCode?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  packageName?: InputMaybe<SortOrder>;
  packageRank?: InputMaybe<SortOrder>;
  packageShortcode?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeMinAggregate = {
  __typename?: 'PackageTypeMinAggregate';
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  iosCode?: Maybe<Scalars['String']>;
  maxCap?: Maybe<Scalars['Int']>;
  packageAmount?: Maybe<Scalars['Decimal']>;
  packageAmountRinggit?: Maybe<Scalars['Decimal']>;
  packageDescription?: Maybe<Scalars['String']>;
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  packageName?: Maybe<Scalars['String']>;
  packageRank?: Maybe<Scalars['String']>;
  packageShortcode?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  sequence?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  validDuration?: Maybe<Scalars['Int']>;
};

export type PackageTypeMinOrderByAggregateInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosCode?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  packageName?: InputMaybe<SortOrder>;
  packageRank?: InputMaybe<SortOrder>;
  packageShortcode?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeOrderByWithAggregationInput = {
  _avg?: InputMaybe<PackageTypeAvgOrderByAggregateInput>;
  _count?: InputMaybe<PackageTypeCountOrderByAggregateInput>;
  _max?: InputMaybe<PackageTypeMaxOrderByAggregateInput>;
  _min?: InputMaybe<PackageTypeMinOrderByAggregateInput>;
  _sum?: InputMaybe<PackageTypeSumOrderByAggregateInput>;
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosCode?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  packageName?: InputMaybe<SortOrder>;
  packageRank?: InputMaybe<SortOrder>;
  packageShortcode?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeOrderByWithRelationInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  iosCode?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDescription?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  packageName?: InputMaybe<SortOrder>;
  packageRank?: InputMaybe<SortOrder>;
  packageSettings?: InputMaybe<PackageSettingOrderByRelationAggregateInput>;
  packageShortcode?: InputMaybe<SortOrder>;
  productCode?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeRelationFilter = {
  is?: InputMaybe<PackageTypeWhereInput>;
  isNot?: InputMaybe<PackageTypeWhereInput>;
};

export enum PackageTypeScalarFieldEnum {
  BvValue = 'bvValue',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Highlight = 'highlight',
  Id = 'id',
  IosCode = 'iosCode',
  MaxCap = 'maxCap',
  PackageAmount = 'packageAmount',
  PackageAmountRinggit = 'packageAmountRinggit',
  PackageDescription = 'packageDescription',
  PackageDiscountAmount = 'packageDiscountAmount',
  PackageDiscountAmountRinggit = 'packageDiscountAmountRinggit',
  PackageName = 'packageName',
  PackageRank = 'packageRank',
  PackageShortcode = 'packageShortcode',
  ProductCode = 'productCode',
  Sequence = 'sequence',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  ValidDuration = 'validDuration',
}

export type PackageTypeScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PackageTypeScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PackageTypeScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PackageTypeScalarWhereWithAggregatesInput>>;
  bvValue?: InputMaybe<DecimalNullableWithAggregatesFilter>;
  companyId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  highlight?: InputMaybe<IntWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  iosCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  maxCap?: InputMaybe<IntWithAggregatesFilter>;
  packageAmount?: InputMaybe<DecimalWithAggregatesFilter>;
  packageAmountRinggit?: InputMaybe<DecimalWithAggregatesFilter>;
  packageDescription?: InputMaybe<StringWithAggregatesFilter>;
  packageDiscountAmount?: InputMaybe<FloatNullableWithAggregatesFilter>;
  packageDiscountAmountRinggit?: InputMaybe<FloatNullableWithAggregatesFilter>;
  packageName?: InputMaybe<StringWithAggregatesFilter>;
  packageRank?: InputMaybe<StringWithAggregatesFilter>;
  packageShortcode?: InputMaybe<StringWithAggregatesFilter>;
  productCode?: InputMaybe<StringNullableWithAggregatesFilter>;
  sequence?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  validDuration?: InputMaybe<IntNullableWithAggregatesFilter>;
};

export type PackageTypeSumAggregate = {
  __typename?: 'PackageTypeSumAggregate';
  bvValue?: Maybe<Scalars['Decimal']>;
  companyId?: Maybe<Scalars['Int']>;
  highlight?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  maxCap?: Maybe<Scalars['Int']>;
  packageAmount?: Maybe<Scalars['Decimal']>;
  packageAmountRinggit?: Maybe<Scalars['Decimal']>;
  packageDiscountAmount?: Maybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: Maybe<Scalars['Float']>;
  sequence?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  validDuration?: Maybe<Scalars['Int']>;
};

export type PackageTypeSumOrderByAggregateInput = {
  bvValue?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  highlight?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  maxCap?: InputMaybe<SortOrder>;
  packageAmount?: InputMaybe<SortOrder>;
  packageAmountRinggit?: InputMaybe<SortOrder>;
  packageDiscountAmount?: InputMaybe<SortOrder>;
  packageDiscountAmountRinggit?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  validDuration?: InputMaybe<SortOrder>;
};

export type PackageTypeUpdateInput = {
  bvValue?: InputMaybe<Scalars['Decimal']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Int']>;
  iosCode?: InputMaybe<Scalars['String']>;
  maxCap?: InputMaybe<Scalars['Int']>;
  packageAmount?: InputMaybe<Scalars['Decimal']>;
  packageAmountRinggit?: InputMaybe<Scalars['Decimal']>;
  packageDescription?: InputMaybe<Scalars['String']>;
  packageDiscountAmount?: InputMaybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: InputMaybe<Scalars['Float']>;
  packageName?: InputMaybe<Scalars['String']>;
  packageRank?: InputMaybe<Scalars['String']>;
  packageSettings?: InputMaybe<PackageSettingUpdateManyWithoutPackageNestedInput>;
  packageShortcode?: InputMaybe<Scalars['String']>;
  productCode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validDuration?: InputMaybe<Scalars['Int']>;
};

export type PackageTypeUpdateManyMutationInput = {
  bvValue?: InputMaybe<Scalars['Decimal']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  highlight?: InputMaybe<Scalars['Int']>;
  iosCode?: InputMaybe<Scalars['String']>;
  maxCap?: InputMaybe<Scalars['Int']>;
  packageAmount?: InputMaybe<Scalars['Decimal']>;
  packageAmountRinggit?: InputMaybe<Scalars['Decimal']>;
  packageDescription?: InputMaybe<Scalars['String']>;
  packageDiscountAmount?: InputMaybe<Scalars['Float']>;
  packageDiscountAmountRinggit?: InputMaybe<Scalars['Float']>;
  packageName?: InputMaybe<Scalars['String']>;
  packageRank?: InputMaybe<Scalars['String']>;
  packageShortcode?: InputMaybe<Scalars['String']>;
  productCode?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  validDuration?: InputMaybe<Scalars['Int']>;
};

export type PackageTypeWhereInput = {
  AND?: InputMaybe<Array<PackageTypeWhereInput>>;
  NOT?: InputMaybe<Array<PackageTypeWhereInput>>;
  OR?: InputMaybe<Array<PackageTypeWhereInput>>;
  bvValue?: InputMaybe<DecimalNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  iosCode?: InputMaybe<StringNullableFilter>;
  maxCap?: InputMaybe<IntFilter>;
  packageAmount?: InputMaybe<DecimalFilter>;
  packageAmountRinggit?: InputMaybe<DecimalFilter>;
  packageDescription?: InputMaybe<StringFilter>;
  packageDiscountAmount?: InputMaybe<FloatNullableFilter>;
  packageDiscountAmountRinggit?: InputMaybe<FloatNullableFilter>;
  packageName?: InputMaybe<StringFilter>;
  packageRank?: InputMaybe<StringFilter>;
  packageSettings?: InputMaybe<PackageSettingListRelationFilter>;
  packageShortcode?: InputMaybe<StringFilter>;
  productCode?: InputMaybe<StringNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  validDuration?: InputMaybe<IntNullableFilter>;
};

export type PackageTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedAssessment = {
  __typename?: 'PaginatedAssessment';
  items?: Maybe<Array<Assessment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBannerType = {
  __typename?: 'PaginatedBannerType';
  items?: Maybe<Array<BannerType>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBookmark = {
  __typename?: 'PaginatedBookmark';
  items?: Maybe<Array<UserBookmark>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCmsPage = {
  __typename?: 'PaginatedCMSPage';
  items?: Maybe<Array<CmsPage>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCategory = {
  __typename?: 'PaginatedCategory';
  items?: Maybe<Array<Category>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCertificate = {
  __typename?: 'PaginatedCertificate';
  items?: Maybe<Array<Certificate>>;
  metadata: PaginatationMetadata;
};

export type PaginatedChannel = {
  __typename?: 'PaginatedChannel';
  items?: Maybe<Array<Channel>>;
  metadata: PaginatationMetadata;
};

export type PaginatedClassRating = {
  __typename?: 'PaginatedClassRating';
  items?: Maybe<Array<ClassRating>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCoach = {
  __typename?: 'PaginatedCoach';
  items?: Maybe<Array<Coach>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCourse = {
  __typename?: 'PaginatedCourse';
  items?: Maybe<Array<Course>>;
  metadata: PaginatationMetadata;
};

export type PaginatedIncentive = {
  __typename?: 'PaginatedIncentive';
  items?: Maybe<Array<Incentive>>;
  metadata: PaginatationMetadata;
};

export type PaginatedInterestType = {
  __typename?: 'PaginatedInterestType';
  items?: Maybe<Array<InterestType>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLeaderboard = {
  __typename?: 'PaginatedLeaderboard';
  items?: Maybe<Array<Leaderboard>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLearning = {
  __typename?: 'PaginatedLearning';
  items?: Maybe<Array<Learning>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLibrary = {
  __typename?: 'PaginatedLibrary';
  items?: Maybe<Array<Library>>;
  metadata: PaginatationMetadata;
};

export type PaginatedLiveClass = {
  __typename?: 'PaginatedLiveClass';
  items?: Maybe<Array<LiveClass>>;
  metadata: PaginatationMetadata;
};

export type PaginatedMaterial = {
  __typename?: 'PaginatedMaterial';
  items?: Maybe<Array<Material>>;
  metadata: PaginatationMetadata;
};

export type PaginatedNewsUpdate = {
  __typename?: 'PaginatedNewsUpdate';
  items?: Maybe<Array<NewsUpdate>>;
  metadata: PaginatationMetadata;
};

export type PaginatedNormalClass = {
  __typename?: 'PaginatedNormalClass';
  items?: Maybe<Array<NormalClass>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPackageType = {
  __typename?: 'PaginatedPackageType';
  items?: Maybe<Array<PackageType>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPodcast = {
  __typename?: 'PaginatedPodcast';
  items?: Maybe<Array<Podcast>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPopupBanner = {
  __typename?: 'PaginatedPopupBanner';
  items?: Maybe<Array<PopupBanner>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPushNotification = {
  __typename?: 'PaginatedPushNotification';
  items?: Maybe<Array<PushNotification>>;
  metadata: PaginatationMetadata;
};

export type PaginatedQuestion = {
  __typename?: 'PaginatedQuestion';
  items?: Maybe<Array<Question>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSubscription = {
  __typename?: 'PaginatedSubscription';
  items?: Maybe<Array<Subscription>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSystemConfig = {
  __typename?: 'PaginatedSystemConfig';
  items?: Maybe<Array<SystemConfig>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTagging = {
  __typename?: 'PaginatedTagging';
  items?: Maybe<Array<Tagging>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  items?: Maybe<Array<User>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUserIncentive = {
  __typename?: 'PaginatedUserIncentive';
  items?: Maybe<Array<UserIncentive>>;
  metadata: PaginatationMetadata;
};

export type PaginatedUserSetting = {
  __typename?: 'PaginatedUserSetting';
  items?: Maybe<Array<UserSetting>>;
  metadata: PaginatationMetadata;
};

export type PaginatedVertical = {
  __typename?: 'PaginatedVertical';
  items?: Maybe<Array<Vertical>>;
  metadata: PaginatationMetadata;
};

export type PaginatedVideoRepo = {
  __typename?: 'PaginatedVideoRepo';
  items?: Maybe<Array<VideoRepo>>;
  metadata: PaginatationMetadata;
};

export type PaginatedViewHistory = {
  __typename?: 'PaginatedViewHistory';
  items?: Maybe<Array<UserViewHistory>>;
  metadata: PaginatationMetadata;
};

export type Partner = {
  __typename?: 'Partner';
  _count?: Maybe<PartnerCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
  users: Array<User>;
};

export type PartnerUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type PartnerCount = {
  __typename?: 'PartnerCount';
  users: Scalars['Int'];
};

export type PartnerCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<PartnerCreateWithoutUserInput>;
};

export type PartnerCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<PartnerCreateWithoutUsersInput>;
};

export type PartnerCreateOrConnectWithoutUserInput = {
  create: PartnerCreateWithoutUserInput;
  where: PartnerWhereUniqueInput;
};

export type PartnerCreateOrConnectWithoutUsersInput = {
  create: PartnerCreateWithoutUsersInput;
  where: PartnerWhereUniqueInput;
};

export type PartnerCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSourceInput>;
};

export type PartnerCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutPartnerInput;
};

export type PartnerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type PartnerRelationFilter = {
  is?: InputMaybe<PartnerWhereInput>;
  isNot?: InputMaybe<PartnerWhereInput>;
};

export type PartnerUpdateOneWithoutUserNestedInput = {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<PartnerCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PartnerUpdateWithoutUserInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutUserInput>;
};

export type PartnerUpdateOneWithoutUsersNestedInput = {
  connect?: InputMaybe<PartnerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PartnerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<PartnerCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PartnerUpdateWithoutUsersInput>;
  upsert?: InputMaybe<PartnerUpsertWithoutUsersInput>;
};

export type PartnerUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutSourceNestedInput>;
};

export type PartnerUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutPartnerNestedInput>;
};

export type PartnerUpsertWithoutUserInput = {
  create: PartnerCreateWithoutUserInput;
  update: PartnerUpdateWithoutUserInput;
};

export type PartnerUpsertWithoutUsersInput = {
  create: PartnerCreateWithoutUsersInput;
  update: PartnerUpdateWithoutUsersInput;
};

export type PartnerWhereInput = {
  AND?: InputMaybe<Array<PartnerWhereInput>>;
  NOT?: InputMaybe<Array<PartnerWhereInput>>;
  OR?: InputMaybe<Array<PartnerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type PartnerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  id: Scalars['String'];
  packageCode: Scalars['String'];
  paymentToken: Scalars['String'];
  paymentUrl: Scalars['String'];
};

export enum Platform {
  All = 'All',
  Mobile = 'Mobile',
  Web = 'Web',
}

export type Podcast = {
  __typename?: 'Podcast';
  _count?: Maybe<PodcastCount>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  file?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  isBookmarked: Scalars['Boolean'];
  isViewed: Scalars['Boolean'];
  podcastTags: Array<PodcastTag>;
  podcastTeachers: Array<PodcastTeacher>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  tags?: Maybe<Array<Tagging>>;
  title: Scalars['String'];
  totalView: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userBookmarks: Array<UserBookmark>;
  userViewHistories: Array<UserViewHistory>;
};

export type PodcastPodcastTagsArgs = {
  cursor?: InputMaybe<PodcastTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastTagWhereInput>;
};

export type PodcastPodcastTeachersArgs = {
  cursor?: InputMaybe<PodcastTeacherWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastTeacherScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastTeacherOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastTeacherWhereInput>;
};

export type PodcastUserBookmarksArgs = {
  cursor?: InputMaybe<UserBookmarkWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserBookmarkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserBookmarkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserBookmarkWhereInput>;
};

export type PodcastUserViewHistoriesArgs = {
  cursor?: InputMaybe<UserViewHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserViewHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserViewHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserViewHistoryWhereInput>;
};

export type PodcastAvgAggregate = {
  __typename?: 'PodcastAvgAggregate';
  channelId?: Maybe<Scalars['Float']>;
  companyId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  totalView?: Maybe<Scalars['Float']>;
};

export type PodcastAvgOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
};

export type PodcastCount = {
  __typename?: 'PodcastCount';
  podcastTags: Scalars['Int'];
  podcastTeachers: Scalars['Int'];
  userBookmarks: Scalars['Int'];
  userViewHistories: Scalars['Int'];
};

export type PodcastCountAggregate = {
  __typename?: 'PodcastCountAggregate';
  _all: Scalars['Int'];
  channelId: Scalars['Int'];
  companyId: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  duration: Scalars['Int'];
  file: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  totalView: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PodcastCountOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PodcastCreateInput = {
  channel?: InputMaybe<ChannelCreateNestedOneWithoutPodcastsInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutPodcastInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkCreateNestedManyWithoutPodcastInput>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutPodcastInput>;
};

export type PodcastCreateManyChannelInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastCreateManyChannelInputEnvelope = {
  data: Array<PodcastCreateManyChannelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PodcastCreateManyInput = {
  channelId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastCreateNestedManyWithoutChannelInput = {
  connect?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<PodcastCreateWithoutChannelInput>>;
  createMany?: InputMaybe<PodcastCreateManyChannelInputEnvelope>;
};

export type PodcastCreateNestedOneWithoutPodcastTagsInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutPodcastTagsInput>;
  create?: InputMaybe<PodcastCreateWithoutPodcastTagsInput>;
};

export type PodcastCreateNestedOneWithoutPodcastTeachersInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutPodcastTeachersInput>;
  create?: InputMaybe<PodcastCreateWithoutPodcastTeachersInput>;
};

export type PodcastCreateNestedOneWithoutUserBookmarksInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutUserBookmarksInput>;
  create?: InputMaybe<PodcastCreateWithoutUserBookmarksInput>;
};

export type PodcastCreateNestedOneWithoutUserViewHistoriesInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutUserViewHistoriesInput>;
  create?: InputMaybe<PodcastCreateWithoutUserViewHistoriesInput>;
};

export type PodcastCreateOrConnectWithoutChannelInput = {
  create: PodcastCreateWithoutChannelInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastCreateOrConnectWithoutPodcastTagsInput = {
  create: PodcastCreateWithoutPodcastTagsInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastCreateOrConnectWithoutPodcastTeachersInput = {
  create: PodcastCreateWithoutPodcastTeachersInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastCreateOrConnectWithoutUserBookmarksInput = {
  create: PodcastCreateWithoutUserBookmarksInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastCreateOrConnectWithoutUserViewHistoriesInput = {
  create: PodcastCreateWithoutUserViewHistoriesInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastCreateWithoutChannelInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutPodcastInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkCreateNestedManyWithoutPodcastInput>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutPodcastInput>;
};

export type PodcastCreateWithoutPodcastTagsInput = {
  channel?: InputMaybe<ChannelCreateNestedOneWithoutPodcastsInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkCreateNestedManyWithoutPodcastInput>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutPodcastInput>;
};

export type PodcastCreateWithoutPodcastTeachersInput = {
  channel?: InputMaybe<ChannelCreateNestedOneWithoutPodcastsInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkCreateNestedManyWithoutPodcastInput>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutPodcastInput>;
};

export type PodcastCreateWithoutUserBookmarksInput = {
  channel?: InputMaybe<ChannelCreateNestedOneWithoutPodcastsInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutPodcastInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryCreateNestedManyWithoutPodcastInput>;
};

export type PodcastCreateWithoutUserViewHistoriesInput = {
  channel?: InputMaybe<ChannelCreateNestedOneWithoutPodcastsInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutPodcastInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherCreateNestedManyWithoutPodcastInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkCreateNestedManyWithoutPodcastInput>;
};

export type PodcastGroupBy = {
  __typename?: 'PodcastGroupBy';
  _avg?: Maybe<PodcastAvgAggregate>;
  _count?: Maybe<PodcastCountAggregate>;
  _max?: Maybe<PodcastMaxAggregate>;
  _min?: Maybe<PodcastMinAggregate>;
  _sum?: Maybe<PodcastSumAggregate>;
  channelId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  file?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  title: Scalars['String'];
  totalView: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastListRelationFilter = {
  every?: InputMaybe<PodcastWhereInput>;
  none?: InputMaybe<PodcastWhereInput>;
  some?: InputMaybe<PodcastWhereInput>;
};

export type PodcastMaxAggregate = {
  __typename?: 'PodcastMaxAggregate';
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalView?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastMaxOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PodcastMinAggregate = {
  __typename?: 'PodcastMinAggregate';
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  totalView?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastMinOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PodcastOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PodcastOrderByWithAggregationInput = {
  _avg?: InputMaybe<PodcastAvgOrderByAggregateInput>;
  _count?: InputMaybe<PodcastCountOrderByAggregateInput>;
  _max?: InputMaybe<PodcastMaxOrderByAggregateInput>;
  _min?: InputMaybe<PodcastMinOrderByAggregateInput>;
  _sum?: InputMaybe<PodcastSumOrderByAggregateInput>;
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PodcastOrderByWithRelationInput = {
  channel?: InputMaybe<ChannelOrderByWithRelationInput>;
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  file?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  podcastTags?: InputMaybe<PodcastTagOrderByRelationAggregateInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userBookmarks?: InputMaybe<UserBookmarkOrderByRelationAggregateInput>;
  userViewHistories?: InputMaybe<UserViewHistoryOrderByRelationAggregateInput>;
};

export type PodcastRelationFilter = {
  is?: InputMaybe<PodcastWhereInput>;
  isNot?: InputMaybe<PodcastWhereInput>;
};

export enum PodcastScalarFieldEnum {
  ChannelId = 'channelId',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Duration = 'duration',
  File = 'file',
  Id = 'id',
  Image = 'image',
  Slug = 'slug',
  Status = 'status',
  Title = 'title',
  TotalView = 'totalView',
  UpdatedAt = 'updatedAt',
}

export type PodcastScalarWhereInput = {
  AND?: InputMaybe<Array<PodcastScalarWhereInput>>;
  NOT?: InputMaybe<Array<PodcastScalarWhereInput>>;
  OR?: InputMaybe<Array<PodcastScalarWhereInput>>;
  channelId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  file?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PodcastScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PodcastScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PodcastScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PodcastScalarWhereWithAggregatesInput>>;
  channelId?: InputMaybe<IntNullableWithAggregatesFilter>;
  companyId?: InputMaybe<IntWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  duration?: InputMaybe<IntWithAggregatesFilter>;
  file?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  totalView?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type PodcastSumAggregate = {
  __typename?: 'PodcastSumAggregate';
  channelId?: Maybe<Scalars['Int']>;
  companyId?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  totalView?: Maybe<Scalars['Int']>;
};

export type PodcastSumOrderByAggregateInput = {
  channelId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalView?: InputMaybe<SortOrder>;
};

export type PodcastTag = {
  __typename?: 'PodcastTag';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  podcast: Podcast;
  podcastId: Scalars['Int'];
  tag: Tagging;
  tagId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastTagCreateManyPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tagId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagCreateManyPodcastInputEnvelope = {
  data: Array<PodcastTagCreateManyPodcastInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PodcastTagCreateManyTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcastId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagCreateManyTagInputEnvelope = {
  data: Array<PodcastTagCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PodcastTagCreateNestedManyWithoutPodcastInput = {
  connect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTagCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<PodcastTagCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<PodcastTagCreateManyPodcastInputEnvelope>;
};

export type PodcastTagCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTagCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<PodcastTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<PodcastTagCreateManyTagInputEnvelope>;
};

export type PodcastTagCreateOrConnectWithoutPodcastInput = {
  create: PodcastTagCreateWithoutPodcastInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagCreateOrConnectWithoutTagInput = {
  create: PodcastTagCreateWithoutTagInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagCreateWithoutPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag: TaggingCreateNestedOneWithoutPodcastTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagCreateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast: PodcastCreateNestedOneWithoutPodcastTagsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagListRelationFilter = {
  every?: InputMaybe<PodcastTagWhereInput>;
  none?: InputMaybe<PodcastTagWhereInput>;
  some?: InputMaybe<PodcastTagWhereInput>;
};

export type PodcastTagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PodcastTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  podcast?: InputMaybe<PodcastOrderByWithRelationInput>;
  podcastId?: InputMaybe<SortOrder>;
  tag?: InputMaybe<TaggingOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PodcastTagScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PodcastId = 'podcastId',
  TagId = 'tagId',
  UpdatedAt = 'updatedAt',
}

export type PodcastTagScalarWhereInput = {
  AND?: InputMaybe<Array<PodcastTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<PodcastTagScalarWhereInput>>;
  OR?: InputMaybe<Array<PodcastTagScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  podcastId?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PodcastTagUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagUpdateManyWithWhereWithoutPodcastInput = {
  data: PodcastTagUpdateManyMutationInput;
  where: PodcastTagScalarWhereInput;
};

export type PodcastTagUpdateManyWithWhereWithoutTagInput = {
  data: PodcastTagUpdateManyMutationInput;
  where: PodcastTagScalarWhereInput;
};

export type PodcastTagUpdateManyWithoutPodcastNestedInput = {
  connect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTagCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<PodcastTagCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<PodcastTagCreateManyPodcastInputEnvelope>;
  delete?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PodcastTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  set?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  update?: InputMaybe<Array<PodcastTagUpdateWithWhereUniqueWithoutPodcastInput>>;
  updateMany?: InputMaybe<Array<PodcastTagUpdateManyWithWhereWithoutPodcastInput>>;
  upsert?: InputMaybe<Array<PodcastTagUpsertWithWhereUniqueWithoutPodcastInput>>;
};

export type PodcastTagUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTagCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<PodcastTagCreateWithoutTagInput>>;
  createMany?: InputMaybe<PodcastTagCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PodcastTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  set?: InputMaybe<Array<PodcastTagWhereUniqueInput>>;
  update?: InputMaybe<Array<PodcastTagUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<PodcastTagUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<PodcastTagUpsertWithWhereUniqueWithoutTagInput>>;
};

export type PodcastTagUpdateWithWhereUniqueWithoutPodcastInput = {
  data: PodcastTagUpdateWithoutPodcastInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagUpdateWithWhereUniqueWithoutTagInput = {
  data: PodcastTagUpdateWithoutTagInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagUpdateWithoutPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  tag?: InputMaybe<TaggingUpdateOneRequiredWithoutPodcastTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagUpdateWithoutTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast?: InputMaybe<PodcastUpdateOneRequiredWithoutPodcastTagsNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTagUpsertWithWhereUniqueWithoutPodcastInput = {
  create: PodcastTagCreateWithoutPodcastInput;
  update: PodcastTagUpdateWithoutPodcastInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagUpsertWithWhereUniqueWithoutTagInput = {
  create: PodcastTagCreateWithoutTagInput;
  update: PodcastTagUpdateWithoutTagInput;
  where: PodcastTagWhereUniqueInput;
};

export type PodcastTagWhereInput = {
  AND?: InputMaybe<Array<PodcastTagWhereInput>>;
  NOT?: InputMaybe<Array<PodcastTagWhereInput>>;
  OR?: InputMaybe<Array<PodcastTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  podcast?: InputMaybe<PodcastRelationFilter>;
  podcastId?: InputMaybe<IntFilter>;
  tag?: InputMaybe<TaggingRelationFilter>;
  tagId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PodcastTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type PodcastTeacher = {
  __typename?: 'PodcastTeacher';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  podcast: Podcast;
  podcastId: Scalars['Int'];
  teacher: Coach;
  teacherId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PodcastTeacherCreateManyPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  teacherId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherCreateManyPodcastInputEnvelope = {
  data: Array<PodcastTeacherCreateManyPodcastInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PodcastTeacherCreateManyTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcastId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherCreateManyTeacherInputEnvelope = {
  data: Array<PodcastTeacherCreateManyTeacherInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PodcastTeacherCreateNestedManyWithoutPodcastInput = {
  connect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTeacherCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<PodcastTeacherCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<PodcastTeacherCreateManyPodcastInputEnvelope>;
};

export type PodcastTeacherCreateNestedManyWithoutTeacherInput = {
  connect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTeacherCreateOrConnectWithoutTeacherInput>>;
  create?: InputMaybe<Array<PodcastTeacherCreateWithoutTeacherInput>>;
  createMany?: InputMaybe<PodcastTeacherCreateManyTeacherInputEnvelope>;
};

export type PodcastTeacherCreateOrConnectWithoutPodcastInput = {
  create: PodcastTeacherCreateWithoutPodcastInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherCreateOrConnectWithoutTeacherInput = {
  create: PodcastTeacherCreateWithoutTeacherInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherCreateWithoutPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  teacher: CoachCreateNestedOneWithoutPodcastTeachersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherCreateWithoutTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast: PodcastCreateNestedOneWithoutPodcastTeachersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherListRelationFilter = {
  every?: InputMaybe<PodcastTeacherWhereInput>;
  none?: InputMaybe<PodcastTeacherWhereInput>;
  some?: InputMaybe<PodcastTeacherWhereInput>;
};

export type PodcastTeacherOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PodcastTeacherOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  podcast?: InputMaybe<PodcastOrderByWithRelationInput>;
  podcastId?: InputMaybe<SortOrder>;
  teacher?: InputMaybe<CoachOrderByWithRelationInput>;
  teacherId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PodcastTeacherScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PodcastId = 'podcastId',
  TeacherId = 'teacherId',
  UpdatedAt = 'updatedAt',
}

export type PodcastTeacherScalarWhereInput = {
  AND?: InputMaybe<Array<PodcastTeacherScalarWhereInput>>;
  NOT?: InputMaybe<Array<PodcastTeacherScalarWhereInput>>;
  OR?: InputMaybe<Array<PodcastTeacherScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  podcastId?: InputMaybe<IntFilter>;
  teacherId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PodcastTeacherUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherUpdateManyWithWhereWithoutPodcastInput = {
  data: PodcastTeacherUpdateManyMutationInput;
  where: PodcastTeacherScalarWhereInput;
};

export type PodcastTeacherUpdateManyWithWhereWithoutTeacherInput = {
  data: PodcastTeacherUpdateManyMutationInput;
  where: PodcastTeacherScalarWhereInput;
};

export type PodcastTeacherUpdateManyWithoutPodcastNestedInput = {
  connect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTeacherCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<PodcastTeacherCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<PodcastTeacherCreateManyPodcastInputEnvelope>;
  delete?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PodcastTeacherScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  set?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  update?: InputMaybe<Array<PodcastTeacherUpdateWithWhereUniqueWithoutPodcastInput>>;
  updateMany?: InputMaybe<Array<PodcastTeacherUpdateManyWithWhereWithoutPodcastInput>>;
  upsert?: InputMaybe<Array<PodcastTeacherUpsertWithWhereUniqueWithoutPodcastInput>>;
};

export type PodcastTeacherUpdateManyWithoutTeacherNestedInput = {
  connect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastTeacherCreateOrConnectWithoutTeacherInput>>;
  create?: InputMaybe<Array<PodcastTeacherCreateWithoutTeacherInput>>;
  createMany?: InputMaybe<PodcastTeacherCreateManyTeacherInputEnvelope>;
  delete?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PodcastTeacherScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  set?: InputMaybe<Array<PodcastTeacherWhereUniqueInput>>;
  update?: InputMaybe<Array<PodcastTeacherUpdateWithWhereUniqueWithoutTeacherInput>>;
  updateMany?: InputMaybe<Array<PodcastTeacherUpdateManyWithWhereWithoutTeacherInput>>;
  upsert?: InputMaybe<Array<PodcastTeacherUpsertWithWhereUniqueWithoutTeacherInput>>;
};

export type PodcastTeacherUpdateWithWhereUniqueWithoutPodcastInput = {
  data: PodcastTeacherUpdateWithoutPodcastInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherUpdateWithWhereUniqueWithoutTeacherInput = {
  data: PodcastTeacherUpdateWithoutTeacherInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherUpdateWithoutPodcastInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  teacher?: InputMaybe<CoachUpdateOneRequiredWithoutPodcastTeachersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherUpdateWithoutTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast?: InputMaybe<PodcastUpdateOneRequiredWithoutPodcastTeachersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastTeacherUpsertWithWhereUniqueWithoutPodcastInput = {
  create: PodcastTeacherCreateWithoutPodcastInput;
  update: PodcastTeacherUpdateWithoutPodcastInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherUpsertWithWhereUniqueWithoutTeacherInput = {
  create: PodcastTeacherCreateWithoutTeacherInput;
  update: PodcastTeacherUpdateWithoutTeacherInput;
  where: PodcastTeacherWhereUniqueInput;
};

export type PodcastTeacherWhereInput = {
  AND?: InputMaybe<Array<PodcastTeacherWhereInput>>;
  NOT?: InputMaybe<Array<PodcastTeacherWhereInput>>;
  OR?: InputMaybe<Array<PodcastTeacherWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  podcast?: InputMaybe<PodcastRelationFilter>;
  podcastId?: InputMaybe<IntFilter>;
  teacher?: InputMaybe<CoachRelationFilter>;
  teacherId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PodcastTeacherWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type PodcastUpdateInput = {
  channel?: InputMaybe<ChannelUpdateOneWithoutPodcastsNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutPodcastNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkUpdateManyWithoutPodcastNestedInput>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpdateManyMutationInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PodcastUpdateManyWithWhereWithoutChannelInput = {
  data: PodcastUpdateManyMutationInput;
  where: PodcastScalarWhereInput;
};

export type PodcastUpdateManyWithoutChannelNestedInput = {
  connect?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PodcastCreateOrConnectWithoutChannelInput>>;
  create?: InputMaybe<Array<PodcastCreateWithoutChannelInput>>;
  createMany?: InputMaybe<PodcastCreateManyChannelInputEnvelope>;
  delete?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PodcastScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  set?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  update?: InputMaybe<Array<PodcastUpdateWithWhereUniqueWithoutChannelInput>>;
  updateMany?: InputMaybe<Array<PodcastUpdateManyWithWhereWithoutChannelInput>>;
  upsert?: InputMaybe<Array<PodcastUpsertWithWhereUniqueWithoutChannelInput>>;
};

export type PodcastUpdateOneRequiredWithoutPodcastTagsNestedInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutPodcastTagsInput>;
  create?: InputMaybe<PodcastCreateWithoutPodcastTagsInput>;
  update?: InputMaybe<PodcastUpdateWithoutPodcastTagsInput>;
  upsert?: InputMaybe<PodcastUpsertWithoutPodcastTagsInput>;
};

export type PodcastUpdateOneRequiredWithoutPodcastTeachersNestedInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutPodcastTeachersInput>;
  create?: InputMaybe<PodcastCreateWithoutPodcastTeachersInput>;
  update?: InputMaybe<PodcastUpdateWithoutPodcastTeachersInput>;
  upsert?: InputMaybe<PodcastUpsertWithoutPodcastTeachersInput>;
};

export type PodcastUpdateOneWithoutUserBookmarksNestedInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutUserBookmarksInput>;
  create?: InputMaybe<PodcastCreateWithoutUserBookmarksInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PodcastUpdateWithoutUserBookmarksInput>;
  upsert?: InputMaybe<PodcastUpsertWithoutUserBookmarksInput>;
};

export type PodcastUpdateOneWithoutUserViewHistoriesNestedInput = {
  connect?: InputMaybe<PodcastWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PodcastCreateOrConnectWithoutUserViewHistoriesInput>;
  create?: InputMaybe<PodcastCreateWithoutUserViewHistoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PodcastUpdateWithoutUserViewHistoriesInput>;
  upsert?: InputMaybe<PodcastUpsertWithoutUserViewHistoriesInput>;
};

export type PodcastUpdateWithWhereUniqueWithoutChannelInput = {
  data: PodcastUpdateWithoutChannelInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastUpdateWithoutChannelInput = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutPodcastNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkUpdateManyWithoutPodcastNestedInput>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpdateWithoutPodcastTagsInput = {
  channel?: InputMaybe<ChannelUpdateOneWithoutPodcastsNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkUpdateManyWithoutPodcastNestedInput>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpdateWithoutPodcastTeachersInput = {
  channel?: InputMaybe<ChannelUpdateOneWithoutPodcastsNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkUpdateManyWithoutPodcastNestedInput>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpdateWithoutUserBookmarksInput = {
  channel?: InputMaybe<ChannelUpdateOneWithoutPodcastsNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutPodcastNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userViewHistories?: InputMaybe<UserViewHistoryUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpdateWithoutUserViewHistoriesInput = {
  channel?: InputMaybe<ChannelUpdateOneWithoutPodcastsNestedInput>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  file?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutPodcastNestedInput>;
  podcastTeachers?: InputMaybe<PodcastTeacherUpdateManyWithoutPodcastNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  totalView?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userBookmarks?: InputMaybe<UserBookmarkUpdateManyWithoutPodcastNestedInput>;
};

export type PodcastUpsertWithWhereUniqueWithoutChannelInput = {
  create: PodcastCreateWithoutChannelInput;
  update: PodcastUpdateWithoutChannelInput;
  where: PodcastWhereUniqueInput;
};

export type PodcastUpsertWithoutPodcastTagsInput = {
  create: PodcastCreateWithoutPodcastTagsInput;
  update: PodcastUpdateWithoutPodcastTagsInput;
};

export type PodcastUpsertWithoutPodcastTeachersInput = {
  create: PodcastCreateWithoutPodcastTeachersInput;
  update: PodcastUpdateWithoutPodcastTeachersInput;
};

export type PodcastUpsertWithoutUserBookmarksInput = {
  create: PodcastCreateWithoutUserBookmarksInput;
  update: PodcastUpdateWithoutUserBookmarksInput;
};

export type PodcastUpsertWithoutUserViewHistoriesInput = {
  create: PodcastCreateWithoutUserViewHistoriesInput;
  update: PodcastUpdateWithoutUserViewHistoriesInput;
};

export type PodcastWhereInput = {
  AND?: InputMaybe<Array<PodcastWhereInput>>;
  NOT?: InputMaybe<Array<PodcastWhereInput>>;
  OR?: InputMaybe<Array<PodcastWhereInput>>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  file?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  podcastTags?: InputMaybe<PodcastTagListRelationFilter>;
  podcastTeachers?: InputMaybe<PodcastTeacherListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userBookmarks?: InputMaybe<UserBookmarkListRelationFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
};

export type PodcastWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PopupBanner = {
  __typename?: 'PopupBanner';
  bannerUrl: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  id: Scalars['Int'];
  startAt: Scalars['DateTime'];
  status: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PopupBannerAvgAggregate = {
  __typename?: 'PopupBannerAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type PopupBannerAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PopupBannerCountAggregate = {
  __typename?: 'PopupBannerCountAggregate';
  _all: Scalars['Int'];
  bannerUrl: Scalars['Int'];
  createdAt: Scalars['Int'];
  endAt: Scalars['Int'];
  id: Scalars['Int'];
  startAt: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PopupBannerCountOrderByAggregateInput = {
  bannerUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PopupBannerCreateInput = {
  bannerUrl: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopupBannerCreateManyInput = {
  bannerUrl: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['Int']>;
  startAt: Scalars['DateTime'];
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopupBannerGroupBy = {
  __typename?: 'PopupBannerGroupBy';
  _avg?: Maybe<PopupBannerAvgAggregate>;
  _count?: Maybe<PopupBannerCountAggregate>;
  _max?: Maybe<PopupBannerMaxAggregate>;
  _min?: Maybe<PopupBannerMinAggregate>;
  _sum?: Maybe<PopupBannerSumAggregate>;
  bannerUrl: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['DateTime'];
  id: Scalars['Int'];
  startAt: Scalars['DateTime'];
  status: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PopupBannerMaxAggregate = {
  __typename?: 'PopupBannerMaxAggregate';
  bannerUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PopupBannerMaxOrderByAggregateInput = {
  bannerUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PopupBannerMinAggregate = {
  __typename?: 'PopupBannerMinAggregate';
  bannerUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PopupBannerMinOrderByAggregateInput = {
  bannerUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PopupBannerOrderByWithAggregationInput = {
  _avg?: InputMaybe<PopupBannerAvgOrderByAggregateInput>;
  _count?: InputMaybe<PopupBannerCountOrderByAggregateInput>;
  _max?: InputMaybe<PopupBannerMaxOrderByAggregateInput>;
  _min?: InputMaybe<PopupBannerMinOrderByAggregateInput>;
  _sum?: InputMaybe<PopupBannerSumOrderByAggregateInput>;
  bannerUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PopupBannerOrderByWithRelationInput = {
  bannerUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  startAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PopupBannerScalarFieldEnum {
  BannerUrl = 'bannerUrl',
  CreatedAt = 'createdAt',
  EndAt = 'endAt',
  Id = 'id',
  StartAt = 'startAt',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
}

export type PopupBannerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PopupBannerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PopupBannerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PopupBannerScalarWhereWithAggregatesInput>>;
  bannerUrl?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  endAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  startAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type PopupBannerSumAggregate = {
  __typename?: 'PopupBannerSumAggregate';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type PopupBannerSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type PopupBannerUpdateInput = {
  bannerUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopupBannerUpdateManyMutationInput = {
  bannerUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  startAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PopupBannerWhereInput = {
  AND?: InputMaybe<Array<PopupBannerWhereInput>>;
  NOT?: InputMaybe<Array<PopupBannerWhereInput>>;
  OR?: InputMaybe<Array<PopupBannerWhereInput>>;
  bannerUrl?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  endAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  startAt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type PopupBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type PreferManyInterestTypesInput = {
  ids: Array<Scalars['BigInt']>;
};

export type PreferManyVerticalsInput = {
  ids: Array<Scalars['Int']>;
};

export type PushNotification = {
  __typename?: 'PushNotification';
  action?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customLink?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PushNotificationAvgAggregate = {
  __typename?: 'PushNotificationAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['Float']>;
};

export type PushNotificationAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
};

export type PushNotificationCountAggregate = {
  __typename?: 'PushNotificationCountAggregate';
  _all: Scalars['Int'];
  action: Scalars['Int'];
  body: Scalars['Int'];
  createdAt: Scalars['Int'];
  customLink: Scalars['Int'];
  id: Scalars['Int'];
  link: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type PushNotificationCountOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PushNotificationCreateInput = {
  action?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PushNotificationCreateManyInput = {
  action?: InputMaybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PushNotificationGroupBy = {
  __typename?: 'PushNotificationGroupBy';
  _avg?: Maybe<PushNotificationAvgAggregate>;
  _count?: Maybe<PushNotificationCountAggregate>;
  _max?: Maybe<PushNotificationMaxAggregate>;
  _min?: Maybe<PushNotificationMinAggregate>;
  _sum?: Maybe<PushNotificationSumAggregate>;
  action?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customLink?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PushNotificationMaxAggregate = {
  __typename?: 'PushNotificationMaxAggregate';
  action?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationMaxOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PushNotificationMinAggregate = {
  __typename?: 'PushNotificationMinAggregate';
  action?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PushNotificationMinOrderByAggregateInput = {
  action?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PushNotificationOrderByWithAggregationInput = {
  _avg?: InputMaybe<PushNotificationAvgOrderByAggregateInput>;
  _count?: InputMaybe<PushNotificationCountOrderByAggregateInput>;
  _max?: InputMaybe<PushNotificationMaxOrderByAggregateInput>;
  _min?: InputMaybe<PushNotificationMinOrderByAggregateInput>;
  _sum?: InputMaybe<PushNotificationSumOrderByAggregateInput>;
  action?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PushNotificationOrderByWithRelationInput = {
  action?: InputMaybe<SortOrder>;
  body?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PushNotificationScalarFieldEnum {
  Action = 'action',
  Body = 'body',
  CreatedAt = 'createdAt',
  CustomLink = 'customLink',
  Id = 'id',
  Link = 'link',
  Title = 'title',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type PushNotificationScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PushNotificationScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PushNotificationScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PushNotificationScalarWhereWithAggregatesInput>>;
  action?: InputMaybe<StringNullableWithAggregatesFilter>;
  body?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  customLink?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  link?: InputMaybe<IntNullableWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<StringNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PushNotificationSumAggregate = {
  __typename?: 'PushNotificationSumAggregate';
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['Int']>;
};

export type PushNotificationSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
};

export type PushNotificationUpdateInput = {
  action?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PushNotificationUpdateManyMutationInput = {
  action?: InputMaybe<Scalars['String']>;
  body?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PushNotificationWhereInput = {
  AND?: InputMaybe<Array<PushNotificationWhereInput>>;
  NOT?: InputMaybe<Array<PushNotificationWhereInput>>;
  OR?: InputMaybe<Array<PushNotificationWhereInput>>;
  action?: InputMaybe<StringNullableFilter>;
  body?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customLink?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  link?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PushNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QrCodeChallenge = {
  __typename?: 'QRCodeChallenge';
  challengeId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiredOn: Scalars['DateTime'];
  id: Scalars['BigInt'];
  qrCodeUrl: Scalars['String'];
  status: QrCodeChallengeStatus;
  updatedAt: Scalars['DateTime'];
};

export type QrCodeChallengeCreateManyUserInput = {
  challengeId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiredOn: Scalars['DateTime'];
  id?: InputMaybe<Scalars['BigInt']>;
  qrCodeUrl: Scalars['String'];
  status?: InputMaybe<QrCodeChallengeStatus>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QrCodeChallengeCreateManyUserInputEnvelope = {
  data: Array<QrCodeChallengeCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QrCodeChallengeCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<QrCodeChallengeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QrCodeChallengeCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<QrCodeChallengeCreateWithoutUserInput>>;
  createMany?: InputMaybe<QrCodeChallengeCreateManyUserInputEnvelope>;
};

export type QrCodeChallengeCreateOrConnectWithoutUserInput = {
  create: QrCodeChallengeCreateWithoutUserInput;
  where: QrCodeChallengeWhereUniqueInput;
};

export type QrCodeChallengeCreateWithoutUserInput = {
  challengeId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiredOn: Scalars['DateTime'];
  id?: InputMaybe<Scalars['BigInt']>;
  qrCodeUrl: Scalars['String'];
  status?: InputMaybe<QrCodeChallengeStatus>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QrCodeChallengeEnquiry = {
  __typename?: 'QRCodeChallengeEnquiry';
  challengeId: Scalars['String'];
  expiredOn: Scalars['DateTime'];
  loginToken?: Maybe<Scalars['String']>;
  status: QrCodeChallengeStatus;
};

export type QrCodeChallengeListRelationFilter = {
  every?: InputMaybe<QrCodeChallengeWhereInput>;
  none?: InputMaybe<QrCodeChallengeWhereInput>;
  some?: InputMaybe<QrCodeChallengeWhereInput>;
};

export type QrCodeChallengeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QrCodeChallengeOrderByWithRelationInput = {
  challengeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiredOn?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  qrCodeUrl?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum QrCodeChallengeScalarFieldEnum {
  ChallengeId = 'challengeId',
  CreatedAt = 'createdAt',
  ExpiredOn = 'expiredOn',
  Id = 'id',
  QrCodeUrl = 'qrCodeUrl',
  Status = 'status',
  Token = 'token',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type QrCodeChallengeScalarWhereInput = {
  AND?: InputMaybe<Array<QrCodeChallengeScalarWhereInput>>;
  NOT?: InputMaybe<Array<QrCodeChallengeScalarWhereInput>>;
  OR?: InputMaybe<Array<QrCodeChallengeScalarWhereInput>>;
  challengeId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredOn?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  qrCodeUrl?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumQrCodeChallengeStatusFilter>;
  token?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntNullableFilter>;
};

export enum QrCodeChallengeStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  NotScanned = 'NotScanned',
}

export type QrCodeChallengeUpdateManyMutationInput = {
  challengeId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiredOn?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  qrCodeUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<QrCodeChallengeStatus>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QrCodeChallengeUpdateManyWithWhereWithoutUserInput = {
  data: QrCodeChallengeUpdateManyMutationInput;
  where: QrCodeChallengeScalarWhereInput;
};

export type QrCodeChallengeUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<QrCodeChallengeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QrCodeChallengeCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<QrCodeChallengeCreateWithoutUserInput>>;
  createMany?: InputMaybe<QrCodeChallengeCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<QrCodeChallengeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QrCodeChallengeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QrCodeChallengeWhereUniqueInput>>;
  set?: InputMaybe<Array<QrCodeChallengeWhereUniqueInput>>;
  update?: InputMaybe<Array<QrCodeChallengeUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<QrCodeChallengeUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<QrCodeChallengeUpsertWithWhereUniqueWithoutUserInput>>;
};

export type QrCodeChallengeUpdateWithWhereUniqueWithoutUserInput = {
  data: QrCodeChallengeUpdateWithoutUserInput;
  where: QrCodeChallengeWhereUniqueInput;
};

export type QrCodeChallengeUpdateWithoutUserInput = {
  challengeId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expiredOn?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  qrCodeUrl?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<QrCodeChallengeStatus>;
  token?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QrCodeChallengeUpsertWithWhereUniqueWithoutUserInput = {
  create: QrCodeChallengeCreateWithoutUserInput;
  update: QrCodeChallengeUpdateWithoutUserInput;
  where: QrCodeChallengeWhereUniqueInput;
};

export type QrCodeChallengeWhereInput = {
  AND?: InputMaybe<Array<QrCodeChallengeWhereInput>>;
  NOT?: InputMaybe<Array<QrCodeChallengeWhereInput>>;
  OR?: InputMaybe<Array<QrCodeChallengeWhereInput>>;
  challengeId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiredOn?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  qrCodeUrl?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumQrCodeChallengeStatusFilter>;
  token?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntNullableFilter>;
};

export type QrCodeChallengeWhereUniqueInput = {
  challengeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  token?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  aggregateAssessment: AggregateAssessment;
  aggregateBannerType: AggregateBannerType;
  aggregateCategory: AggregateCategory;
  aggregateCertificate: AggregateCertificate;
  aggregateClassRating: AggregateClassRating;
  aggregateCourse: AggregateCourse;
  aggregateIncentive: AggregateIncentive;
  aggregateInterestType: AggregateInterestType;
  aggregateMaterial: AggregateMaterial;
  aggregateNewsUpdate: AggregateNewsUpdate;
  aggregateNormalClass: AggregateNormalClass;
  aggregatePackageType: AggregatePackageType;
  aggregatePodcast: AggregatePodcast;
  aggregatePopupBanner: AggregatePopupBanner;
  aggregatePushNotification: AggregatePushNotification;
  aggregateQuestion: AggregateQuestion;
  aggregateSystemConfig: AggregateSystemConfig;
  aggregateTagging: AggregateTagging;
  aggregateTransaction: AggregateTransaction;
  aggregateUserIncentive: AggregateUserIncentive;
  aggregateUserSetting: AggregateUserSetting;
  aggregateVertical: AggregateVertical;
  aggregateVideoRepo: AggregateVideoRepo;
  assessment?: Maybe<Assessment>;
  assessments: Array<Assessment>;
  bannerType?: Maybe<BannerType>;
  bannerTypes: Array<BannerType>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  certificate?: Maybe<Certificate>;
  certificates: Array<Certificate>;
  classRating?: Maybe<ClassRating>;
  classRatings: Array<ClassRating>;
  course?: Maybe<Course>;
  courses: Array<Course>;
  fetchReferralSettings: ReferralSettings;
  fetchRemoteConfig: AppRemoteConfig;
  findFirstAssessment?: Maybe<Assessment>;
  findFirstBannerType?: Maybe<BannerType>;
  findFirstCategory?: Maybe<Category>;
  findFirstCertificate?: Maybe<Certificate>;
  findFirstClassRating?: Maybe<ClassRating>;
  findFirstCourse?: Maybe<Course>;
  findFirstIncentive?: Maybe<Incentive>;
  findFirstInterestType?: Maybe<InterestType>;
  findFirstMaterial?: Maybe<Material>;
  findFirstNewsUpdate?: Maybe<NewsUpdate>;
  findFirstNormalClass?: Maybe<NormalClass>;
  findFirstPackageType?: Maybe<PackageType>;
  findFirstPodcast?: Maybe<Podcast>;
  findFirstPopupBanner?: Maybe<PopupBanner>;
  findFirstPushNotification?: Maybe<PushNotification>;
  findFirstQuestion?: Maybe<Question>;
  findFirstSystemConfig?: Maybe<SystemConfig>;
  findFirstTagging?: Maybe<Tagging>;
  findFirstTransaction?: Maybe<Transaction>;
  findFirstUserIncentive?: Maybe<UserIncentive>;
  findFirstUserSetting?: Maybe<UserSetting>;
  findFirstVertical?: Maybe<Vertical>;
  findFirstVideoRepo?: Maybe<VideoRepo>;
  getAssessment?: Maybe<Assessment>;
  getBannerType?: Maybe<BannerType>;
  getCMSPage?: Maybe<CmsPage>;
  getCategory?: Maybe<Category>;
  getCertificate?: Maybe<Certificate>;
  getChannel?: Maybe<Channel>;
  getClassRating?: Maybe<ClassRating>;
  getCoach?: Maybe<Coach>;
  getCourse?: Maybe<Course>;
  getDefaultPackageSettings?: Maybe<PackageSettings>;
  getIncentive?: Maybe<Incentive>;
  getInterestType?: Maybe<InterestType>;
  getLibrary?: Maybe<Library>;
  getLiveClass?: Maybe<LiveClass>;
  getLiveClassAssessments: Array<Assessment>;
  getMaterial?: Maybe<Material>;
  getMyLearningProfile: LearningProfile;
  getMyNotificationSetting?: Maybe<UserNotificationSetting>;
  getMyRefereesSummary: GetMyRefereesSummary;
  getNewsUpdate?: Maybe<NewsUpdate>;
  getNormalClass?: Maybe<NormalClass>;
  getPackageType?: Maybe<PackageType>;
  getPodcast?: Maybe<Podcast>;
  getPopupBanner?: Maybe<PopupBanner>;
  getPushNotification?: Maybe<PushNotification>;
  getQRCodeChallengeEnquiry: QrCodeChallengeEnquiry;
  getQuestion?: Maybe<Question>;
  getSystemConfig?: Maybe<SystemConfig>;
  getTag?: Maybe<Tagging>;
  getTransaction?: Maybe<Transaction>;
  getUser?: Maybe<User>;
  getUserIncentive?: Maybe<UserIncentive>;
  getUserLeaderBoardRanking?: Maybe<UserLeaderboardRanking>;
  getUserSetting?: Maybe<UserSetting>;
  getVertical?: Maybe<Vertical>;
  getVideoRepo?: Maybe<VideoRepo>;
  groupByAssessment: Array<AssessmentGroupBy>;
  groupByBannerType: Array<BannerTypeGroupBy>;
  groupByCategory: Array<CategoryGroupBy>;
  groupByCertificate: Array<CertificateGroupBy>;
  groupByClassRating: Array<ClassRatingGroupBy>;
  groupByCourse: Array<CourseGroupBy>;
  groupByIncentive: Array<IncentiveGroupBy>;
  groupByInterestType: Array<InterestTypeGroupBy>;
  groupByMaterial: Array<MaterialGroupBy>;
  groupByNewsUpdate: Array<NewsUpdateGroupBy>;
  groupByNormalClass: Array<NormalClassGroupBy>;
  groupByPackageType: Array<PackageTypeGroupBy>;
  groupByPodcast: Array<PodcastGroupBy>;
  groupByPopupBanner: Array<PopupBannerGroupBy>;
  groupByPushNotification: Array<PushNotificationGroupBy>;
  groupByQuestion: Array<QuestionGroupBy>;
  groupBySystemConfig: Array<SystemConfigGroupBy>;
  groupByTagging: Array<TaggingGroupBy>;
  groupByTransaction: Array<TransactionGroupBy>;
  groupByUserIncentive: Array<UserIncentiveGroupBy>;
  groupByUserSetting: Array<UserSettingGroupBy>;
  groupByVertical: Array<VerticalGroupBy>;
  groupByVideoRepo: Array<VideoRepoGroupBy>;
  health: Scalars['String'];
  incentive?: Maybe<Incentive>;
  incentives: Array<Incentive>;
  interestType?: Maybe<InterestType>;
  interestTypes: Array<InterestType>;
  listAssessments: PaginatedAssessment;
  listBannerTypes: PaginatedBannerType;
  listCMSDiscoverRecommendedContents: DiscoverContentsDto;
  listCMSDiscoverVerticalContents: DiscoverContentsDto;
  listCMSPages: PaginatedCmsPage;
  listCategories: PaginatedCategory;
  listChannels: PaginatedChannel;
  listCoaches: PaginatedCoach;
  listCourses: PaginatedCourse;
  listCurrentWeekLiveClasses: PaginatedLiveClass;
  listIncentives: PaginatedIncentive;
  listInterestTypes: PaginatedInterestType;
  listLeaderboards: PaginatedLeaderboard;
  listLibraries: PaginatedLibrary;
  listLiveClassCoaches: PaginatedCoach;
  listLiveClassRatings: PaginatedClassRating;
  listLiveClasses: PaginatedLiveClass;
  listMaterials: PaginatedMaterial;
  listMyBookmarks: PaginatedBookmark;
  listMyCertificates: PaginatedCertificate;
  listMyCoaches: PaginatedCoach;
  listMyFavouriteVerticalNormalClasses: PaginatedNormalClass;
  listMyPastLearning: PaginatedLearning;
  listMyReferees: PaginatedUser;
  listMyUpcomingLearning: PaginatedLearning;
  listMyViewHistories: PaginatedViewHistory;
  listMyViewedVerticalLibraries: PaginatedLibrary;
  listMyViewedVerticalNormalClasses: PaginatedNormalClass;
  listNewsUpdates: PaginatedNewsUpdate;
  listNormalClassRatings: PaginatedClassRating;
  listNormalClasses: PaginatedNormalClass;
  listPackageTypes: PaginatedPackageType;
  listPastLiveClasses: PaginatedLiveClass;
  listPastVerticalLiveClasses: PaginatedLiveClass;
  listPastWeekLiveClasses: PaginatedLiveClass;
  listPodcastCoaches: PaginatedCoach;
  listPodcasts: PaginatedPodcast;
  listPopupBanners: PaginatedPopupBanner;
  listPushNotifications: PaginatedPushNotification;
  listQuestions: PaginatedQuestion;
  listSimilarLiveClasses: PaginatedLiveClass;
  listSimilarNormalClasses: PaginatedNormalClass;
  listSubscriptions: PaginatedSubscription;
  listSystemConfigs: PaginatedSystemConfig;
  listTags: PaginatedTagging;
  listTodayLiveClasses: PaginatedLiveClass;
  listTodayVerticalLiveClasses: PaginatedLiveClass;
  listTransactions: PaginatedTransaction;
  listUpComingLiveClasses: PaginatedLiveClass;
  listUpComingVerticalLiveClasses: PaginatedLiveClass;
  listUserIncentives: PaginatedUserIncentive;
  listUserSettings: PaginatedUserSetting;
  listUsers: PaginatedUser;
  listVerticalLibraries: PaginatedLibrary;
  listVerticalLiveClasses: PaginatedLiveClass;
  listVerticalNormalClasses: PaginatedNormalClass;
  listVerticalPodcasts: PaginatedPodcast;
  listVerticals: PaginatedVertical;
  listVideoRepos: PaginatedVideoRepo;
  listViewedVerticalLibraries: PaginatedLibrary;
  listViewedVerticalNormalClasses: PaginatedNormalClass;
  material?: Maybe<Material>;
  materials: Array<Material>;
  me: AuthUserUnion;
  newsUpdate?: Maybe<NewsUpdate>;
  newsUpdates: Array<NewsUpdate>;
  normalClass?: Maybe<NormalClass>;
  normalClasses: Array<NormalClass>;
  packageType?: Maybe<PackageType>;
  packageTypes: Array<PackageType>;
  podcast?: Maybe<Podcast>;
  podcasts: Array<Podcast>;
  popupBanner?: Maybe<PopupBanner>;
  popupBanners: Array<PopupBanner>;
  pushNotification?: Maybe<PushNotification>;
  pushNotifications: Array<PushNotification>;
  question?: Maybe<Question>;
  questions: Array<Question>;
  systemConfig?: Maybe<SystemConfig>;
  systemConfigs: Array<SystemConfig>;
  tagging?: Maybe<Tagging>;
  taggings: Array<Tagging>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  userIncentive?: Maybe<UserIncentive>;
  userIncentives: Array<UserIncentive>;
  userSetting?: Maybe<UserSetting>;
  userSettings: Array<UserSetting>;
  vertical?: Maybe<Vertical>;
  verticals: Array<Vertical>;
  videoRepo?: Maybe<VideoRepo>;
  videoRepos: Array<VideoRepo>;
};

export type QueryAggregateAssessmentArgs = {
  cursor?: InputMaybe<AssessmentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AssessmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type QueryAggregateBannerTypeArgs = {
  cursor?: InputMaybe<BannerTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BannerTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type QueryAggregateCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryAggregateCertificateArgs = {
  cursor?: InputMaybe<CertificateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type QueryAggregateClassRatingArgs = {
  cursor?: InputMaybe<ClassRatingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryAggregateCourseArgs = {
  cursor?: InputMaybe<CourseWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CourseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhereInput>;
};

export type QueryAggregateIncentiveArgs = {
  cursor?: InputMaybe<IncentiveWhereUniqueInput>;
  orderBy?: InputMaybe<Array<IncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type QueryAggregateInterestTypeArgs = {
  cursor?: InputMaybe<InterestTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type QueryAggregateMaterialArgs = {
  cursor?: InputMaybe<MaterialWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaterialWhereInput>;
};

export type QueryAggregateNewsUpdateArgs = {
  cursor?: InputMaybe<NewsUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NewsUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type QueryAggregateNormalClassArgs = {
  cursor?: InputMaybe<NormalClassWhereUniqueInput>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type QueryAggregatePackageTypeArgs = {
  cursor?: InputMaybe<PackageTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PackageTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type QueryAggregatePodcastArgs = {
  cursor?: InputMaybe<PodcastWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type QueryAggregatePopupBannerArgs = {
  cursor?: InputMaybe<PopupBannerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PopupBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type QueryAggregatePushNotificationArgs = {
  cursor?: InputMaybe<PushNotificationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type QueryAggregateQuestionArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};

export type QueryAggregateSystemConfigArgs = {
  cursor?: InputMaybe<SystemConfigWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SystemConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type QueryAggregateTaggingArgs = {
  cursor?: InputMaybe<TaggingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaggingWhereInput>;
};

export type QueryAggregateTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryAggregateUserIncentiveArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type QueryAggregateUserSettingArgs = {
  cursor?: InputMaybe<UserSettingWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type QueryAggregateVerticalArgs = {
  cursor?: InputMaybe<VerticalWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VerticalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalWhereInput>;
};

export type QueryAggregateVideoRepoArgs = {
  cursor?: InputMaybe<VideoRepoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VideoRepoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type QueryAssessmentArgs = {
  where: AssessmentWhereUniqueInput;
};

export type QueryAssessmentsArgs = {
  cursor?: InputMaybe<AssessmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssessmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssessmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type QueryBannerTypeArgs = {
  where: BannerTypeWhereUniqueInput;
};

export type QueryBannerTypesArgs = {
  cursor?: InputMaybe<BannerTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BannerTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BannerTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type QueryCategoriesArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type QueryCertificateArgs = {
  where: CertificateWhereUniqueInput;
};

export type QueryCertificatesArgs = {
  cursor?: InputMaybe<CertificateWhereUniqueInput>;
  distinct?: InputMaybe<Array<CertificateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type QueryClassRatingArgs = {
  where: ClassRatingWhereUniqueInput;
};

export type QueryClassRatingsArgs = {
  cursor?: InputMaybe<ClassRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryCourseArgs = {
  where: CourseWhereUniqueInput;
};

export type QueryCoursesArgs = {
  cursor?: InputMaybe<CourseWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhereInput>;
};

export type QueryFindFirstAssessmentArgs = {
  cursor?: InputMaybe<AssessmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssessmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssessmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type QueryFindFirstBannerTypeArgs = {
  cursor?: InputMaybe<BannerTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BannerTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BannerTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type QueryFindFirstCategoryArgs = {
  cursor?: InputMaybe<CategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<CategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryFindFirstCertificateArgs = {
  cursor?: InputMaybe<CertificateWhereUniqueInput>;
  distinct?: InputMaybe<Array<CertificateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type QueryFindFirstClassRatingArgs = {
  cursor?: InputMaybe<ClassRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryFindFirstCourseArgs = {
  cursor?: InputMaybe<CourseWhereUniqueInput>;
  distinct?: InputMaybe<Array<CourseScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CourseOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhereInput>;
};

export type QueryFindFirstIncentiveArgs = {
  cursor?: InputMaybe<IncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<IncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type QueryFindFirstInterestTypeArgs = {
  cursor?: InputMaybe<InterestTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterestTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type QueryFindFirstMaterialArgs = {
  cursor?: InputMaybe<MaterialWhereUniqueInput>;
  distinct?: InputMaybe<Array<MaterialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaterialWhereInput>;
};

export type QueryFindFirstNewsUpdateArgs = {
  cursor?: InputMaybe<NewsUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type QueryFindFirstNormalClassArgs = {
  cursor?: InputMaybe<NormalClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type QueryFindFirstPackageTypeArgs = {
  cursor?: InputMaybe<PackageTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type QueryFindFirstPodcastArgs = {
  cursor?: InputMaybe<PodcastWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type QueryFindFirstPopupBannerArgs = {
  cursor?: InputMaybe<PopupBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PopupBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PopupBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type QueryFindFirstPushNotificationArgs = {
  cursor?: InputMaybe<PushNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<PushNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type QueryFindFirstQuestionArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};

export type QueryFindFirstSystemConfigArgs = {
  cursor?: InputMaybe<SystemConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<SystemConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SystemConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type QueryFindFirstTaggingArgs = {
  cursor?: InputMaybe<TaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaggingWhereInput>;
};

export type QueryFindFirstTransactionArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryFindFirstUserIncentiveArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserIncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type QueryFindFirstUserSettingArgs = {
  cursor?: InputMaybe<UserSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type QueryFindFirstVerticalArgs = {
  cursor?: InputMaybe<VerticalWhereUniqueInput>;
  distinct?: InputMaybe<Array<VerticalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VerticalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalWhereInput>;
};

export type QueryFindFirstVideoRepoArgs = {
  cursor?: InputMaybe<VideoRepoWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoRepoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoRepoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type QueryGetAssessmentArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetBannerTypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCmsPageArgs = {
  category?: InputMaybe<CmsPageCategory>;
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCategoryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCertificateArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
  serialNumber?: InputMaybe<Scalars['String']>;
};

export type QueryGetChannelArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetClassRatingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCoachArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCourseArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetIncentiveArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetInterestTypeArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type QueryGetLibraryArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetLiveClassArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetLiveClassAssessmentsArgs = {
  liveClassId: Scalars['Int'];
};

export type QueryGetMaterialArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetMyRefereesSummaryArgs = {
  where?: InputMaybe<GetMyRefereesSummaryInput>;
};

export type QueryGetNewsUpdateArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetNormalClassArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPackageTypeArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPodcastArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPopupBannerArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetPushNotificationArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetQrCodeChallengeEnquiryArgs = {
  challengeId: Scalars['String'];
};

export type QueryGetQuestionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSystemConfigArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTagArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetTransactionArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type QueryGetUserIncentiveArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetUserLeaderBoardRankingArgs = {
  period: LeaderboardPeriod;
  userId: Scalars['BigInt'];
};

export type QueryGetUserSettingArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGetVerticalArgs = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type QueryGetVideoRepoArgs = {
  id?: InputMaybe<Scalars['Int']>;
};

export type QueryGroupByAssessmentArgs = {
  by: Array<AssessmentScalarFieldEnum>;
  having?: InputMaybe<AssessmentScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AssessmentOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type QueryGroupByBannerTypeArgs = {
  by: Array<BannerTypeScalarFieldEnum>;
  having?: InputMaybe<BannerTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<BannerTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type QueryGroupByCategoryArgs = {
  by: Array<CategoryScalarFieldEnum>;
  having?: InputMaybe<CategoryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CategoryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryGroupByCertificateArgs = {
  by: Array<CertificateScalarFieldEnum>;
  having?: InputMaybe<CertificateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type QueryGroupByClassRatingArgs = {
  by: Array<ClassRatingScalarFieldEnum>;
  having?: InputMaybe<ClassRatingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryGroupByCourseArgs = {
  by: Array<CourseScalarFieldEnum>;
  having?: InputMaybe<CourseScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CourseOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhereInput>;
};

export type QueryGroupByIncentiveArgs = {
  by: Array<IncentiveScalarFieldEnum>;
  having?: InputMaybe<IncentiveScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<IncentiveOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type QueryGroupByInterestTypeArgs = {
  by: Array<InterestTypeScalarFieldEnum>;
  having?: InputMaybe<InterestTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type QueryGroupByMaterialArgs = {
  by: Array<MaterialScalarFieldEnum>;
  having?: InputMaybe<MaterialScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MaterialOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaterialWhereInput>;
};

export type QueryGroupByNewsUpdateArgs = {
  by: Array<NewsUpdateScalarFieldEnum>;
  having?: InputMaybe<NewsUpdateScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NewsUpdateOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type QueryGroupByNormalClassArgs = {
  by: Array<NormalClassScalarFieldEnum>;
  having?: InputMaybe<NormalClassScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type QueryGroupByPackageTypeArgs = {
  by: Array<PackageTypeScalarFieldEnum>;
  having?: InputMaybe<PackageTypeScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PackageTypeOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type QueryGroupByPodcastArgs = {
  by: Array<PodcastScalarFieldEnum>;
  having?: InputMaybe<PodcastScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PodcastOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type QueryGroupByPopupBannerArgs = {
  by: Array<PopupBannerScalarFieldEnum>;
  having?: InputMaybe<PopupBannerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PopupBannerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type QueryGroupByPushNotificationArgs = {
  by: Array<PushNotificationScalarFieldEnum>;
  having?: InputMaybe<PushNotificationScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type QueryGroupByQuestionArgs = {
  by: Array<QuestionScalarFieldEnum>;
  having?: InputMaybe<QuestionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};

export type QueryGroupBySystemConfigArgs = {
  by: Array<SystemConfigScalarFieldEnum>;
  having?: InputMaybe<SystemConfigScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SystemConfigOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type QueryGroupByTaggingArgs = {
  by: Array<TaggingScalarFieldEnum>;
  having?: InputMaybe<TaggingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TaggingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaggingWhereInput>;
};

export type QueryGroupByTransactionArgs = {
  by: Array<TransactionScalarFieldEnum>;
  having?: InputMaybe<TransactionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryGroupByUserIncentiveArgs = {
  by: Array<UserIncentiveScalarFieldEnum>;
  having?: InputMaybe<UserIncentiveScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type QueryGroupByUserSettingArgs = {
  by: Array<UserSettingScalarFieldEnum>;
  having?: InputMaybe<UserSettingScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserSettingOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type QueryGroupByVerticalArgs = {
  by: Array<VerticalScalarFieldEnum>;
  having?: InputMaybe<VerticalScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VerticalOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalWhereInput>;
};

export type QueryGroupByVideoRepoArgs = {
  by: Array<VideoRepoScalarFieldEnum>;
  having?: InputMaybe<VideoRepoScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VideoRepoOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type QueryIncentiveArgs = {
  where: IncentiveWhereUniqueInput;
};

export type QueryIncentivesArgs = {
  cursor?: InputMaybe<IncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<IncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<IncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type QueryInterestTypeArgs = {
  where: InterestTypeWhereUniqueInput;
};

export type QueryInterestTypesArgs = {
  cursor?: InputMaybe<InterestTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterestTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type QueryListAssessmentsArgs = {
  orderBy?: InputMaybe<Array<AssessmentOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentWhereInput>;
};

export type QueryListBannerTypesArgs = {
  orderBy?: InputMaybe<Array<BannerTypeOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerTypeWhereInput>;
};

export type QueryListCmsDiscoverRecommendedContentsArgs = {
  channelId?: InputMaybe<Scalars['Int']>;
  channelLocale?: InputMaybe<Scalars['String']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryListCmsDiscoverVerticalContentsArgs = {
  channelId?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  verticalId?: InputMaybe<Scalars['Int']>;
  verticalSlug?: InputMaybe<Scalars['String']>;
};

export type QueryListCmsPagesArgs = {
  orderBy?: InputMaybe<Array<CmsPageOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CmsPageWhereInput>;
};

export type QueryListCategoriesArgs = {
  orderBy?: InputMaybe<Array<CategoryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryListChannelsArgs = {
  orderBy?: InputMaybe<Array<ChannelOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChannelWhereInput>;
};

export type QueryListCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};

export type QueryListCoursesArgs = {
  orderBy?: InputMaybe<Array<CourseOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CourseWhereInput>;
};

export type QueryListCurrentWeekLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListIncentivesArgs = {
  orderBy?: InputMaybe<Array<IncentiveOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IncentiveWhereInput>;
};

export type QueryListInterestTypesArgs = {
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type QueryListLeaderboardsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where: LeaderboardWhereInput;
};

export type QueryListLibrariesArgs = {
  orderBy?: InputMaybe<Array<LibraryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LibraryWhereInput>;
};

export type QueryListLiveClassCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassCoachesWhereInput>;
};

export type QueryListLiveClassRatingsArgs = {
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryListLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListMaterialsArgs = {
  orderBy?: InputMaybe<Array<MaterialOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaterialWhereInput>;
};

export type QueryListMyBookmarksArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<BookmarkTargetType>;
};

export type QueryListMyCertificatesArgs = {
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type QueryListMyCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};

export type QueryListMyFavouriteVerticalNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalNormalClassWhereInput>;
};

export type QueryListMyPastLearningArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryListMyRefereesArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryListMyUpcomingLearningArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryListMyViewHistoriesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  targetType: ViewTargetType;
};

export type QueryListMyViewedVerticalLibrariesArgs = {
  orderBy?: InputMaybe<Array<LibraryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLibraryWhereInput>;
};

export type QueryListMyViewedVerticalNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalNormalClassWhereInput>;
};

export type QueryListNewsUpdatesArgs = {
  orderBy?: InputMaybe<Array<NewsUpdateOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type QueryListNormalClassRatingsArgs = {
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type QueryListNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type QueryListPackageTypesArgs = {
  orderBy?: InputMaybe<Array<PackageTypeOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type QueryListPastLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListPastVerticalLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLiveClassWhereInput>;
};

export type QueryListPastWeekLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListPodcastCoachesArgs = {
  orderBy?: InputMaybe<Array<CoachOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CoachWhereInput>;
};

export type QueryListPodcastsArgs = {
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type QueryListPopupBannersArgs = {
  orderBy?: InputMaybe<Array<PopupBannerOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type QueryListPushNotificationsArgs = {
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type QueryListQuestionsArgs = {
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};

export type QueryListSimilarLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimilarLiveClassWhereInput>;
};

export type QueryListSimilarNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SimilarNormalClassWhereInput>;
};

export type QueryListSubscriptionsArgs = {
  orderBy?: InputMaybe<Array<SubscriptionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SubscriptionWhereInput>;
};

export type QueryListSystemConfigsArgs = {
  orderBy?: InputMaybe<Array<SystemConfigOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type QueryListTagsArgs = {
  orderBy?: InputMaybe<Array<TaggingOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaggingWhereInput>;
};

export type QueryListTodayLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListTodayVerticalLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLiveClassWhereInput>;
};

export type QueryListTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryListUpComingLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassWhereInputV2>;
};

export type QueryListUpComingVerticalLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLiveClassWhereInput>;
};

export type QueryListUserIncentivesArgs = {
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type QueryListUserSettingsArgs = {
  orderBy?: InputMaybe<Array<UserSettingOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type QueryListUsersArgs = {
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryListVerticalLibrariesArgs = {
  orderBy?: InputMaybe<Array<LibraryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLibraryWhereInput>;
};

export type QueryListVerticalLiveClassesArgs = {
  orderBy?: InputMaybe<Array<LiveClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLiveClassWhereInput>;
};

export type QueryListVerticalNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalNormalClassWhereInput>;
};

export type QueryListVerticalPodcastsArgs = {
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalPodcastWhereInput>;
};

export type QueryListVerticalsArgs = {
  orderBy?: InputMaybe<Array<VerticalOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalWhereInput>;
};

export type QueryListVideoReposArgs = {
  orderBy?: InputMaybe<Array<VideoRepoOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type QueryListViewedVerticalLibrariesArgs = {
  orderBy?: InputMaybe<Array<LibraryOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalLibraryWhereInput>;
};

export type QueryListViewedVerticalNormalClassesArgs = {
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalNormalClassWhereInput>;
};

export type QueryMaterialArgs = {
  where: MaterialWhereUniqueInput;
};

export type QueryMaterialsArgs = {
  cursor?: InputMaybe<MaterialWhereUniqueInput>;
  distinct?: InputMaybe<Array<MaterialScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MaterialOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MaterialWhereInput>;
};

export type QueryNewsUpdateArgs = {
  where: NewsUpdateWhereUniqueInput;
};

export type QueryNewsUpdatesArgs = {
  cursor?: InputMaybe<NewsUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<NewsUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NewsUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NewsUpdateWhereInput>;
};

export type QueryNormalClassArgs = {
  where: NormalClassWhereUniqueInput;
};

export type QueryNormalClassesArgs = {
  cursor?: InputMaybe<NormalClassWhereUniqueInput>;
  distinct?: InputMaybe<Array<NormalClassScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NormalClassOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NormalClassWhereInput>;
};

export type QueryPackageTypeArgs = {
  where: PackageTypeWhereUniqueInput;
};

export type QueryPackageTypesArgs = {
  cursor?: InputMaybe<PackageTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PackageTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PackageTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PackageTypeWhereInput>;
};

export type QueryPodcastArgs = {
  where: PodcastWhereUniqueInput;
};

export type QueryPodcastsArgs = {
  cursor?: InputMaybe<PodcastWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastWhereInput>;
};

export type QueryPopupBannerArgs = {
  where: PopupBannerWhereUniqueInput;
};

export type QueryPopupBannersArgs = {
  cursor?: InputMaybe<PopupBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PopupBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PopupBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PopupBannerWhereInput>;
};

export type QueryPushNotificationArgs = {
  where: PushNotificationWhereUniqueInput;
};

export type QueryPushNotificationsArgs = {
  cursor?: InputMaybe<PushNotificationWhereUniqueInput>;
  distinct?: InputMaybe<Array<PushNotificationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PushNotificationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PushNotificationWhereInput>;
};

export type QueryQuestionArgs = {
  where: QuestionWhereUniqueInput;
};

export type QueryQuestionsArgs = {
  cursor?: InputMaybe<QuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionWhereInput>;
};

export type QuerySystemConfigArgs = {
  where: SystemConfigWhereUniqueInput;
};

export type QuerySystemConfigsArgs = {
  cursor?: InputMaybe<SystemConfigWhereUniqueInput>;
  distinct?: InputMaybe<Array<SystemConfigScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SystemConfigOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SystemConfigWhereInput>;
};

export type QueryTaggingArgs = {
  where: TaggingWhereUniqueInput;
};

export type QueryTaggingsArgs = {
  cursor?: InputMaybe<TaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaggingWhereInput>;
};

export type QueryTransactionArgs = {
  where: TransactionWhereUniqueInput;
};

export type QueryTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type QueryUserIncentiveArgs = {
  where: UserIncentiveWhereUniqueInput;
};

export type QueryUserIncentivesArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserIncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type QueryUserSettingArgs = {
  where: UserSettingWhereUniqueInput;
};

export type QueryUserSettingsArgs = {
  cursor?: InputMaybe<UserSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type QueryVerticalArgs = {
  where: VerticalWhereUniqueInput;
};

export type QueryVerticalsArgs = {
  cursor?: InputMaybe<VerticalWhereUniqueInput>;
  distinct?: InputMaybe<Array<VerticalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VerticalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerticalWhereInput>;
};

export type QueryVideoRepoArgs = {
  where: VideoRepoWhereUniqueInput;
};

export type QueryVideoReposArgs = {
  cursor?: InputMaybe<VideoRepoWhereUniqueInput>;
  distinct?: InputMaybe<Array<VideoRepoScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VideoRepoOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VideoRepoWhereInput>;
};

export type Question = {
  __typename?: 'Question';
  _count?: Maybe<QuestionCount>;
  assessmentQuestions: Array<AssessmentQuestion>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  questionAnswers: Array<QuestionAnswer>;
  status: Scalars['Int'];
  title: Scalars['String'];
  type: QuestionType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAssessmentRecords: Array<UserAssessmentRecord>;
};

export type QuestionAssessmentQuestionsArgs = {
  cursor?: InputMaybe<AssessmentQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssessmentQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssessmentQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssessmentQuestionWhereInput>;
};

export type QuestionQuestionAnswersArgs = {
  cursor?: InputMaybe<QuestionAnswerWhereUniqueInput>;
  distinct?: InputMaybe<Array<QuestionAnswerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QuestionAnswerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QuestionAnswerWhereInput>;
};

export type QuestionUserAssessmentRecordsArgs = {
  cursor?: InputMaybe<UserAssessmentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserAssessmentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserAssessmentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserAssessmentRecordWhereInput>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer: Answer;
  answerId: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  question: Question;
  questionId: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionAnswerCreateManyAnswerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  questionId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerCreateManyAnswerInputEnvelope = {
  data: Array<QuestionAnswerCreateManyAnswerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionAnswerCreateManyQuestionInput = {
  answerId: Scalars['BigInt'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerCreateManyQuestionInputEnvelope = {
  data: Array<QuestionAnswerCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type QuestionAnswerCreateNestedManyWithoutAnswerInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutAnswerInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutAnswerInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyAnswerInputEnvelope>;
};

export type QuestionAnswerCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyQuestionInputEnvelope>;
};

export type QuestionAnswerCreateOrConnectWithoutAnswerInput = {
  create: QuestionAnswerCreateWithoutAnswerInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerCreateOrConnectWithoutQuestionInput = {
  create: QuestionAnswerCreateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerCreateWithoutAnswerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  question: QuestionCreateNestedOneWithoutQuestionAnswersInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerCreateWithoutQuestionInput = {
  answer: AnswerCreateNestedOneWithoutQuestionAnswersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerInput = {
  answerId: Scalars['BigInt'];
  questionId: Scalars['BigInt'];
};

export type QuestionAnswerListRelationFilter = {
  every?: InputMaybe<QuestionAnswerWhereInput>;
  none?: InputMaybe<QuestionAnswerWhereInput>;
  some?: InputMaybe<QuestionAnswerWhereInput>;
};

export type QuestionAnswerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type QuestionAnswerOrderByWithRelationInput = {
  answer?: InputMaybe<AnswerOrderByWithRelationInput>;
  answerId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationInput>;
  questionId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum QuestionAnswerScalarFieldEnum {
  AnswerId = 'answerId',
  CreatedAt = 'createdAt',
  Id = 'id',
  QuestionId = 'questionId',
  UpdatedAt = 'updatedAt',
}

export type QuestionAnswerScalarWhereInput = {
  AND?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  NOT?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  OR?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  answerId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QuestionAnswerUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerUpdateManyWithWhereWithoutAnswerInput = {
  data: QuestionAnswerUpdateManyMutationInput;
  where: QuestionAnswerScalarWhereInput;
};

export type QuestionAnswerUpdateManyWithWhereWithoutQuestionInput = {
  data: QuestionAnswerUpdateManyMutationInput;
  where: QuestionAnswerScalarWhereInput;
};

export type QuestionAnswerUpdateManyWithoutAnswerNestedInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutAnswerInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutAnswerInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyAnswerInputEnvelope>;
  delete?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionAnswerUpdateWithWhereUniqueWithoutAnswerInput>>;
  updateMany?: InputMaybe<Array<QuestionAnswerUpdateManyWithWhereWithoutAnswerInput>>;
  upsert?: InputMaybe<Array<QuestionAnswerUpsertWithWhereUniqueWithoutAnswerInput>>;
};

export type QuestionAnswerUpdateManyWithoutQuestionNestedInput = {
  connect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<QuestionAnswerCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<QuestionAnswerCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<QuestionAnswerCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<QuestionAnswerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  set?: InputMaybe<Array<QuestionAnswerWhereUniqueInput>>;
  update?: InputMaybe<Array<QuestionAnswerUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<QuestionAnswerUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<QuestionAnswerUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type QuestionAnswerUpdateWithWhereUniqueWithoutAnswerInput = {
  data: QuestionAnswerUpdateWithoutAnswerInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpdateWithWhereUniqueWithoutQuestionInput = {
  data: QuestionAnswerUpdateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpdateWithoutAnswerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutQuestionAnswersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerUpdateWithoutQuestionInput = {
  answer?: InputMaybe<AnswerUpdateOneRequiredWithoutQuestionAnswersNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionAnswerUpsertWithWhereUniqueWithoutAnswerInput = {
  create: QuestionAnswerCreateWithoutAnswerInput;
  update: QuestionAnswerUpdateWithoutAnswerInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerUpsertWithWhereUniqueWithoutQuestionInput = {
  create: QuestionAnswerCreateWithoutQuestionInput;
  update: QuestionAnswerUpdateWithoutQuestionInput;
  where: QuestionAnswerWhereUniqueInput;
};

export type QuestionAnswerWhereInput = {
  AND?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  NOT?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  OR?: InputMaybe<Array<QuestionAnswerWhereInput>>;
  answer?: InputMaybe<AnswerRelationFilter>;
  answerId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type QuestionAnswerWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type QuestionAvgAggregate = {
  __typename?: 'QuestionAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
};

export type QuestionAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export type QuestionCount = {
  __typename?: 'QuestionCount';
  assessmentQuestions: Scalars['Int'];
  questionAnswers: Scalars['Int'];
  userAssessmentRecords: Scalars['Int'];
};

export type QuestionCountAggregate = {
  __typename?: 'QuestionCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type QuestionCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionCreateInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutQuestionInput>;
};

export type QuestionCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionCreateNestedOneWithoutAssessmentQuestionsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAssessmentQuestionsInput>;
  create?: InputMaybe<QuestionCreateWithoutAssessmentQuestionsInput>;
};

export type QuestionCreateNestedOneWithoutQuestionAnswersInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutQuestionAnswersInput>;
};

export type QuestionCreateNestedOneWithoutUserAssessmentRecordsInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutUserAssessmentRecordsInput>;
  create?: InputMaybe<QuestionCreateWithoutUserAssessmentRecordsInput>;
};

export type QuestionCreateOrConnectWithoutAssessmentQuestionsInput = {
  create: QuestionCreateWithoutAssessmentQuestionsInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutQuestionAnswersInput = {
  create: QuestionCreateWithoutQuestionAnswersInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateOrConnectWithoutUserAssessmentRecordsInput = {
  create: QuestionCreateWithoutUserAssessmentRecordsInput;
  where: QuestionWhereUniqueInput;
};

export type QuestionCreateWithoutAssessmentQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutQuestionInput>;
};

export type QuestionCreateWithoutQuestionAnswersInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordCreateNestedManyWithoutQuestionInput>;
};

export type QuestionCreateWithoutUserAssessmentRecordsInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionCreateNestedManyWithoutQuestionInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerCreateNestedManyWithoutQuestionInput>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionGroupBy = {
  __typename?: 'QuestionGroupBy';
  _avg?: Maybe<QuestionAvgAggregate>;
  _count?: Maybe<QuestionCountAggregate>;
  _max?: Maybe<QuestionMaxAggregate>;
  _min?: Maybe<QuestionMinAggregate>;
  _sum?: Maybe<QuestionSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['BigInt'];
  status: Scalars['Int'];
  title: Scalars['String'];
  type: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionMaxAggregate = {
  __typename?: 'QuestionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionMinAggregate = {
  __typename?: 'QuestionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type QuestionMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionOrderByWithAggregationInput = {
  _avg?: InputMaybe<QuestionAvgOrderByAggregateInput>;
  _count?: InputMaybe<QuestionCountOrderByAggregateInput>;
  _max?: InputMaybe<QuestionMaxOrderByAggregateInput>;
  _min?: InputMaybe<QuestionMinOrderByAggregateInput>;
  _sum?: InputMaybe<QuestionSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type QuestionOrderByWithRelationInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  questionAnswers?: InputMaybe<QuestionAnswerOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordOrderByRelationAggregateInput>;
};

export type QuestionRelationFilter = {
  is?: InputMaybe<QuestionWhereInput>;
  isNot?: InputMaybe<QuestionWhereInput>;
};

export enum QuestionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Status = 'status',
  Title = 'title',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type QuestionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<QuestionScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  type?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type QuestionSumAggregate = {
  __typename?: 'QuestionSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
};

export type QuestionSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
};

export enum QuestionType {
  MultipleAnswer = 'MULTIPLE_ANSWER',
  Objective = 'OBJECTIVE',
  SingleAnswer = 'SINGLE_ANSWER',
}

export type QuestionUpdateInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionUpdateManyWithoutQuestionNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordUpdateManyWithoutQuestionNestedInput>;
};

export type QuestionUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionUpdateOneRequiredWithoutAssessmentQuestionsNestedInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutAssessmentQuestionsInput>;
  create?: InputMaybe<QuestionCreateWithoutAssessmentQuestionsInput>;
  update?: InputMaybe<QuestionUpdateWithoutAssessmentQuestionsInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutAssessmentQuestionsInput>;
};

export type QuestionUpdateOneRequiredWithoutQuestionAnswersNestedInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutQuestionAnswersInput>;
  create?: InputMaybe<QuestionCreateWithoutQuestionAnswersInput>;
  update?: InputMaybe<QuestionUpdateWithoutQuestionAnswersInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutQuestionAnswersInput>;
};

export type QuestionUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput = {
  connect?: InputMaybe<QuestionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<QuestionCreateOrConnectWithoutUserAssessmentRecordsInput>;
  create?: InputMaybe<QuestionCreateWithoutUserAssessmentRecordsInput>;
  update?: InputMaybe<QuestionUpdateWithoutUserAssessmentRecordsInput>;
  upsert?: InputMaybe<QuestionUpsertWithoutUserAssessmentRecordsInput>;
};

export type QuestionUpdateWithoutAssessmentQuestionsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordUpdateManyWithoutQuestionNestedInput>;
};

export type QuestionUpdateWithoutQuestionAnswersInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionUpdateManyWithoutQuestionNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordUpdateManyWithoutQuestionNestedInput>;
};

export type QuestionUpdateWithoutUserAssessmentRecordsInput = {
  assessmentQuestions?: InputMaybe<AssessmentQuestionUpdateManyWithoutQuestionNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  questionAnswers?: InputMaybe<QuestionAnswerUpdateManyWithoutQuestionNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type QuestionUpsertWithoutAssessmentQuestionsInput = {
  create: QuestionCreateWithoutAssessmentQuestionsInput;
  update: QuestionUpdateWithoutAssessmentQuestionsInput;
};

export type QuestionUpsertWithoutQuestionAnswersInput = {
  create: QuestionCreateWithoutQuestionAnswersInput;
  update: QuestionUpdateWithoutQuestionAnswersInput;
};

export type QuestionUpsertWithoutUserAssessmentRecordsInput = {
  create: QuestionCreateWithoutUserAssessmentRecordsInput;
  update: QuestionUpdateWithoutUserAssessmentRecordsInput;
};

export type QuestionWhereInput = {
  AND?: InputMaybe<Array<QuestionWhereInput>>;
  NOT?: InputMaybe<Array<QuestionWhereInput>>;
  OR?: InputMaybe<Array<QuestionWhereInput>>;
  assessmentQuestions?: InputMaybe<AssessmentQuestionListRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<BigIntFilter>;
  questionAnswers?: InputMaybe<QuestionAnswerListRelationFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userAssessmentRecords?: InputMaybe<UserAssessmentRecordListRelationFilter>;
};

export type QuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type QuestionWithAnswers = {
  __typename?: 'QuestionWithAnswers';
  answers: Array<Answer>;
  question: Question;
};

export type ReferralSettings = {
  __typename?: 'ReferralSettings';
  referralCode?: Maybe<Scalars['String']>;
  referralStatus: Scalars['Boolean'];
  referralUrl?: Maybe<Scalars['String']>;
};

export type RegisterInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  encrypt?: InputMaybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  otpCode: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type RegisterLiveClassInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  liveClassId: Scalars['Int'];
  phone?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
};

export type RequestOtpInput = {
  email: Scalars['String'];
  isResend?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  purpose: VerificationPurpose;
  username?: InputMaybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  otpChallengeID: Scalars['String'];
  otpCode: Scalars['String'];
  password: Scalars['String'];
};

export type SendPushNotificationInput = {
  segments?: InputMaybe<Array<Scalars['String']>>;
  userIds?: InputMaybe<Array<Scalars['Float']>>;
};

export type SendPushNotificationResponse = {
  __typename?: 'SendPushNotificationResponse';
  id?: Maybe<Scalars['Float']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  totalSent?: Maybe<Scalars['Float']>;
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export type SimilarLiveClassWhereInput = {
  AND?: InputMaybe<Array<LiveClassWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassWhereInput>>;
  OR?: InputMaybe<Array<LiveClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  certPrice?: InputMaybe<DecimalFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  classType?: InputMaybe<EnumLiveClassTypeFilter>;
  closeTime?: InputMaybe<DateTimeNullableFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createBy?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customDuration?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  durationTime?: InputMaybe<IntFilter>;
  enableFree?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringFilter>;
  isCertFree?: InputMaybe<IntFilter>;
  issuedCertificates?: InputMaybe<CertificateListRelationFilter>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationListRelationFilter>;
  liveClassTags?: InputMaybe<LiveClassTaggingListRelationFilter>;
  materialIds?: InputMaybe<StringNullableFilter>;
  payPerView?: InputMaybe<IntFilter>;
  payPerViewAmount?: InputMaybe<FloatFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  previewId?: InputMaybe<IntNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  roomId?: InputMaybe<StringFilter>;
  roomPasscode?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  specialEvent?: InputMaybe<BoolNullableFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  tagIds: Array<Scalars['BigInt']>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
  videoEmbed?: InputMaybe<StringNullableFilter>;
};

export type SimilarNormalClassWhereInput = {
  AND?: InputMaybe<Array<NormalClassWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassWhereInput>>;
  OR?: InputMaybe<Array<NormalClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  classViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  classesBookmarks?: InputMaybe<ClassesBookmarksListRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  libraryBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  libraryViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  likedBy?: InputMaybe<NormalClassLikeListRelationFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  tagIds: Array<Scalars['BigInt']>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoRepoId?: InputMaybe<StringFilter>;
};

export type SocialIdentity = {
  __typename?: 'SocialIdentity';
  User: User;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  providerId?: Maybe<Scalars['String']>;
  providerName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type SocialIdentityCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SocialIdentityCreateManyUserInputEnvelope = {
  data: Array<SocialIdentityCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SocialIdentityCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SocialIdentityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SocialIdentityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SocialIdentityCreateWithoutUserInput>>;
  createMany?: InputMaybe<SocialIdentityCreateManyUserInputEnvelope>;
};

export type SocialIdentityCreateOrConnectWithoutUserInput = {
  create: SocialIdentityCreateWithoutUserInput;
  where: SocialIdentityWhereUniqueInput;
};

export type SocialIdentityCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SocialIdentityListRelationFilter = {
  every?: InputMaybe<SocialIdentityWhereInput>;
  none?: InputMaybe<SocialIdentityWhereInput>;
  some?: InputMaybe<SocialIdentityWhereInput>;
};

export type SocialIdentityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SocialIdentityOrderByWithRelationInput = {
  User?: InputMaybe<UserOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  providerId?: InputMaybe<SortOrder>;
  providerName?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum SocialIdentityScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  ProviderId = 'providerId',
  ProviderName = 'providerName',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type SocialIdentityScalarWhereInput = {
  AND?: InputMaybe<Array<SocialIdentityScalarWhereInput>>;
  NOT?: InputMaybe<Array<SocialIdentityScalarWhereInput>>;
  OR?: InputMaybe<Array<SocialIdentityScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  providerId?: InputMaybe<StringNullableFilter>;
  providerName?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type SocialIdentityUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SocialIdentityUpdateManyWithWhereWithoutUserInput = {
  data: SocialIdentityUpdateManyMutationInput;
  where: SocialIdentityScalarWhereInput;
};

export type SocialIdentityUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<SocialIdentityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SocialIdentityCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SocialIdentityCreateWithoutUserInput>>;
  createMany?: InputMaybe<SocialIdentityCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SocialIdentityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SocialIdentityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SocialIdentityWhereUniqueInput>>;
  set?: InputMaybe<Array<SocialIdentityWhereUniqueInput>>;
  update?: InputMaybe<Array<SocialIdentityUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<SocialIdentityUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<SocialIdentityUpsertWithWhereUniqueWithoutUserInput>>;
};

export type SocialIdentityUpdateWithWhereUniqueWithoutUserInput = {
  data: SocialIdentityUpdateWithoutUserInput;
  where: SocialIdentityWhereUniqueInput;
};

export type SocialIdentityUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  providerId?: InputMaybe<Scalars['String']>;
  providerName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SocialIdentityUpsertWithWhereUniqueWithoutUserInput = {
  create: SocialIdentityCreateWithoutUserInput;
  update: SocialIdentityUpdateWithoutUserInput;
  where: SocialIdentityWhereUniqueInput;
};

export type SocialIdentityWhereInput = {
  AND?: InputMaybe<Array<SocialIdentityWhereInput>>;
  NOT?: InputMaybe<Array<SocialIdentityWhereInput>>;
  OR?: InputMaybe<Array<SocialIdentityWhereInput>>;
  User?: InputMaybe<UserRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  providerId?: InputMaybe<StringNullableFilter>;
  providerName?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type SocialIdentityWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  providerId?: InputMaybe<Scalars['String']>;
};

export type SocialLoginInput = {
  email?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  token: Scalars['String'];
};

export type SocialRegisterInput = {
  countryCode?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  encrypt?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  username: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StatusResponse = {
  __typename?: 'StatusResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SubmitAssessmentResponse = {
  __typename?: 'SubmitAssessmentResponse';
  message?: Maybe<Scalars['String']>;
  passStatus?: Maybe<Scalars['Boolean']>;
  scored?: Maybe<Scalars['Int']>;
  success: Scalars['Boolean'];
  totalScore?: Maybe<Scalars['Int']>;
};

export type SubmitLiveClassAssessmentInput = {
  assessmentId: Scalars['Int'];
  liveClassId: Scalars['Int'];
  questionAnswers: Array<QuestionAnswerInput>;
};

export type Subscription = {
  __typename?: 'Subscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  externalSubscriptionId?: Maybe<Scalars['String']>;
  externalSubscriptionStatus?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  packageId: Scalars['Int'];
  packageType?: Maybe<Scalars['String']>;
  status: SubscriptionStatus;
  subscribedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type SubscriptionOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  externalSubscriptionId?: InputMaybe<SortOrder>;
  externalSubscriptionStatus?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  packageId?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribedAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum SubscriptionStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
}

export type SubscriptionWhereInput = {
  AND?: InputMaybe<Array<SubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  externalSubscriptionId?: InputMaybe<StringNullableFilter>;
  externalSubscriptionStatus?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  packageId?: InputMaybe<IntFilter>;
  packageType?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumSubscriptionStatusFilter>;
  subscribedAt?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type SystemConfig = {
  __typename?: 'SystemConfig';
  configName: Scalars['String'];
  configValue: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemConfigAvgAggregate = {
  __typename?: 'SystemConfigAvgAggregate';
  id?: Maybe<Scalars['Float']>;
};

export type SystemConfigAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type SystemConfigCountAggregate = {
  __typename?: 'SystemConfigCountAggregate';
  _all: Scalars['Int'];
  configName: Scalars['Int'];
  configValue: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type SystemConfigCountOrderByAggregateInput = {
  configName?: InputMaybe<SortOrder>;
  configValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SystemConfigCreateInput = {
  configName: Scalars['String'];
  configValue: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SystemConfigCreateManyInput = {
  configName: Scalars['String'];
  configValue: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SystemConfigGroupBy = {
  __typename?: 'SystemConfigGroupBy';
  _avg?: Maybe<SystemConfigAvgAggregate>;
  _count?: Maybe<SystemConfigCountAggregate>;
  _max?: Maybe<SystemConfigMaxAggregate>;
  _min?: Maybe<SystemConfigMinAggregate>;
  _sum?: Maybe<SystemConfigSumAggregate>;
  configName: Scalars['String'];
  configValue: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemConfigMaxAggregate = {
  __typename?: 'SystemConfigMaxAggregate';
  configName?: Maybe<Scalars['String']>;
  configValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemConfigMaxOrderByAggregateInput = {
  configName?: InputMaybe<SortOrder>;
  configValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SystemConfigMinAggregate = {
  __typename?: 'SystemConfigMinAggregate';
  configName?: Maybe<Scalars['String']>;
  configValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SystemConfigMinOrderByAggregateInput = {
  configName?: InputMaybe<SortOrder>;
  configValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SystemConfigOrderByWithAggregationInput = {
  _avg?: InputMaybe<SystemConfigAvgOrderByAggregateInput>;
  _count?: InputMaybe<SystemConfigCountOrderByAggregateInput>;
  _max?: InputMaybe<SystemConfigMaxOrderByAggregateInput>;
  _min?: InputMaybe<SystemConfigMinOrderByAggregateInput>;
  _sum?: InputMaybe<SystemConfigSumOrderByAggregateInput>;
  configName?: InputMaybe<SortOrder>;
  configValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type SystemConfigOrderByWithRelationInput = {
  configName?: InputMaybe<SortOrder>;
  configValue?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SystemConfigScalarFieldEnum {
  ConfigName = 'configName',
  ConfigValue = 'configValue',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  UpdatedAt = 'updatedAt',
}

export type SystemConfigScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SystemConfigScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SystemConfigScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SystemConfigScalarWhereWithAggregatesInput>>;
  configName?: InputMaybe<StringWithAggregatesFilter>;
  configValue?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type SystemConfigSumAggregate = {
  __typename?: 'SystemConfigSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
};

export type SystemConfigSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
};

export type SystemConfigUpdateInput = {
  configName?: InputMaybe<Scalars['String']>;
  configValue?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SystemConfigUpdateManyMutationInput = {
  configName?: InputMaybe<Scalars['String']>;
  configValue?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SystemConfigWhereInput = {
  AND?: InputMaybe<Array<SystemConfigWhereInput>>;
  NOT?: InputMaybe<Array<SystemConfigWhereInput>>;
  OR?: InputMaybe<Array<SystemConfigWhereInput>>;
  configName?: InputMaybe<StringFilter>;
  configValue?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type SystemConfigWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Tagging = {
  __typename?: 'Tagging';
  _count?: Maybe<TaggingCount>;
  classTags: Array<ClassTagging>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  interestTags: Array<InterestTagging>;
  liveClassTags: Array<LiveClassTagging>;
  podcastTags: Array<PodcastTag>;
  slug: Scalars['String'];
  status: Scalars['Int'];
  tagColor?: Maybe<Scalars['String']>;
  tagDescription?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  totalClick?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  viewStatus: Scalars['Int'];
};

export type TaggingClassTagsArgs = {
  cursor?: InputMaybe<ClassTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassTaggingWhereInput>;
};

export type TaggingInterestTagsArgs = {
  cursor?: InputMaybe<InterestTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterestTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterestTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTaggingWhereInput>;
};

export type TaggingLiveClassTagsArgs = {
  cursor?: InputMaybe<LiveClassTaggingWhereUniqueInput>;
  distinct?: InputMaybe<Array<LiveClassTaggingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LiveClassTaggingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LiveClassTaggingWhereInput>;
};

export type TaggingPodcastTagsArgs = {
  cursor?: InputMaybe<PodcastTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<PodcastTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PodcastTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PodcastTagWhereInput>;
};

export type TaggingAvgAggregate = {
  __typename?: 'TaggingAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  totalClick?: Maybe<Scalars['Float']>;
  viewStatus?: Maybe<Scalars['Float']>;
};

export type TaggingAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingCount = {
  __typename?: 'TaggingCount';
  classTags: Scalars['Int'];
  interestTags: Scalars['Int'];
  liveClassTags: Scalars['Int'];
  podcastTags: Scalars['Int'];
};

export type TaggingCountAggregate = {
  __typename?: 'TaggingCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  slug: Scalars['Int'];
  status: Scalars['Int'];
  tagColor: Scalars['Int'];
  tagDescription: Scalars['Int'];
  tagName: Scalars['Int'];
  totalClick: Scalars['Int'];
  updatedAt: Scalars['Int'];
  viewStatus: Scalars['Int'];
};

export type TaggingCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagColor?: InputMaybe<SortOrder>;
  tagDescription?: InputMaybe<SortOrder>;
  tagName?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingCreateInput = {
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutTagInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutTagInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutTagInput>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutTagInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingCreateNestedOneWithoutClassTagsInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutClassTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutClassTagsInput>;
};

export type TaggingCreateNestedOneWithoutInterestTagsInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutInterestTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutInterestTagsInput>;
};

export type TaggingCreateNestedOneWithoutLiveClassTagsInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutLiveClassTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutLiveClassTagsInput>;
};

export type TaggingCreateNestedOneWithoutPodcastTagsInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutPodcastTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutPodcastTagsInput>;
};

export type TaggingCreateOrConnectWithoutClassTagsInput = {
  create: TaggingCreateWithoutClassTagsInput;
  where: TaggingWhereUniqueInput;
};

export type TaggingCreateOrConnectWithoutInterestTagsInput = {
  create: TaggingCreateWithoutInterestTagsInput;
  where: TaggingWhereUniqueInput;
};

export type TaggingCreateOrConnectWithoutLiveClassTagsInput = {
  create: TaggingCreateWithoutLiveClassTagsInput;
  where: TaggingWhereUniqueInput;
};

export type TaggingCreateOrConnectWithoutPodcastTagsInput = {
  create: TaggingCreateWithoutPodcastTagsInput;
  where: TaggingWhereUniqueInput;
};

export type TaggingCreateWithoutClassTagsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutTagInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutTagInput>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutTagInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingCreateWithoutInterestTagsInput = {
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutTagInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutTagInput>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutTagInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingCreateWithoutLiveClassTagsInput = {
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutTagInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutTagInput>;
  podcastTags?: InputMaybe<PodcastTagCreateNestedManyWithoutTagInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingCreateWithoutPodcastTagsInput = {
  classTags?: InputMaybe<ClassTaggingCreateNestedManyWithoutTagInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingCreateNestedManyWithoutTagInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingCreateNestedManyWithoutTagInput>;
  slug: Scalars['String'];
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingGroupBy = {
  __typename?: 'TaggingGroupBy';
  _avg?: Maybe<TaggingAvgAggregate>;
  _count?: Maybe<TaggingCountAggregate>;
  _max?: Maybe<TaggingMaxAggregate>;
  _min?: Maybe<TaggingMinAggregate>;
  _sum?: Maybe<TaggingSumAggregate>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  slug: Scalars['String'];
  status: Scalars['Int'];
  tagColor?: Maybe<Scalars['String']>;
  tagDescription?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  totalClick?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  viewStatus: Scalars['Int'];
};

export type TaggingMaxAggregate = {
  __typename?: 'TaggingMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  tagColor?: Maybe<Scalars['String']>;
  tagDescription?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  totalClick?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewStatus?: Maybe<Scalars['Int']>;
};

export type TaggingMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagColor?: InputMaybe<SortOrder>;
  tagDescription?: InputMaybe<SortOrder>;
  tagName?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingMinAggregate = {
  __typename?: 'TaggingMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  tagColor?: Maybe<Scalars['String']>;
  tagDescription?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  totalClick?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  viewStatus?: Maybe<Scalars['Int']>;
};

export type TaggingMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagColor?: InputMaybe<SortOrder>;
  tagDescription?: InputMaybe<SortOrder>;
  tagName?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingOrderByWithAggregationInput = {
  _avg?: InputMaybe<TaggingAvgOrderByAggregateInput>;
  _count?: InputMaybe<TaggingCountOrderByAggregateInput>;
  _max?: InputMaybe<TaggingMaxOrderByAggregateInput>;
  _min?: InputMaybe<TaggingMinOrderByAggregateInput>;
  _sum?: InputMaybe<TaggingSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagColor?: InputMaybe<SortOrder>;
  tagDescription?: InputMaybe<SortOrder>;
  tagName?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingOrderByWithRelationInput = {
  classTags?: InputMaybe<ClassTaggingOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  interestTags?: InputMaybe<InterestTaggingOrderByRelationAggregateInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingOrderByRelationAggregateInput>;
  podcastTags?: InputMaybe<PodcastTagOrderByRelationAggregateInput>;
  slug?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tagColor?: InputMaybe<SortOrder>;
  tagDescription?: InputMaybe<SortOrder>;
  tagName?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingRelationFilter = {
  is?: InputMaybe<TaggingWhereInput>;
  isNot?: InputMaybe<TaggingWhereInput>;
};

export enum TaggingScalarFieldEnum {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Slug = 'slug',
  Status = 'status',
  TagColor = 'tagColor',
  TagDescription = 'tagDescription',
  TagName = 'tagName',
  TotalClick = 'totalClick',
  UpdatedAt = 'updatedAt',
  ViewStatus = 'viewStatus',
}

export type TaggingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TaggingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TaggingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TaggingScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  tagColor?: InputMaybe<StringNullableWithAggregatesFilter>;
  tagDescription?: InputMaybe<StringNullableWithAggregatesFilter>;
  tagName?: InputMaybe<StringNullableWithAggregatesFilter>;
  totalClick?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  viewStatus?: InputMaybe<IntWithAggregatesFilter>;
};

export type TaggingSumAggregate = {
  __typename?: 'TaggingSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  totalClick?: Maybe<Scalars['Int']>;
  viewStatus?: Maybe<Scalars['Int']>;
};

export type TaggingSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalClick?: InputMaybe<SortOrder>;
  viewStatus?: InputMaybe<SortOrder>;
};

export type TaggingUpdateInput = {
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutTagNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutTagNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutTagNestedInput>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpdateOneRequiredWithoutClassTagsNestedInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutClassTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutClassTagsInput>;
  update?: InputMaybe<TaggingUpdateWithoutClassTagsInput>;
  upsert?: InputMaybe<TaggingUpsertWithoutClassTagsInput>;
};

export type TaggingUpdateOneRequiredWithoutInterestTagsNestedInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutInterestTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutInterestTagsInput>;
  update?: InputMaybe<TaggingUpdateWithoutInterestTagsInput>;
  upsert?: InputMaybe<TaggingUpsertWithoutInterestTagsInput>;
};

export type TaggingUpdateOneRequiredWithoutLiveClassTagsNestedInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutLiveClassTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutLiveClassTagsInput>;
  update?: InputMaybe<TaggingUpdateWithoutLiveClassTagsInput>;
  upsert?: InputMaybe<TaggingUpsertWithoutLiveClassTagsInput>;
};

export type TaggingUpdateOneRequiredWithoutPodcastTagsNestedInput = {
  connect?: InputMaybe<TaggingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaggingCreateOrConnectWithoutPodcastTagsInput>;
  create?: InputMaybe<TaggingCreateWithoutPodcastTagsInput>;
  update?: InputMaybe<TaggingUpdateWithoutPodcastTagsInput>;
  upsert?: InputMaybe<TaggingUpsertWithoutPodcastTagsInput>;
};

export type TaggingUpdateWithoutClassTagsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutTagNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutTagNestedInput>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpdateWithoutInterestTagsInput = {
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutTagNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutTagNestedInput>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpdateWithoutLiveClassTagsInput = {
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutTagNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutTagNestedInput>;
  podcastTags?: InputMaybe<PodcastTagUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpdateWithoutPodcastTagsInput = {
  classTags?: InputMaybe<ClassTaggingUpdateManyWithoutTagNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTags?: InputMaybe<InterestTaggingUpdateManyWithoutTagNestedInput>;
  liveClassTags?: InputMaybe<LiveClassTaggingUpdateManyWithoutTagNestedInput>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  tagColor?: InputMaybe<Scalars['String']>;
  tagDescription?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
  totalClick?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  viewStatus?: InputMaybe<Scalars['Int']>;
};

export type TaggingUpsertWithoutClassTagsInput = {
  create: TaggingCreateWithoutClassTagsInput;
  update: TaggingUpdateWithoutClassTagsInput;
};

export type TaggingUpsertWithoutInterestTagsInput = {
  create: TaggingCreateWithoutInterestTagsInput;
  update: TaggingUpdateWithoutInterestTagsInput;
};

export type TaggingUpsertWithoutLiveClassTagsInput = {
  create: TaggingCreateWithoutLiveClassTagsInput;
  update: TaggingUpdateWithoutLiveClassTagsInput;
};

export type TaggingUpsertWithoutPodcastTagsInput = {
  create: TaggingCreateWithoutPodcastTagsInput;
  update: TaggingUpdateWithoutPodcastTagsInput;
};

export type TaggingWhereInput = {
  AND?: InputMaybe<Array<TaggingWhereInput>>;
  NOT?: InputMaybe<Array<TaggingWhereInput>>;
  OR?: InputMaybe<Array<TaggingWhereInput>>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  interestTags?: InputMaybe<InterestTaggingListRelationFilter>;
  liveClassTags?: InputMaybe<LiveClassTaggingListRelationFilter>;
  podcastTags?: InputMaybe<PodcastTagListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  tagColor?: InputMaybe<StringNullableFilter>;
  tagDescription?: InputMaybe<StringNullableFilter>;
  tagName?: InputMaybe<StringNullableFilter>;
  totalClick?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  viewStatus?: InputMaybe<IntFilter>;
};

export type TaggingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type TeacherFollower = {
  __typename?: 'TeacherFollower';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  teacher: Coach;
  teacherId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type TeacherFollowerCreateManyTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type TeacherFollowerCreateManyTeacherInputEnvelope = {
  data: Array<TeacherFollowerCreateManyTeacherInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TeacherFollowerCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  teacherId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherFollowerCreateManyUserInputEnvelope = {
  data: Array<TeacherFollowerCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TeacherFollowerCreateNestedManyWithoutTeacherInput = {
  connect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeacherFollowerCreateOrConnectWithoutTeacherInput>>;
  create?: InputMaybe<Array<TeacherFollowerCreateWithoutTeacherInput>>;
  createMany?: InputMaybe<TeacherFollowerCreateManyTeacherInputEnvelope>;
};

export type TeacherFollowerCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeacherFollowerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TeacherFollowerCreateWithoutUserInput>>;
  createMany?: InputMaybe<TeacherFollowerCreateManyUserInputEnvelope>;
};

export type TeacherFollowerCreateOrConnectWithoutTeacherInput = {
  create: TeacherFollowerCreateWithoutTeacherInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerCreateOrConnectWithoutUserInput = {
  create: TeacherFollowerCreateWithoutUserInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerCreateWithoutTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutFollowedTeachersInput;
};

export type TeacherFollowerCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  teacher: CoachCreateNestedOneWithoutFollowedByInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherFollowerListRelationFilter = {
  every?: InputMaybe<TeacherFollowerWhereInput>;
  none?: InputMaybe<TeacherFollowerWhereInput>;
  some?: InputMaybe<TeacherFollowerWhereInput>;
};

export type TeacherFollowerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TeacherFollowerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  teacher?: InputMaybe<CoachOrderByWithRelationInput>;
  teacherId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum TeacherFollowerScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  TeacherId = 'teacherId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type TeacherFollowerScalarWhereInput = {
  AND?: InputMaybe<Array<TeacherFollowerScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeacherFollowerScalarWhereInput>>;
  OR?: InputMaybe<Array<TeacherFollowerScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  teacherId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type TeacherFollowerUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherFollowerUpdateManyWithWhereWithoutTeacherInput = {
  data: TeacherFollowerUpdateManyMutationInput;
  where: TeacherFollowerScalarWhereInput;
};

export type TeacherFollowerUpdateManyWithWhereWithoutUserInput = {
  data: TeacherFollowerUpdateManyMutationInput;
  where: TeacherFollowerScalarWhereInput;
};

export type TeacherFollowerUpdateManyWithoutTeacherNestedInput = {
  connect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeacherFollowerCreateOrConnectWithoutTeacherInput>>;
  create?: InputMaybe<Array<TeacherFollowerCreateWithoutTeacherInput>>;
  createMany?: InputMaybe<TeacherFollowerCreateManyTeacherInputEnvelope>;
  delete?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeacherFollowerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  set?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  update?: InputMaybe<Array<TeacherFollowerUpdateWithWhereUniqueWithoutTeacherInput>>;
  updateMany?: InputMaybe<Array<TeacherFollowerUpdateManyWithWhereWithoutTeacherInput>>;
  upsert?: InputMaybe<Array<TeacherFollowerUpsertWithWhereUniqueWithoutTeacherInput>>;
};

export type TeacherFollowerUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeacherFollowerCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TeacherFollowerCreateWithoutUserInput>>;
  createMany?: InputMaybe<TeacherFollowerCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeacherFollowerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  set?: InputMaybe<Array<TeacherFollowerWhereUniqueInput>>;
  update?: InputMaybe<Array<TeacherFollowerUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TeacherFollowerUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TeacherFollowerUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TeacherFollowerUpdateWithWhereUniqueWithoutTeacherInput = {
  data: TeacherFollowerUpdateWithoutTeacherInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerUpdateWithWhereUniqueWithoutUserInput = {
  data: TeacherFollowerUpdateWithoutUserInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerUpdateWithoutTeacherInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutFollowedTeachersNestedInput>;
};

export type TeacherFollowerUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  teacher?: InputMaybe<CoachUpdateOneRequiredWithoutFollowedByNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeacherFollowerUpsertWithWhereUniqueWithoutTeacherInput = {
  create: TeacherFollowerCreateWithoutTeacherInput;
  update: TeacherFollowerUpdateWithoutTeacherInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerUpsertWithWhereUniqueWithoutUserInput = {
  create: TeacherFollowerCreateWithoutUserInput;
  update: TeacherFollowerUpdateWithoutUserInput;
  where: TeacherFollowerWhereUniqueInput;
};

export type TeacherFollowerUserIdTeacherIdCompoundUniqueInput = {
  teacherId: Scalars['Int'];
  userId: Scalars['BigInt'];
};

export type TeacherFollowerWhereInput = {
  AND?: InputMaybe<Array<TeacherFollowerWhereInput>>;
  NOT?: InputMaybe<Array<TeacherFollowerWhereInput>>;
  OR?: InputMaybe<Array<TeacherFollowerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  teacher?: InputMaybe<CoachRelationFilter>;
  teacherId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type TeacherFollowerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId_teacherId?: InputMaybe<TeacherFollowerUserIdTeacherIdCompoundUniqueInput>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  invoice?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  returnDetails?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  target: Scalars['Int'];
  targetId?: Maybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  target?: Maybe<Scalars['Float']>;
  targetId?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type TransactionAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  invoice: Scalars['Int'];
  ipAddress: Scalars['Int'];
  paidAt: Scalars['Int'];
  pdf: Scalars['Int'];
  referenceId: Scalars['Int'];
  returnDetails: Scalars['Int'];
  status: Scalars['Int'];
  target: Scalars['Int'];
  targetId: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type TransactionCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  returnDetails?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionCreateInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutTransactionsInput;
};

export type TransactionCreateManyInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type TransactionCreateManyUserInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionCreateManyUserInputEnvelope = {
  data: Array<TransactionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TransactionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
};

export type TransactionCreateOrConnectWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionCreateWithoutUserInput = {
  amount: Scalars['Float'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionGroupBy = {
  __typename?: 'TransactionGroupBy';
  _avg?: Maybe<TransactionAvgAggregate>;
  _count?: Maybe<TransactionCountAggregate>;
  _max?: Maybe<TransactionMaxAggregate>;
  _min?: Maybe<TransactionMinAggregate>;
  _sum?: Maybe<TransactionSumAggregate>;
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  invoice?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  paidAt?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  returnDetails?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  target: Scalars['Int'];
  targetId?: Maybe<Scalars['Int']>;
  type: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['BigInt'];
};

export type TransactionListRelationFilter = {
  every?: InputMaybe<TransactionWhereInput>;
  none?: InputMaybe<TransactionWhereInput>;
  some?: InputMaybe<TransactionWhereInput>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  returnDetails?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
  targetId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type TransactionMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  returnDetails?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  paidAt?: Maybe<Scalars['DateTime']>;
  pdf?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  returnDetails?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
  targetId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type TransactionMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  returnDetails?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithAggregationInput = {
  _avg?: InputMaybe<TransactionAvgOrderByAggregateInput>;
  _count?: InputMaybe<TransactionCountOrderByAggregateInput>;
  _max?: InputMaybe<TransactionMaxOrderByAggregateInput>;
  _min?: InputMaybe<TransactionMinOrderByAggregateInput>;
  _sum?: InputMaybe<TransactionSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  returnDetails?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoice?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  paidAt?: InputMaybe<SortOrder>;
  pdf?: InputMaybe<SortOrder>;
  referenceId?: InputMaybe<SortOrder>;
  returnDetails?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum TransactionScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Invoice = 'invoice',
  IpAddress = 'ipAddress',
  PaidAt = 'paidAt',
  Pdf = 'pdf',
  ReferenceId = 'referenceId',
  ReturnDetails = 'returnDetails',
  Status = 'status',
  Target = 'target',
  TargetId = 'targetId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type TransactionScalarWhereInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<StringNullableFilter>;
  ipAddress?: InputMaybe<StringFilter>;
  paidAt?: InputMaybe<DateTimeNullableFilter>;
  pdf?: InputMaybe<StringNullableFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  returnDetails?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  target?: InputMaybe<IntFilter>;
  targetId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type TransactionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TransactionScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<FloatWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  description?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  invoice?: InputMaybe<StringNullableWithAggregatesFilter>;
  ipAddress?: InputMaybe<StringWithAggregatesFilter>;
  paidAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  pdf?: InputMaybe<StringNullableWithAggregatesFilter>;
  referenceId?: InputMaybe<StringNullableWithAggregatesFilter>;
  returnDetails?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  target?: InputMaybe<IntWithAggregatesFilter>;
  targetId?: InputMaybe<IntNullableWithAggregatesFilter>;
  type?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<BigIntWithAggregatesFilter>;
};

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  target?: Maybe<Scalars['Int']>;
  targetId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type TransactionSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  target?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TransactionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutTransactionsNestedInput>;
};

export type TransactionUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionUpdateManyWithWhereWithoutUserInput = {
  data: TransactionUpdateManyMutationInput;
  where: TransactionScalarWhereInput;
};

export type TransactionUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TransactionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<TransactionCreateWithoutUserInput>>;
  createMany?: InputMaybe<TransactionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TransactionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  set?: InputMaybe<Array<TransactionWhereUniqueInput>>;
  update?: InputMaybe<Array<TransactionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<TransactionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<TransactionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type TransactionUpdateWithWhereUniqueWithoutUserInput = {
  data: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionUpdateWithoutUserInput = {
  amount?: InputMaybe<Scalars['Float']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Scalars['String']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  paidAt?: InputMaybe<Scalars['DateTime']>;
  pdf?: InputMaybe<Scalars['String']>;
  referenceId?: InputMaybe<Scalars['String']>;
  returnDetails?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  target?: InputMaybe<Scalars['Int']>;
  targetId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TransactionUpsertWithWhereUniqueWithoutUserInput = {
  create: TransactionCreateWithoutUserInput;
  update: TransactionUpdateWithoutUserInput;
  where: TransactionWhereUniqueInput;
};

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  invoice?: InputMaybe<StringNullableFilter>;
  ipAddress?: InputMaybe<StringFilter>;
  paidAt?: InputMaybe<DateTimeNullableFilter>;
  pdf?: InputMaybe<StringNullableFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  returnDetails?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  target?: InputMaybe<IntFilter>;
  targetId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type TransactionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UnfollowCoachInput = {
  id: Scalars['Int'];
};

export type UnpreferManyInterestTypesInput = {
  ids: Array<Scalars['BigInt']>;
};

export type UnsubscribeEmail = {
  __typename?: 'UnsubscribeEmail';
  code: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['BigInt'];
};

export type UnsubscribeEmailCreateManyUserInput = {
  code: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnsubscribeEmailCreateManyUserInputEnvelope = {
  data: Array<UnsubscribeEmailCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UnsubscribeEmailCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UnsubscribeEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnsubscribeEmailCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UnsubscribeEmailCreateWithoutUserInput>>;
  createMany?: InputMaybe<UnsubscribeEmailCreateManyUserInputEnvelope>;
};

export type UnsubscribeEmailCreateOrConnectWithoutUserInput = {
  create: UnsubscribeEmailCreateWithoutUserInput;
  where: UnsubscribeEmailWhereUniqueInput;
};

export type UnsubscribeEmailCreateWithoutUserInput = {
  code: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnsubscribeEmailListRelationFilter = {
  every?: InputMaybe<UnsubscribeEmailWhereInput>;
  none?: InputMaybe<UnsubscribeEmailWhereInput>;
  some?: InputMaybe<UnsubscribeEmailWhereInput>;
};

export type UnsubscribeEmailOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UnsubscribeEmailOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UnsubscribeEmailScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UnsubscribeEmailScalarWhereInput = {
  AND?: InputMaybe<Array<UnsubscribeEmailScalarWhereInput>>;
  NOT?: InputMaybe<Array<UnsubscribeEmailScalarWhereInput>>;
  OR?: InputMaybe<Array<UnsubscribeEmailScalarWhereInput>>;
  code?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UnsubscribeEmailUpdateManyMutationInput = {
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnsubscribeEmailUpdateManyWithWhereWithoutUserInput = {
  data: UnsubscribeEmailUpdateManyMutationInput;
  where: UnsubscribeEmailScalarWhereInput;
};

export type UnsubscribeEmailUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UnsubscribeEmailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UnsubscribeEmailCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UnsubscribeEmailCreateWithoutUserInput>>;
  createMany?: InputMaybe<UnsubscribeEmailCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UnsubscribeEmailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UnsubscribeEmailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UnsubscribeEmailWhereUniqueInput>>;
  set?: InputMaybe<Array<UnsubscribeEmailWhereUniqueInput>>;
  update?: InputMaybe<Array<UnsubscribeEmailUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UnsubscribeEmailUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UnsubscribeEmailUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UnsubscribeEmailUpdateWithWhereUniqueWithoutUserInput = {
  data: UnsubscribeEmailUpdateWithoutUserInput;
  where: UnsubscribeEmailWhereUniqueInput;
};

export type UnsubscribeEmailUpdateWithoutUserInput = {
  code?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UnsubscribeEmailUpsertWithWhereUniqueWithoutUserInput = {
  create: UnsubscribeEmailCreateWithoutUserInput;
  update: UnsubscribeEmailUpdateWithoutUserInput;
  where: UnsubscribeEmailWhereUniqueInput;
};

export type UnsubscribeEmailWhereInput = {
  AND?: InputMaybe<Array<UnsubscribeEmailWhereInput>>;
  NOT?: InputMaybe<Array<UnsubscribeEmailWhereInput>>;
  OR?: InputMaybe<Array<UnsubscribeEmailWhereInput>>;
  code?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UnsubscribeEmailWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UpdateUserInterestTypesInput = {
  ids: Array<Scalars['BigInt']>;
};

export type Urls = {
  __typename?: 'Urls';
  faq: Scalars['String'];
  privacyPolicy: Scalars['String'];
  termsOfUse: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  active?: Maybe<Scalars['DateTime']>;
  badgeLevel?: Maybe<Scalars['Int']>;
  certificates: Array<Certificate>;
  classRatings: Array<ClassRating>;
  countryCode?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currentSubscription?: Maybe<Subscription>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  followedTeachers: Array<TeacherFollower>;
  freeRegister?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  hasActiveSubscription: Scalars['Boolean'];
  id: Scalars['BigInt'];
  image?: Maybe<Scalars['String']>;
  introducerId?: Maybe<Scalars['Int']>;
  isAdminBadge?: Maybe<Scalars['String']>;
  isRewardableReferral: Scalars['Boolean'];
  isSpecialIntroducer: Scalars['Int'];
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  partner?: Maybe<Partner>;
  phone?: Maybe<Scalars['String']>;
  phoneVerifiedAt?: Maybe<Scalars['DateTime']>;
  qRCodeChallenges: Array<QrCodeChallenge>;
  qrCode?: Maybe<Scalars['String']>;
  /** @deprecated Use referralCode instead */
  referalCode?: Maybe<Scalars['String']>;
  refereeIncentives: Array<UserIncentive>;
  referralCode?: Maybe<Scalars['String']>;
  referralCodeStatus: Scalars['Boolean'];
  referralCodeTotalUsage: Scalars['Int'];
  referralCodeValidity?: Maybe<Scalars['Int']>;
  referrerIncentives: Array<UserIncentive>;
  rewardAmount: Scalars['Decimal'];
  rewardStatus: Scalars['Int'];
  socialIdentities: Array<SocialIdentity>;
  source?: Maybe<Partner>;
  sourceId?: Maybe<Scalars['String']>;
  sponsor?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
  subscribedAt?: Maybe<Scalars['DateTime']>;
  transactions: Array<Transaction>;
  unsubscribeEmails: Array<UnsubscribeEmail>;
  updatedAt: Scalars['DateTime'];
  userDeactivatedLogs: Array<UserDeactivatedLog>;
  userInformationChangeRequests: Array<UserInformationChangeRequest>;
  userInterests: Array<UserInterest>;
  userLoginIps: Array<UserLoginIp>;
  userRank?: Maybe<Scalars['String']>;
  userSettings: Array<UserSetting>;
  userType?: Maybe<Scalars['Int']>;
  userVerticals: Array<UserVertical>;
  username?: Maybe<Scalars['String']>;
};

export type UserCertificatesArgs = {
  cursor?: InputMaybe<CertificateWhereUniqueInput>;
  distinct?: InputMaybe<Array<CertificateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CertificateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CertificateWhereInput>;
};

export type UserClassRatingsArgs = {
  cursor?: InputMaybe<ClassRatingWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClassRatingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClassRatingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClassRatingWhereInput>;
};

export type UserFollowedTeachersArgs = {
  cursor?: InputMaybe<TeacherFollowerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeacherFollowerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeacherFollowerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeacherFollowerWhereInput>;
};

export type UserQrCodeChallengesArgs = {
  cursor?: InputMaybe<QrCodeChallengeWhereUniqueInput>;
  distinct?: InputMaybe<Array<QrCodeChallengeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<QrCodeChallengeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<QrCodeChallengeWhereInput>;
};

export type UserRefereeIncentivesArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserIncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type UserReferrerIncentivesArgs = {
  cursor?: InputMaybe<UserIncentiveWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserIncentiveScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserIncentiveOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserIncentiveWhereInput>;
};

export type UserSocialIdentitiesArgs = {
  cursor?: InputMaybe<SocialIdentityWhereUniqueInput>;
  distinct?: InputMaybe<Array<SocialIdentityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SocialIdentityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SocialIdentityWhereInput>;
};

export type UserTransactionsArgs = {
  cursor?: InputMaybe<TransactionWhereUniqueInput>;
  distinct?: InputMaybe<Array<TransactionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TransactionWhereInput>;
};

export type UserUnsubscribeEmailsArgs = {
  cursor?: InputMaybe<UnsubscribeEmailWhereUniqueInput>;
  distinct?: InputMaybe<Array<UnsubscribeEmailScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UnsubscribeEmailOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UnsubscribeEmailWhereInput>;
};

export type UserUserDeactivatedLogsArgs = {
  cursor?: InputMaybe<UserDeactivatedLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserDeactivatedLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserDeactivatedLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserDeactivatedLogWhereInput>;
};

export type UserUserInformationChangeRequestsArgs = {
  cursor?: InputMaybe<UserInformationChangeRequestWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserInformationChangeRequestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserInformationChangeRequestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserInformationChangeRequestWhereInput>;
};

export type UserUserInterestsArgs = {
  cursor?: InputMaybe<UserInterestWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserInterestScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserInterestOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserInterestWhereInput>;
};

export type UserUserLoginIpsArgs = {
  cursor?: InputMaybe<UserLoginIpWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLoginIpScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLoginIpOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserLoginIpWhereInput>;
};

export type UserUserSettingsArgs = {
  cursor?: InputMaybe<UserSettingWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserSettingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserSettingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserSettingWhereInput>;
};

export type UserUserVerticalsArgs = {
  cursor?: InputMaybe<UserVerticalWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserVerticalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserVerticalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserVerticalWhereInput>;
};

export type UserAssessmentRecord = {
  __typename?: 'UserAssessmentRecord';
  answers: Answer;
  assessment: Assessment;
  assessmentId: Scalars['BigInt'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  liveClassId: Scalars['BigInt'];
  question: Question;
  questionId: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userAnswerId: Scalars['BigInt'];
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateManyAnswersInput = {
  assessmentId: Scalars['BigInt'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  questionId: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateManyAnswersInputEnvelope = {
  data: Array<UserAssessmentRecordCreateManyAnswersInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserAssessmentRecordCreateManyAssessmentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  questionId: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAnswerId: Scalars['BigInt'];
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateManyAssessmentInputEnvelope = {
  data: Array<UserAssessmentRecordCreateManyAssessmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserAssessmentRecordCreateManyQuestionInput = {
  assessmentId: Scalars['BigInt'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userAnswerId: Scalars['BigInt'];
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateManyQuestionInputEnvelope = {
  data: Array<UserAssessmentRecordCreateManyQuestionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserAssessmentRecordCreateNestedManyWithoutAnswersInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutAnswersInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutAnswersInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyAnswersInputEnvelope>;
};

export type UserAssessmentRecordCreateNestedManyWithoutAssessmentInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyAssessmentInputEnvelope>;
};

export type UserAssessmentRecordCreateNestedManyWithoutQuestionInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyQuestionInputEnvelope>;
};

export type UserAssessmentRecordCreateOrConnectWithoutAnswersInput = {
  create: UserAssessmentRecordCreateWithoutAnswersInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordCreateOrConnectWithoutAssessmentInput = {
  create: UserAssessmentRecordCreateWithoutAssessmentInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordCreateOrConnectWithoutQuestionInput = {
  create: UserAssessmentRecordCreateWithoutQuestionInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordCreateWithoutAnswersInput = {
  assessment: AssessmentCreateNestedOneWithoutUserAssessmentRecordsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  question: QuestionCreateNestedOneWithoutUserAssessmentRecordsInput;
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateWithoutAssessmentInput = {
  answers: AnswerCreateNestedOneWithoutUserAssessmentRecordInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  question: QuestionCreateNestedOneWithoutUserAssessmentRecordsInput;
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordCreateWithoutQuestionInput = {
  answers: AnswerCreateNestedOneWithoutUserAssessmentRecordInput;
  assessment: AssessmentCreateNestedOneWithoutUserAssessmentRecordsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  status: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentRecordListRelationFilter = {
  every?: InputMaybe<UserAssessmentRecordWhereInput>;
  none?: InputMaybe<UserAssessmentRecordWhereInput>;
  some?: InputMaybe<UserAssessmentRecordWhereInput>;
};

export type UserAssessmentRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserAssessmentRecordOrderByWithRelationInput = {
  answers?: InputMaybe<AnswerOrderByWithRelationInput>;
  assessment?: InputMaybe<AssessmentOrderByWithRelationInput>;
  assessmentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  question?: InputMaybe<QuestionOrderByWithRelationInput>;
  questionId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userAnswerId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserAssessmentRecordScalarFieldEnum {
  AssessmentId = 'assessmentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  LiveClassId = 'liveClassId',
  QuestionId = 'questionId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserAnswerId = 'userAnswerId',
  UserId = 'userId',
}

export type UserAssessmentRecordScalarWhereInput = {
  AND?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userAnswerId?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserAssessmentRecordUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentRecordUpdateManyWithWhereWithoutAnswersInput = {
  data: UserAssessmentRecordUpdateManyMutationInput;
  where: UserAssessmentRecordScalarWhereInput;
};

export type UserAssessmentRecordUpdateManyWithWhereWithoutAssessmentInput = {
  data: UserAssessmentRecordUpdateManyMutationInput;
  where: UserAssessmentRecordScalarWhereInput;
};

export type UserAssessmentRecordUpdateManyWithWhereWithoutQuestionInput = {
  data: UserAssessmentRecordUpdateManyMutationInput;
  where: UserAssessmentRecordScalarWhereInput;
};

export type UserAssessmentRecordUpdateManyWithoutAnswersNestedInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutAnswersInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutAnswersInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyAnswersInputEnvelope>;
  delete?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<UserAssessmentRecordUpdateWithWhereUniqueWithoutAnswersInput>>;
  updateMany?: InputMaybe<Array<UserAssessmentRecordUpdateManyWithWhereWithoutAnswersInput>>;
  upsert?: InputMaybe<Array<UserAssessmentRecordUpsertWithWhereUniqueWithoutAnswersInput>>;
};

export type UserAssessmentRecordUpdateManyWithoutAssessmentNestedInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutAssessmentInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutAssessmentInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyAssessmentInputEnvelope>;
  delete?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<UserAssessmentRecordUpdateWithWhereUniqueWithoutAssessmentInput>>;
  updateMany?: InputMaybe<Array<UserAssessmentRecordUpdateManyWithWhereWithoutAssessmentInput>>;
  upsert?: InputMaybe<Array<UserAssessmentRecordUpsertWithWhereUniqueWithoutAssessmentInput>>;
};

export type UserAssessmentRecordUpdateManyWithoutQuestionNestedInput = {
  connect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentRecordCreateOrConnectWithoutQuestionInput>>;
  create?: InputMaybe<Array<UserAssessmentRecordCreateWithoutQuestionInput>>;
  createMany?: InputMaybe<UserAssessmentRecordCreateManyQuestionInputEnvelope>;
  delete?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserAssessmentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<UserAssessmentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<UserAssessmentRecordUpdateWithWhereUniqueWithoutQuestionInput>>;
  updateMany?: InputMaybe<Array<UserAssessmentRecordUpdateManyWithWhereWithoutQuestionInput>>;
  upsert?: InputMaybe<Array<UserAssessmentRecordUpsertWithWhereUniqueWithoutQuestionInput>>;
};

export type UserAssessmentRecordUpdateWithWhereUniqueWithoutAnswersInput = {
  data: UserAssessmentRecordUpdateWithoutAnswersInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordUpdateWithWhereUniqueWithoutAssessmentInput = {
  data: UserAssessmentRecordUpdateWithoutAssessmentInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordUpdateWithWhereUniqueWithoutQuestionInput = {
  data: UserAssessmentRecordUpdateWithoutQuestionInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordUpdateWithoutAnswersInput = {
  assessment?: InputMaybe<AssessmentUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentRecordUpdateWithoutAssessmentInput = {
  answers?: InputMaybe<AnswerUpdateOneRequiredWithoutUserAssessmentRecordNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  question?: InputMaybe<QuestionUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentRecordUpdateWithoutQuestionInput = {
  answers?: InputMaybe<AnswerUpdateOneRequiredWithoutUserAssessmentRecordNestedInput>;
  assessment?: InputMaybe<AssessmentUpdateOneRequiredWithoutUserAssessmentRecordsNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentRecordUpsertWithWhereUniqueWithoutAnswersInput = {
  create: UserAssessmentRecordCreateWithoutAnswersInput;
  update: UserAssessmentRecordUpdateWithoutAnswersInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordUpsertWithWhereUniqueWithoutAssessmentInput = {
  create: UserAssessmentRecordCreateWithoutAssessmentInput;
  update: UserAssessmentRecordUpdateWithoutAssessmentInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordUpsertWithWhereUniqueWithoutQuestionInput = {
  create: UserAssessmentRecordCreateWithoutQuestionInput;
  update: UserAssessmentRecordUpdateWithoutQuestionInput;
  where: UserAssessmentRecordWhereUniqueInput;
};

export type UserAssessmentRecordWhereInput = {
  AND?: InputMaybe<Array<UserAssessmentRecordWhereInput>>;
  NOT?: InputMaybe<Array<UserAssessmentRecordWhereInput>>;
  OR?: InputMaybe<Array<UserAssessmentRecordWhereInput>>;
  answers?: InputMaybe<AnswerRelationFilter>;
  assessment?: InputMaybe<AssessmentRelationFilter>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  question?: InputMaybe<QuestionRelationFilter>;
  questionId?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userAnswerId?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserAssessmentRecordWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentResult = {
  __typename?: 'UserAssessmentResult';
  assessmentId: Scalars['BigInt'];
  assessments: Assessment;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  liveClassId: Scalars['BigInt'];
  score: Scalars['Float'];
  status: Scalars['Int'];
  totalQuestions: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentResultCreateManyAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  score: Scalars['Float'];
  status: Scalars['Int'];
  totalQuestions: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentResultCreateManyAssessmentsInputEnvelope = {
  data: Array<UserAssessmentResultCreateManyAssessmentsInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserAssessmentResultCreateNestedManyWithoutAssessmentsInput = {
  connect?: InputMaybe<Array<UserAssessmentResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentResultCreateOrConnectWithoutAssessmentsInput>>;
  create?: InputMaybe<Array<UserAssessmentResultCreateWithoutAssessmentsInput>>;
  createMany?: InputMaybe<UserAssessmentResultCreateManyAssessmentsInputEnvelope>;
};

export type UserAssessmentResultCreateOrConnectWithoutAssessmentsInput = {
  create: UserAssessmentResultCreateWithoutAssessmentsInput;
  where: UserAssessmentResultWhereUniqueInput;
};

export type UserAssessmentResultCreateWithoutAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId: Scalars['BigInt'];
  score: Scalars['Float'];
  status: Scalars['Int'];
  totalQuestions: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserAssessmentResultListRelationFilter = {
  every?: InputMaybe<UserAssessmentResultWhereInput>;
  none?: InputMaybe<UserAssessmentResultWhereInput>;
  some?: InputMaybe<UserAssessmentResultWhereInput>;
};

export type UserAssessmentResultOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserAssessmentResultOrderByWithRelationInput = {
  assessmentId?: InputMaybe<SortOrder>;
  assessments?: InputMaybe<AssessmentOrderByWithRelationInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  liveClassId?: InputMaybe<SortOrder>;
  score?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalQuestions?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserAssessmentResultScalarFieldEnum {
  AssessmentId = 'assessmentId',
  CreatedAt = 'createdAt',
  Id = 'id',
  LiveClassId = 'liveClassId',
  Score = 'score',
  Status = 'status',
  TotalQuestions = 'totalQuestions',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserAssessmentResultScalarWhereInput = {
  AND?: InputMaybe<Array<UserAssessmentResultScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserAssessmentResultScalarWhereInput>>;
  OR?: InputMaybe<Array<UserAssessmentResultScalarWhereInput>>;
  assessmentId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  score?: InputMaybe<FloatFilter>;
  status?: InputMaybe<IntFilter>;
  totalQuestions?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserAssessmentResultUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  score?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  totalQuestions?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentResultUpdateManyWithWhereWithoutAssessmentsInput = {
  data: UserAssessmentResultUpdateManyMutationInput;
  where: UserAssessmentResultScalarWhereInput;
};

export type UserAssessmentResultUpdateManyWithoutAssessmentsNestedInput = {
  connect?: InputMaybe<Array<UserAssessmentResultWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserAssessmentResultCreateOrConnectWithoutAssessmentsInput>>;
  create?: InputMaybe<Array<UserAssessmentResultCreateWithoutAssessmentsInput>>;
  createMany?: InputMaybe<UserAssessmentResultCreateManyAssessmentsInputEnvelope>;
  delete?: InputMaybe<Array<UserAssessmentResultWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserAssessmentResultScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserAssessmentResultWhereUniqueInput>>;
  set?: InputMaybe<Array<UserAssessmentResultWhereUniqueInput>>;
  update?: InputMaybe<Array<UserAssessmentResultUpdateWithWhereUniqueWithoutAssessmentsInput>>;
  updateMany?: InputMaybe<Array<UserAssessmentResultUpdateManyWithWhereWithoutAssessmentsInput>>;
  upsert?: InputMaybe<Array<UserAssessmentResultUpsertWithWhereUniqueWithoutAssessmentsInput>>;
};

export type UserAssessmentResultUpdateWithWhereUniqueWithoutAssessmentsInput = {
  data: UserAssessmentResultUpdateWithoutAssessmentsInput;
  where: UserAssessmentResultWhereUniqueInput;
};

export type UserAssessmentResultUpdateWithoutAssessmentsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['BigInt']>;
  score?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['Int']>;
  totalQuestions?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserAssessmentResultUpsertWithWhereUniqueWithoutAssessmentsInput = {
  create: UserAssessmentResultCreateWithoutAssessmentsInput;
  update: UserAssessmentResultUpdateWithoutAssessmentsInput;
  where: UserAssessmentResultWhereUniqueInput;
};

export type UserAssessmentResultWhereInput = {
  AND?: InputMaybe<Array<UserAssessmentResultWhereInput>>;
  NOT?: InputMaybe<Array<UserAssessmentResultWhereInput>>;
  OR?: InputMaybe<Array<UserAssessmentResultWhereInput>>;
  assessmentId?: InputMaybe<BigIntFilter>;
  assessments?: InputMaybe<AssessmentRelationFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  liveClassId?: InputMaybe<BigIntFilter>;
  score?: InputMaybe<FloatFilter>;
  status?: InputMaybe<IntFilter>;
  totalQuestions?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserAssessmentResultWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserBookmark = {
  __typename?: 'UserBookmark';
  class?: Maybe<NormalClass>;
  classId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  library?: Maybe<Library>;
  libraryId?: Maybe<Scalars['Int']>;
  podcast?: Maybe<Podcast>;
  podcastId?: Maybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateManyClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  libraryId?: InputMaybe<Scalars['Int']>;
  podcastId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateManyClassInputEnvelope = {
  data: Array<UserBookmarkCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserBookmarkCreateManyLibraryInput = {
  classId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcastId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateManyLibraryInputEnvelope = {
  data: Array<UserBookmarkCreateManyLibraryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserBookmarkCreateManyPodcastInput = {
  classId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  libraryId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateManyPodcastInputEnvelope = {
  data: Array<UserBookmarkCreateManyPodcastInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserBookmarkCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutClassInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyClassInputEnvelope>;
};

export type UserBookmarkCreateNestedManyWithoutLibraryInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutLibraryInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutLibraryInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyLibraryInputEnvelope>;
};

export type UserBookmarkCreateNestedManyWithoutPodcastInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyPodcastInputEnvelope>;
};

export type UserBookmarkCreateOrConnectWithoutClassInput = {
  create: UserBookmarkCreateWithoutClassInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkCreateOrConnectWithoutLibraryInput = {
  create: UserBookmarkCreateWithoutLibraryInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkCreateOrConnectWithoutPodcastInput = {
  create: UserBookmarkCreateWithoutPodcastInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkCreateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassCreateNestedOneWithoutLibraryBookmarkedInput>;
  podcast?: InputMaybe<PodcastCreateNestedOneWithoutUserBookmarksInput>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateWithoutLibraryInput = {
  class?: InputMaybe<NormalClassCreateNestedOneWithoutClassBookmarkedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast?: InputMaybe<PodcastCreateNestedOneWithoutUserBookmarksInput>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkCreateWithoutPodcastInput = {
  class?: InputMaybe<NormalClassCreateNestedOneWithoutClassBookmarkedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassCreateNestedOneWithoutLibraryBookmarkedInput>;
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserBookmarkListRelationFilter = {
  every?: InputMaybe<UserBookmarkWhereInput>;
  none?: InputMaybe<UserBookmarkWhereInput>;
  some?: InputMaybe<UserBookmarkWhereInput>;
};

export type UserBookmarkOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserBookmarkOrderByWithRelationInput = {
  class?: InputMaybe<NormalClassOrderByWithRelationInput>;
  classId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  library?: InputMaybe<NormalClassOrderByWithRelationInput>;
  libraryId?: InputMaybe<SortOrder>;
  podcast?: InputMaybe<PodcastOrderByWithRelationInput>;
  podcastId?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  targetType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserBookmarkScalarFieldEnum {
  ClassId = 'classId',
  CreatedAt = 'createdAt',
  Id = 'id',
  LibraryId = 'libraryId',
  PodcastId = 'podcastId',
  TargetId = 'targetId',
  TargetType = 'targetType',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserBookmarkScalarWhereInput = {
  AND?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  OR?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  classId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  libraryId?: InputMaybe<IntNullableFilter>;
  podcastId?: InputMaybe<IntNullableFilter>;
  targetId?: InputMaybe<IntFilter>;
  targetType?: InputMaybe<EnumBookmarkTargetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserBookmarkUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<BookmarkTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserBookmarkUpdateManyWithWhereWithoutClassInput = {
  data: UserBookmarkUpdateManyMutationInput;
  where: UserBookmarkScalarWhereInput;
};

export type UserBookmarkUpdateManyWithWhereWithoutLibraryInput = {
  data: UserBookmarkUpdateManyMutationInput;
  where: UserBookmarkScalarWhereInput;
};

export type UserBookmarkUpdateManyWithWhereWithoutPodcastInput = {
  data: UserBookmarkUpdateManyMutationInput;
  where: UserBookmarkScalarWhereInput;
};

export type UserBookmarkUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutClassInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  set?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  update?: InputMaybe<Array<UserBookmarkUpdateWithWhereUniqueWithoutClassInput>>;
  updateMany?: InputMaybe<Array<UserBookmarkUpdateManyWithWhereWithoutClassInput>>;
  upsert?: InputMaybe<Array<UserBookmarkUpsertWithWhereUniqueWithoutClassInput>>;
};

export type UserBookmarkUpdateManyWithoutLibraryNestedInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutLibraryInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutLibraryInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyLibraryInputEnvelope>;
  delete?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  set?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  update?: InputMaybe<Array<UserBookmarkUpdateWithWhereUniqueWithoutLibraryInput>>;
  updateMany?: InputMaybe<Array<UserBookmarkUpdateManyWithWhereWithoutLibraryInput>>;
  upsert?: InputMaybe<Array<UserBookmarkUpsertWithWhereUniqueWithoutLibraryInput>>;
};

export type UserBookmarkUpdateManyWithoutPodcastNestedInput = {
  connect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserBookmarkCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<UserBookmarkCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<UserBookmarkCreateManyPodcastInputEnvelope>;
  delete?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserBookmarkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  set?: InputMaybe<Array<UserBookmarkWhereUniqueInput>>;
  update?: InputMaybe<Array<UserBookmarkUpdateWithWhereUniqueWithoutPodcastInput>>;
  updateMany?: InputMaybe<Array<UserBookmarkUpdateManyWithWhereWithoutPodcastInput>>;
  upsert?: InputMaybe<Array<UserBookmarkUpsertWithWhereUniqueWithoutPodcastInput>>;
};

export type UserBookmarkUpdateWithWhereUniqueWithoutClassInput = {
  data: UserBookmarkUpdateWithoutClassInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUpdateWithWhereUniqueWithoutLibraryInput = {
  data: UserBookmarkUpdateWithoutLibraryInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUpdateWithWhereUniqueWithoutPodcastInput = {
  data: UserBookmarkUpdateWithoutPodcastInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUpdateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassUpdateOneWithoutLibraryBookmarkedNestedInput>;
  podcast?: InputMaybe<PodcastUpdateOneWithoutUserBookmarksNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<BookmarkTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserBookmarkUpdateWithoutLibraryInput = {
  class?: InputMaybe<NormalClassUpdateOneWithoutClassBookmarkedNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  podcast?: InputMaybe<PodcastUpdateOneWithoutUserBookmarksNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<BookmarkTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserBookmarkUpdateWithoutPodcastInput = {
  class?: InputMaybe<NormalClassUpdateOneWithoutClassBookmarkedNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassUpdateOneWithoutLibraryBookmarkedNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<BookmarkTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserBookmarkUpsertWithWhereUniqueWithoutClassInput = {
  create: UserBookmarkCreateWithoutClassInput;
  update: UserBookmarkUpdateWithoutClassInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUpsertWithWhereUniqueWithoutLibraryInput = {
  create: UserBookmarkCreateWithoutLibraryInput;
  update: UserBookmarkUpdateWithoutLibraryInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUpsertWithWhereUniqueWithoutPodcastInput = {
  create: UserBookmarkCreateWithoutPodcastInput;
  update: UserBookmarkUpdateWithoutPodcastInput;
  where: UserBookmarkWhereUniqueInput;
};

export type UserBookmarkUserIdTargetIdTargetTypeCompoundUniqueInput = {
  targetId: Scalars['Int'];
  targetType: BookmarkTargetType;
  userId: Scalars['BigInt'];
};

export type UserBookmarkWhereInput = {
  AND?: InputMaybe<Array<UserBookmarkWhereInput>>;
  NOT?: InputMaybe<Array<UserBookmarkWhereInput>>;
  OR?: InputMaybe<Array<UserBookmarkWhereInput>>;
  class?: InputMaybe<NormalClassRelationFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  library?: InputMaybe<NormalClassRelationFilter>;
  libraryId?: InputMaybe<IntNullableFilter>;
  podcast?: InputMaybe<PodcastRelationFilter>;
  podcastId?: InputMaybe<IntNullableFilter>;
  targetId?: InputMaybe<IntFilter>;
  targetType?: InputMaybe<EnumBookmarkTargetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserBookmarkWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId_targetId_targetType?: InputMaybe<UserBookmarkUserIdTargetIdTargetTypeCompoundUniqueInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  certificates: Scalars['Int'];
  classRatings: Scalars['Int'];
  followedTeachers: Scalars['Int'];
  qRCodeChallenges: Scalars['Int'];
  refereeIncentives: Scalars['Int'];
  referrerIncentives: Scalars['Int'];
  socialIdentities: Scalars['Int'];
  transactions: Scalars['Int'];
  unsubscribeEmails: Scalars['Int'];
  userDeactivatedLogs: Scalars['Int'];
  userInformationChangeRequests: Scalars['Int'];
  userInterests: Scalars['Int'];
  userLoginIps: Scalars['Int'];
  userSettings: Scalars['Int'];
  userVerticals: Scalars['Int'];
};

export type UserCreateClassRatingInput = {
  classId: Scalars['Int'];
  classRatingType: ClassRatingType;
  comment: Scalars['String'];
  rating: Scalars['Int'];
};

export type UserCreateManySourceInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qrCode?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userRank?: InputMaybe<Scalars['String']>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateManySourceInputEnvelope = {
  data: Array<UserCreateManySourceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutSourceInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSourceInput>>;
  createMany?: InputMaybe<UserCreateManySourceInputEnvelope>;
};

export type UserCreateNestedOneWithoutCertificatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCertificatesInput>;
  create?: InputMaybe<UserCreateWithoutCertificatesInput>;
};

export type UserCreateNestedOneWithoutClassRatingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClassRatingsInput>;
  create?: InputMaybe<UserCreateWithoutClassRatingsInput>;
};

export type UserCreateNestedOneWithoutFollowedTeachersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedTeachersInput>;
  create?: InputMaybe<UserCreateWithoutFollowedTeachersInput>;
};

export type UserCreateNestedOneWithoutPartnerInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPartnerInput>;
  create?: InputMaybe<UserCreateWithoutPartnerInput>;
};

export type UserCreateNestedOneWithoutRefereeIncentivesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRefereeIncentivesInput>;
  create?: InputMaybe<UserCreateWithoutRefereeIncentivesInput>;
};

export type UserCreateNestedOneWithoutReferrerIncentivesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReferrerIncentivesInput>;
  create?: InputMaybe<UserCreateWithoutReferrerIncentivesInput>;
};

export type UserCreateNestedOneWithoutTransactionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
};

export type UserCreateNestedOneWithoutUserInterestsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserInterestsInput>;
  create?: InputMaybe<UserCreateWithoutUserInterestsInput>;
};

export type UserCreateNestedOneWithoutUserSettingsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSettingsInput>;
  create?: InputMaybe<UserCreateWithoutUserSettingsInput>;
};

export type UserCreateOrConnectWithoutCertificatesInput = {
  create: UserCreateWithoutCertificatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClassRatingsInput = {
  create: UserCreateWithoutClassRatingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFollowedTeachersInput = {
  create: UserCreateWithoutFollowedTeachersInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPartnerInput = {
  create: UserCreateWithoutPartnerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRefereeIncentivesInput = {
  create: UserCreateWithoutRefereeIncentivesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutReferrerIncentivesInput = {
  create: UserCreateWithoutReferrerIncentivesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSourceInput = {
  create: UserCreateWithoutSourceInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserInterestsInput = {
  create: UserCreateWithoutUserInterestsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserSettingsInput = {
  create: UserCreateWithoutUserSettingsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCertificatesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutClassRatingsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFollowedTeachersInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPartnerInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutRefereeIncentivesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutReferrerIncentivesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutSourceInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutTransactionsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutUserInterestsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingCreateNestedManyWithoutUserInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutUserSettingsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateCreateNestedManyWithoutUserInput>;
  classRatings?: InputMaybe<ClassRatingCreateNestedManyWithoutUserInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerCreateNestedManyWithoutUserInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerCreateNestedOneWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeCreateNestedManyWithoutUserInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutRefereeInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveCreateNestedManyWithoutUserInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityCreateNestedManyWithoutUserInput>;
  source?: InputMaybe<PartnerCreateNestedOneWithoutUsersInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionCreateNestedManyWithoutUserInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailCreateNestedManyWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogCreateNestedManyWithoutUserInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestCreateNestedManyWithoutUserInput>;
  userInterests?: InputMaybe<UserInterestCreateNestedManyWithoutUserInput>;
  userLoginIps?: InputMaybe<UserLoginIpCreateNestedManyWithoutUserInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalCreateNestedManyWithoutUserInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserDeactivatedLog = {
  __typename?: 'UserDeactivatedLog';
  byAdminId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['BigInt'];
  reason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type UserDeactivatedLogCreateManyUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserDeactivatedLogCreateManyUserInputEnvelope = {
  data: Array<UserDeactivatedLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserDeactivatedLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserDeactivatedLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserDeactivatedLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserDeactivatedLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserDeactivatedLogCreateManyUserInputEnvelope>;
};

export type UserDeactivatedLogCreateOrConnectWithoutUserInput = {
  create: UserDeactivatedLogCreateWithoutUserInput;
  where: UserDeactivatedLogWhereUniqueInput;
};

export type UserDeactivatedLogCreateWithoutUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserDeactivatedLogListRelationFilter = {
  every?: InputMaybe<UserDeactivatedLogWhereInput>;
  none?: InputMaybe<UserDeactivatedLogWhereInput>;
  some?: InputMaybe<UserDeactivatedLogWhereInput>;
};

export type UserDeactivatedLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserDeactivatedLogOrderByWithRelationInput = {
  byAdminId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserDeactivatedLogScalarFieldEnum {
  ByAdminId = 'byAdminId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Reason = 'reason',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserDeactivatedLogScalarWhereInput = {
  AND?: InputMaybe<Array<UserDeactivatedLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserDeactivatedLogScalarWhereInput>>;
  OR?: InputMaybe<Array<UserDeactivatedLogScalarWhereInput>>;
  byAdminId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserDeactivatedLogUpdateManyMutationInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserDeactivatedLogUpdateManyWithWhereWithoutUserInput = {
  data: UserDeactivatedLogUpdateManyMutationInput;
  where: UserDeactivatedLogScalarWhereInput;
};

export type UserDeactivatedLogUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserDeactivatedLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserDeactivatedLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserDeactivatedLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserDeactivatedLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserDeactivatedLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserDeactivatedLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserDeactivatedLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UserDeactivatedLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UserDeactivatedLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserDeactivatedLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserDeactivatedLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserDeactivatedLogUpdateWithWhereUniqueWithoutUserInput = {
  data: UserDeactivatedLogUpdateWithoutUserInput;
  where: UserDeactivatedLogWhereUniqueInput;
};

export type UserDeactivatedLogUpdateWithoutUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  reason?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserDeactivatedLogUpsertWithWhereUniqueWithoutUserInput = {
  create: UserDeactivatedLogCreateWithoutUserInput;
  update: UserDeactivatedLogUpdateWithoutUserInput;
  where: UserDeactivatedLogWhereUniqueInput;
};

export type UserDeactivatedLogWhereInput = {
  AND?: InputMaybe<Array<UserDeactivatedLogWhereInput>>;
  NOT?: InputMaybe<Array<UserDeactivatedLogWhereInput>>;
  OR?: InputMaybe<Array<UserDeactivatedLogWhereInput>>;
  byAdminId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserDeactivatedLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserIncentive = {
  __typename?: 'UserIncentive';
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  incentive: Incentive;
  incentiveId: Scalars['BigInt'];
  paymentStatus: Scalars['Int'];
  referee: User;
  refereeId: Scalars['BigInt'];
  reference?: Maybe<Scalars['JSON']>;
  remarks?: Maybe<Scalars['String']>;
  settlementId?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  type: IncentiveType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type UserIncentiveAvgAggregate = {
  __typename?: 'UserIncentiveAvgAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['Float']>;
  incentiveId?: Maybe<Scalars['Float']>;
  paymentStatus?: Maybe<Scalars['Float']>;
  refereeId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type UserIncentiveAvgOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveCountAggregate = {
  __typename?: 'UserIncentiveCountAggregate';
  _all: Scalars['Int'];
  amount: Scalars['Int'];
  createdAt: Scalars['Int'];
  currency: Scalars['Int'];
  description: Scalars['Int'];
  id: Scalars['Int'];
  incentiveId: Scalars['Int'];
  paymentStatus: Scalars['Int'];
  refereeId: Scalars['Int'];
  reference: Scalars['Int'];
  remarks: Scalars['Int'];
  settlementId: Scalars['Int'];
  status: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserIncentiveCountOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  settlementId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveCreateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive: IncentiveCreateNestedOneWithoutUserIncentivesInput;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee: UserCreateNestedOneWithoutRefereeIncentivesInput;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutReferrerIncentivesInput;
};

export type UserIncentiveCreateManyIncentiveInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  refereeId: Scalars['BigInt'];
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserIncentiveCreateManyIncentiveInputEnvelope = {
  data: Array<UserIncentiveCreateManyIncentiveInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserIncentiveCreateManyInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentiveId: Scalars['BigInt'];
  paymentStatus?: InputMaybe<Scalars['Int']>;
  refereeId: Scalars['BigInt'];
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserIncentiveCreateManyRefereeInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentiveId: Scalars['BigInt'];
  paymentStatus?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserIncentiveCreateManyRefereeInputEnvelope = {
  data: Array<UserIncentiveCreateManyRefereeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserIncentiveCreateManyUserInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentiveId: Scalars['BigInt'];
  paymentStatus?: InputMaybe<Scalars['Int']>;
  refereeId: Scalars['BigInt'];
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserIncentiveCreateManyUserInputEnvelope = {
  data: Array<UserIncentiveCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserIncentiveCreateNestedManyWithoutIncentiveInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutIncentiveInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutIncentiveInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyIncentiveInputEnvelope>;
};

export type UserIncentiveCreateNestedManyWithoutRefereeInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutRefereeInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutRefereeInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyRefereeInputEnvelope>;
};

export type UserIncentiveCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyUserInputEnvelope>;
};

export type UserIncentiveCreateOrConnectWithoutIncentiveInput = {
  create: UserIncentiveCreateWithoutIncentiveInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveCreateOrConnectWithoutRefereeInput = {
  create: UserIncentiveCreateWithoutRefereeInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveCreateOrConnectWithoutUserInput = {
  create: UserIncentiveCreateWithoutUserInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveCreateWithoutIncentiveInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee: UserCreateNestedOneWithoutRefereeIncentivesInput;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutReferrerIncentivesInput;
};

export type UserIncentiveCreateWithoutRefereeInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive: IncentiveCreateNestedOneWithoutUserIncentivesInput;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutReferrerIncentivesInput;
};

export type UserIncentiveCreateWithoutUserInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive: IncentiveCreateNestedOneWithoutUserIncentivesInput;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee: UserCreateNestedOneWithoutRefereeIncentivesInput;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type: IncentiveType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserIncentiveGroupBy = {
  __typename?: 'UserIncentiveGroupBy';
  _avg?: Maybe<UserIncentiveAvgAggregate>;
  _count?: Maybe<UserIncentiveCountAggregate>;
  _max?: Maybe<UserIncentiveMaxAggregate>;
  _min?: Maybe<UserIncentiveMinAggregate>;
  _sum?: Maybe<UserIncentiveSumAggregate>;
  amount: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  incentiveId: Scalars['BigInt'];
  paymentStatus: Scalars['Int'];
  refereeId: Scalars['BigInt'];
  reference?: Maybe<Scalars['JSON']>;
  remarks?: Maybe<Scalars['String']>;
  settlementId?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  type: IncentiveType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['BigInt'];
};

export type UserIncentiveListRelationFilter = {
  every?: InputMaybe<UserIncentiveWhereInput>;
  none?: InputMaybe<UserIncentiveWhereInput>;
  some?: InputMaybe<UserIncentiveWhereInput>;
};

export type UserIncentiveMaxAggregate = {
  __typename?: 'UserIncentiveMaxAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  incentiveId?: Maybe<Scalars['BigInt']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['BigInt']>;
  remarks?: Maybe<Scalars['String']>;
  settlementId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<IncentiveType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserIncentiveMaxOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  settlementId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveMinAggregate = {
  __typename?: 'UserIncentiveMinAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['BigInt']>;
  incentiveId?: Maybe<Scalars['BigInt']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['BigInt']>;
  remarks?: Maybe<Scalars['String']>;
  settlementId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  type?: Maybe<IncentiveType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserIncentiveMinOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  settlementId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserIncentiveOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserIncentiveAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserIncentiveCountOrderByAggregateInput>;
  _max?: InputMaybe<UserIncentiveMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserIncentiveMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserIncentiveSumOrderByAggregateInput>;
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  settlementId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentive?: InputMaybe<IncentiveOrderByWithRelationInput>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  referee?: InputMaybe<UserOrderByWithRelationInput>;
  refereeId?: InputMaybe<SortOrder>;
  reference?: InputMaybe<SortOrder>;
  remarks?: InputMaybe<SortOrder>;
  settlementId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserIncentiveScalarFieldEnum {
  Amount = 'amount',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Description = 'description',
  Id = 'id',
  IncentiveId = 'incentiveId',
  PaymentStatus = 'paymentStatus',
  RefereeId = 'refereeId',
  Reference = 'reference',
  Remarks = 'remarks',
  SettlementId = 'settlementId',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserIncentiveScalarWhereInput = {
  AND?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  OR?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  incentiveId?: InputMaybe<BigIntFilter>;
  paymentStatus?: InputMaybe<IntFilter>;
  refereeId?: InputMaybe<BigIntFilter>;
  reference?: InputMaybe<JsonNullableFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
  settlementId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumIncentiveTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserIncentiveScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserIncentiveScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserIncentiveScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserIncentiveScalarWhereWithAggregatesInput>>;
  amount?: InputMaybe<DecimalWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  currency?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  incentiveId?: InputMaybe<BigIntWithAggregatesFilter>;
  paymentStatus?: InputMaybe<IntWithAggregatesFilter>;
  refereeId?: InputMaybe<BigIntWithAggregatesFilter>;
  reference?: InputMaybe<JsonNullableWithAggregatesFilter>;
  remarks?: InputMaybe<StringNullableWithAggregatesFilter>;
  settlementId?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  type?: InputMaybe<EnumIncentiveTypeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<BigIntWithAggregatesFilter>;
};

export type UserIncentiveSumAggregate = {
  __typename?: 'UserIncentiveSumAggregate';
  amount?: Maybe<Scalars['Decimal']>;
  id?: Maybe<Scalars['BigInt']>;
  incentiveId?: Maybe<Scalars['BigInt']>;
  paymentStatus?: Maybe<Scalars['Int']>;
  refereeId?: Maybe<Scalars['BigInt']>;
  status?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserIncentiveSumOrderByAggregateInput = {
  amount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incentiveId?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  refereeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserIncentiveUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive?: InputMaybe<IncentiveUpdateOneRequiredWithoutUserIncentivesNestedInput>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee?: InputMaybe<UserUpdateOneRequiredWithoutRefereeIncentivesNestedInput>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReferrerIncentivesNestedInput>;
};

export type UserIncentiveUpdateManyMutationInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserIncentiveUpdateManyWithWhereWithoutIncentiveInput = {
  data: UserIncentiveUpdateManyMutationInput;
  where: UserIncentiveScalarWhereInput;
};

export type UserIncentiveUpdateManyWithWhereWithoutRefereeInput = {
  data: UserIncentiveUpdateManyMutationInput;
  where: UserIncentiveScalarWhereInput;
};

export type UserIncentiveUpdateManyWithWhereWithoutUserInput = {
  data: UserIncentiveUpdateManyMutationInput;
  where: UserIncentiveScalarWhereInput;
};

export type UserIncentiveUpdateManyWithoutIncentiveNestedInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutIncentiveInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutIncentiveInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyIncentiveInputEnvelope>;
  delete?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  set?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  update?: InputMaybe<Array<UserIncentiveUpdateWithWhereUniqueWithoutIncentiveInput>>;
  updateMany?: InputMaybe<Array<UserIncentiveUpdateManyWithWhereWithoutIncentiveInput>>;
  upsert?: InputMaybe<Array<UserIncentiveUpsertWithWhereUniqueWithoutIncentiveInput>>;
};

export type UserIncentiveUpdateManyWithoutRefereeNestedInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutRefereeInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutRefereeInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyRefereeInputEnvelope>;
  delete?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  set?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  update?: InputMaybe<Array<UserIncentiveUpdateWithWhereUniqueWithoutRefereeInput>>;
  updateMany?: InputMaybe<Array<UserIncentiveUpdateManyWithWhereWithoutRefereeInput>>;
  upsert?: InputMaybe<Array<UserIncentiveUpsertWithWhereUniqueWithoutRefereeInput>>;
};

export type UserIncentiveUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserIncentiveCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserIncentiveCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserIncentiveCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserIncentiveScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  set?: InputMaybe<Array<UserIncentiveWhereUniqueInput>>;
  update?: InputMaybe<Array<UserIncentiveUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserIncentiveUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserIncentiveUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserIncentiveUpdateWithWhereUniqueWithoutIncentiveInput = {
  data: UserIncentiveUpdateWithoutIncentiveInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveUpdateWithWhereUniqueWithoutRefereeInput = {
  data: UserIncentiveUpdateWithoutRefereeInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveUpdateWithWhereUniqueWithoutUserInput = {
  data: UserIncentiveUpdateWithoutUserInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveUpdateWithoutIncentiveInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee?: InputMaybe<UserUpdateOneRequiredWithoutRefereeIncentivesNestedInput>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReferrerIncentivesNestedInput>;
};

export type UserIncentiveUpdateWithoutRefereeInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive?: InputMaybe<IncentiveUpdateOneRequiredWithoutUserIncentivesNestedInput>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutReferrerIncentivesNestedInput>;
};

export type UserIncentiveUpdateWithoutUserInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
  incentive?: InputMaybe<IncentiveUpdateOneRequiredWithoutUserIncentivesNestedInput>;
  paymentStatus?: InputMaybe<Scalars['Int']>;
  referee?: InputMaybe<UserUpdateOneRequiredWithoutRefereeIncentivesNestedInput>;
  reference?: InputMaybe<Scalars['JSON']>;
  remarks?: InputMaybe<Scalars['String']>;
  settlementId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<IncentiveType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserIncentiveUpsertWithWhereUniqueWithoutIncentiveInput = {
  create: UserIncentiveCreateWithoutIncentiveInput;
  update: UserIncentiveUpdateWithoutIncentiveInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveUpsertWithWhereUniqueWithoutRefereeInput = {
  create: UserIncentiveCreateWithoutRefereeInput;
  update: UserIncentiveUpdateWithoutRefereeInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveUpsertWithWhereUniqueWithoutUserInput = {
  create: UserIncentiveCreateWithoutUserInput;
  update: UserIncentiveUpdateWithoutUserInput;
  where: UserIncentiveWhereUniqueInput;
};

export type UserIncentiveWhereInput = {
  AND?: InputMaybe<Array<UserIncentiveWhereInput>>;
  NOT?: InputMaybe<Array<UserIncentiveWhereInput>>;
  OR?: InputMaybe<Array<UserIncentiveWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  incentive?: InputMaybe<IncentiveRelationFilter>;
  incentiveId?: InputMaybe<BigIntFilter>;
  paymentStatus?: InputMaybe<IntFilter>;
  referee?: InputMaybe<UserRelationFilter>;
  refereeId?: InputMaybe<BigIntFilter>;
  reference?: InputMaybe<JsonNullableFilter>;
  remarks?: InputMaybe<StringNullableFilter>;
  settlementId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumIncentiveTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserIncentiveWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserInformationChangeRequest = {
  __typename?: 'UserInformationChangeRequest';
  byAdminId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['BigInt'];
  status: Scalars['Int'];
  type: UserInformationType;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
  value: Scalars['String'];
};

export type UserInformationChangeRequestCreateManyUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  type: UserInformationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type UserInformationChangeRequestCreateManyUserInputEnvelope = {
  data: Array<UserInformationChangeRequestCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserInformationChangeRequestCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserInformationChangeRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInformationChangeRequestCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserInformationChangeRequestCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserInformationChangeRequestCreateManyUserInputEnvelope>;
};

export type UserInformationChangeRequestCreateOrConnectWithoutUserInput = {
  create: UserInformationChangeRequestCreateWithoutUserInput;
  where: UserInformationChangeRequestWhereUniqueInput;
};

export type UserInformationChangeRequestCreateWithoutUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  type: UserInformationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['String'];
};

export type UserInformationChangeRequestListRelationFilter = {
  every?: InputMaybe<UserInformationChangeRequestWhereInput>;
  none?: InputMaybe<UserInformationChangeRequestWhereInput>;
  some?: InputMaybe<UserInformationChangeRequestWhereInput>;
};

export type UserInformationChangeRequestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserInformationChangeRequestOrderByWithRelationInput = {
  byAdminId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum UserInformationChangeRequestScalarFieldEnum {
  ByAdminId = 'byAdminId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  Value = 'value',
}

export type UserInformationChangeRequestScalarWhereInput = {
  AND?: InputMaybe<Array<UserInformationChangeRequestScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserInformationChangeRequestScalarWhereInput>>;
  OR?: InputMaybe<Array<UserInformationChangeRequestScalarWhereInput>>;
  byAdminId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumUserInformationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserInformationChangeRequestUpdateManyMutationInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UserInformationType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserInformationChangeRequestUpdateManyWithWhereWithoutUserInput = {
  data: UserInformationChangeRequestUpdateManyMutationInput;
  where: UserInformationChangeRequestScalarWhereInput;
};

export type UserInformationChangeRequestUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserInformationChangeRequestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInformationChangeRequestCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserInformationChangeRequestCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserInformationChangeRequestCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserInformationChangeRequestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserInformationChangeRequestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserInformationChangeRequestWhereUniqueInput>>;
  set?: InputMaybe<Array<UserInformationChangeRequestWhereUniqueInput>>;
  update?: InputMaybe<Array<UserInformationChangeRequestUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserInformationChangeRequestUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserInformationChangeRequestUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserInformationChangeRequestUpdateWithWhereUniqueWithoutUserInput = {
  data: UserInformationChangeRequestUpdateWithoutUserInput;
  where: UserInformationChangeRequestWhereUniqueInput;
};

export type UserInformationChangeRequestUpdateWithoutUserInput = {
  byAdminId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<UserInformationType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UserInformationChangeRequestUpsertWithWhereUniqueWithoutUserInput = {
  create: UserInformationChangeRequestCreateWithoutUserInput;
  update: UserInformationChangeRequestUpdateWithoutUserInput;
  where: UserInformationChangeRequestWhereUniqueInput;
};

export type UserInformationChangeRequestWhereInput = {
  AND?: InputMaybe<Array<UserInformationChangeRequestWhereInput>>;
  NOT?: InputMaybe<Array<UserInformationChangeRequestWhereInput>>;
  OR?: InputMaybe<Array<UserInformationChangeRequestWhereInput>>;
  byAdminId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumUserInformationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
  value?: InputMaybe<StringFilter>;
};

export type UserInformationChangeRequestWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export enum UserInformationType {
  FullName = 'FullName',
  Phone = 'Phone',
}

export type UserInterest = {
  __typename?: 'UserInterest';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  interestType: InterestType;
  interestTypeId: Scalars['BigInt'];
  percentage: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['BigInt'];
};

export type UserInterestCreateManyInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  percentage: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserInterestCreateManyInterestTypeInputEnvelope = {
  data: Array<UserInterestCreateManyInterestTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserInterestCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestTypeId: Scalars['BigInt'];
  percentage: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInterestCreateManyUserInputEnvelope = {
  data: Array<UserInterestCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserInterestCreateNestedManyWithoutInterestTypeInput = {
  connect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInterestCreateOrConnectWithoutInterestTypeInput>>;
  create?: InputMaybe<Array<UserInterestCreateWithoutInterestTypeInput>>;
  createMany?: InputMaybe<UserInterestCreateManyInterestTypeInputEnvelope>;
};

export type UserInterestCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInterestCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserInterestCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserInterestCreateManyUserInputEnvelope>;
};

export type UserInterestCreateOrConnectWithoutInterestTypeInput = {
  create: UserInterestCreateWithoutInterestTypeInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestCreateOrConnectWithoutUserInput = {
  create: UserInterestCreateWithoutUserInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestCreateWithoutInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  percentage: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserInterestsInput;
};

export type UserInterestCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestType: InterestTypeCreateNestedOneWithoutUserInterestsInput;
  percentage: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInterestListRelationFilter = {
  every?: InputMaybe<UserInterestWhereInput>;
  none?: InputMaybe<UserInterestWhereInput>;
  some?: InputMaybe<UserInterestWhereInput>;
};

export type UserInterestOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserInterestOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  interestType?: InputMaybe<InterestTypeOrderByWithRelationInput>;
  interestTypeId?: InputMaybe<SortOrder>;
  percentage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserInterestScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterestTypeId = 'interestTypeId',
  Percentage = 'percentage',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserInterestScalarWhereInput = {
  AND?: InputMaybe<Array<UserInterestScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserInterestScalarWhereInput>>;
  OR?: InputMaybe<Array<UserInterestScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  interestTypeId?: InputMaybe<BigIntFilter>;
  percentage?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserInterestUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  percentage?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInterestUpdateManyWithWhereWithoutInterestTypeInput = {
  data: UserInterestUpdateManyMutationInput;
  where: UserInterestScalarWhereInput;
};

export type UserInterestUpdateManyWithWhereWithoutUserInput = {
  data: UserInterestUpdateManyMutationInput;
  where: UserInterestScalarWhereInput;
};

export type UserInterestUpdateManyWithoutInterestTypeNestedInput = {
  connect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInterestCreateOrConnectWithoutInterestTypeInput>>;
  create?: InputMaybe<Array<UserInterestCreateWithoutInterestTypeInput>>;
  createMany?: InputMaybe<UserInterestCreateManyInterestTypeInputEnvelope>;
  delete?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserInterestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  set?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  update?: InputMaybe<Array<UserInterestUpdateWithWhereUniqueWithoutInterestTypeInput>>;
  updateMany?: InputMaybe<Array<UserInterestUpdateManyWithWhereWithoutInterestTypeInput>>;
  upsert?: InputMaybe<Array<UserInterestUpsertWithWhereUniqueWithoutInterestTypeInput>>;
};

export type UserInterestUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserInterestCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserInterestCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserInterestCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserInterestScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  set?: InputMaybe<Array<UserInterestWhereUniqueInput>>;
  update?: InputMaybe<Array<UserInterestUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserInterestUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserInterestUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserInterestUpdateWithWhereUniqueWithoutInterestTypeInput = {
  data: UserInterestUpdateWithoutInterestTypeInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestUpdateWithWhereUniqueWithoutUserInput = {
  data: UserInterestUpdateWithoutUserInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestUpdateWithoutInterestTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  percentage?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserInterestsNestedInput>;
};

export type UserInterestUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  interestType?: InputMaybe<InterestTypeUpdateOneRequiredWithoutUserInterestsNestedInput>;
  percentage?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserInterestUpsertWithWhereUniqueWithoutInterestTypeInput = {
  create: UserInterestCreateWithoutInterestTypeInput;
  update: UserInterestUpdateWithoutInterestTypeInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestUpsertWithWhereUniqueWithoutUserInput = {
  create: UserInterestCreateWithoutUserInput;
  update: UserInterestUpdateWithoutUserInput;
  where: UserInterestWhereUniqueInput;
};

export type UserInterestWhereInput = {
  AND?: InputMaybe<Array<UserInterestWhereInput>>;
  NOT?: InputMaybe<Array<UserInterestWhereInput>>;
  OR?: InputMaybe<Array<UserInterestWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  interestType?: InputMaybe<InterestTypeRelationFilter>;
  interestTypeId?: InputMaybe<BigIntFilter>;
  percentage?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserInterestWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserLeaderboardRanking = {
  __typename?: 'UserLeaderboardRanking';
  totalCerts: Scalars['Int'];
  user: User;
  userId: Scalars['BigInt'];
  userRanking?: Maybe<Scalars['Int']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserLiveClassRegistration = {
  __typename?: 'UserLiveClassRegistration';
  appVersion?: Maybe<Scalars['String']>;
  attended: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deviceId: Scalars['String'];
  deviceName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['Int'];
  introducer?: Maybe<Scalars['String']>;
  liveClass: LiveClass;
  liveClassId: Scalars['Int'];
  microCertStatus: Scalars['Boolean'];
  name: Scalars['String'];
  phoneNo?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  type: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserLiveClassRegistrationCreateManyLiveClassInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  attended?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId: Scalars['String'];
  deviceName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  introducer?: InputMaybe<Scalars['String']>;
  microCertStatus?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLiveClassRegistrationCreateManyLiveClassInputEnvelope = {
  data: Array<UserLiveClassRegistrationCreateManyLiveClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserLiveClassRegistrationCreateNestedManyWithoutLiveClassInput = {
  connect?: InputMaybe<Array<UserLiveClassRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLiveClassRegistrationCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<UserLiveClassRegistrationCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<UserLiveClassRegistrationCreateManyLiveClassInputEnvelope>;
};

export type UserLiveClassRegistrationCreateOrConnectWithoutLiveClassInput = {
  create: UserLiveClassRegistrationCreateWithoutLiveClassInput;
  where: UserLiveClassRegistrationWhereUniqueInput;
};

export type UserLiveClassRegistrationCreateWithoutLiveClassInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  attended?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId: Scalars['String'];
  deviceName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  introducer?: InputMaybe<Scalars['String']>;
  microCertStatus?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phoneNo?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLiveClassRegistrationListRelationFilter = {
  every?: InputMaybe<UserLiveClassRegistrationWhereInput>;
  none?: InputMaybe<UserLiveClassRegistrationWhereInput>;
  some?: InputMaybe<UserLiveClassRegistrationWhereInput>;
};

export type UserLiveClassRegistrationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserLiveClassRegistrationOrderByWithRelationInput = {
  appVersion?: InputMaybe<SortOrder>;
  attended?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deviceId?: InputMaybe<SortOrder>;
  deviceName?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  introducer?: InputMaybe<SortOrder>;
  liveClass?: InputMaybe<LiveClassOrderByWithRelationInput>;
  liveClassId?: InputMaybe<SortOrder>;
  microCertStatus?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  phoneNo?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum UserLiveClassRegistrationScalarFieldEnum {
  AppVersion = 'appVersion',
  Attended = 'attended',
  CreatedAt = 'createdAt',
  DeviceId = 'deviceId',
  DeviceName = 'deviceName',
  Email = 'email',
  Id = 'id',
  Introducer = 'introducer',
  LiveClassId = 'liveClassId',
  MicroCertStatus = 'microCertStatus',
  Name = 'name',
  PhoneNo = 'phoneNo',
  Platform = 'platform',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
}

export type UserLiveClassRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<UserLiveClassRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserLiveClassRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<UserLiveClassRegistrationScalarWhereInput>>;
  appVersion?: InputMaybe<StringNullableFilter>;
  attended?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  deviceId?: InputMaybe<StringFilter>;
  deviceName?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  introducer?: InputMaybe<StringNullableFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  microCertStatus?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNo?: InputMaybe<StringNullableFilter>;
  platform?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type UserLiveClassRegistrationUpdateManyMutationInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  attended?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  introducer?: InputMaybe<Scalars['String']>;
  microCertStatus?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLiveClassRegistrationUpdateManyWithWhereWithoutLiveClassInput = {
  data: UserLiveClassRegistrationUpdateManyMutationInput;
  where: UserLiveClassRegistrationScalarWhereInput;
};

export type UserLiveClassRegistrationUpdateManyWithoutLiveClassNestedInput = {
  connect?: InputMaybe<Array<UserLiveClassRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLiveClassRegistrationCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<UserLiveClassRegistrationCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<UserLiveClassRegistrationCreateManyLiveClassInputEnvelope>;
  delete?: InputMaybe<Array<UserLiveClassRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserLiveClassRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserLiveClassRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<UserLiveClassRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<UserLiveClassRegistrationUpdateWithWhereUniqueWithoutLiveClassInput>>;
  updateMany?: InputMaybe<Array<UserLiveClassRegistrationUpdateManyWithWhereWithoutLiveClassInput>>;
  upsert?: InputMaybe<Array<UserLiveClassRegistrationUpsertWithWhereUniqueWithoutLiveClassInput>>;
};

export type UserLiveClassRegistrationUpdateWithWhereUniqueWithoutLiveClassInput = {
  data: UserLiveClassRegistrationUpdateWithoutLiveClassInput;
  where: UserLiveClassRegistrationWhereUniqueInput;
};

export type UserLiveClassRegistrationUpdateWithoutLiveClassInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  attended?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  introducer?: InputMaybe<Scalars['String']>;
  microCertStatus?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNo?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLiveClassRegistrationUpsertWithWhereUniqueWithoutLiveClassInput = {
  create: UserLiveClassRegistrationCreateWithoutLiveClassInput;
  update: UserLiveClassRegistrationUpdateWithoutLiveClassInput;
  where: UserLiveClassRegistrationWhereUniqueInput;
};

export type UserLiveClassRegistrationWhereInput = {
  AND?: InputMaybe<Array<UserLiveClassRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<UserLiveClassRegistrationWhereInput>>;
  OR?: InputMaybe<Array<UserLiveClassRegistrationWhereInput>>;
  appVersion?: InputMaybe<StringNullableFilter>;
  attended?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  deviceId?: InputMaybe<StringFilter>;
  deviceName?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  introducer?: InputMaybe<StringNullableFilter>;
  liveClass?: InputMaybe<LiveClassRelationFilter>;
  liveClassId?: InputMaybe<IntFilter>;
  microCertStatus?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  phoneNo?: InputMaybe<StringNullableFilter>;
  platform?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  type?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type UserLiveClassRegistrationWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserLoginIp = {
  __typename?: 'UserLoginIp';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  ip: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['BigInt'];
};

export type UserLoginIpCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  ip: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLoginIpCreateManyUserInputEnvelope = {
  data: Array<UserLoginIpCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserLoginIpCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserLoginIpWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLoginIpCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLoginIpCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLoginIpCreateManyUserInputEnvelope>;
};

export type UserLoginIpCreateOrConnectWithoutUserInput = {
  create: UserLoginIpCreateWithoutUserInput;
  where: UserLoginIpWhereUniqueInput;
};

export type UserLoginIpCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ip: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLoginIpListRelationFilter = {
  every?: InputMaybe<UserLoginIpWhereInput>;
  none?: InputMaybe<UserLoginIpWhereInput>;
  some?: InputMaybe<UserLoginIpWhereInput>;
};

export type UserLoginIpOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserLoginIpOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ip?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserLoginIpScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  Ip = 'ip',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserLoginIpScalarWhereInput = {
  AND?: InputMaybe<Array<UserLoginIpScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserLoginIpScalarWhereInput>>;
  OR?: InputMaybe<Array<UserLoginIpScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ip?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserLoginIpUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ip?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLoginIpUpdateManyWithWhereWithoutUserInput = {
  data: UserLoginIpUpdateManyMutationInput;
  where: UserLoginIpScalarWhereInput;
};

export type UserLoginIpUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserLoginIpWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLoginIpCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLoginIpCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLoginIpCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserLoginIpWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserLoginIpScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserLoginIpWhereUniqueInput>>;
  set?: InputMaybe<Array<UserLoginIpWhereUniqueInput>>;
  update?: InputMaybe<Array<UserLoginIpUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserLoginIpUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserLoginIpUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserLoginIpUpdateWithWhereUniqueWithoutUserInput = {
  data: UserLoginIpUpdateWithoutUserInput;
  where: UserLoginIpWhereUniqueInput;
};

export type UserLoginIpUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ip?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserLoginIpUpsertWithWhereUniqueWithoutUserInput = {
  create: UserLoginIpCreateWithoutUserInput;
  update: UserLoginIpUpdateWithoutUserInput;
  where: UserLoginIpWhereUniqueInput;
};

export type UserLoginIpWhereInput = {
  AND?: InputMaybe<Array<UserLoginIpWhereInput>>;
  NOT?: InputMaybe<Array<UserLoginIpWhereInput>>;
  OR?: InputMaybe<Array<UserLoginIpWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  ip?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserLoginIpWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type UserNotificationSetting = {
  __typename?: 'UserNotificationSetting';
  emailNotification: Scalars['Boolean'];
  pushNotification: Scalars['Boolean'];
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  active?: InputMaybe<SortOrder>;
  badgeLevel?: InputMaybe<SortOrder>;
  certificates?: InputMaybe<CertificateOrderByRelationAggregateInput>;
  classRatings?: InputMaybe<ClassRatingOrderByRelationAggregateInput>;
  countryCode?: InputMaybe<SortOrder>;
  coverImage?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrder>;
  dcomToken?: InputMaybe<SortOrder>;
  dsmartIntroducer?: InputMaybe<SortOrder>;
  dsmartPosition?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerifiedAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  followedTeachers?: InputMaybe<TeacherFollowerOrderByRelationAggregateInput>;
  freeRegister?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  introducerId?: InputMaybe<SortOrder>;
  ipAddress?: InputMaybe<SortOrder>;
  isAdminBadge?: InputMaybe<SortOrder>;
  isRewardableReferral?: InputMaybe<SortOrder>;
  isSpecialIntroducer?: InputMaybe<SortOrder>;
  lastLoginAt?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  packageType?: InputMaybe<SortOrder>;
  partner?: InputMaybe<PartnerOrderByWithRelationInput>;
  password?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneVerifiedAt?: InputMaybe<SortOrder>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeOrderByRelationAggregateInput>;
  qrCode?: InputMaybe<SortOrder>;
  refereeIncentives?: InputMaybe<UserIncentiveOrderByRelationAggregateInput>;
  referralCode?: InputMaybe<SortOrder>;
  referralCodeStatus?: InputMaybe<SortOrder>;
  referralCodeTotalUsage?: InputMaybe<SortOrder>;
  referralCodeValidity?: InputMaybe<SortOrder>;
  referrerIncentives?: InputMaybe<UserIncentiveOrderByRelationAggregateInput>;
  rememberToken?: InputMaybe<SortOrder>;
  rewardAmount?: InputMaybe<SortOrder>;
  rewardStatus?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  socialIdentities?: InputMaybe<SocialIdentityOrderByRelationAggregateInput>;
  source?: InputMaybe<PartnerOrderByWithRelationInput>;
  sourceId?: InputMaybe<SortOrder>;
  sponsor?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subscribedAt?: InputMaybe<SortOrder>;
  tawkKey?: InputMaybe<SortOrder>;
  transactions?: InputMaybe<TransactionOrderByRelationAggregateInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogOrderByRelationAggregateInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestOrderByRelationAggregateInput>;
  userInterests?: InputMaybe<UserInterestOrderByRelationAggregateInput>;
  userLoginIps?: InputMaybe<UserLoginIpOrderByRelationAggregateInput>;
  userRank?: InputMaybe<SortOrder>;
  userSettings?: InputMaybe<UserSettingOrderByRelationAggregateInput>;
  userToken?: InputMaybe<SortOrder>;
  userType?: InputMaybe<SortOrder>;
  userVerticals?: InputMaybe<UserVerticalOrderByRelationAggregateInput>;
  username?: InputMaybe<SortOrder>;
};

export type UserProfileUpdateInput = {
  coverImage?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  Active = 'active',
  BadgeLevel = 'badgeLevel',
  CountryCode = 'countryCode',
  CoverImage = 'coverImage',
  CreatedAt = 'createdAt',
  DateOfBirth = 'dateOfBirth',
  DcomToken = 'dcomToken',
  DsmartIntroducer = 'dsmartIntroducer',
  DsmartPosition = 'dsmartPosition',
  Email = 'email',
  EmailVerifiedAt = 'emailVerifiedAt',
  ExpiredAt = 'expiredAt',
  FirstName = 'firstName',
  FreeRegister = 'freeRegister',
  Id = 'id',
  Image = 'image',
  IntroducerId = 'introducerId',
  IpAddress = 'ipAddress',
  IsAdminBadge = 'isAdminBadge',
  IsRewardableReferral = 'isRewardableReferral',
  IsSpecialIntroducer = 'isSpecialIntroducer',
  LastLoginAt = 'lastLoginAt',
  LastName = 'lastName',
  PackageType = 'packageType',
  Password = 'password',
  Phone = 'phone',
  PhoneVerifiedAt = 'phoneVerifiedAt',
  QrCode = 'qrCode',
  ReferralCode = 'referralCode',
  ReferralCodeStatus = 'referralCodeStatus',
  ReferralCodeTotalUsage = 'referralCodeTotalUsage',
  ReferralCodeValidity = 'referralCodeValidity',
  RememberToken = 'rememberToken',
  RewardAmount = 'rewardAmount',
  RewardStatus = 'rewardStatus',
  SessionToken = 'sessionToken',
  SourceId = 'sourceId',
  Sponsor = 'sponsor',
  Status = 'status',
  SubscribedAt = 'subscribedAt',
  TawkKey = 'tawkKey',
  UpdatedAt = 'updatedAt',
  UserRank = 'userRank',
  UserToken = 'userToken',
  UserType = 'userType',
  Username = 'username',
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  active?: InputMaybe<DateTimeNullableFilter>;
  badgeLevel?: InputMaybe<IntNullableFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  coverImage?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  dcomToken?: InputMaybe<StringNullableFilter>;
  dsmartIntroducer?: InputMaybe<StringNullableFilter>;
  dsmartPosition?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerifiedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  freeRegister?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  introducerId?: InputMaybe<IntNullableFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  isAdminBadge?: InputMaybe<StringNullableFilter>;
  isRewardableReferral?: InputMaybe<BoolFilter>;
  isSpecialIntroducer?: InputMaybe<IntFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  packageType?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  phoneVerifiedAt?: InputMaybe<DateTimeNullableFilter>;
  qrCode?: InputMaybe<StringNullableFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referralCodeStatus?: InputMaybe<BoolFilter>;
  referralCodeTotalUsage?: InputMaybe<IntFilter>;
  referralCodeValidity?: InputMaybe<IntNullableFilter>;
  rememberToken?: InputMaybe<StringNullableFilter>;
  rewardAmount?: InputMaybe<DecimalFilter>;
  rewardStatus?: InputMaybe<IntFilter>;
  sessionToken?: InputMaybe<StringNullableFilter>;
  sourceId?: InputMaybe<StringNullableFilter>;
  sponsor?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  subscribedAt?: InputMaybe<DateTimeNullableFilter>;
  tawkKey?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userRank?: InputMaybe<StringNullableFilter>;
  userToken?: InputMaybe<StringNullableFilter>;
  userType?: InputMaybe<IntNullableFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type UserSetting = {
  __typename?: 'UserSetting';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  pushNotification: Scalars['Int'];
  registerLimit: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['BigInt'];
};

export type UserSettingAvgAggregate = {
  __typename?: 'UserSettingAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  pushNotification?: Maybe<Scalars['Float']>;
  registerLimit?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

export type UserSettingAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingCountAggregate = {
  __typename?: 'UserSettingCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  id: Scalars['Int'];
  pushNotification: Scalars['Int'];
  registerLimit: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type UserSettingCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutUserSettingsInput;
};

export type UserSettingCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserSettingCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSettingCreateManyUserInputEnvelope = {
  data: Array<UserSettingCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserSettingCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSettingCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSettingCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSettingCreateManyUserInputEnvelope>;
};

export type UserSettingCreateOrConnectWithoutUserInput = {
  create: UserSettingCreateWithoutUserInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSettingGroupBy = {
  __typename?: 'UserSettingGroupBy';
  _avg?: Maybe<UserSettingAvgAggregate>;
  _count?: Maybe<UserSettingCountAggregate>;
  _max?: Maybe<UserSettingMaxAggregate>;
  _min?: Maybe<UserSettingMinAggregate>;
  _sum?: Maybe<UserSettingSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  pushNotification: Scalars['Int'];
  registerLimit: Scalars['BigInt'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserSettingListRelationFilter = {
  every?: InputMaybe<UserSettingWhereInput>;
  none?: InputMaybe<UserSettingWhereInput>;
  some?: InputMaybe<UserSettingWhereInput>;
};

export type UserSettingMaxAggregate = {
  __typename?: 'UserSettingMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  pushNotification?: Maybe<Scalars['Int']>;
  registerLimit?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserSettingMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingMinAggregate = {
  __typename?: 'UserSettingMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['BigInt']>;
  pushNotification?: Maybe<Scalars['Int']>;
  registerLimit?: Maybe<Scalars['BigInt']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserSettingMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserSettingOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserSettingAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserSettingCountOrderByAggregateInput>;
  _max?: InputMaybe<UserSettingMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserSettingMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSettingSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserSettingScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  PushNotification = 'pushNotification',
  RegisterLimit = 'registerLimit',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserSettingScalarWhereInput = {
  AND?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  OR?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  pushNotification?: InputMaybe<IntFilter>;
  registerLimit?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserSettingScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserSettingScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<BigIntWithAggregatesFilter>;
  pushNotification?: InputMaybe<IntWithAggregatesFilter>;
  registerLimit?: InputMaybe<BigIntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  userId?: InputMaybe<BigIntWithAggregatesFilter>;
};

export type UserSettingSumAggregate = {
  __typename?: 'UserSettingSumAggregate';
  id?: Maybe<Scalars['BigInt']>;
  pushNotification?: Maybe<Scalars['Int']>;
  registerLimit?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['BigInt']>;
};

export type UserSettingSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  pushNotification?: InputMaybe<SortOrder>;
  registerLimit?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type UserSettingUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutUserSettingsNestedInput>;
};

export type UserSettingUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSettingUpdateManyWithWhereWithoutUserInput = {
  data: UserSettingUpdateManyMutationInput;
  where: UserSettingScalarWhereInput;
};

export type UserSettingUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserSettingCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserSettingCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserSettingCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserSettingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  set?: InputMaybe<Array<UserSettingWhereUniqueInput>>;
  update?: InputMaybe<Array<UserSettingUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserSettingUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserSettingUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserSettingUpdateWithWhereUniqueWithoutUserInput = {
  data: UserSettingUpdateWithoutUserInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  pushNotification?: InputMaybe<Scalars['Int']>;
  registerLimit?: InputMaybe<Scalars['BigInt']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserSettingUpsertWithWhereUniqueWithoutUserInput = {
  create: UserSettingCreateWithoutUserInput;
  update: UserSettingUpdateWithoutUserInput;
  where: UserSettingWhereUniqueInput;
};

export type UserSettingWhereInput = {
  AND?: InputMaybe<Array<UserSettingWhereInput>>;
  NOT?: InputMaybe<Array<UserSettingWhereInput>>;
  OR?: InputMaybe<Array<UserSettingWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  pushNotification?: InputMaybe<IntFilter>;
  registerLimit?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type UserUpdateManyMutationInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qrCode?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userRank?: InputMaybe<Scalars['String']>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateManyWithWhereWithoutSourceInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutSourceNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSourceInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSourceInput>>;
  createMany?: InputMaybe<UserCreateManySourceInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutSourceInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutSourceInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutSourceInput>>;
};

export type UserUpdateOneRequiredWithoutCertificatesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCertificatesInput>;
  create?: InputMaybe<UserCreateWithoutCertificatesInput>;
  update?: InputMaybe<UserUpdateWithoutCertificatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutCertificatesInput>;
};

export type UserUpdateOneRequiredWithoutClassRatingsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClassRatingsInput>;
  create?: InputMaybe<UserCreateWithoutClassRatingsInput>;
  update?: InputMaybe<UserUpdateWithoutClassRatingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutClassRatingsInput>;
};

export type UserUpdateOneRequiredWithoutFollowedTeachersNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFollowedTeachersInput>;
  create?: InputMaybe<UserCreateWithoutFollowedTeachersInput>;
  update?: InputMaybe<UserUpdateWithoutFollowedTeachersInput>;
  upsert?: InputMaybe<UserUpsertWithoutFollowedTeachersInput>;
};

export type UserUpdateOneRequiredWithoutPartnerNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPartnerInput>;
  create?: InputMaybe<UserCreateWithoutPartnerInput>;
  update?: InputMaybe<UserUpdateWithoutPartnerInput>;
  upsert?: InputMaybe<UserUpsertWithoutPartnerInput>;
};

export type UserUpdateOneRequiredWithoutRefereeIncentivesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutRefereeIncentivesInput>;
  create?: InputMaybe<UserCreateWithoutRefereeIncentivesInput>;
  update?: InputMaybe<UserUpdateWithoutRefereeIncentivesInput>;
  upsert?: InputMaybe<UserUpsertWithoutRefereeIncentivesInput>;
};

export type UserUpdateOneRequiredWithoutReferrerIncentivesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutReferrerIncentivesInput>;
  create?: InputMaybe<UserCreateWithoutReferrerIncentivesInput>;
  update?: InputMaybe<UserUpdateWithoutReferrerIncentivesInput>;
  upsert?: InputMaybe<UserUpsertWithoutReferrerIncentivesInput>;
};

export type UserUpdateOneRequiredWithoutTransactionsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTransactionsInput>;
  create?: InputMaybe<UserCreateWithoutTransactionsInput>;
  update?: InputMaybe<UserUpdateWithoutTransactionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutTransactionsInput>;
};

export type UserUpdateOneRequiredWithoutUserInterestsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserInterestsInput>;
  create?: InputMaybe<UserCreateWithoutUserInterestsInput>;
  update?: InputMaybe<UserUpdateWithoutUserInterestsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserInterestsInput>;
};

export type UserUpdateOneRequiredWithoutUserSettingsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserSettingsInput>;
  create?: InputMaybe<UserCreateWithoutUserSettingsInput>;
  update?: InputMaybe<UserUpdateWithoutUserSettingsInput>;
  upsert?: InputMaybe<UserUpsertWithoutUserSettingsInput>;
};

export type UserUpdateWithWhereUniqueWithoutSourceInput = {
  data: UserUpdateWithoutSourceInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCertificatesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutClassRatingsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutFollowedTeachersInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutPartnerInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutRefereeIncentivesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutReferrerIncentivesInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutSourceInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutTransactionsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutUserInterestsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userSettings?: InputMaybe<UserSettingUpdateManyWithoutUserNestedInput>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithoutUserSettingsInput = {
  active?: InputMaybe<Scalars['DateTime']>;
  badgeLevel?: InputMaybe<Scalars['Int']>;
  certificates?: InputMaybe<CertificateUpdateManyWithoutUserNestedInput>;
  classRatings?: InputMaybe<ClassRatingUpdateManyWithoutUserNestedInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  dcomToken?: InputMaybe<Scalars['String']>;
  dsmartIntroducer?: InputMaybe<Scalars['String']>;
  dsmartPosition?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  followedTeachers?: InputMaybe<TeacherFollowerUpdateManyWithoutUserNestedInput>;
  freeRegister?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['BigInt']>;
  image?: InputMaybe<Scalars['String']>;
  introducerId?: InputMaybe<Scalars['Int']>;
  ipAddress?: InputMaybe<Scalars['String']>;
  isAdminBadge?: InputMaybe<Scalars['String']>;
  isRewardableReferral?: InputMaybe<Scalars['Boolean']>;
  isSpecialIntroducer?: InputMaybe<Scalars['Int']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']>;
  lastName?: InputMaybe<Scalars['String']>;
  packageType?: InputMaybe<Scalars['String']>;
  partner?: InputMaybe<PartnerUpdateOneWithoutUserNestedInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerifiedAt?: InputMaybe<Scalars['DateTime']>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeUpdateManyWithoutUserNestedInput>;
  qrCode?: InputMaybe<Scalars['String']>;
  refereeIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutRefereeNestedInput>;
  referralCode?: InputMaybe<Scalars['String']>;
  referralCodeStatus?: InputMaybe<Scalars['Boolean']>;
  referralCodeTotalUsage?: InputMaybe<Scalars['Int']>;
  referralCodeValidity?: InputMaybe<Scalars['Int']>;
  referrerIncentives?: InputMaybe<UserIncentiveUpdateManyWithoutUserNestedInput>;
  rememberToken?: InputMaybe<Scalars['String']>;
  rewardAmount?: InputMaybe<Scalars['Decimal']>;
  rewardStatus?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['String']>;
  socialIdentities?: InputMaybe<SocialIdentityUpdateManyWithoutUserNestedInput>;
  source?: InputMaybe<PartnerUpdateOneWithoutUsersNestedInput>;
  sponsor?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  subscribedAt?: InputMaybe<Scalars['DateTime']>;
  tawkKey?: InputMaybe<Scalars['String']>;
  transactions?: InputMaybe<TransactionUpdateManyWithoutUserNestedInput>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailUpdateManyWithoutUserNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogUpdateManyWithoutUserNestedInput>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestUpdateManyWithoutUserNestedInput>;
  userInterests?: InputMaybe<UserInterestUpdateManyWithoutUserNestedInput>;
  userLoginIps?: InputMaybe<UserLoginIpUpdateManyWithoutUserNestedInput>;
  userRank?: InputMaybe<Scalars['String']>;
  userToken?: InputMaybe<Scalars['String']>;
  userType?: InputMaybe<Scalars['Int']>;
  userVerticals?: InputMaybe<UserVerticalUpdateManyWithoutUserNestedInput>;
  username?: InputMaybe<Scalars['String']>;
};

export type UserUpsertWithWhereUniqueWithoutSourceInput = {
  create: UserCreateWithoutSourceInput;
  update: UserUpdateWithoutSourceInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutCertificatesInput = {
  create: UserCreateWithoutCertificatesInput;
  update: UserUpdateWithoutCertificatesInput;
};

export type UserUpsertWithoutClassRatingsInput = {
  create: UserCreateWithoutClassRatingsInput;
  update: UserUpdateWithoutClassRatingsInput;
};

export type UserUpsertWithoutFollowedTeachersInput = {
  create: UserCreateWithoutFollowedTeachersInput;
  update: UserUpdateWithoutFollowedTeachersInput;
};

export type UserUpsertWithoutPartnerInput = {
  create: UserCreateWithoutPartnerInput;
  update: UserUpdateWithoutPartnerInput;
};

export type UserUpsertWithoutRefereeIncentivesInput = {
  create: UserCreateWithoutRefereeIncentivesInput;
  update: UserUpdateWithoutRefereeIncentivesInput;
};

export type UserUpsertWithoutReferrerIncentivesInput = {
  create: UserCreateWithoutReferrerIncentivesInput;
  update: UserUpdateWithoutReferrerIncentivesInput;
};

export type UserUpsertWithoutTransactionsInput = {
  create: UserCreateWithoutTransactionsInput;
  update: UserUpdateWithoutTransactionsInput;
};

export type UserUpsertWithoutUserInterestsInput = {
  create: UserCreateWithoutUserInterestsInput;
  update: UserUpdateWithoutUserInterestsInput;
};

export type UserUpsertWithoutUserSettingsInput = {
  create: UserCreateWithoutUserSettingsInput;
  update: UserUpdateWithoutUserSettingsInput;
};

export type UserVertical = {
  __typename?: 'UserVertical';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
  userId: Scalars['BigInt'];
  verticalIds?: Maybe<Scalars['JSON']>;
};

export type UserVerticalCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verticalIds?: InputMaybe<Scalars['JSON']>;
};

export type UserVerticalCreateManyUserInputEnvelope = {
  data: Array<UserVerticalCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserVerticalCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserVerticalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserVerticalCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserVerticalCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserVerticalCreateManyUserInputEnvelope>;
};

export type UserVerticalCreateOrConnectWithoutUserInput = {
  create: UserVerticalCreateWithoutUserInput;
  where: UserVerticalWhereUniqueInput;
};

export type UserVerticalCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verticalIds?: InputMaybe<Scalars['JSON']>;
};

export type UserVerticalListRelationFilter = {
  every?: InputMaybe<UserVerticalWhereInput>;
  none?: InputMaybe<UserVerticalWhereInput>;
  some?: InputMaybe<UserVerticalWhereInput>;
};

export type UserVerticalOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserVerticalOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
  verticalIds?: InputMaybe<SortOrder>;
};

export enum UserVerticalScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  VerticalIds = 'verticalIds',
}

export type UserVerticalScalarWhereInput = {
  AND?: InputMaybe<Array<UserVerticalScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserVerticalScalarWhereInput>>;
  OR?: InputMaybe<Array<UserVerticalScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
  verticalIds?: InputMaybe<JsonNullableFilter>;
};

export type UserVerticalUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verticalIds?: InputMaybe<Scalars['JSON']>;
};

export type UserVerticalUpdateManyWithWhereWithoutUserInput = {
  data: UserVerticalUpdateManyMutationInput;
  where: UserVerticalScalarWhereInput;
};

export type UserVerticalUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserVerticalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserVerticalCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserVerticalCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserVerticalCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserVerticalWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserVerticalScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserVerticalWhereUniqueInput>>;
  set?: InputMaybe<Array<UserVerticalWhereUniqueInput>>;
  update?: InputMaybe<Array<UserVerticalUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserVerticalUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserVerticalUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserVerticalUpdateWithWhereUniqueWithoutUserInput = {
  data: UserVerticalUpdateWithoutUserInput;
  where: UserVerticalWhereUniqueInput;
};

export type UserVerticalUpdateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  verticalIds?: InputMaybe<Scalars['JSON']>;
};

export type UserVerticalUpsertWithWhereUniqueWithoutUserInput = {
  create: UserVerticalCreateWithoutUserInput;
  update: UserVerticalUpdateWithoutUserInput;
  where: UserVerticalWhereUniqueInput;
};

export type UserVerticalWhereInput = {
  AND?: InputMaybe<Array<UserVerticalWhereInput>>;
  NOT?: InputMaybe<Array<UserVerticalWhereInput>>;
  OR?: InputMaybe<Array<UserVerticalWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<BigIntFilter>;
  verticalIds?: InputMaybe<JsonNullableFilter>;
};

export type UserVerticalWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistory = {
  __typename?: 'UserViewHistory';
  class?: Maybe<NormalClass>;
  classId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['BigInt'];
  library?: Maybe<Library>;
  libraryId?: Maybe<Scalars['Int']>;
  liveClass?: Maybe<LiveClass>;
  liveClassId?: Maybe<Scalars['Int']>;
  podcast?: Maybe<Podcast>;
  podcastId?: Maybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateManyClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  libraryId?: InputMaybe<Scalars['Int']>;
  liveClassId?: InputMaybe<Scalars['Int']>;
  podcastId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateManyClassInputEnvelope = {
  data: Array<UserViewHistoryCreateManyClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewHistoryCreateManyLibraryInput = {
  classId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClassId?: InputMaybe<Scalars['Int']>;
  podcastId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateManyLibraryInputEnvelope = {
  data: Array<UserViewHistoryCreateManyLibraryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewHistoryCreateManyLiveClassInput = {
  classId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  libraryId?: InputMaybe<Scalars['Int']>;
  podcastId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateManyLiveClassInputEnvelope = {
  data: Array<UserViewHistoryCreateManyLiveClassInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewHistoryCreateManyPodcastInput = {
  classId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  libraryId?: InputMaybe<Scalars['Int']>;
  liveClassId?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateManyPodcastInputEnvelope = {
  data: Array<UserViewHistoryCreateManyPodcastInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserViewHistoryCreateNestedManyWithoutClassInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutClassInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyClassInputEnvelope>;
};

export type UserViewHistoryCreateNestedManyWithoutLibraryInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutLibraryInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutLibraryInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyLibraryInputEnvelope>;
};

export type UserViewHistoryCreateNestedManyWithoutLiveClassInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyLiveClassInputEnvelope>;
};

export type UserViewHistoryCreateNestedManyWithoutPodcastInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyPodcastInputEnvelope>;
};

export type UserViewHistoryCreateOrConnectWithoutClassInput = {
  create: UserViewHistoryCreateWithoutClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryCreateOrConnectWithoutLibraryInput = {
  create: UserViewHistoryCreateWithoutLibraryInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryCreateOrConnectWithoutLiveClassInput = {
  create: UserViewHistoryCreateWithoutLiveClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryCreateOrConnectWithoutPodcastInput = {
  create: UserViewHistoryCreateWithoutPodcastInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryCreateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassCreateNestedOneWithoutLibraryViewedInput>;
  liveClass?: InputMaybe<LiveClassCreateNestedOneWithoutUserViewHistoriesInput>;
  podcast?: InputMaybe<PodcastCreateNestedOneWithoutUserViewHistoriesInput>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateWithoutLibraryInput = {
  class?: InputMaybe<NormalClassCreateNestedOneWithoutClassViewedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClass?: InputMaybe<LiveClassCreateNestedOneWithoutUserViewHistoriesInput>;
  podcast?: InputMaybe<PodcastCreateNestedOneWithoutUserViewHistoriesInput>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateWithoutLiveClassInput = {
  class?: InputMaybe<NormalClassCreateNestedOneWithoutClassViewedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassCreateNestedOneWithoutLibraryViewedInput>;
  podcast?: InputMaybe<PodcastCreateNestedOneWithoutUserViewHistoriesInput>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryCreateWithoutPodcastInput = {
  class?: InputMaybe<NormalClassCreateNestedOneWithoutClassViewedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassCreateNestedOneWithoutLibraryViewedInput>;
  liveClass?: InputMaybe<LiveClassCreateNestedOneWithoutUserViewHistoriesInput>;
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryListRelationFilter = {
  every?: InputMaybe<UserViewHistoryWhereInput>;
  none?: InputMaybe<UserViewHistoryWhereInput>;
  some?: InputMaybe<UserViewHistoryWhereInput>;
};

export type UserViewHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserViewHistoryOrderByWithRelationInput = {
  class?: InputMaybe<NormalClassOrderByWithRelationInput>;
  classId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  library?: InputMaybe<NormalClassOrderByWithRelationInput>;
  libraryId?: InputMaybe<SortOrder>;
  liveClass?: InputMaybe<LiveClassOrderByWithRelationInput>;
  liveClassId?: InputMaybe<SortOrder>;
  podcast?: InputMaybe<PodcastOrderByWithRelationInput>;
  podcastId?: InputMaybe<SortOrder>;
  targetId?: InputMaybe<SortOrder>;
  targetType?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserViewHistoryScalarFieldEnum {
  ClassId = 'classId',
  CreatedAt = 'createdAt',
  Id = 'id',
  LibraryId = 'libraryId',
  LiveClassId = 'liveClassId',
  PodcastId = 'podcastId',
  TargetId = 'targetId',
  TargetType = 'targetType',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
}

export type UserViewHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  classId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  libraryId?: InputMaybe<IntNullableFilter>;
  liveClassId?: InputMaybe<IntNullableFilter>;
  podcastId?: InputMaybe<IntNullableFilter>;
  targetId?: InputMaybe<IntFilter>;
  targetType?: InputMaybe<EnumViewTargetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserViewHistoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<ViewTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistoryUpdateManyWithWhereWithoutClassInput = {
  data: UserViewHistoryUpdateManyMutationInput;
  where: UserViewHistoryScalarWhereInput;
};

export type UserViewHistoryUpdateManyWithWhereWithoutLibraryInput = {
  data: UserViewHistoryUpdateManyMutationInput;
  where: UserViewHistoryScalarWhereInput;
};

export type UserViewHistoryUpdateManyWithWhereWithoutLiveClassInput = {
  data: UserViewHistoryUpdateManyMutationInput;
  where: UserViewHistoryScalarWhereInput;
};

export type UserViewHistoryUpdateManyWithWhereWithoutPodcastInput = {
  data: UserViewHistoryUpdateManyMutationInput;
  where: UserViewHistoryScalarWhereInput;
};

export type UserViewHistoryUpdateManyWithoutClassNestedInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutClassInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutClassInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyClassInputEnvelope>;
  delete?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewHistoryUpdateWithWhereUniqueWithoutClassInput>>;
  updateMany?: InputMaybe<Array<UserViewHistoryUpdateManyWithWhereWithoutClassInput>>;
  upsert?: InputMaybe<Array<UserViewHistoryUpsertWithWhereUniqueWithoutClassInput>>;
};

export type UserViewHistoryUpdateManyWithoutLibraryNestedInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutLibraryInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutLibraryInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyLibraryInputEnvelope>;
  delete?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewHistoryUpdateWithWhereUniqueWithoutLibraryInput>>;
  updateMany?: InputMaybe<Array<UserViewHistoryUpdateManyWithWhereWithoutLibraryInput>>;
  upsert?: InputMaybe<Array<UserViewHistoryUpsertWithWhereUniqueWithoutLibraryInput>>;
};

export type UserViewHistoryUpdateManyWithoutLiveClassNestedInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutLiveClassInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutLiveClassInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyLiveClassInputEnvelope>;
  delete?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewHistoryUpdateWithWhereUniqueWithoutLiveClassInput>>;
  updateMany?: InputMaybe<Array<UserViewHistoryUpdateManyWithWhereWithoutLiveClassInput>>;
  upsert?: InputMaybe<Array<UserViewHistoryUpsertWithWhereUniqueWithoutLiveClassInput>>;
};

export type UserViewHistoryUpdateManyWithoutPodcastNestedInput = {
  connect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserViewHistoryCreateOrConnectWithoutPodcastInput>>;
  create?: InputMaybe<Array<UserViewHistoryCreateWithoutPodcastInput>>;
  createMany?: InputMaybe<UserViewHistoryCreateManyPodcastInputEnvelope>;
  delete?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserViewHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<UserViewHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<UserViewHistoryUpdateWithWhereUniqueWithoutPodcastInput>>;
  updateMany?: InputMaybe<Array<UserViewHistoryUpdateManyWithWhereWithoutPodcastInput>>;
  upsert?: InputMaybe<Array<UserViewHistoryUpsertWithWhereUniqueWithoutPodcastInput>>;
};

export type UserViewHistoryUpdateWithWhereUniqueWithoutClassInput = {
  data: UserViewHistoryUpdateWithoutClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpdateWithWhereUniqueWithoutLibraryInput = {
  data: UserViewHistoryUpdateWithoutLibraryInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpdateWithWhereUniqueWithoutLiveClassInput = {
  data: UserViewHistoryUpdateWithoutLiveClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpdateWithWhereUniqueWithoutPodcastInput = {
  data: UserViewHistoryUpdateWithoutPodcastInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpdateWithoutClassInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassUpdateOneWithoutLibraryViewedNestedInput>;
  liveClass?: InputMaybe<LiveClassUpdateOneWithoutUserViewHistoriesNestedInput>;
  podcast?: InputMaybe<PodcastUpdateOneWithoutUserViewHistoriesNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<ViewTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistoryUpdateWithoutLibraryInput = {
  class?: InputMaybe<NormalClassUpdateOneWithoutClassViewedNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  liveClass?: InputMaybe<LiveClassUpdateOneWithoutUserViewHistoriesNestedInput>;
  podcast?: InputMaybe<PodcastUpdateOneWithoutUserViewHistoriesNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<ViewTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistoryUpdateWithoutLiveClassInput = {
  class?: InputMaybe<NormalClassUpdateOneWithoutClassViewedNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassUpdateOneWithoutLibraryViewedNestedInput>;
  podcast?: InputMaybe<PodcastUpdateOneWithoutUserViewHistoriesNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<ViewTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistoryUpdateWithoutPodcastInput = {
  class?: InputMaybe<NormalClassUpdateOneWithoutClassViewedNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['BigInt']>;
  library?: InputMaybe<NormalClassUpdateOneWithoutLibraryViewedNestedInput>;
  liveClass?: InputMaybe<LiveClassUpdateOneWithoutUserViewHistoriesNestedInput>;
  targetId?: InputMaybe<Scalars['Int']>;
  targetType?: InputMaybe<ViewTargetType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['BigInt']>;
};

export type UserViewHistoryUpsertWithWhereUniqueWithoutClassInput = {
  create: UserViewHistoryCreateWithoutClassInput;
  update: UserViewHistoryUpdateWithoutClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpsertWithWhereUniqueWithoutLibraryInput = {
  create: UserViewHistoryCreateWithoutLibraryInput;
  update: UserViewHistoryUpdateWithoutLibraryInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpsertWithWhereUniqueWithoutLiveClassInput = {
  create: UserViewHistoryCreateWithoutLiveClassInput;
  update: UserViewHistoryUpdateWithoutLiveClassInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUpsertWithWhereUniqueWithoutPodcastInput = {
  create: UserViewHistoryCreateWithoutPodcastInput;
  update: UserViewHistoryUpdateWithoutPodcastInput;
  where: UserViewHistoryWhereUniqueInput;
};

export type UserViewHistoryUserIdTargetIdTargetTypeCompoundUniqueInput = {
  targetId: Scalars['Int'];
  targetType: ViewTargetType;
  userId: Scalars['BigInt'];
};

export type UserViewHistoryWhereInput = {
  AND?: InputMaybe<Array<UserViewHistoryWhereInput>>;
  NOT?: InputMaybe<Array<UserViewHistoryWhereInput>>;
  OR?: InputMaybe<Array<UserViewHistoryWhereInput>>;
  class?: InputMaybe<NormalClassRelationFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  library?: InputMaybe<NormalClassRelationFilter>;
  libraryId?: InputMaybe<IntNullableFilter>;
  liveClass?: InputMaybe<LiveClassRelationFilter>;
  liveClassId?: InputMaybe<IntNullableFilter>;
  podcast?: InputMaybe<PodcastRelationFilter>;
  podcastId?: InputMaybe<IntNullableFilter>;
  targetId?: InputMaybe<IntFilter>;
  targetType?: InputMaybe<EnumViewTargetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type UserViewHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
  userId_targetId_targetType?: InputMaybe<UserViewHistoryUserIdTargetIdTargetTypeCompoundUniqueInput>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  active?: InputMaybe<DateTimeNullableFilter>;
  badgeLevel?: InputMaybe<IntNullableFilter>;
  certificates?: InputMaybe<CertificateListRelationFilter>;
  classRatings?: InputMaybe<ClassRatingListRelationFilter>;
  countryCode?: InputMaybe<StringNullableFilter>;
  coverImage?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  dcomToken?: InputMaybe<StringNullableFilter>;
  dsmartIntroducer?: InputMaybe<StringNullableFilter>;
  dsmartPosition?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerifiedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  followedTeachers?: InputMaybe<TeacherFollowerListRelationFilter>;
  freeRegister?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<BigIntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  introducerId?: InputMaybe<IntNullableFilter>;
  ipAddress?: InputMaybe<StringNullableFilter>;
  isAdminBadge?: InputMaybe<StringNullableFilter>;
  isRewardableReferral?: InputMaybe<BoolFilter>;
  isSpecialIntroducer?: InputMaybe<IntFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  packageType?: InputMaybe<StringNullableFilter>;
  partner?: InputMaybe<PartnerRelationFilter>;
  password?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  phoneVerifiedAt?: InputMaybe<DateTimeNullableFilter>;
  qRCodeChallenges?: InputMaybe<QrCodeChallengeListRelationFilter>;
  qrCode?: InputMaybe<StringNullableFilter>;
  refereeIncentives?: InputMaybe<UserIncentiveListRelationFilter>;
  referralCode?: InputMaybe<StringNullableFilter>;
  referralCodeStatus?: InputMaybe<BoolFilter>;
  referralCodeTotalUsage?: InputMaybe<IntFilter>;
  referralCodeValidity?: InputMaybe<IntNullableFilter>;
  referrerIncentives?: InputMaybe<UserIncentiveListRelationFilter>;
  rememberToken?: InputMaybe<StringNullableFilter>;
  rewardAmount?: InputMaybe<DecimalFilter>;
  rewardStatus?: InputMaybe<IntFilter>;
  sessionToken?: InputMaybe<StringNullableFilter>;
  socialIdentities?: InputMaybe<SocialIdentityListRelationFilter>;
  source?: InputMaybe<PartnerRelationFilter>;
  sourceId?: InputMaybe<StringNullableFilter>;
  sponsor?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  subscribedAt?: InputMaybe<DateTimeNullableFilter>;
  tawkKey?: InputMaybe<StringNullableFilter>;
  transactions?: InputMaybe<TransactionListRelationFilter>;
  unsubscribeEmails?: InputMaybe<UnsubscribeEmailListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userDeactivatedLogs?: InputMaybe<UserDeactivatedLogListRelationFilter>;
  userInformationChangeRequests?: InputMaybe<UserInformationChangeRequestListRelationFilter>;
  userInterests?: InputMaybe<UserInterestListRelationFilter>;
  userLoginIps?: InputMaybe<UserLoginIpListRelationFilter>;
  userRank?: InputMaybe<StringNullableFilter>;
  userSettings?: InputMaybe<UserSettingListRelationFilter>;
  userToken?: InputMaybe<StringNullableFilter>;
  userType?: InputMaybe<IntNullableFilter>;
  userVerticals?: InputMaybe<UserVerticalListRelationFilter>;
  username?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export enum VerificationPurpose {
  PasswordReset = 'PasswordReset',
  Registration = 'Registration',
}

export type VerifyOtpInput = {
  email: Scalars['String'];
  isResend?: InputMaybe<Scalars['Boolean']>;
  otpCode: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  purpose: VerificationPurpose;
  username?: InputMaybe<Scalars['String']>;
};

export type Vertical = {
  __typename?: 'Vertical';
  _count?: Maybe<VerticalCount>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hasPreferred: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  interestTypes: Array<InterestType>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sortValue: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerticalInterestTypesArgs = {
  cursor?: InputMaybe<InterestTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<InterestTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<InterestTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InterestTypeWhereInput>;
};

export type VerticalAvgAggregate = {
  __typename?: 'VerticalAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  sortValue?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

export type VerticalAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VerticalCount = {
  __typename?: 'VerticalCount';
  interestTypes: Scalars['Int'];
};

export type VerticalCountAggregate = {
  __typename?: 'VerticalCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  customLink: Scalars['Int'];
  description: Scalars['Int'];
  icon: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  name: Scalars['Int'];
  slug: Scalars['Int'];
  sortValue: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VerticalCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerticalCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  interestTypes?: InputMaybe<InterestTypeCreateNestedManyWithoutVerticalInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalCreateNestedOneWithoutInterestTypesInput = {
  connect?: InputMaybe<VerticalWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VerticalCreateOrConnectWithoutInterestTypesInput>;
  create?: InputMaybe<VerticalCreateWithoutInterestTypesInput>;
};

export type VerticalCreateOrConnectWithoutInterestTypesInput = {
  create: VerticalCreateWithoutInterestTypesInput;
  where: VerticalWhereUniqueInput;
};

export type VerticalCreateWithoutInterestTypesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalGroupBy = {
  __typename?: 'VerticalGroupBy';
  _avg?: Maybe<VerticalAvgAggregate>;
  _count?: Maybe<VerticalCountAggregate>;
  _max?: Maybe<VerticalMaxAggregate>;
  _min?: Maybe<VerticalMinAggregate>;
  _sum?: Maybe<VerticalSumAggregate>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  sortValue: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerticalLibraryWhereInput = {
  AND?: InputMaybe<Array<NormalClassWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassWhereInput>>;
  OR?: InputMaybe<Array<NormalClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  classViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  classesBookmarks?: InputMaybe<ClassesBookmarksListRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  libraryBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  libraryViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  likedBy?: InputMaybe<NormalClassLikeListRelationFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verticalId: Scalars['Int'];
  videoRepoId?: InputMaybe<StringFilter>;
};

export type VerticalLiveClassWhereInput = {
  AND?: InputMaybe<Array<LiveClassWhereInput>>;
  NOT?: InputMaybe<Array<LiveClassWhereInput>>;
  OR?: InputMaybe<Array<LiveClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  certPrice?: InputMaybe<DecimalFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classId?: InputMaybe<IntNullableFilter>;
  classType?: InputMaybe<EnumLiveClassTypeFilter>;
  closeTime?: InputMaybe<DateTimeNullableFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createBy?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customDuration?: InputMaybe<IntFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  durationTime?: InputMaybe<IntFilter>;
  enableFree?: InputMaybe<IntFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringFilter>;
  isCertFree?: InputMaybe<IntFilter>;
  issuedCertificates?: InputMaybe<CertificateListRelationFilter>;
  liveClassRegistrations?: InputMaybe<UserLiveClassRegistrationListRelationFilter>;
  liveClassTags?: InputMaybe<LiveClassTaggingListRelationFilter>;
  materialIds?: InputMaybe<StringNullableFilter>;
  payPerView?: InputMaybe<IntFilter>;
  payPerViewAmount?: InputMaybe<FloatFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  previewId?: InputMaybe<IntNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  roomId?: InputMaybe<StringFilter>;
  roomPasscode?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  specialEvent?: InputMaybe<BoolNullableFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
  verticalId: Scalars['Int'];
  videoEmbed?: InputMaybe<StringNullableFilter>;
};

export type VerticalMaxAggregate = {
  __typename?: 'VerticalMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sortValue?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerticalMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerticalMinAggregate = {
  __typename?: 'VerticalMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  customLink?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  sortValue?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerticalMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerticalNormalClassWhereInput = {
  AND?: InputMaybe<Array<NormalClassWhereInput>>;
  NOT?: InputMaybe<Array<NormalClassWhereInput>>;
  OR?: InputMaybe<Array<NormalClassWhereInput>>;
  adminRating?: InputMaybe<IntFilter>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  classBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  classTags?: InputMaybe<ClassTaggingListRelationFilter>;
  classViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  classesBookmarks?: InputMaybe<ClassesBookmarksListRelationFilter>;
  coach?: InputMaybe<CoachRelationFilter>;
  coachId?: InputMaybe<IntNullableFilter>;
  contentType?: InputMaybe<IntFilter>;
  courseId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  enableFree?: InputMaybe<IntFilter>;
  highlight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableFilter>;
  libraryBookmarked?: InputMaybe<UserBookmarkListRelationFilter>;
  libraryViewed?: InputMaybe<UserViewHistoryListRelationFilter>;
  likedBy?: InputMaybe<NormalClassLikeListRelationFilter>;
  materialId?: InputMaybe<StringFilter>;
  missionStatus?: InputMaybe<IntFilter>;
  missionTime?: InputMaybe<IntFilter>;
  portraitImages?: InputMaybe<StringNullableFilter>;
  rating?: InputMaybe<IntNullableFilter>;
  sequence?: InputMaybe<IntFilter>;
  shortDescription?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  tagId?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalDuration?: InputMaybe<IntFilter>;
  totalShare?: InputMaybe<BigIntFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  verticalId: Scalars['Int'];
  videoRepoId?: InputMaybe<StringFilter>;
};

export type VerticalOrderByWithAggregationInput = {
  _avg?: InputMaybe<VerticalAvgOrderByAggregateInput>;
  _count?: InputMaybe<VerticalCountOrderByAggregateInput>;
  _max?: InputMaybe<VerticalMaxOrderByAggregateInput>;
  _min?: InputMaybe<VerticalMinOrderByAggregateInput>;
  _sum?: InputMaybe<VerticalSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerticalOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customLink?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  icon?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  interestTypes?: InputMaybe<InterestTypeOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerticalPodcastWhereInput = {
  AND?: InputMaybe<Array<PodcastWhereInput>>;
  NOT?: InputMaybe<Array<PodcastWhereInput>>;
  OR?: InputMaybe<Array<PodcastWhereInput>>;
  channel?: InputMaybe<ChannelRelationFilter>;
  channelId?: InputMaybe<IntNullableFilter>;
  companyId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  file?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  podcastTags?: InputMaybe<PodcastTagListRelationFilter>;
  podcastTeachers?: InputMaybe<PodcastTeacherListRelationFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  totalView?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  userBookmarks?: InputMaybe<UserBookmarkListRelationFilter>;
  userViewHistories?: InputMaybe<UserViewHistoryListRelationFilter>;
  verticalId: Scalars['Int'];
};

export type VerticalRelationFilter = {
  is?: InputMaybe<VerticalWhereInput>;
  isNot?: InputMaybe<VerticalWhereInput>;
};

export enum VerticalScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomLink = 'customLink',
  Description = 'description',
  Icon = 'icon',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Slug = 'slug',
  SortValue = 'sortValue',
  Status = 'status',
  UpdatedAt = 'updatedAt',
}

export type VerticalScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VerticalScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VerticalScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VerticalScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  customLink?: InputMaybe<StringNullableWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  icon?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  slug?: InputMaybe<StringWithAggregatesFilter>;
  sortValue?: InputMaybe<IntWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
};

export type VerticalSumAggregate = {
  __typename?: 'VerticalSumAggregate';
  id?: Maybe<Scalars['Int']>;
  sortValue?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
};

export type VerticalSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  sortValue?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type VerticalUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  interestTypes?: InputMaybe<InterestTypeUpdateManyWithoutVerticalNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalUpdateOneWithoutInterestTypesNestedInput = {
  connect?: InputMaybe<VerticalWhereUniqueInput>;
  connectOrCreate?: InputMaybe<VerticalCreateOrConnectWithoutInterestTypesInput>;
  create?: InputMaybe<VerticalCreateWithoutInterestTypesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<VerticalUpdateWithoutInterestTypesInput>;
  upsert?: InputMaybe<VerticalUpsertWithoutInterestTypesInput>;
};

export type VerticalUpdateWithoutInterestTypesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  sortValue?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerticalUpsertWithoutInterestTypesInput = {
  create: VerticalCreateWithoutInterestTypesInput;
  update: VerticalUpdateWithoutInterestTypesInput;
};

export type VerticalWhereInput = {
  AND?: InputMaybe<Array<VerticalWhereInput>>;
  NOT?: InputMaybe<Array<VerticalWhereInput>>;
  OR?: InputMaybe<Array<VerticalWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  customLink?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  icon?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  interestTypes?: InputMaybe<InterestTypeListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  sortValue?: InputMaybe<IntFilter>;
  status?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type VerticalWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type VideoRepo = {
  __typename?: 'VideoRepo';
  channel: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  videoPath: Scalars['String'];
  videoSource: Scalars['Int'];
};

export type VideoRepoAvgAggregate = {
  __typename?: 'VideoRepoAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  videoSource?: Maybe<Scalars['Float']>;
};

export type VideoRepoAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoCountAggregate = {
  __typename?: 'VideoRepoCountAggregate';
  _all: Scalars['Int'];
  channel: Scalars['Int'];
  createdAt: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  status: Scalars['Int'];
  title: Scalars['Int'];
  updatedAt: Scalars['Int'];
  videoPath: Scalars['Int'];
  videoSource: Scalars['Int'];
};

export type VideoRepoCountOrderByAggregateInput = {
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoPath?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoCreateInput = {
  channel: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoPath: Scalars['String'];
  videoSource?: InputMaybe<Scalars['Int']>;
};

export type VideoRepoCreateManyInput = {
  channel: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoPath: Scalars['String'];
  videoSource?: InputMaybe<Scalars['Int']>;
};

export type VideoRepoGroupBy = {
  __typename?: 'VideoRepoGroupBy';
  _avg?: Maybe<VideoRepoAvgAggregate>;
  _count?: Maybe<VideoRepoCountAggregate>;
  _max?: Maybe<VideoRepoMaxAggregate>;
  _min?: Maybe<VideoRepoMinAggregate>;
  _sum?: Maybe<VideoRepoSumAggregate>;
  channel: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  image?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  videoPath: Scalars['String'];
  videoSource: Scalars['Int'];
};

export type VideoRepoMaxAggregate = {
  __typename?: 'VideoRepoMaxAggregate';
  channel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoPath?: Maybe<Scalars['String']>;
  videoSource?: Maybe<Scalars['Int']>;
};

export type VideoRepoMaxOrderByAggregateInput = {
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoPath?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoMinAggregate = {
  __typename?: 'VideoRepoMinAggregate';
  channel?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videoPath?: Maybe<Scalars['String']>;
  videoSource?: Maybe<Scalars['Int']>;
};

export type VideoRepoMinOrderByAggregateInput = {
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoPath?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoOrderByWithAggregationInput = {
  _avg?: InputMaybe<VideoRepoAvgOrderByAggregateInput>;
  _count?: InputMaybe<VideoRepoCountOrderByAggregateInput>;
  _max?: InputMaybe<VideoRepoMaxOrderByAggregateInput>;
  _min?: InputMaybe<VideoRepoMinOrderByAggregateInput>;
  _sum?: InputMaybe<VideoRepoSumOrderByAggregateInput>;
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoPath?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoOrderByWithRelationInput = {
  channel?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoPath?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export enum VideoRepoScalarFieldEnum {
  Channel = 'channel',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Image = 'image',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  VideoPath = 'videoPath',
  VideoSource = 'videoSource',
}

export type VideoRepoScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VideoRepoScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VideoRepoScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VideoRepoScalarWhereWithAggregatesInput>>;
  channel?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  status?: InputMaybe<IntWithAggregatesFilter>;
  title?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  videoPath?: InputMaybe<StringWithAggregatesFilter>;
  videoSource?: InputMaybe<IntWithAggregatesFilter>;
};

export type VideoRepoSumAggregate = {
  __typename?: 'VideoRepoSumAggregate';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  videoSource?: Maybe<Scalars['Int']>;
};

export type VideoRepoSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  videoSource?: InputMaybe<SortOrder>;
};

export type VideoRepoUpdateInput = {
  channel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoPath?: InputMaybe<Scalars['String']>;
  videoSource?: InputMaybe<Scalars['Int']>;
};

export type VideoRepoUpdateManyMutationInput = {
  channel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoPath?: InputMaybe<Scalars['String']>;
  videoSource?: InputMaybe<Scalars['Int']>;
};

export type VideoRepoWhereInput = {
  AND?: InputMaybe<Array<VideoRepoWhereInput>>;
  NOT?: InputMaybe<Array<VideoRepoWhereInput>>;
  OR?: InputMaybe<Array<VideoRepoWhereInput>>;
  channel?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoPath?: InputMaybe<StringFilter>;
  videoSource?: InputMaybe<IntFilter>;
};

export type VideoRepoWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export enum ViewTargetType {
  Class = 'Class',
  Library = 'Library',
  LiveClass = 'LiveClass',
  Podcast = 'Podcast',
}
