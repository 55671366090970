import {
  IResourceComponentsProps,
  HttpError,
  CrudFilters,
  useCustom,
  LogicalFilter,
  CrudFilter,
} from '@pankod/refine-core';
import {
  List,
  Table,
  useTable,
  Row,
  Col,
  Space,
  ShowButton,
  DateField,
  FormProps,
  Form,
  Input,
  Button,
  Icons,
  Card,
  Typography,
  DatePicker,
} from '@pankod/refine-antd';
import { PAGINATION_FIELDS } from 'common/constant';
import { IUserIncentive, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { Incentive, IncentiveType } from 'api';
import { formatMoney } from 'common/formatter';
import dayjs from 'dayjs';

const { Title, Text } = Typography;

export const UserIncentiveList: React.FC<IResourceComponentsProps> = () => {
  const initialDateFilter: LogicalFilter = {
    field: 'createdAt',
    operator: 'between',
    value: [dayjs().startOf('month'), dayjs().endOf('month')],
  };

  const { tableProps, searchFormProps, filters } = useTable<IUserIncentive, HttpError, { dates: string[] }>({
    dataProviderName: 'gql',
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyIncentives',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialFilter: [initialDateFilter],
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];

      const { dates } = params;

      filters.push({
        field: 'createdAt',
        operator: 'between',
        value: dates,
      });

      return filters;
    },
  });

  searchFormProps.initialValues = {
    dates: initialDateFilter.value,
  };

  let queryResult = useCustom({
    url: '',
    method: 'get',
    dataProviderName: 'gql',
    metaData: {
      operation: 'getMyUserIncentiveRevenueSummary',
      fields: ['type', 'name', 'currency', 'amount'],
      variables: {
        where: {
          value: {
            createdAt: {
              gte: [filters.find((o) => (o as LogicalFilter).field === 'createdAt')?.value[0] || ''],
              lte: [filters.find((o) => (o as LogicalFilter).field === 'createdAt')?.value[1] || ''],
            },
          },
          type: 'UserIncentiveWhereInput',
        },
      },
    },
  });

  const summary = queryResult?.data?.data;

  return (
    <>
      <Row gutter={[16, 16]}>
        {summary?.map((item, index) => {
          return (
            <Col span={6} key={`${index}summary`}>
              <Card style={{ backgroundColor: '#3d335b', height: 130 }}>
                <Text style={{ color: 'white' }} strong>
                  Total {item.name?.toLowerCase()}: <br />
                </Text>
                <Text style={{ color: 'white' }}>{formatMoney(item?.currency, item.amount)}</Text>
              </Card>
            </Col>
          );
        })}

        <Col xl={24} lg={24} xs={24}>
          <Filter formProps={searchFormProps} />
        </Col>

        <Col span={24}>
          <List>
            <Table {...tableProps} rowKey="id">
              <Table.Column<Incentive> key="refereeId" dataIndex="refereeId" title="Referee Id" />
              <Table.Column<Incentive> key="description" dataIndex="description" title="Description" />
              <Table.Column<Incentive>
                key="amount"
                dataIndex="amount"
                title="Amount"
                render={(value, record) =>
                  record.type === IncentiveType.Cash ? `${record.currency} ${value}` : `${value} Points`
                }
              />
              <Table.Column
                title="Created At"
                dataIndex="createdAt"
                key="createdAt"
                render={(value) => <DateField value={value} format="LL" />}
              />
              <Table.Column<Incentive>
                key="status"
                dataIndex="status"
                title="Status"
                render={(_, record) => {
                  const status = ['Pending', 'Approved', 'Rejected'];
                  return status[record.status];
                }}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps }> = (props) => {
  const { formProps } = props;

  const dates = Form.useWatch('dates', formProps.form);

  return (
    <Form layout="vertical" {...props.formProps}>
      <Row gutter={[10, 0]} align="bottom">
        <Col span={6}>
          <Form.Item label={'Search'} name="dates">
            <DatePicker.RangePicker style={{ width: '100%' }} allowClear={true} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button style={{ width: '100%' }} htmlType="submit" type="primary" disabled={!dates}>
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
