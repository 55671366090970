import React from 'react';
import { useRouterContext, TitleProps } from '@pankod/refine-core';
import { Icons } from '@pankod/refine-antd';

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const { Link } = useRouterContext();

  return (
    <Link to="/">
      {collapsed ? (
        <img
          src={'./images/logo.png'}
          alt="Reskills"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 24px',
          }}
        />
      ) : (
        <img
          src={'./images/logo-partner.png'}
          alt="Refine"
          style={{
            width: '180px',
            padding: '12px 24px',
          }}
        />
      )}
    </Link>
  );
};
