import _routerProvider from '@pankod/refine-react-router-v6';

export const routerProvider = {
  ..._routerProvider,
  routes: [
    // custom page
    // {
    //   element: <CustomPage />,
    //   path: '/custom-page',
    //   layout: true,
    // },
  ],
};
