import { CrudFilters, CrudSorting, DataProvider, LogicalFilter } from '@pankod/refine-core';
import { GraphQLClient } from '@pankod/refine-graphql';
import * as gql from 'gql-query-builder';
import { camelCase, startCase } from 'lodash';
import pluralize from 'pluralize';

const pascalCase = (str: string) => startCase(camelCase(str)).replace(/ /g, '');

const prismaStringFilters = {
  eq: 'equals',
  lt: 'lt',
  gt: 'gt',
  lte: 'lte',
  gte: 'gte',
  in: 'in',
  nin: 'notIn',
  contains: 'contains',
  ne: false,
  ncontains: false,
  containss: false,
  ncontainss: false,
  null: false,
  between: 'between',
};

const prismaInputType = {
  paginate: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findUnique: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findFirst: (name: string) => pascalCase(`${name}WhereInput`),
  findMany: (name: string) => pascalCase(`${name}WhereInput`),
  create: (name: string) => pascalCase(`${name}CreateInput`),
  createMany: (name: string) => pascalCase(`${name}CreateManyInput`),
  update: (name: string) => pascalCase(`${name}UpdateInput`),
  updateMany: (name: string) => pascalCase(`${name}UpdateManyInput`),
  orderBy: (name: string) => pascalCase(`${name}OrderByWithRelationInput`),
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortQuery = sort.map((i) => {
      return {
        [i.field]: i.order,
      };
    });

    return sortQuery;
  }

  return [];
};

const generateFilter = (filters?: CrudFilters) => {
  let queryFilters: { [key: string]: any }[] = [];

  if (filters && filters.length > 0) {
    (filters as LogicalFilter[]).map((filter) => {
      const prismaOperator = prismaStringFilters[filter.operator];

      if (prismaOperator === 'between') {
        queryFilters.push({
          [filter.field]: {
            [prismaStringFilters.gte]: filter.value[0],
            [prismaStringFilters.lte]: filter.value[1],
          },
        });
        return;
      }

      queryFilters.push({
        [filter.field]: { [prismaOperator]: filter.value },
      });
    });
  }

  return queryFilters;
};

interface PrismaDataProvider extends DataProvider {}

export const gqlDataProvider = (client: GraphQLClient): PrismaDataProvider => {
  return {
    getList: async ({ resource, pagination, sort, filters, metaData }) => {
      const orderBy = generateSort(sort);
      const filterBy = generateFilter(filters);
      const operation = metaData?.operation || camelCase(`list_${resource}`);
      const singularResource = pluralize.singular(resource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          page: pagination?.current || 1,
          perPage: pagination?.pageSize || 10,
          ...(orderBy.length > 0 && {
            orderBy: {
              value: orderBy,
              type: `[${prismaInputType.orderBy(singularResource)}!]`,
            },
          }),
          ...(filterBy.length > 0 && {
            where: {
              value: { OR: filterBy },
              type: prismaInputType.findMany(singularResource),
            },
          }),
        },
        fields: [
          {
            items: metaData?.items,
            metadata: metaData?.metadata,
          },
        ],
      });

      const response = await client.request(query, variables, {});

      return {
        data: response[operation].items || [],
        total: response[operation].metadata?.totalCount || 0,
      };
    },

    getMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(resource);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id: { in: ids } },
            type: metaData?.operationType || prismaInputType.findMany(singularResource),
          },
        },
        fields: metaData?.fields || [],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(singularResource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields,
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    create: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`create_one_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.create(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    createMany: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`create_many_${singularResource}`);

      const response = await Promise.all(
        variables.map(async (param) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { data: param },
                type: prismaInputType.createMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        }),
      );
      return {
        data: response,
      };
    },

    update: async ({ resource, id, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`update_one_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.update(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelUpdateName = camelCase(`update-${singularResource}`);

      const operation = metaData?.operation ?? camelUpdateName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id }, data: variables },
                type: prismaInputType.updateMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await client.request(query, gqlVariables);

          return result[operation];
        }),
      );
      return {
        data: response,
      };
    },

    deleteOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_one_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_many_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: {
              id: { in: ids },
            },
            type: prismaInputType.findMany(singularResource),
            required: true,
          },
        },
        fields: ['count'],
      });

      const response = await client.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.');
    },
    custom: async ({ url, method, headers, metaData }) => {
      let gqlClient = client;

      if (url) {
        gqlClient = new GraphQLClient(url, { headers });
      }

      if (metaData) {
        if (metaData.operation) {
          if (method === 'get') {
            const { query, variables } = gql.query({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          } else {
            const { query, variables } = gql.mutation({
              operation: metaData.operation,
              fields: metaData.fields,
              variables: metaData.variables,
            });

            const response = await gqlClient.request(query, variables);

            return {
              data: response[metaData.operation],
            };
          }
        } else {
          throw Error('GraphQL operation name required.');
        }
      } else {
        throw Error('GraphQL need to operation, fields and variables values in metaData object.');
      }
    },
  };
};
