import { User } from 'api';

export const RESOURCE_NAME = 'users';

export const RESOURCE_FIELDS = [
  'id',
  'username',
  'firstName',
  'lastName',
  'dateOfBirth',
  'email',
  'phone',
  'emailVerifiedAt',
  'phoneVerifiedAt',
  'subscribedAt',
  'expiredAt',
  'lastLoginAt',
  'createdAt',
  'updatedAt',
  'status',
] as const;

export type IUser = Pick<User, typeof RESOURCE_FIELDS[number]>;

export interface UserFilterVariables {
  q: string;
}
