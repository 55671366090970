// import { CSSProperties } from 'react';

// export const antLayoutSider: CSSProperties = {
//   position: 'relative',
//   backgroundColor: 'black',
// };
// export const antLayoutSiderMobile: CSSProperties = {
//   position: 'fixed',
//   height: '100vh',
//   zIndex: 999,
//   backgroundColor: 'black',
// };

// export const antLayoutMenuSelected: CSSProperties = {
//   fontSize: 'bold',
//   backgroundColor: '#fa3532',
// };
// export const antLayoutMenuNotSelected: CSSProperties = {
//   fontSize: 'light',
// };

import { CSSProperties } from 'react';

export const antLayoutSider: CSSProperties = {
  position: 'relative',
};
export const antLayoutSiderMobile: CSSProperties = {
  position: 'fixed',
  height: '100vh',
  zIndex: 999,
};
