import { User, UserIncentive } from 'api';

export const RESOURCE_NAME = 'user-incentives';

export const RESOURCE_FIELDS = [
  'id',
  'userId',
  'refereeId',
  'incentiveId',
  'description',
  'reference',
  'type',
  'currency',
  'amount',
  'status',
  'paymentStatus',
  'remarks',
  'createdAt',
  'updatedAt',
  'status',
] as const;

export type IUserIncentive = Pick<UserIncentive, typeof RESOURCE_FIELDS[number]>;
