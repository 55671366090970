import { Refine } from '@pankod/refine-core';
import { notificationProvider, ReadyPage, ErrorComponent, Layout } from '@pankod/refine-antd';
import { routerProvider } from 'providers/routeProvider';
import { gqlDataProvider } from 'providers/gqlDataProvider';
import { restDataProvider } from 'providers/restDataProvider';
import { authProvider as baseAuthProvider } from 'providers/authProvider';
import { Header, Title, Sider, Footer, OffLayoutArea } from 'components/layout';
import { GraphQLClient } from '@pankod/refine-graphql';
import { resourceRoutes } from 'resources/route';
//
// Style
//
import 'styles/antd.less';
import { useTranslation } from 'react-i18next';
import { LoginPage } from 'resources/login';
import { DashboardPage } from 'resources/dashboard';

//
// Config
//
const reactQueryOptions = {
  defaultOptions: {
    mutations: {
      retry: 0,
    },
    queries: {
      retry: 0,
    },
  },
};

//
// Provider
//
export const gqlClient = new GraphQLClient((process.env.REACT_APP_API_URL as string) + '/graphql');
export const dataProvider = gqlDataProvider(gqlClient);
export const authProvider = baseAuthProvider(gqlClient);
const rest = restDataProvider(process.env.REACT_APP_API_URL + '/api');

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      // default layout
      Title={Title}
      Header={Header}
      Layout={Layout}
      Sider={Sider}
      // Footer={Footer}
      // OffLayoutArea={OffLayoutArea}
      //
      // default pages
      //
      ReadyPage={ReadyPage}
      LoginPage={LoginPage}
      DashboardPage={DashboardPage}
      //
      //
      catchAll={<ErrorComponent />}
      reactQueryClientConfig={reactQueryOptions}
      //
      // providers
      //
      resources={[...resourceRoutes]}
      authProvider={authProvider}
      dataProvider={{
        default: dataProvider,
        gql: dataProvider,
        rest,
      }}
      routerProvider={{
        ...routerProvider,
      }}
      notificationProvider={notificationProvider}
      i18nProvider={i18nProvider}
    />
  );
}

export default App;
