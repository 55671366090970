import { RefereeList } from './referee';
import { RefereeShow } from './referee/show';
import { UserIncentiveList } from './user-incentive';

export const resourceRoutes = [
  {
    key: 'referee',
    name: 'referee',
    options: {
      label: 'Referee',
    },
    list: RefereeList,
    show: RefereeShow,
  },
  {
    key: 'user-incentive',
    name: 'user-incentive',
    options: {
      label: 'User Incentive',
    },
    list: UserIncentiveList,
  },
];
